import React, { useState } from 'react'
import { Grid, Button, Typography, useMediaQuery, Dialog } from '@mui/material';
import ChromeIcon from "../../assests/Chrome.svg"
import './ChatbotPricingCustomeSection.css'
import "../ChatbotGradient/ChatbotGradient.css";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import rightmark from '../../assests/ChatbotPricing/cpc-checkmark-icon.svg'
import BookAMeetingDialog from '../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog';

const ChatbotPricingCustomeSection = () => {
    const [paraphraserPoints, setParaphraserPoints] = useState(
        [
            {
                index: 1,
                label: "Product Customization",
                icon: rightmark
            },
            {
                index: 2,
                label: "Custom Feature Development",
                icon: rightmark,
            },
            {
                index: 3,
                label: "On-Premise Installation",
                icon: rightmark,
            },
            {
                index: 4,
                label: "Custom Integrations",
                icon: rightmark
            },
            {
                index: 5,
                label: "Product Customization",
                icon: rightmark
            },
            {
                index: 6,
                label: "Custom Integrations",
                icon: rightmark
            },
        ]
    )

    const laptopWidth = useMediaQuery('(min-width: 1536px) and (max-width: 1852px)')
    const tabWidth = useMediaQuery('(min-width: 600px) and (max-width: 780px)')
    const mobileWidth = useMediaQuery('(min-width: 0px) and (max-width: 599px)')
    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);


    return (
        <>
            {flagOpenBookaMeetingDemo && (
                <Dialog open={flagOpenBookaMeetingDemo}
                    maxWidth='lg'
                    sx={{ '&.MuiPaper-root': { width: '100%' } }}
                    className="iframe-dialog"
                >
                    <BookAMeetingDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    />
                </Dialog>
            )}

            <Grid container>
                <Grid item xl={laptopWidth ? 8.2 : 7.5} lg={11} md={11} sm={11} xs={11} className='cpc-gradiant-item'>
                    <Grid container className='cpc-main-container'>
                        <Grid item xs={12}>
                            <Grid container sx={{ justifyContent: 'space-between' }}>
                                <Grid item xl={5} lg={5} md={5.5} sm={tabWidth ? 12 : 5.5} xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <span className='cpc-main-head-section'>Looking for Enterprise, Custom or On-Premise Chatbot?</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xl={6.5} lg={6.8} md={5.5} sm={tabWidth ? 12 : 5.5} xs={12} className='cpc-right-main-item'>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ margin: tabWidth ? '20px 0px 0px' : mobileWidth ? '40px 0px 0px' : '' }}>
                                            <span className='cpc-right-head-text'>We provide custom solution&nbsp;<ArrowForwardIcon style={{ verticalAlign: 'middle' }} /></span>
                                        </Grid>
                                        <Grid item xs={12} sx={{ margin: '15px 0px' }}>
                                            <Grid container className='cpc-points-main-container'>
                                                {paraphraserPoints.map((points) => {
                                                    return (
                                                        <>
                                                            <Grid item className='cpc-points-list-main-item'>
                                                                <Grid container className='cpc-points-list-main-container'>
                                                                    <Grid item className='cpc-points-part1'>
                                                                        <Grid container sx={{ justifyContent: 'center', alignItem: 'center' }}>
                                                                            <img src={points.icon} alt="" className='cpc-points-icon' />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item className='cpc-points-part2'>
                                                                        <Typography variant='span'>
                                                                            {points.label}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ margin: '20px auto 0px' }}>
                            <Button
                                variant='contained'
                                fullWidth
                                size='large'
                                className='cpc-book-meeting-btn'
                                onClick={() => {
                                    setFlagOpenBookaMeetingDemo(true)
                                }}
                            >
                                Book a Meeting
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotPricingCustomeSection