import React, { useState } from 'react';
import "../../Component/NewScrollElement/NewScrollElement.css";
import { Grid, Typography, useMediaQuery } from "@mui/material";

import "./Chatbot.css";
import '../../Pages/Paraphraser/Paraphraser.css'


import LandingFooter from '../../Component/Footer/LandingFooter';
import Navbar from '../Navbar/Navbar';
import ChatbotHero from '../../Component/ChatbotHero/ChatbotHero';
import ChatbotExplore from "../../Component/ChatbotExplore/ChatbotExplore";
import ChatbotExploreMobile from "../../Component/ChatbotExplore/ChatbotExploreMobile"
import ChatbotGradient from '../../Component/ChatbotGradient/ChatbotGradient';
import ChatbotFeatures from '../../Component/ChatbotFeatures/ChatbotFeatures';
import ChatbotStats from '../../Component/ChatbotStats/ChatbotStats';
import ChatbotWorkFlow from '../../Component/ChatbotWorkFlow/ChatbotWorkFlow';
import ChatbotExperience from '../../Component/ChatbotExperience/ChatbotExperience';
import ChatbotCardItems from '../../Component/ChatbotCardItems/ChatbotCardItems';
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter';
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs';


const Chatbot = () => {
  // const [isBelow1200, setIsBelow1000] = useState(window.innerWidth < 1200);
  const isBelow1200 = useMediaQuery('(min-width: 0px) and (max-width: 1200px)')
  const [pageName, setPageName] = useState('Chatbot');

  return (
    <Grid container>
      <Navbar />
      <Grid item xs={12}>
        <Grid container>

          <Grid item xs={12} className='brsw-component-main-item'>
            <ChatbotHero />
          </Grid>

          <Grid item xs={12} >
            <ChatbotExperience />
          </Grid>

          <Grid item xs={12} className='brsw-component-main-item'>
            {isBelow1200 ? <ChatbotExploreMobile /> : <ChatbotExplore />}
          </Grid>

          <Grid item xs={12} className='CB-chatBotFeatures'>
            <ChatbotFeatures />
          </Grid>

          <Grid item xs={12} className='CB-chatBotStats'>
            <ChatbotStats />
          </Grid>

          <Grid item xs={12} className='CB-chatBotStats'>
            <ChatbotWorkFlow />
          </Grid>

          <Grid item xs={12} className='brsw-component-main-item'>
            <ChatbotCardItems />
          </Grid>

          <Grid item xs={12} className='brsw-component-main-item'>
            <ChatbotGradient />
          </Grid>

          <Grid item xs={12} className='chatbot-faq-section'>
            <ChatbotFAQs />
          </Grid>

          <Grid item xs={12} className='pr-overlap-item'>
            <ParaphraseOverlapFooter pageName={pageName} />
          </Grid>

          <Grid item xs={12} className='brsw-footer-component-main-item'>
            <LandingFooter />
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}

export default Chatbot