import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import Navbar from "../Navbar/Navbar";
import "./GitexInvite.css";
import GitexRedirectingPopup from "../../Component/GitexRedirectingPopUp/GitexRedirectingPopup";
import { Context as mainContext } from '../../Context/MainContext/GlobalContext'


const GitexInvite = () => {
  const intialObj = {
    visitor_name: "",
    visitor_email: "",
    company_name: "",
    contact_number: "",
    country: "",
  };
  const { setLoader } = useContext(mainContext)

  const [openPopup, setOpenPopup] = useState(false);

  const handleClosePopup = () => {
    setOpenPopup(false);
  }

  const [userInfo, setUserInfo] = useState(intialObj);
  const [formSubmitted, setFormSubmitted] = useState({
    message: "",
    status: false,
    type: "",
  });

  const emptyErrorObj = {
    flagShowError: false,
    message: "",
  };

  const emptySuccessObj = {
    flagShowSuccess: false,
    message: "",
  };

  const [error, setError] = useState(emptyErrorObj);
  const [flagEmailValid, setFlagEmailValid] = useState(true);
  const [flagContactValid, setFlagContactValid] = useState(true);

  function isValidEmail(email) {
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  }

  function isValidContact(phoneNumber) {
    const phoneRegex = /^\d+$/;
    return phoneRegex.test(phoneNumber);
  }

  const submitForm = async (e) => {
    e.preventDefault();

    setLoader(true)

    if (
      userInfo.visitor_name == "" ||
      userInfo.visitor_email == "" ||
      !isValidEmail(userInfo.visitor_email) ||
      userInfo.company_name == "" ||
      userInfo.contact_number == "" ||
      !isValidContact(userInfo.contact_number) ||
      userInfo.country == ""
    ) {
      setFormSubmitted({
        message: "Please Enter Valid Information",
        status: true,
        type: "error",
      });
      setLoader(false);
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_CALL_URL}/visitor/add-visitor-detail`;
      const data = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userInfo),
      };

      const response = await fetch(url, data);

      if (response.status) {
        setUserInfo(intialObj);
        setFormSubmitted({
          message: "Form Submitted Successfully",
          status: true,
          type: "success",
        });

        setOpenPopup(true);
      }
    } catch (error) {
      console.log("Error", error);
    }

    setLoader(false);
  };

  return (
    <>
      <Navbar className="gitex-navbar" />
      {/* <div className='gitex-navbar-content-divider'></div> */}
      <Grid container direction={"column"} className="gx-main-container">
        <Grid item className="gx-white-section-container">
          <Box className="gx-white-section">
            <span className="gx-small-heading">Meet Us</span>
            <h1 className="gx-main-heading">GITEX Global, Dubai</h1>
            <p className="gx-main-sub-text">
              The largest & most inclusive tech show in the digital economy.
              Whether you’re a budding blogger, a content strategist, or a
              novelist in the making, our platform is set to transform the way
              you write and think.
            </p>
          </Box>
        </Grid>
        <Grid item className="gx-colored-section-container">
          <Grid container className="gx-cards-wrapper">
            <Grid
              item
              className="gx-join-event-card"
              // xs="12" lg="5.5"
              xs={12}
              lg={5.5}
            >
              <span className="gx-small-heading">Event Overview</span>
              <Typography variant="h5" className="gx-card-heading">
                Join Us at the GITEX Event in Dubai!
              </Typography>
              <p className="gx-card-description">
                Thrilled to announce that Writewiz AI will be showcasing how AI
                could revolutionize your writing game! 🚀
              </p>
              <p className="gx-card-description">
                Our team is geared up to present cutting-edge solutions that are
                set to redefine the future of technology. <br />
                <br />
                Join us at Writewiz AI
                <br />
                📍 Hall No. 9 <br />
                📍 Stand No. H9-25 for an immersive experience!
                <br />
                <br />
                See you at #GITEXDubai! 🌐
              </p>
            </Grid>

            <Grid item xs={12} lg={6} className="gi-form-main-item">
              <Grid container>
                <Grid item xs={12} sx={{ margin: "0 auto" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="span" className="gx-small-heading">
                        {" "}
                        GITEX VISITOR PASS 2023{" "}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} className="gi-sub-head-item">
                      <Typography variant="span" className="gi-sub-head">
                        Get Free Visitor Pass for Gitex
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="gi-global-form-fields-item">
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          className="gi-form-global-label-item"
                        >
                          <Typography
                            variant="span"
                            className="gi-form-global-label"
                          >
                            Name{" "}
                            <Typography variant="span" sx={{ color: "#F00" }}>
                              *
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            size="small"
                            type="text"
                            placeholder="Name"
                            required
                            fullWidth
                            className="gi-form-textfields"
                            InputProps={{
                              inputProps: { style: { borderColor: 'green !important' } },
                            }}
                            value={userInfo?.visitor_name}
                            onChange={(e) =>
                              setUserInfo({
                                ...userInfo,
                                visitor_name: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className="gi-global-form-fields-item">
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          className="gi-form-global-label-item"
                        >
                          <Typography
                            variant="span"
                            className="gi-form-global-label"
                          >
                            Email{" "}
                            <Typography variant="span" sx={{ color: "#F00" }}>
                              *
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            placeholder="Email"
                            size="small"
                            type="email"
                            required
                            fullWidth
                            className="gi-form-textfields"
                            value={userInfo?.visitor_email}
                            onChange={(e) => {
                              setUserInfo({
                                ...userInfo,
                                visitor_email: e.target.value,
                              });
                              setError(emptyErrorObj);
                              setFlagEmailValid(true);
                            }}
                            onBlur={() => {
                              setFlagEmailValid(isValidEmail(userInfo.visitor_email));
                            }}
                            error={!flagEmailValid}
                            helperText={
                              !flagEmailValid && "Please enter a valid Email Address"
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className="gi-global-form-fields-item">
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          className="gi-form-global-label-item"
                        >
                          <Typography
                            variant="span"
                            className="gi-form-global-label"
                          >
                            Company Name{" "}
                            <Typography variant="span" sx={{ color: "#F00" }}>
                              *
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            placeholder="Company Name"
                            size="small"
                            type="text"
                            required
                            fullWidth
                            className="gi-form-textfields"
                            value={userInfo?.company_name}
                            onChange={(e) =>
                              setUserInfo({
                                ...userInfo,
                                company_name: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className="gi-global-form-fields-item">
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          className="gi-form-global-label-item"
                        >
                          <Typography
                            variant="span"
                            className="gi-form-global-label"
                          >
                            Contact Number{" "}
                            <Typography variant="span" sx={{ color: "#F00" }}>
                              *
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            placeholder="Contact Number"
                            size="small"
                            type="email"
                            required
                            fullWidth
                            className="gi-form-textfields"
                            value={userInfo?.contact_number}
                            onChange={(e) => {
                              setUserInfo({
                                ...userInfo,
                                contact_number: e.target.value,
                              });
                              setError(emptyErrorObj);
                              setFlagContactValid(true);
                            }}
                            onBlur={() => {
                              setFlagContactValid(isValidContact(userInfo.contact_number));
                            }}
                            error={!flagContactValid}
                            helperText={
                              !flagContactValid && "Please enter a valid Contact Number"
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className="gi-global-form-fields-item">
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          className="gi-form-global-label-item"
                        >
                          <Typography
                            variant="span"
                            className="gi-form-global-label"
                          >
                            Country / Region{" "}
                            <Typography variant="span" sx={{ color: "#F00" }}>
                              *
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            placeholder="Country / Region"
                            size="small"
                            type="text"
                            fullWidth
                            required
                            className="gi-form-textfields"
                            value={userInfo?.country}
                            onChange={(e) =>
                              setUserInfo({
                                ...userInfo,
                                country: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ margin: "10px 0px" }}>
                      <Grid container>
                        <Button
                          className="gi-submit-btn"
                          variant="contained"
                          size="medium"
                          onClick={submitForm}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item className='gx-form-card-container' xs="12" lg="6">
                        <span className='gx-small-heading'>DUBAI</span>
                        <Typography variant='h5' className='gx-card-heading'>Get Free Visitor Pass for Gitex</Typography>
                        <Box className="">
                            <form>
                                Form
                            </form>
                        </Box>
                    </Grid> */}
          </Grid>
        </Grid>
      </Grid>

      <GitexRedirectingPopup open={openPopup} handleClose={handleClosePopup} />
    </>
  );
};

export default GitexInvite;
