import React, { useRef, useEffect, useState } from "react";
import "./Scroll.css";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

function App() {
  const containerRef = useRef(null);
  const [focusedDiv, setFocusedDiv] = useState(null);

  // useEffect(() => {
  //   const container = containerRef.current;

  //   // document.addEventListener("scroll", (e) => {
  //   //   console.log("insile function", e.target);
  //   // });
  //   let observer = new IntersectionObserver(
  //     function (entries) {
  //       try {
  //         var ele = entries.filter((entry) => entry.isIntersecting);
  //         // if (ele.length > 0) {
  //         ele = ele[0].target;
  //         console.log("Visible element is now: " + ele);
  //         // ;
  //         setTimeout(() => {
  //           ele.scrollIntoView({ behavior: "smooth" });
  //         }, 100);
  //         // }
  //       } catch (err) {
  //         console.log("++++++++++", err);
  //       }
  //     },
  //     {
  //       rootMargin: "-100px 0px", // Add buffering space of 100px at top and bottom
  //       threshold: 0.1 // Trigger callback when 1% of the element is visible
  //     }
  //   );
  //   document.querySelectorAll(".page").forEach((ele) => observer.observe(ele));
  //   // observer.observe(document.getElementById("container"));

  //   return () => {
  //     // container.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    const container = containerRef.current;

    gsap.to(container, {
      y: () => {
        const windowHeight = window.innerHeight;
        const containerHeight = container.clientHeight;
        return -containerHeight + windowHeight; // Scroll until top part of container reaches bottom of screen
      },
      ease: "power2.inOut",
      
      scrollTrigger: {
        trigger: container,
        start: "top bottom", // Start the animation when top part of container reaches bottom of the screen
        markers: true,
        end: "bottom top",
        scrub: true,
      }
    });
  }, []);


  return (
    <div>
      <div>
        <div className="one">
          <div>One</div>
        </div>
        <div className="two">
          <div>Two</div>
        </div>

        <div ref={containerRef} id="container">
          <div className="first-page page" id="first-page">
            <div>One</div>
          </div>
          <div className="second-page page" id="second-page">
            <div>Two</div>
          </div>
          <div className="third-page page" id="third-page">
            <div>Three</div>
          </div>
          <div className="fourth-page page" id="fourth-page">
            <div>Four</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
