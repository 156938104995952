import React from 'react'
import { Grid } from "@mui/material";
import "./ChatbotFeaturesBox.css";

const ChatbotFeaturesBox = (props) => {
  const { logoImgLink, title, description, subTitle, mainImg } = props;

  return (
    <Grid container className="CFB-mainOuterContainer">
      <Grid xs={12}>
        <img src={mainImg} alt="Feature Image" className='CFB-FeatureImage' />
      </Grid>
      <Grid xs={12} className="CFB-titleContainer">
        <img src={logoImgLink} className='CFB-logo-img' alt="Logo Image" />
        <h3 className="CFB-HeaderText">{title}</h3>
      </Grid>
      <Grid xs={12}>
        <h3 className={'CFB-subTitleText'}>{subTitle}</h3>
        <p className='CFB-description'>{description}</p>
      </Grid>
    </Grid>
  )
}

export default ChatbotFeaturesBox