import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid } from '@mui/material'
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Pricing.css'
import './PricingFAQ.css'

const PricingFAQ = () => {

    const [expanded, setExpanded] = React.useState([]);
    const faqData = [

        {
            question: "What payment methods do you accept?",
            answer:
                "We partner with Stripe to ensure a secure payment experience. You can use any major credit card, including Visa, Mastercard, and American Express.",
        },
        {
            question: "When do I get new credits each month?",
            answer:
                "Your credits refresh will happen on the date that you subscribed to the plan, or if it falls on the last day of the month, your credits will refresh on the last day of each subsequent month. Unused credits do not roll over for monthly plans as well as for yearly plans, but for annual plans, all credits will be given on monthly basis.\n For example, in the Premium plan, if you subscribed on the 31st of March and you've used up 1500 credits out of 2000 credits by 30th April, then your account will be reset to 2000 credits on 30th April."
        },
        {
            question: "I operate a small agency. Which subscription suits me best?",
            answer:
                "For those producing fewer than 50 documents monthly or serving a single client, the Starter plan might be a fit. The Premium plan, however, is favored by rapidly growing businesses and expanding agencies that create up to 200 documents monthly."
        },
        {
            question: "What is the duration of your contracts?",
            answer:
                "You have the flexibility to choose between monthly or annual subscription durations."
        },
        {
            question: "What happens if I modify my plan in the middle of a billing period?",
            answer:
                "Should you opt to upgrade or downgrade, your current plan remains active until the billing cycle concludes. There won't be a pro-rated amount for the ongoing subscription."
        },
        {
            question: "Can I modify or terminate my subscription whenever I want?",
            answer:
                "Definitely! You have the freedom to cancel, step up, or scale down your subscription at any given moment. Your satisfaction is paramount to us. Should you cancel, the plan remains active till the end of the billing period, and you can utilize any leftover credits for content generation."
        },
        {
            question: "How does the credit-based pricing function?",
            answer:
                "With our credit-based pricing, you receive a set amount of credits each month, which you can use for generating content.\n For instance, if your monthly subscription offers you 1000 credits, it enables you to produce over 500+ content pieces within the month. How you utilize these credits, be it for concise product descriptions, catchy social media captions, or comprehensive blogs, is your choice."
        },
    ];


    const handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded([...expanded, panel]);
        } else {
            setExpanded(expanded.filter(item => item !== panel));
        }
    };


    return (
        <>
            <Grid container >

                <Grid item xs={12} className='pp-plan-faq-head-item'>
                    <Grid container>
                        <Grid item xl={8} lg={8} md={8} sm={11} xs={11} className='pp-plan-faq-main-head-item' >
                            <Typography
                                component={'span'}
                                className='pp-plan-faq-head'
                            >Frequently Asked Questions</Typography>
                        </Grid>
                        <Grid item xl={8} lg={8} md={8} sm={11} xs={11} className='pp-plan-faq-subtitle-item'>
                            <Typography
                                component={'p'}
                                variant='subtitle2'
                                className='pp-plan-faq-subtitle'
                            >
                                {/* Lorem ipsum dolor sit amet consectetur. Molestie in odio ut diam. Tortor commodo vestibulum donec quis nisl turpis convallis.
                                Sed sitsuspendisse sapien vel ullamcorper molestie egestas. Volutpat dui sed ut arcu commodo. */}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className='pp-faq-accordion-item'>

                    {faqData.map((item, index) => (
                        <Accordion
                            key={index}
                            expanded={expanded.includes(`panel${index}`)}
                            onChange={handleChange(`panel${index}`)}
                            className="pp-faq-accordion"
                        >
                            <AccordionSummary
                                className="pp-faq-expand-accordion-summary"
                                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                            >
                                <Typography className="pp-faq-ques-head">{item.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className="pp-faq-details-section">
                                {item.answer.split('\n').map((it) => {
                                    return (
                                        <Typography className="pp-faq-ans-text" sx={{ marginBottom: "1rem" }}>
                                            {it}
                                        </Typography>
                                    )
                                })}
                                {/* <Typography>{item.answer}</Typography> */}
                            </AccordionDetails>
                        </Accordion>
                    ))}


                    {/* <Accordion
                        key={item.id}
                        expanded={expanded.includes('panel1')}
                        onChange={handleChange('panel1')}
                        className='pp-faq-accordion'
                    >
                        <AccordionSummary
                            className={"pp-faq-expand-accordion-summary"}
                            expandIcon={<ExpandMoreIcon className='expand-icon' />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className='pp-faq-ques-head' >
                                How does a word-based pricing model work?
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails className='pp-faq-details-section'>
                            <Typography className='pp-faq-ans-text'>
                                In a word-based pricing model, you are allocated a number of words each month for content generation.
                            </Typography>
                            <br />
                            <Typography className='pp-faq-ans-text'>
                                Let's say you have a monthly subscription that grants you 20,000 words. This means you can generate content up to a
                                cumulative total of 20,000 words within that month. Whether you choose to utilize these words for short product
                                descriptions, social media captions, or longer blogs is entirely up to you.
                            </Typography>
                        </AccordionDetails>
                    </Accordion> */}

                    {/* <Accordion
                        expanded={expanded.includes('panel2')}
                        onChange={handleChange('panel2')}
                        className='pp-faq-accordion'
                    >
                        <AccordionSummary
                            className={"pp-faq-expand-accordion-summary"}
                            expandIcon={<ExpandMoreIcon className='expand-icon' />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className='pp-faq-ques-head' >
                                Can I change or cancel my subscription anytime?
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails className='pp-faq-details-section'>
                            <Typography className='pp-faq-ans-text'>
                                Absolutely. You can cancel, downgrade or upgrade your subscription at any time—we want you to be 100% happy with
                                your experience!
                            </Typography>
                            <br />
                            <Typography className='pp-faq-ans-text'>
                                If you cancel your plan, you'll stay on that plan until the end of your billing cycle and can continue to use any remaining
                                words to generate content.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded.includes('panel3')}
                        onChange={handleChange('panel3')}
                        className='pp-faq-accordion'
                    >
                        <AccordionSummary
                            className={"pp-faq-expand-accordion-summary"}
                            expandIcon={<ExpandMoreIcon className='expand-icon' />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className='pp-faq-ques-head' >
                                What if I upgrade or downgrade my plan in the middle of my billing cycle?
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails className='pp-faq-details-section'>
                            <Typography className='pp-faq-ans-text'>
                                If you decide to downgrade your plan, you'll continue on your existing plan until the end of your billing cycle. You won't be
                                pro-rated for the existing subscription.
                            </Typography>
                            <br />
                            <Typography className='pp-faq-ans-text'>
                                If you decide to upgrade your plan, you'll be charged immediately and receive the full sum of words on the upgraded plan,
                                and your billing cycle will be reset to that day.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded.includes('panel5')}
                        onChange={handleChange('panel5')}
                        className='pp-faq-accordion'
                    >
                        <AccordionSummary
                            className={"pp-faq-expand-accordion-summary"}
                            expandIcon={<ExpandMoreIcon className='expand-icon' />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className='pp-faq-ques-head' >
                                How long are your contracts?
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails className='pp-faq-details-section'>
                            <Typography className='pp-faq-ans-text'>
                                You can opt for a monthly or annual subscription plan. For a custom model on the Enterprise plan, the minimum period for a custom model is 1 year.
                            </Typography>
                            <br />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded.includes('panel6')}
                        onChange={handleChange('panel6')}
                        className='pp-faq-accordion'
                    >
                        <AccordionSummary
                            className={"pp-faq-expand-accordion-summary"}
                            expandIcon={<ExpandMoreIcon className='expand-icon' />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className='pp-faq-ques-head' >
                                What's the difference between the Hypotenuse Basic AI model and a Bespoke AI model?
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails className='pp-faq-details-section'>
                            <Typography className='pp-faq-ans-text'>
                                The difference between watching your friend's Netflix recommendations, versus watching your own.The difference
                                between an off-the-rack suit, and the one you'll wear to your wedding.
                            </Typography>
                            <br />
                            <Typography className='pp-faq-ans-text'>
                                We can tailor custom models to your specific needs, such as length, bullet formats, style, tone, channels and more so the
                                generated content always aligns with your brand.
                            </Typography>
                            <br />
                            <Typography className='pp-faq-ans-text'>
                                Visit https://www.hypotenuse.ai/for-businesses or contact us at hello@hypotenuse.ai for more information.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded.includes('panel7')}
                        onChange={handleChange('panel7')}
                        className='pp-faq-accordion'
                    >
                        <AccordionSummary
                            className={"pp-faq-expand-accordion-summary"}
                            expandIcon={<ExpandMoreIcon className='expand-icon' />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className='pp-faq-ques-head' >
                                Which would be better for me, Individual or Teams?
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails className='pp-faq-details-section'>
                            <Typography className='pp-faq-ans-text'>
                                Our Individual plans have 1 user seat and are perfect for solo entrepreneurs, freelancers, or personal bloggers.
                            </Typography>
                            <br />
                            <Typography className='pp-faq-ans-text'>
                                Our Teams plans are best suited for companies with teams of up to 5 users, focused on SEO and content marketing,
                                freelancers with a large portfolio, in-house copywriters, and agencies.
                            </Typography>
                            <br />
                            <Typography className='pp-faq-ans-text'>
                                If you have more than 5 users, contact us at hello@hypotenuse.ai for an Enterprise plan!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded.includes('panel8')}
                        onChange={handleChange('panel8')}
                        className='pp-faq-accordion'
                    >
                        <AccordionSummary
                            className={"pp-faq-expand-accordion-summary"}
                            expandIcon={<ExpandMoreIcon className='expand-icon' />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className='pp-faq-ques-head' >
                                I run a small agency. What plan should I choose?
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails className='pp-faq-details-section'>
                            <Typography className='pp-faq-ans-text'>
                                If you write less than 50 articles or 700 product descriptions a month, or if this is for just one client, you might go with the
                                Individual plan.
                            </Typography>
                            <br />
                            <Typography className='pp-faq-ans-text'>
                                The Teams plan is the most popular for fast-growing businesses and expanding agencies that write up to 200 articles or
                                3,000 descriptions per month.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded.includes('panel9')}
                        onChange={handleChange('panel9')}
                        className='pp-faq-accordion'
                    >
                        <AccordionSummary
                            className={"pp-faq-expand-accordion-summary"}
                            expandIcon={<ExpandMoreIcon className='expand-icon' />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className='pp-faq-ques-head' >
                                When do I get new words each month?
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails className='pp-faq-details-section'>
                            <Typography className='pp-faq-ans-text'>
                                Your word refresh will happen on the date that you subscribed to the plan, or if it falls on the last
                                day of the month, the last day of each month. Unused words do not roll over for monthly plans, but will roll over from month to month on annual plans.
                            </Typography>
                            <br />
                            <Typography className='pp-faq-ans-text'>
                                For example, in the Individual I plan, if you subscribed on the 31st of March and you've used up 18,000 out of 20,000 words
                                by 30th April, then your account will be reset to 20,000 words on 30th April.
                            </Typography>
                            <br />
                            <Typography className='pp-faq-ans-text'>
                                For annual plans, all words will be given at the beginning of the subscription.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        expanded={expanded.includes('panel10')}
                        onChange={handleChange('panel10')}
                        className='pp-faq-accordion'
                    >
                        <AccordionSummary
                            className={"pp-faq-expand-accordion-summary"}
                            expandIcon={<ExpandMoreIcon className='expand-icon' />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography className='pp-faq-ques-head' >
                                What payment methods do you accept?
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails className='pp-faq-details-section'>
                            <Typography className='pp-faq-ans-text'>
                                We use Stripe to process your payments securely. You can pay with the following payment methods:
                            </Typography>
                            <Typography component="ul" className="pp-faq-ans-list">
                                <Typography component="li" className='pp-faq-ans-text'>
                                    All major credit cards including Visa, Mastercard, American Express and China UnionPay
                                </Typography>
                                <Typography component="li" className='pp-faq-ans-text'>
                                    Wallets including Apple Pay, Google Pay and Alipay
                                </Typography>
                                <Typography component="li" className='pp-faq-ans-text'>
                                    Bank payment methods in the EU including Sofort, giropay, iDEAL, and Bancontact
                                </Typography>
                            </Typography>
                        </AccordionDetails>
                    </Accordion> */}


                </Grid>
            </Grid>
        </>
    )
}

export default PricingFAQ