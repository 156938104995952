import "./ChatbotExperience.css"
import { Grid, Typography, Button } from "@mui/material";
import chatbot_chat_frame from "../../assests/chatbot-chats-frame.png"
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from "react-router-dom";
import chatbotHifiIcon from '../../assests/Chatbot-Hifi-Icon.png'

const ChatbotExperience = () => {
    const navigate = useNavigate()
    return (
        <Grid container className="cbe-main-container">
            <Grid item xl={9.5} lg={9.5} md={9.5} sm={9.5} xs={10.5} className="cbe-main-container-item">
                <Grid container className="cbe-content-container">

                    <Grid item xs={12} sm={12} md={12} lg={5.5} className="cbe-left-seciton-main-item">
                        <Grid container className="cbe-text-content-container">
                            <Grid item xs={12}>
                                <Grid container className="cbe-left-section-heading-container">
                                    <Grid item sx={{ borderBottom: '3px solid rgba(106, 9, 125, 0.8)' }}>

                                        <Typography variant="span" className="cbe-main-heading">
                                            Meet<img src={chatbotHifiIcon} className="cbe-hifi-icon" alt="" />
                                            <Typography className="cbe-main-heading-color" variant="span" >
                                                &nbsp;ToyGenius
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="cbe-left-section-subheading-item">
                                <Typography
                                    className="cbe-left-section-subheading"
                                    variant="span"
                                >
                                    Experience&nbsp;
                                    <Typography className="cbe-left-section-subheading-color" variant="span" >
                                        Sample Live Demo
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="cbe-text-item">
                                <Typography variant="span" className="cbe-text-item-typo">
                                    Dive into a live chat experience with our Toy Company Assistant - ToyGenius, a premier virtual shopping assistant designed to enhance your browsing and shopping experience on the Toyland e-commerce platform.
                                    With ToyGenius, finding the perfect toys for children of all ages has never been easier or more enjoyable.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: '5px' }}>
                                <Typography variant="span" className="cbe-text-item-typo">
                                    With ToyGenius, finding the perfect toys for children of all ages has never been easier or more enjoyable.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="cbe-text-item" >
                                <Typography variant="span" className="cbe-text-item-typo">
                                    Experience Live Demo & witness firsthand how our chatbot simplifies interactions, providing instant, accurate responses.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="cbe-demo-button-item">
                                <Grid container className="cbe-try-it-now-container">

                                    <Typography
                                        variant="span"
                                        sx={{
                                            fontSize: "18px",
                                            fontWeight: '600',
                                            color: '#6A097D',
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            window.open('https://blog.writewiz.ai/2024/03/15/sample-chatbot-toygenius/', '_blank')
                                        }}
                                    >
                                        Try it Now&nbsp; <EastIcon />
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={5.5} className="cbe-i-frame-item" sx={{ textAlign: 'center' }}>
                        <img
                            src="https://storage.writewiz-staging.in/general/Toygenius_image_1710941196621.png"
                            style={{
                                width: '100%',
                                objectFit: 'fill',
                                margin: '0 auto',
                                maxWidth: '520px'
                            }}
                            alt=""
                            onClick={() => {
                                window.open(`${process.env.REACT_APP_WEB_APP_REDIRECT_URL}/dashboard/chatbot`, `_blank`)
                            }}
                        />
                        {/* <iframe src="https://chatbot.writewiz.ai/Lz9w9g7Q1710847806996" width="100%" style={{ maxHeight: "710px", minHeight: "710px", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 3px 0px", borderRadius: "10px" }} frameborder="0"></iframe> */}
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )

}
export default ChatbotExperience;