import React, { useRef, useEffect, useState } from "react";
import gsap from "gsap";
// import "./styles.css";
import ScrollToPlugin from "gsap/ScrollToPlugin";

function TestScroll() {
  const containerRef = useRef(null);
  const [focusedDiv, setFocusedDiv] = useState(null);

  gsap.registerPlugin(ScrollToPlugin);
  useEffect(() => {
    const container = containerRef.current;

    // document.addEventListener("scroll", (e) => {
    //   console.log("insile function", e.target);
    // });
    let observer = new IntersectionObserver(
      function (entries) {
        try {
          var ele = entries.filter((entry) => entry.isIntersecting);
          ele = ele[0].target;
          console.log("Visible element is now: " + ele.id);
          gsap.to(window, {
            duration: 0.5,
            scrollTo: { y: "#" + ele.id }
          });
        } catch (err) {
          console.log("++++++++++", err);
        }
      },
      {
        rootMargin: "-20px 0px", // Add buffering space of 100px at top and bottom
        threshold: 0.1 // Trigger callback when 1% of the element is visible
      }
    );
    document.querySelectorAll(".page").forEach((ele) => observer.observe(ele));
    // observer.observe(document.getElementById("container"));

    return () => {
      // container.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div>
        <div className="one">
          <div>One</div>
        </div>
        <div className="two">
          <div>Two</div>
        </div>

        <div ref={containerRef} id="container">
          <div className="first-page page" id="first-page">
            <div>One</div>
          </div>
          <div className="second-page page" id="second-page">
            <div>Two</div>
          </div>
          <div className="third-page page" id="third-page">
            <div>Three</div>
          </div>
          <div className="fourth-page page" id="fourth-page">
            <div>Four</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestScroll;
