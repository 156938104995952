import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./NewScrollElement.css";
import { bottom } from "@popperjs/core";
import $ from "jquery";
import SmallTextStarIcon from '../../assests/star-extenstion.png'
import NewScrollElementImageOne from "../../assests/newscrollelement-first-image.jpg";
import NewScrollElementImageTwo from "../../assests/newscrollelement-second-image.jpg";
import NewScrollElementImageThree from "../../assests/campaign.jpg";
import NewScrollElementImageFour from "../../assests/template.jpg";
import NewScrollElementImageFive from "../../assests/NewScrollElementImageFour.jpg";
import NewScrollElementImageSix from "../../assests/chatbot.jpg";
import { Button } from "@mui/material";
import ForwardArrowIcon from '../../assests/forward-arrow.png'

gsap.registerPlugin(ScrollTrigger);

const NewScrollElement = () => {
  const mainData = [
    {
      name: "one",
      text: "Engage with your Ideas Instantly!",
      smallTitle: "Chrome Extension",
      title: "Turn every tab into a canvas with your AI-powered companion.",
      description: `Ever had an idea while browsing the web? With our Chrome Extension, instantly access WriteWiz’s powerful features. Create, edit, and save content without switching tabs. The web is your canvas; let’s make every word count,`,
    },
    {
      name: "two",
      text: "Make it more than content—make it you.",
      smallTitle: "Brand Or Individual Persona",
      title: "Your voice, our craft—persona perfected",
      description:
        "Craft content that echoes your unique voice or your brand’s personality. Whether you're an enthusiastic startup or a seasoned blogger, define your persona and let WriteWiz curate content that resonates with your audience. Stand out. Be authentic. Be you",
    },
    {
      name: "three",
      text: "Power Your Marketing with Precision",
      smallTitle: "Campaigns",
      title: "Every asset, every platform—your campaign content, simplified",
      description:
        "Embarking on a new product launch? Promoting a cause? With WriteWiz, structure your campaigns with ease. Generate series of related content, keeping a consistent voice throughout, and ensure every piece aligns perfectly with your campaign’s objective.",
    },
    {
      name: "four",
      text: "Transform Ideas into Impactful Words",
      smallTitle: "Generate Content or Summarize",
      title: "Less fluff, more stuff.",
      description:
        "Whether you need detailed blog posts or concise summaries, WriteWiz has your back. Turn your thoughts into well-structured content or distill lengthy articles into bite-sized insights. Embrace clarity and maximize engagement.",
    },
    {
      name: "six",
      text: "Enhance User Experience with AI-powered Dialogues",
      smallTitle: "Chatbots",
      title: "Chat today, convert tomorrow",
      description:
        "Elevate your chatbot's conversational prowess with WriteWiz. Integrate seamlessly and craft responses that feel human. With our AI, ensure every chat leads to user satisfaction and brand loyalty.",
    },
  ];

  const ChangeNextElement = (e) => {
    const idOfCurrentImageSection = e.trigger.getAttribute("id");
    const idOfCurrentTextSection = `${idOfCurrentImageSection}-text`;

    const tempNextComponentText = mainData.filter(
      (el) => el.name === idOfCurrentImageSection
    );

    // if(idOfCurrentImageSection != 'one'){

    const nextComponentTitle = tempNextComponentText[0]?.title
      ?.split(" ")
      .slice(0, -1)
      .join(" ");
    const nextComponentText = tempNextComponentText[0]?.text;
    const nextComponentTitleLastWord = tempNextComponentText[0]?.title
      ?.split(" ")
      .splice(-1)[0];
    const nextComponentDescription = tempNextComponentText[0]?.description;
    const nextComponentSmallTitle = tempNextComponentText[0]?.smallTitle;

    const newScrollSmallTitleDiv = document.createElement("div");
    const newScrollSmallTitleIcon = document.createElement("img");
    newScrollSmallTitleIcon.src = SmallTextStarIcon;
    newScrollSmallTitleIcon.classList.add("small-title-icon");
    const newScrollSmallTitleText = document.createTextNode(`${nextComponentSmallTitle}`);
    newScrollSmallTitleDiv.append(newScrollSmallTitleIcon);
    newScrollSmallTitleDiv.append(newScrollSmallTitleText);

    $("#newScrollSmallTitle").html(newScrollSmallTitleDiv);
    // $("#newScrollSmallTitle").text(`${nextComponentSmallTitle}`);
    $("#newScrollTitle").text(`${nextComponentTitle}`);
    $("#newScrollText").text(`${nextComponentText}`);
    $("#newScrollTitleLastWord").text(`${nextComponentTitleLastWord}`);
    $("#newScrollDescription").text(`${nextComponentDescription}`);

    $("#newScrollTitle").addClass("animateTextFadeIn");
    $("#newScrollTitleDiv").addClass("animateTextFadeIn");
    $("#newScrollText").addClass("animateTextFadeIn");
    $("#newScrollTitleLastWord").addClass("animateTextFadeIn");
    $("#newScrollDescription").addClass("animateTextFadeIn");
    $("#newScrollSmallTitle").addClass("animateTextFadeIn");
    $("newScrollButton").addClass("animateTextFadeIn");

    setTimeout(() => {
      $("#newScrollSmallTitle").removeClass("animateTextFadeIn");
      $("#newScrollTitle").removeClass("animateTextFadeIn");
      $("#newScrollTitleDiv").removeClass("animateTextFadeIn");
      $("#newScrollText").removeClass("animateTextFadeIn");
      $("#newScrollTitleLastWord").removeClass("animateTextFadeIn");
      $("#newScrollDescription").removeClass("animateTextFadeIn");
      $("newScrollButton").removeClass("animateTextFadeIn");
    }, 1000);
  };

  useEffect(() => {
    const textElement = document.querySelector(
      "#newscrollelement-main-text-inner-div"
    );
    const mainDiv = document.querySelector(".newscrollelement-main-div");
    const mainImage = document.querySelector(".newscrollelement-main-image-wrapper");
    const elements = document.querySelectorAll(".abcdefg");
    const lastSection = document.querySelector(
      "#newscrollelement-main-div-inner-last"
    );
    gsap.timeline({
      scrollTrigger: {
        trigger: textElement,
        start: "center center", // Start pinning at the top of .newscrollelement-main-div
        endTrigger: lastSection,
        end: "center center", // End pinning at the bottom of .newscrollelement-main-image-wrapper
        scrub: true,
        pin: "#newscrollelement-main-text-inner-div",
        anticipatePin: 1,
        // markers: true,
      },
    });
    elements.forEach((element) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: "20% 70%",
          end: "20% 10%",
          scrub: true,
          anticipatePin: 1,
          onEnter: (e) => ChangeNextElement(e),
          onEnterBack: (e) => ChangeNextElement(e),
          // markers: true,
        },
      });
    });
  }, []);

  return (
    <div className="newscrollelement-main-div">
      <div
        id="newscrollelement-main-div-inner"
        className="newscrollelement-main-div-inner"
      >
        <div className="newscrollelement-main-image-wrapper">
          <div id="one" className="abcdefg">
            <div className="newscrollelement-main-image-container">
            <img
              src={NewScrollElementImageOne}
              className="newscrollelement-main-image"
              alt=""
            />
            </div>
          </div>
        </div>
        <div
          id="newscrollelement-main-text"
          className="newscrollelement-main-text"
        >
          <div className="tempDiv"></div>
          <div
            id="newscrollelement-main-text-inner-div"
            className="newscrollelement-main-text-inner-div"
          >
            <div id="newScrollSmallTitle" className="text small-title">
              {mainData[0].smallTitle}
            </div>

            <div id="newScrollTitleDiv">
              <span id="newScrollTitle">
                {mainData[0].title?.split(" ").slice(0, -1).join(" ")}
              </span>
              <span id="newScrollTitleLastWord">
                {mainData[0].title?.split(" ").splice(-1)[0]}
              </span>
            </div>
            <div id="newScrollText">{mainData[0].text}</div>
            <div id="newScrollDescription">{mainData[0].description}</div>
            <div id="newScrollButton">
              <Button
                variant="outlined"
                className="newscroll-learn-more-button"
                size="medium"
                onClick={() => window.open("/browser-extension", "_blank")}
                sx={{
                  border: 'none',
                  borderRadius: '10px',
                  padding: '6px 18px',
                  boxShadow: '0 0 3px 0 #6A097D',
                  color: '#6A097D',
                  fontWeight: '600',
                  textTransform: 'capitalize',
                  fontSize: '18px',
                  marginTop: '50px'
                }}
              >
                Learn More
                <img src={ForwardArrowIcon} className="newscroll-learn-more-button-forward-icon" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="newscrollelement-main-div-inner">
        <div className="newscrollelement-main-image-wrapper">
          <div id="two" className="abcdefg">
            {" "}
            <img
              src={NewScrollElementImageTwo}
              className="newscrollelement-main-image"
              alt=""
            />
          </div>
        </div>
        <div className="newscrollelement-main-text">
          <div className="newscrollelement-main-text-inner-div"></div>
        </div>
      </div>

      <div className="newscrollelement-main-div-inner">
        <div className="newscrollelement-main-image-wrapper">
          <div id="three" className="abcdefg">
            {" "}
            <img
              src={NewScrollElementImageThree}
              className="newscrollelement-main-image"
              alt=""
            />
          </div>
        </div>
        <div className="newscrollelement-main-text">
          <div className="newscrollelement-main-text-inner-div"></div>
        </div>
      </div>

      <div className="newscrollelement-main-div-inner">
        <div className="newscrollelement-main-image-wrapper">
          <div id="four" className="abcdefg">
            {" "}
            <img
              src={NewScrollElementImageFour}
              className="newscrollelement-main-image"
              alt=""
            />
          </div>
        </div>
        <div className="newscrollelement-main-text">
          <div className="newscrollelement-main-text-inner-div"></div>
        </div>
      </div>

      <div
        id="newscrollelement-main-div-inner-last"
        className="newscrollelement-main-div-inner"
      >
        <div className="newscrollelement-main-image-wrapper">
          <div id="six" className="abcdefg">
            {" "}
            <img
              src={NewScrollElementImageSix}
              className="newscrollelement-main-image"
              alt=""
            />
          </div>
        </div>
        <div className="newscrollelement-main-text">
          <div className="newscrollelement-main-text-inner-div"></div>
        </div>
      </div>
    </div>
  );
};

export default NewScrollElement;
