import AxiosInstance from "../../Services/Axios";


export const UserMeAPI = async (onSuccess, onError) => {
    try {
        const response = await AxiosInstance.get('/user/get-user-detail', {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing response of user me", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const RegisterAPI = async (data, onSuccess, onError) => {
    try {
        const response = await AxiosInstance.post('/user/create-user', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing response of register api", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const VerifyLoginOtpAPI = async (data, onSuccess, onError) => {
    try {
        const response = await AxiosInstance.put('/user/auth', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing response of VerifyLoginOtpAPI api", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error VerifyLoginOtpAPI", err);
        onError && onError(err)
    }
}

export const ResendLoginOtpAPI = async (data, onSuccess, onError) => {
    try {
        const response = await AxiosInstance.post('/user/auth', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing response of ResendLoginOtpAPI api", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error ResendLoginOtpAPI", err);
        onError && onError(err)
    }
}

export const continueWithGoogleAPI = async (data, onSuccess, onError) => {
    try {
        const response = await AxiosInstance.get(`/apiIntegration/api/oauth/outbound/auth-url?currentUrl=${data.currentUrl}&gc_integration_type_id=${data.gc_integration_type_id}`, data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing response of ResendLoginOtpAPI api", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error ResendLoginOtpAPI", err);
        onError && onError(err)
    }
}

