import React, { useEffect, useState } from 'react'
import {
    Button,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Typography,
    useMediaQuery
} from "@mui/material";
import '../../Pages/Paraphraser/Paraphraser.css'
import ParaphrasingGroup2 from '../../assests/ParphraseBlank.png'

import voice1 from '../../assests/Paraphraser-Worldwide.png'
import bag from '../../assests/pr-book-bag.png'
import book from '../../assests/pr-open-book.png'
import worldLanguage from '../../assests/pr-language.png'
import worldLanguage2 from '../../assests/pr-language2-exchange.png'
import worldLanguageExchange from '../../assests/pr-language-exchange.png'
import cmd from '../../assests/pr-terminal.png'
import gear from '../../assests/pr-Group.png'
import creative from '../../assests/pr-creative-brain.png'
import simplify from '../../assests/simplify-img.png'
import book1 from '../../assests/book.png'
import earth from '../../assests/voice1.png'



import image1 from '../../assests/ParaphrasingGroup1.png'
import image2 from '../../assests/pr-sec2-Group2.png'
import image3 from '../../assests/pr-sec2-Group3.png'
import image4 from '../../assests/pr-sec2-Group3.png'
import mainHeadLebelImg from '../../assests/Steps-Head-Label-Icon.png'
import stepimg1 from '../../assests/Steps-Tone-of-voice.png'
import stepimg2 from '../../assests/Steps-Multi-Language.png'
import stepimg3 from '../../assests/Setps-Custom-Prompt.png'
import stepimg4 from '../../assests/Steps-Reading-Ease.png'


const Section2 = () => {

    const smallScreen = useMediaQuery('(max-width : 1200px)')

    const [section2, setSection2] = useState([
        {
            index: 1,
            mainHead: 'Get Tone-Ready for Every Occasion',
            mainHeadLebel: 'TONE OF VOICE',
            subhead: 'Tailor your writing with a range of tones - from professional to casual, persuasive to informative. Writewiz AI ensures your message hits the right note every time.',
            backgroundColor: "#F0F2F4",
            imageAlignment: "left",
            mainimage: image1,
            mainHeadCornerImages: stepimg1,
            alt: 'rewrite ai for free with advanced options like Tone of voice',
            group: [
                {
                    groupMainHead: 'Versatile Tone Selection',
                    groupSubHead: 'Whether it’s professional, friendly, or persuasive, match your text to the perfect tone.',
                    groupImg: "https://storage.writewiz-staging.in/general/Tone_1708331174911.png"
                },
                {
                    groupMainHead: 'Resonance with Your Targeted Audience',
                    groupSubHead: 'Tailor your content to fit specific industries, from academic research to business marketing.',
                    groupImg: "https://storage.writewiz-staging.in/general/Briefcase_1708331272133.png"
                },
                {
                    groupMainHead: 'Consistent Brand Voice',
                    groupSubHead: 'Simplify complex texts or enrich simple language to suit your audience’s reading level.',
                    groupImg: "https://storage.writewiz-staging.in/general/Read_1708331311044.png"
                },
            ]
        },
        {
            index: 2,
            mainHead: 'Break Barriers, Speak Everyone’s Language',
            mainHeadLebel: 'MULTI-LINGUAL',
            subhead: "Break language barriers with extensive language and dialect support. Whether you're targeting a local audience or a global one, our ai tool is a paraphraser with translator enabling you to connect with audiences worldwide through authentic and localized content, in over 40 languages.",
            backgroundColor: "#E7EFFF",
            imageAlignment: "right",
            alt: 'rewrite ai for free with multiple language suppport',
            mainimage: image2,
            mainHeadCornerImages: stepimg2,
            group: [
                {

                    groupMainHead: 'Cross-Language Paraphrasing and Translation',
                    groupSubHead: 'A unique 2-in-1 solution. Allows you to input text in one language and receive paraphrased output in another of your choice.',
                    groupImg: "https://storage.writewiz-staging.in/general/Wide_language_1708331373110.png"
                },
                {
                    groupMainHead: 'Wide Language Coverage',
                    groupSubHead: 'Rewrite and translate in over 40 languages making every word count catering to global user base.',
                    groupImg: "https://storage.writewiz-staging.in/general/Dialect_language_1708331461347.png"
                },
                {
                    groupMainHead: 'Dialect Understanding',
                    groupSubHead: 'Capture the essence of local expressions and idioms, enriching your content with authenticity with dialects.',
                    groupImg: "https://storage.writewiz-staging.in/general/Cross_language_1708331513659.png"
                },
            ]
        },
        {
            index: 3,
            mainHead: "Customize to Your Content's Heart",
            mainHeadLebel: 'CUSTOM INSTRUCTIONS',
            subhead: "Experience the power of customization with Writewiz AI. Our tool's custom prompting feature allows you to guide the AI to produce content that fits your exact needs.",
            backgroundColor: "#F4FFFA",
            imageAlignment: "left",
            mainimage: image3,
            mainHeadCornerImages: stepimg3,
            alt: 'rewrite ai free with unlimited customization',
            group: [
                {

                    groupMainHead: 'User Driven Output',
                    groupSubHead: "Experience the power of customization with Writewiz AI. Our tool's custom prompting feature allows you to guide the AI to produce content that fits your exact needs.",
                    groupImg: "https://storage.writewiz-staging.in/general/User_Driven_1708331634186.png"
                },
                {
                    groupMainHead: 'Precision Targeting',
                    groupSubHead: 'Generate content that aligns closely with your specific goals.',
                    groupImg: "https://storage.writewiz-staging.in/general/Precision_targeting_1708331678756.png"
                },
                {
                    groupMainHead: 'Creative Flexibility',
                    groupSubHead: 'Experiment with different styles and formats.',
                    groupImg: "https://storage.writewiz-staging.in/general/Creative_flexibility_1708331731449.png"
                },
            ]
        },
        {
            index: 4,
            mainHead: "Embrace Creativity with Clarity",
            mainHeadLebel: 'READING EASE',
            subhead: "Our Advanced AI Paraphraser optimizes text to ensure your message is understood by everyone, making your writing more accessible and engaging for all reader types. ",
            backgroundColor: "#FFFFF4",
            imageAlignment: "right",
            alt: 'rewrite ai for free with advanced options like reading ease',
            mainimage: image4,
            mainHeadCornerImages: stepimg4,
            group: [
                {

                    groupMainHead: 'Simplified Complexity',
                    groupSubHead: "Break down complex sentences into easy-to-understand language by choosing with reading ease output preference.",
                    groupImg: "https://storage.writewiz-staging.in/general/Simplified_complexity_1708331900542.png"
                },
                {
                    groupMainHead: 'Inclusivity First',
                    groupSubHead: 'Make your content accessible to a wider audience, including non-native speakers and readers of all levels.',
                    groupImg: "https://storage.writewiz-staging.in/general/Inclusivity_first_1708332035498.png"
                },
                {
                    groupMainHead: 'Improved Comprehension',
                    groupSubHead: 'Enhance reader understanding by optimizing content for clarity and conciseness.',
                    groupImg: "https://storage.writewiz-staging.in/general/Improved_comprehension_1708332191501.png"
                },
            ]
        },
    ]
    )

    useEffect(() => {
        let adjustData = section2.map((parent, i) => {
            return {
                index: parent.index,
                mainHead: parent.mainHead,
                mainHeadLebel: parent.mainHeadLebel,
                subhead: parent.subhead,
                imageAlignment: parent.imageAlignment,
                backgroundColor: parent.backgroundColor,
                mainimage: parent.mainimage,
                alt: parent.alt,
                mainHeadCornerImages: parent.mainHeadCornerImages,
                group: parent.group.map((child, j) => {
                    return {
                        childIndex: j,
                        groupMainHead: child.groupMainHead,
                        groupSubHead: child.groupSubHead,
                        groupImg: child.groupImg
                    }
                })
            }
        })
        console.log("adjustData", adjustData);
        setSection2(adjustData)
    }, [])


    return (
        <>
            <Grid container>
                <Grid item xl={10} lg={11} md={11} sm={11} xs={11} className='pr-ai-sub-section2-item'>
                    <Grid container>

                        <Grid item xs={12} sx={{ margin: "0 auto" }}>
                            <Grid container>
                                <Grid item xl={10} lg={10} md={10} sm={10} xs={12} className='pr-ai-section2-head-item'>
                                    <Typography variant='span' className='pr-ai-section-global-head'>What Can the
                                        <Typography variant='span' className='pr-ai-section-global-head-highlighted'> Paraphrasing Tool </Typography>Do for You?</Typography>
                                </Grid>

                                <Grid item xl={6} lg={8} md={10} sm={11} xs={12} className='pr-ai-section2-sub-head-item'>
                                    <Typography variant='span' className='pr-ai-section2-sub-head'>
                                        Get unparalleled customization in tone, language, and style. Whether it's crafting messages with the perfect tone, connecting with global audiences,
                                        or personalizing your writing experience,AI-powered Paraphraser will transform how you communicate.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className='pr-ai-section2-content-main-item'>
                            <Grid container>

                                {section2.map((ele) => {
                                    return (
                                        <>
                                            <Grid item
                                                key={ele.index}
                                                xs={12}
                                                className={`pr-ai-sec2-bgContent-main-item ${ele.backgroundColor || smallScreen ? "pr-ai-sec2-bgContent-group1-item" : "pr-ai-sec2-content-group1-item "}`}
                                                sx={{
                                                    backgroundColor: ele.backgroundColor == '#F0F2F4' ? '#F0F2F4' :
                                                        ele.backgroundColor == '#E7EFFF' ? '#E7EFFF' :
                                                            ele.backgroundColor == '#F4FFFA' ? '#F4FFFA' :
                                                                ele.backgroundColor == '#FFFFF4' ? '#FFFFF4' : ''
                                                }}
                                            >
                                                <Grid container sx={{ justifyContent: { xl: 'space-between', lg: 'space-between', md: 'center', sm: 'center', xs: 'center' } }}>

                                                    {smallScreen ? (
                                                        <>
                                                            <Grid item xl={5} lg={5} md={7} sm={9} xs={12} className='pr-ai-sec2-content-group-images'>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <img src={ele.mainimage} alt={ele.alt} className='pr-ai-section2-groupImg1' />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xl={5} lg={5.5} md={12} sm={12} xs={12} className='pr-ai-start-alignment'>
                                                                <Grid container>
                                                                    <Grid item xs={12} className='pr-ai-sec2-group-main-head-item'>
                                                                        <Typography variant='span' className='pr-ai-sec2-group-main-head'>
                                                                            {ele.mainHead}
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid item xs={12} className='pr-ai-sec2-group-main-subhead-item'>
                                                                        <Typography variant='span' className='pr-ai-sec2-group-main-subhead'>
                                                                            {ele.subhead}
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid item xs={12} className='pr-ai-sec2-group-list-item'>
                                                                        <Grid container>
                                                                            <Grid item xs={12} className='pr-ai-sec2-group-list-subitems'>
                                                                                <Grid container sx={{ justifyContent: { sm: "flex-start", xs: "space-between" }, gap: { sm: "15px", xs: "0px" } }} >
                                                                                    <Grid item className=''>
                                                                                        <img src={ele?.group[0].groupImg} className='pr-ai-sec2-images' alt="" />
                                                                                    </Grid>
                                                                                    <Grid item xs={11}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-head'>
                                                                                                    {ele?.group[0].groupMainHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-points'>
                                                                                                    {ele?.group[0].groupSubHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={12} className='pr-ai-sec2-group-list-subitems'>
                                                                                <Grid container sx={{ justifyContent: { sm: "flex-start", xs: "space-between" }, gap: { sm: "15px", xs: "0px" } }} >
                                                                                    <Grid item className=''>
                                                                                        <img src={ele?.group[1].groupImg} className='pr-ai-sec2-images' alt="" />
                                                                                    </Grid>
                                                                                    <Grid item xs={11}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-head'>
                                                                                                    {ele?.group[1].groupMainHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-points'>
                                                                                                    {ele?.group[1].groupSubHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={12} className='pr-ai-sec2-group-list-subitems'>
                                                                                <Grid container sx={{ justifyContent: { sm: "flex-start", xs: "space-between" }, gap: { sm: "15px", xs: "0px" } }} >
                                                                                    <Grid item className=''>
                                                                                        <img src={ele?.group[2].groupImg} className='pr-ai-sec2-images' alt="" />
                                                                                    </Grid>
                                                                                    <Grid item xs={11}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-head'>
                                                                                                    {ele?.group[2].groupMainHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-points'>
                                                                                                    {ele?.group[2].groupSubHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {ele.imageAlignment == 'left' &&
                                                                <>
                                                                    <Grid item xl={5} lg={5.5} md={6} sm={6} xs={12} className='pr-ai-sec2-content-group-images'>
                                                                        <Grid container>
                                                                            <Grid item className='pr-ai-sec2-bg-left-img-item'>
                                                                                <img src={ele.mainHeadCornerImages} alt="" />
                                                                            </Grid>
                                                                            <Grid item xs={12} className='pr-ai-section2-groupImg1-item'>
                                                                                <img src={ele.mainimage} alt={ele.alt} className='pr-ai-section2-groupImg1' />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            }

                                                            <Grid item xl={5} lg={5.5} md={12} sm={12} xs={12} className='pr-ai-start-alignment'>
                                                                <Grid container>

                                                                    <Grid item xs={12}>
                                                                        <Grid container sx={{ gap: "10px", alignItems: "baseline" }}>
                                                                            <Grid item>
                                                                                <img src={mainHeadLebelImg} alt="" style={{ width: "20px", height: "auto", verticalAlign: 'middle' }} />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant='span' className='pr-ai-sec2-group-main-head-label'>
                                                                                    {ele.mainHeadLebel}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </Grid>
                                                                    <Grid item xs={12} className='pr-ai-sec2-group-main-head-item'>
                                                                        <Typography variant='span' className='pr-ai-sec2-group-main-head'>
                                                                            {ele.mainHead}
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid item xs={12} className='pr-ai-sec2-group-main-subhead-item'>
                                                                        <Typography variant='span' className='pr-ai-sec2-group-main-subhead'>
                                                                            {ele.subhead}
                                                                        </Typography>
                                                                    </Grid>

                                                                    <Grid item xs={12} className='pr-ai-sec2-group-list-item'>
                                                                        <Grid container>
                                                                            <Grid item xs={12} className='pr-ai-sec2-group-list-subitems'>
                                                                                <Grid container sx={{ justifyContent: { sm: "flex-start", xs: "space-between" }, gap: { sm: "10px", xs: "0px" } }} >
                                                                                    <Grid item className=''>
                                                                                        <img src={ele?.group[0].groupImg} className='pr-ai-sec2-images' alt="" />
                                                                                    </Grid>
                                                                                    <Grid item xs={11}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-head'>
                                                                                                    {ele?.group[0].groupMainHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-points'>
                                                                                                    {ele?.group[0].groupSubHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={12} className='pr-ai-sec2-group-list-subitems'>
                                                                                <Grid container sx={{ justifyContent: { sm: "flex-start", xs: "space-between" }, gap: { sm: "10px", xs: "0px" } }} >
                                                                                    <Grid item className=''>
                                                                                        <img src={ele?.group[1].groupImg} className='pr-ai-sec2-images' alt="" />
                                                                                    </Grid>
                                                                                    <Grid item xs={11}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-head'>
                                                                                                    {ele?.group[1].groupMainHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-points'>
                                                                                                    {ele?.group[1].groupSubHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={12} className='pr-ai-sec2-group-list-subitems'>
                                                                                <Grid container sx={{ justifyContent: { sm: "flex-start", xs: "space-between" }, gap: { sm: "10px", xs: "0px" } }} >
                                                                                    <Grid item className=''>
                                                                                        <img src={ele?.group[2].groupImg} className='pr-ai-sec2-images' alt="" />
                                                                                    </Grid>
                                                                                    <Grid item xs={11}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-head'>
                                                                                                    {ele?.group[2].groupMainHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-sec2-list-points'>
                                                                                                    {ele?.group[2].groupSubHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            {ele.imageAlignment == 'right' &&
                                                                <>
                                                                    <Grid item xl={5} lg={5.5} md={6} sm={6} xs={12} className='pr-ai-sec2-content-group-images'>
                                                                        <Grid container>

                                                                            <Grid item xs={12} className='pr-ai-section2-groupImg1-item'>
                                                                                <img src={ele.mainimage} alt={ele.alt} className='pr-ai-section2-groupImg1' />
                                                                            </Grid>
                                                                            <Grid item className='pr-ai-sec2-bg-right-img-item'>
                                                                                <img src={ele.mainHeadCornerImages} alt="" />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            }
                                                        </>
                                                    )}

                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                })}

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Section2