import {
  Button,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import "./LandingFooter.css";
import WritewizWhiteLogo from "../../assests/GlobalImages/WritewizAI-White-Logo.svg";
import facebookimg from "../../assests/FacebookLogo.svg";
import tweeterimg from "../../assests/TwitterLogo.svg";
import linkedinimg from "../../assests/LinkedinLogo.svg";
import instagramimg from "../../assests/InstagramLogo.svg";
import { useNavigate } from "react-router-dom";

const LandingFooter = () => {
  const writeWizWebsiteURL = "https://writeWiz.ai";
  const navigate = useNavigate();
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className="lp-footer-main-container">

            <Grid item xs={12} className="lp-ft-footer-content-item">
              <Grid container className="lp-ft-footer-content-container">
                <Grid
                  item
                  xl={11}
                  lg={11}
                  md={12}
                  sm={11}
                  xs={12}
                  className="lp-ft-footer-content-sub-item"
                >
                  <Grid
                    container
                    className="lp-ft-footer-content-sub-container"
                  >
                    <Grid
                      item
                      xl={4.5}
                      lg={4.5}
                      md={5.5}
                      sm={12}
                      xs={12}
                      className="lp-ft-left-content-item"
                    >
                      <Grid container>
                        <Grid item xs={12} className="lp-ft-logo-item">
                          <a href={writeWizWebsiteURL} target="_self">
                            <img
                              src={WritewizWhiteLogo}
                              height={25}
                              width={"auto"}
                              alt=""
                            />
                          </a>
                        </Grid>

                        <Grid item xs={12} marginTop={"15px"}>
                          <Typography
                            component={"p"}
                            className="lp-ft-content-caption"
                          >
                            AI Content Generator, Writing Assistant & Chatbot{" "}
                            <br />
                            The best AI platform to cater your personalized
                            content needs.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xl={3}
                      lg={3.5}
                      md={4}
                      sm={12}
                      xs={12}
                      className="lp-ft-right-content-item"
                    >
                      <Grid container className="lp-footer-ul-points-main-item">
                        <Grid item xl={4} lg={5} md={5} sm={3} xs={5}>
                          <ul className="lp-footer-ul-points">
                            <li>
                              <Link
                                href="alternative"
                                className="lp-ft-category-links"
                              >
                                <a href="/pricing" target="_blank" style={{ textDecoration: 'none', color: "white" }}>Pricing</a>
                              </Link>
                            </li>
                            <li>
                              <Link
                                href="alternative"
                                className="lp-ft-category-links"
                              >
                                <a href="/browser-extension" target="_blank" style={{ textDecoration: 'none', color: "white" }}>Chrome Extension</a>
                              </Link>
                            </li>
                          </ul>
                        </Grid>

                        <Grid item xl={4} lg={5} md={5} sm={3} xs={5}>
                          <ul className="lp-footer-ul-points">
                            <li>
                              <a className="lp-ft-category-links">
                                <a href="https://app.writewiz.ai/privacy-policy" target="_blank" style={{ textDecoration: 'none', color: "white" }}>Privacy Policy</a>
                              </a>
                            </li>
                            <li>
                              <a className="lp-ft-category-links">
                                <a href="https://app.writewiz.ai/terms" target="_blank" style={{ textDecoration: 'none', color: "white" }}>T&C</a>
                              </a>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item
                      xs={12}
                      md={4}
                      lg={3.5}
                      xl={3}
                      className="lp-ft-company-info"
                    >
                      <Grid container direction={"column"}>
                        <Grid item height={"30px"}>
                          <Grid container direction={"row"}>
                            <Grid item xs={3.5} sm={3} md={4} lg={3}>
                              Company
                            </Grid>
                            <Grid item xs={8.5} sm={9} md={8} lg={9}>
                              <Grid container>
                                <Grid item xs={0.25}>

                                </Grid>
                                <Grid item xs={11.75}>
                                  CHRONOX AI PVT LTD
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <Grid container>
                            <Grid item xs={3.5} sm={3} md={4} lg={3}>
                              Address
                            </Grid>
                            <Grid item xs={8.5} sm={9} md={8} lg={9}>
                              <Grid container>
                                <Grid item xs={0.25}>

                                </Grid>
                                <Grid item xs={11.75}>
                                  611,612, Satyamev Eminence, Science City Road, Gujarat, 380059, Thaltej, Ahmedabad 380059, Gujarat
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xl={11}
                  lg={11}
                  md={12}
                  sm={12}
                  xs={12}
                  className="lp-ft-divider-item"
                >
                  <Divider className="lp-ft-divider" />
                </Grid>

                <Grid
                  item
                  xl={11}
                  lg={11}
                  md={12}
                  sm={12}
                  xs={12}
                  className="lp-ft-cp-item"
                >
                  <Grid container className="lp-ft-cp-container">
                    <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12}>
                      <span>
                        {" "}
                        Copyright &copy; {new Date().getFullYear()} Writewiz AI.
                        All Rights Reserved.
                      </span>
                    </Grid>
                    <Grid
                      item
                      xl={5.5}
                      lg={5.5}
                      md={5.5}
                      sm={5.5}
                      xs={12}
                      className="lp-ft-social-item"
                    >
                      <a href="https://twitter.com/Writewiz_ai" target="_blank" className="lp-ft-social-link1">
                        <img src={tweeterimg} alt="" />
                      </a>
                      <a href="https://www.linkedin.com/showcase/writewiz-ai/" target="_blank" className="lp-ft-social-link">
                        <img src={linkedinimg} alt="" />
                      </a>
                      <a href="https://www.facebook.com/writewizai" target="_blank" className="lp-ft-social-link">
                        <img src={facebookimg} alt="" />
                      </a>
                      <a href="https://www.instagram.com/writewiz.ai/" target="_blank" className="lp-ft-social-link">
                        <img src={instagramimg} alt="" />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LandingFooter;
