import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../../assests/ValtflowIcon.png'
import WriteWizMainLogo from '../../assests/WriteWizMainLogo.svg'
import WritewizaiPrimaryLogo from "../../assests/GlobalImages/WritewizAI-Primary-Logo.svg"

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import downloadExtension from '../../assests/download.png';
import './FeedbackNavbar.css'


const FeedbackNavbar = (props) => {

    const drawerWidth = '100%';

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', width: '100%' }}>
            <List>
                <ListItem key={1} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} >
                        <ListItemText primary={"Home"} sx={{ textTransform: "capitalize" }} />
                    </ListItemButton>
                    <ListItemButton sx={{ textAlign: 'center' }} >
                        <ListItemText primary={"Home"} sx={{ textTransform: "capitalize" }} />
                    </ListItemButton>
                    <ListItemButton sx={{ textAlign: 'center' }} >
                        <ListItemText primary={"Home"} sx={{ textTransform: "capitalize" }} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar component="nav" className='fb-navbar' >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, color: 'black', display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img src={WritewizaiPrimaryLogo} alt="WriteWiz" width={'auto'} height={25} />&nbsp;
                            </div>
                        </Typography>

                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Button
                                key={"Reinstall WriteWiz"}
                                variant={'contained'}
                                className={'fb-reinstall-wz-btn'}
                                startIcon={<img src={downloadExtension} style={{ height: "20px", width: "auto" }} />}
                                onClick={() => {
                                    window.open("https://chrome.google.com/webstore/detail/writewiz-ai-content-creat/nikfnkpdalalkollmjgjkgmfblfmlpbd?hl=en", "_blank")
                                }}
                            >
                                Reinstall WriteWiz
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box component="nav">
                    <Drawer
                        // container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Box>
            </Box>
        </>
    )
}

export default FeedbackNavbar