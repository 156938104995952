import React from 'react'
import './AlternativeCardItem.css'
import { Button, Grid } from '@mui/material';
import logo1 from "../../assests/logoImg1.svg"
import sampleImage from "../../assests/SampleImage.svg"

const cardData = [
    { title: "Limited Lorem ipsum", text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor!", logo: logo1 },
    { title: "Limited Lorem ipsum", text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor", logo: logo1 },
    { title: "Limited Lorem ipsum", text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor", logo: logo1 },
    { title: "Limited Lorem ipsum", text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor", logo: logo1 },
    { title: "Limited Lorem ipsum", text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor!", logo: logo1 },
    { title: "Limited Lorem ipsum", text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor", logo: logo1 }]

const Card = ({ title, text, logo }) => {
    return (
        <div className='an-p-card-maincontainer'>
            <div className='an-p-card-logo-container'>
                <img src={logo} alt="logo" />
            </div>
            <div className="an-p-card-text-area">
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        </div>
    )

}

const AlternativeCardItem = () => {
    return (
        <>
            <Grid container className='alternative-page-info-section' >
                <Grid item xl={8} lg={10} md={11} sm={11} xs={11} className='ap-card-ifream-item' sx={{ backgroundColor: "white" }}  >
                    <Grid container className='ap-card-ifream-container'>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <img src={sampleImage} alt="Sample Image" style={{ width: "100%" }} />
                        </Grid>
                        <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className='alternative-page-info-section-header'>
                            <span className='ap-normal-text'>Why look for a <span className='ap-highlighted-text'>WriteWiz</span> alternative in the first place? </span>
                            <p className='alternative-page-info-section-text'>Lorem ipsum dolor sit amet consectetur. Elit commodo et feugiat pharetra sed integer. Lorem ipsum dolor sit amet consectetur. Elit commodo et feugiat pharetra sed integer. Lorem ipsum dolor sit amet consectetur. Elit commodo et  feugiat pharetra sed integer.Lorem ipsum dolor sit amet consectetur. Elit commodo et feugiat pharetra sed integer.</p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xl={10} lg={12} md={12} sm={12} xs={12} className='alternative-page-card-item'>
                    <Grid container className='alternative-page-card-container'>
                        {cardData.map((item, index) => (
                            <Grid item xl={5} lg={5.2} md={5} sm={11} xs={11} key={index} className='alternative-page-card-sub-item'>
                                <Card title={item.title} text={item.text} logo={item.logo} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AlternativeCardItem