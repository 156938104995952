import React, { useEffect, useState } from "react";
import "./TryOurProduct.css";
import { Tabs, Tab, Box } from "@mui/material";
import { red } from "@mui/material/colors";
import TabPanel from "./TabPanel";
import { Margin } from "@mui/icons-material";
import starIcon from '../../assests/star-extenstion.png';
import ImproveIcon from '../../assests/improve-icon.png';
import ShortenIcon from '../../assests/shorten-icon.png';
import SimplifyIcon from '../../assests/simplify-icon.png';

const TryOurProduct = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [userAddedData, setUserAddedData] = useState("");
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  // const [promptList, setPromptList] = useState([])
  // const [templateGenericData, setTemplateGenericData] = useState([])

  const [userSelectedPrompt, setUserSelectedPrompt] = useState("Improve");
  console.log("userSelectedPrompt---", userSelectedPrompt);
  const [inputFieldDetail, setInputFieldPlaceDetail] = useState({
    // placeholder:
    //   "Write or paste your content here",
    // leftButtonText: "Video Hook",
    // rightButtonText: "Generate Full Video Script",
    // options: null,
    placeholder:
      "Write or paste your content here",
    leftButtonText: "Rewrite",
    rightButtonText: "Get started",
    options: ["Improve", "Shorten", "Simplify"],
    optionIcons: [ImproveIcon, ShortenIcon, SimplifyIcon]
  });

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    console.log("select new tab", event);
    setUserSelectedPrompt(event.target.innerText);
    // 
    switch (event.target.innerText) {
      case "Video Hooks": {
        setInputFieldPlaceDetail({
          placeholder:
            "Write or paste your content here",
          leftButtonText: "Video Hook",
          rightButtonText: "Generate Full Video Script",
          options: null,
        });
        break;
      }
      case "Rewrite": {
        setInputFieldPlaceDetail({
          placeholder:
            "Write or paste your content here",
          leftButtonText: "Rewrite",
          rightButtonText: "Get started",
          options: ["Improve", "Shorten", "Simplify"],
          optionIcons: [ImproveIcon, ShortenIcon, SimplifyIcon]
        });
        break;
      }
      case "Blog Ideas": {
        setInputFieldPlaceDetail({
          placeholder:
            "Write or paste your content here",
          leftButtonText: "Blog Ideas",
          rightButtonText: "Generate a Complete Blog",
          options: null,
        });
        break;
      }
      case "Ads": {
        setInputFieldPlaceDetail({
          placeholder:
            "Write or paste your content here",
          leftButtonText: "Generate Ads",
          rightButtonText: "Generate Ad Copy",
          // options: ["Google Ad", "Facebook Ad"],
          options: null
        });
        break;
      }
      default: {
      }
    }
  };

  useEffect(() => {
    // Calculate the width of the indicator based on the selected tab's label length
    console.log("Inside");
    const selectedTabEle = document.querySelector(`.tab-${selectedTab}`);

    const computedStyle = getComputedStyle(selectedTabEle);
    const fontSize = computedStyle.fontSize;
    const fontWeight = computedStyle.fontWeight;
    const fontFamily = computedStyle.fontFamily;

    const span = document.createElement('span');
    span.style.fontSize = fontSize;
    span.style.fontWeight = fontWeight;
    span.style.fontFamily = fontFamily;
    span.style.visibility = 'hidden';

    span.innerText = selectedTabEle.textContent;
    document.body.appendChild(span);
    const textWidth = span.offsetWidth;
    document.body.removeChild(span);

    if (selectedTabEle) {
      setIndicatorWidth(textWidth + 10);
    }
  }, [selectedTab]);

  // useEffect(() => {
  //   GetPromptList();
  //   GetTemplateGenericData()
  // }, [])
  // const GetPromptList = async () => {
  //   try {
  //     const response = await fetch(
  //       process.env.REACT_APP_API_CALL_URL + "/ai/get-prompt-list",
  //       {
  //         credentials: "include",
  //       }
  //     );
  //     console.log("response of prompt list----", response)
  //     if (!response.ok) {
  //       // setIsRecaptchaPopupOpen(true);
  //       // setUserData(null);
  //       throw new Error("Network response was not ok");
  //     }

  //     const jsonData = await response.json();
  //     console.log("jsonData", jsonData);

  //     if (jsonData?.status) {
  //       setPromptList(jsonData?.data[0]);
  //       // HandleButton();
  //     } else {
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // }
  // const GetTemplateGenericData = async () => {
  //   try {
  //     const response = await fetch(
  //       process.env.REACT_APP_API_CALL_URL + "/template/get-template-generic-data",
  //       {
  //         credentials: "include",
  //       }
  //     );
  //     console.log("response of prompt list----", response)
  //     if (!response.ok) {
  //       // setIsRecaptchaPopupOpen(true);
  //       // setUserData(null);
  //       throw new Error("Network response was not ok");
  //     }

  //     const jsonData = await response.json();
  //     console.log("jsonData", jsonData);

  //     if (jsonData?.status) {
  //       setTemplateGenericData(jsonData?.data);
  //       // HandleButton();
  //     } else {
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // }
  return (
    <>
      <div className="tryourproduct-main">
        <div className="tryoutproducts-innerbox">
          <div className="tryoutproducts-top-text">Experience Now</div>
          <div className="tryourproducts-text">
            Get a feel for what Writewiz AI can do!
          </div>
          <Tabs
            className="tryout-our-products-tab"
            value={selectedTab}
            onChange={handleChange}
            sx={{
              fontWeight: "600 !important",
            }}
            // centered
            TabIndicatorProps={{
              style: {
                backgroundColor: "#6A097D",
                height: "3px",
                borderRadius: "25px",
                // width: `${indicatorWidth}px`,
                // marginLeft: `calc(12.5% - ${indicatorWidth / 2}px)`
              },
            }}
            // textColor="inherit"
            // indicatorColor="blue"
            variant="fullWidth"
          >
            <Tab
              label="Rewrite"
              className="tab-0"
              sx={{ flexGrow: 1, textTransform: "none" }}
            />
            <Tab
              label="Video Hooks"
              className="tab-1"
              sx={{ flexGrow: 1, textTransform: "none" }}
            />
            <Tab
              label="Blog Ideas"
              className="tab-2"
              sx={{ flexGrow: 1, textTransform: "none" }}
            />
            <Tab
              label="Ads"
              className="tab-3"
              sx={{ flexGrow: 1, textTransform: "none" }}
            />
          </Tabs>
          {selectedTab === 0 && (
            <TabPanel
              value={selectedTab}
              index={0}
              userAddedData={userAddedData}
              setUserAddedData={setUserAddedData}
              userSelectedPrompt={userSelectedPrompt}
              setUserSelectedPrompt={setUserSelectedPrompt}
              inputFieldDetail={inputFieldDetail}
              isRecaptchaVerified={isRecaptchaVerified}
              setIsRecaptchaVerified={setIsRecaptchaVerified}
            // promptList={promptList}
            // templateGenericData={templateGenericData}
            // GetPromptListAndTemplateGenericData={() => { GetPromptList(); GetTemplateGenericData() }}

            />
          )}
          {selectedTab === 1 && (
            <TabPanel
              value={selectedTab}
              index={1}
              userAddedData={userAddedData}
              setUserAddedData={setUserAddedData}
              userSelectedPrompt={userSelectedPrompt}
              setUserSelectedPrompt={setUserSelectedPrompt}
              inputFieldDetail={inputFieldDetail}
              isRecaptchaVerified={isRecaptchaVerified}
              setIsRecaptchaVerified={setIsRecaptchaVerified}
            // promptList={promptList}
            // templateGenericData={templateGenericData}


            />
          )}
          {selectedTab === 2 && (
            <TabPanel
              value={selectedTab}
              index={2}
              userAddedData={userAddedData}
              setUserAddedData={setUserAddedData}
              userSelectedPrompt={userSelectedPrompt}
              setUserSelectedPrompt={setUserSelectedPrompt}
              inputFieldDetail={inputFieldDetail}
              isRecaptchaVerified={isRecaptchaVerified}
              setIsRecaptchaVerified={setIsRecaptchaVerified}
            // promptList={promptList}
            // templateGenericData={templateGenericData}


            />
          )}
          {selectedTab === 3 && (
            <TabPanel
              value={selectedTab}
              index={3}
              userAddedData={userAddedData}
              setUserAddedData={setUserAddedData}
              userSelectedPrompt={userSelectedPrompt}
              setUserSelectedPrompt={setUserSelectedPrompt}
              inputFieldDetail={inputFieldDetail}
              isRecaptchaVerified={isRecaptchaVerified}
              setIsRecaptchaVerified={setIsRecaptchaVerified}
            // promptList={promptList}
            // templateGenericData={templateGenericData}

            />
          )}
        </div>
      </div>
    </>
  );
};

export default TryOurProduct;
