import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WritewizLogo from "../../assests/WriteWizMainLogo.svg";
import WritewizaiPrimaryLogo from "../../assests/GlobalImages/WritewizAI-Primary-Logo.svg"

import WzLogo from "../../assests/W.svg"
import "./GitexRedirectingPopup.css";
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

export default function GitexRedirectingPopup({ open, handleClose }) {

    const navigate = useNavigate();

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                        <Grid item>
                            <img src={WritewizaiPrimaryLogo} alt="logo" className='grp-wz-logo' />
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" className='grp-popup-text'>
                                Details Saved Successfully!
                            </Typography>
                            <Typography variant="h6" className='grp-popup-text'>
                                We will get back to you soon
                            </Typography>
                        </Grid>
                    </Grid>
                    <Button className="grp-back-to-home-btn" variant='contained' onClick={() => navigate("/")}>
                        Back to Home Page
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
}