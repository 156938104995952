import createDataContext from "../MainContext/MainContext";


const chatbotPricing_reducer = (state, action) => {
    switch (action.type) {
        case "setChatbotPricingDetails":
            return {
                ...state,
                chatbotPricingDetails: action.payload
            };

    }
};

const setChatbotPricingDetails = (dispatch) => async (data) => {
    dispatch({
        type: "setChatbotPricingDetails",
        payload: data
    })
}

export const { Provider, Context } = createDataContext(
    chatbotPricing_reducer,
    {
        setChatbotPricingDetails,
    },
    {
        chatbotPricingDetails: [],
    }
);