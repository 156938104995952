import { Button, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WritewizVideoPlaceholder from "../../assests/WriteWizzVideoPlaceholder.png";

const WelcomeCExtDialog = (props) => {

    const { setFlagOpenChromeExtentionDialog, onClose, onSkip, setFlagCheckUserLogin
    } = props

    // const handleSkip = () => {
    //     onSkip(); // Call the onSkip function from the parent
    //     onClose(); // Close the dialog
    // };

    return (
        <>
            <Grid container>
                <Grid item xs={12} className='wp-ext-dialog-main-item'>
                    <Grid container>

                        <Grid item xs={10} className='wp-ext-dialog-item'>
                            <img src={WritewizVideoPlaceholder} alt="" style={{ height: 300, width: "100%", borderRadius: "10px" }} />
                        </Grid>

                        <Grid item xs={10} className="wp-ext-caption-item">
                            <Typography
                                component={'span'}
                                className="wp-ext-caption-highlight-text"
                            > Welcome to  WriteWiz</Typography>
                        </Grid>

                        <Grid item xs={10} className='wp-ext-caption2-subtitle-text-item'>
                            <Typography
                                component={'span'}
                                className="wp-ext-caption2-subtitle-text"
                            > From grammar checks to style suggestions, Writewiz equips you with the best in-class writing tools.</Typography>
                        </Grid>

                        <Grid item xs={10} className="wp-ext-caption2-item">
                            <Typography
                                component={'span'}
                                className="wp-ext-caption2-text"

                            >

                                Sign Up Today & Claim   <Typography
                                    component={'span'}
                                    className="wp-ext-caption2-highlight-text"
                                >100</Typography> Credits For Free!
                            </Typography>
                        </Grid>

                        <Grid item xs={5} className='wp-ext-signup-btn-item'>
                            <Button
                                variant='contained'
                                fullWidth
                                className='wp-ext-signup-btn'
                                size='medium'
                                onClick={() => { window.open(`${process.env.REACT_APP_WEB_APP_REDIRECT_URL}/Register`, '_blank') }}
                            >Join Now !</Button>
                        </Grid>

                        <Grid item xs={8} className='wp-ext-caption3-text-item'>
                            <Typography
                                component={'span'}
                                className="wp-ext-caption3-text"
                            >
                                Already have an account?&nbsp;
                                <Button
                                    variant="text"
                                    component={'span'}
                                    className="wp-ext-caption3-highlight-text"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => { window.open(`${process.env.REACT_APP_WEB_APP_REDIRECT_URL}/login`, '_blank') }}
                                >Log In</Button>
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container justifyContent={"end"}>
                                <Grid item xs={2} className=''>
                                    <Button
                                        variant='text'
                                        fullWidth
                                        className='wp-ext-skip-btn'
                                        size='medium'
                                        endIcon={<ArrowForwardIcon />}
                                        onClick={() => {
                                            setFlagCheckUserLogin(false)
                                            setFlagOpenChromeExtentionDialog(false)
                                        }}
                                    >
                                        Skip
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}

export default WelcomeCExtDialog