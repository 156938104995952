import React, { useState } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import './ArticlePage.css'
import IconButton from '@mui/material/IconButton';
import TopPanel from '../../Component/TopPanel/TopPanel.jsx';
import Navbar from '../Navbar/Navbar';
import { Divider, Grid, ListItemIcon, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import StarBorder from '@mui/icons-material/StarBorder';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/system';

const dashboardItems = [
  {
    id: 1,
    title: "Dashboard",
    subtitles: ["Analysis", "Monitor", "Workpplace"],
    heading: "What is WriteWiz?",
    text: "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident."
  },
  {
    id: 2,
    title: "Form",
    subtitles: ["Basic Form", "Step Form", "Advanced Form"],
    text: "enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident."

  },
  {
    id: 3,
    title: "List",
    subtitles: ["Search Table", "Basic List", "Card List"],
    text: "richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident."
  },
  {
    id: 4,
    title: "Profile",
    subtitles: ["Basic Profile", "Advanced Profile"],
    text: "Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident."
  },
  {
    id: 5,
    title: "Result",
    subtitles: ["Success", "Failure"],
    text: "craft beer labore wes anderson cred nesciunt sapiente ea proident."
  },
  {
    id: 6,
    title: "Account",
    subtitles: ["Account Center", "Account Settings"],
    text: "wes anderson cred nesciunt sapiente ea proident."
  }
];

function ArticlePage() {



  const [open, setOpen] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);

  const handleListItemClick = (item) => {
    console.log("item", item);
    setSelectedItem(item);
  };

  const handleCollapseToggle = (item) => {
    setOpen({
      ...open,
      [item.id]: !open[item.id],
    });
  };

  const listItems = [
    {
      id: 1,
      title: 'About WriteWiz',
      subItems: [
        {
          id: 11,
          title: 'What is WriteWiz',
          content: 'This is the content for "What is WriteWiz".',
        },
        {
          id: 12,
          title: 'Introduction',
          content: 'This is the content for the introduction of WriteWiz.',
        },
        {
          id: 13,
          title: 'Features',
          content: 'This is the content for the features of WriteWiz.',
        },
      ],
    },
    {
      id: 2,
      title: 'About WriteWiz2',
      subItems: [
        {
          id: 21,
          title: 'What is WriteWiz',
          content: 'This is the content for "What is WriteWiz".',
        },
        {
          id: 22,
          title: 'Introduction',
          content: 'This is the content for the introduction of WriteWiz.',
        },
        {
          id: 23,
          title: 'Features',
          content: 'This is the content for the features of WriteWiz.',
        },
      ],
    },
    // Add more main headings and sub-items as needed
  ];

  // const [selectedItemId, setSelectedItemId] = useState(null);

  // const handleClick = (id) => {
  //   if (selectedItemId !== id) {
  //     setOpen({}); // Reset any expanded sublists
  //   }
  //   setSelectedItemId(id === selectedItemId ? null : id);
  // };

  // const handleExpand = (id, event) => {
  //   event.stopPropagation();
  //   if (selectedItemId === id) {
  //     setOpen(prev => ({
  //       ...prev,
  //       [id]: !prev[id]
  //     }));
  //   }
  // };


  return (
    <>
      <Navbar />
      <Grid container>

        <Grid item xs={12}>
          <TopPanel />
        </Grid>


        <Grid item xs={11.5} className='arp-content-main-item'>
          <Grid container className='arp-content-main-container' >
            <Grid item xs={2} className="arp-content-list-item">
              {/* <List sx={{ listStyleType: 'disc' }}>
                {dashboardItems.map(item => (
                  <div key={item.id}>
                    <ListItem button onClick={() => handleClick(item.id)}>
                      <IconButton size="small" onClick={(event) => handleExpand(item.id, event)}>
                        {open[item.id] || (item.id === selectedItemId && !open[item.id]) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </IconButton>
                      <ListItemText primary={item.title} />
                    </ListItem>
                    <Collapse
                      in={open[item.id] || (item.id === selectedItemId && !open[item.id])}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List disablePadding>
                        {item.subtitles.map((subtitle) => (
                          <ListItem key={subtitle} >
                            <ListItemText primary={subtitle} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </div>
                ))}
              </List> */}
              {/* <List component="nav">
                {listItems.map((mainItem) => (
                  <div key={mainItem.id}>
                    <ListItem onClick={() => handleCollapseToggle(mainItem)} >
                      {open[mainItem.id] ? <ArrowDropUpIcon sx={{ cursor: "pointer" }} className='' /> : <ArrowDropDownIcon sx={{ cursor: "pointer" }} />}
                      <ListItemText sx={{ cursor: "pointer" }} primary={mainItem.title} />
                    </ListItem>

                    <Collapse in={open[mainItem.id]} timeout="auto" unmountOnExit>
                      <List disablePadding>
                        {mainItem.subItems.map((subItem) => (
                          <ListItem
                            key={subItem.id}
                            onClick={() => handleListItemClick(subItem)}
                          >
                            <ListItemIcon>
                              <CircleIcon className='artical-bullet-points' />
                            </ListItemIcon>
                            <ListItemText
                              primary={subItem.title}
                              sx={{ cursor: "pointer" }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </div>
                ))}
              </List> */}
              <List component="nav">
                {listItems.map((mainItem) => (
                  <div key={mainItem.id}>
                    <ListItem
                      onClick={() => handleCollapseToggle(mainItem)}

                    >
                      {open[mainItem.id] ? (
                        <ArrowDropUpIcon sx={{ cursor: 'pointer' }} className={selectedItem ? 'arrow-up-down-selected' : 'arrow-up-down'} />
                      ) : (
                        <ArrowDropDownIcon sx={{ cursor: 'pointer' }} className={selectedItem ? 'arrow-up-down-selected' : 'arrow-up-down'} />
                      )}
                      <ListItemText
                        primary={mainItem.title}
                        className={selectedItem ? 'arp-main-heading-selected' : 'arp-main-heading'}
                        sx={{ cursor: 'pointer' }}
                      />
                    </ListItem>

                    <Collapse in={open[mainItem.id]} timeout="auto" unmountOnExit>
                      <List disablePadding>
                        {mainItem.subItems.map((subItem) => (
                          <ListItem
                            key={subItem.id}
                            onClick={() => handleListItemClick(subItem)}
                            className={selectedItem ? 'arp-sub-heading-selected' : 'arp-sub-heading'}

                          >
                            <ListItemIcon>
                              <CircleIcon className={selectedItem ? 'artical-bullet-points-selected' : 'artical-bullet-points'} />
                            </ListItemIcon>
                            <ListItemText
                              primary={subItem.title}
                              sx={{ cursor: 'pointer' }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </div>
                ))}
              </List>
            </Grid>

            <Grid item className='arp-divider-item'>
              <Divider
                orientation='vertical'
                flexItem
                className='arp-divider'
              ></Divider>
            </Grid>


            <Grid item xs={9} className='arp-blog-content-item'>
              {selectedItem ? (
                <div>
                  <Typography variant="h4">{selectedItem.title}</Typography>
                  <Typography variant="body1">{selectedItem.content}</Typography>
                </div>
              ) : (
                null
              )}
              {/* <Grid container>
                <Grid item xs={12} className="arp-blog-content-head-item" >
                  <Typography
                    component={'h4'}
                    className='arp-blog-content-head'
                  >
                    {selectedItemId && dashboardItems.find(item => item.id === selectedItemId)?.heading}
                    {selectedItem.title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {selectedItemId && dashboardItems.find(item => item.id === selectedItemId)?.text}
                  {selectedItem.content}
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        {/* <div className="articlepage-container">
          <div className="articlepage-left-section">
          </div>
          <div className="articlepage-right-section">
          </div>
        </div> */}
      </Grid>

    </>
  );
}

export default ArticlePage;