import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import "./RecaptchaPopup.css"

const RECAPTCHA_KEY = "YOUR_SITE_KEY_HERE"; // Replace with your reCAPTCHA site key

const RecaptchaPopup = ({ onClose, open, onVerify,setRecaptchaToken, HandleButton,MakeUserApiCall }) => {
  const handleVerification = (value) => {
    if (value) {
      onVerify()
      setRecaptchaToken(value)
      MakeUserApiCall(value)

      // Optionally, send 'value' to your server for further verification
    }
    else{
      onClose()
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Verify you're human</DialogTitle>
      <DialogContent>
        <ReCAPTCHA
          className='lp-recaptcha-popup'
          sitekey={process.env.REACT_APP_RECAPTCHA}
          onChange={handleVerification}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecaptchaPopup;
