import React from 'react'
import {
    Button,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import '../../Pages/Paraphraser/Paraphraser.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import subImg from '../../assests/Paraphraser-training-phrase.png'
import prLeftArrows from '../../assests/Pr-leftarrowhead 2.png'
import prRightArrows from '../../assests/pr-rightarrowhead.png'

import img1 from '../../assests/g1.png'
import img2 from '../../assests/g2.png'
import img3 from '../../assests/g3.png'
import img4 from '../../assests/g4.png'

import img5 from '../../assests/g5.png'
import img6 from '../../assests/g6.png'
import img7 from '../../assests/g7.png'
import img8 from '../../assests/g8.png'

import Usetool1 from '../../assests/Usetool1.png'
import Usetool2 from '../../assests/Usetool2.png'
import Usetool3 from '../../assests/Usetool3.png'
import Usetool4 from '../../assests/Usetool4.png'
import Usetool5 from '../../assests/Usetool5.png'
import Usetool6 from '../../assests/Usetool6.png'
import Usetool7 from '../../assests/Usetool7.svg'
import Usetool8 from '../../assests/Usetool8.png'
import Usetool9 from '../../assests/Usetool9.png'

const Section4 = () => {

    let leftDiffArray = [
        {
            mainHead: "Time-Intensive Adjustments",
            subHead: "Often requires manual tweaking to perfect the tone and style, making it less efficient.."
        },
        {
            mainHead: "Limited Lexical Innovation",
            subHead: "NLP tools may not always introduce novel expressions or vocabulary, potentially resulting in less engaging content."
        },
        {
            mainHead: "Uniformity in Paraphrasing",
            subHead: "Might struggle with adapting to varied writing styles or achieving a natural, human- like quality in rewritten text."
        },
        {
            mainHead: "Dependency on Predefined Rules",
            subHead: "Constrained by the algorithms' ability to interpret context, sometimes leading to inaccuracies."
        },
    ]

    let rightDiffArray = [
        {
            mainHead: "Effortless and Fast",
            subHead: "Instantly produce multiple paraphrasing options, seamlessly blending speed with innovation. Ideal for writers looking to optimize their workflow with an AI Paraphraser."
        },
        {
            mainHead: "Unmatched Creativity",
            subHead: "Tap into a broader lexicon and dynamic expression generation, far surpassing traditional Paraphrase Tools in delivering fresh and captivating content"
        },
        {
            mainHead: "Consistent Tone and Style",
            subHead: "With generative AI, maintain a consistent voice across all paraphrased content, tailored to the writer's preferences, showcasing the prowess of an AI Writing Assistant."
        },
        {
            mainHead: "Minimized Plagiarism Risks",
            subHead: "Advanced understanding of context and creativity significantly reduces plagiarism, making it a trustworthy Paraphrasing Tool."
        },
        {
            mainHead: "SEO Optimization",
            subHead: "Integrate relevant keywords effortlessly, enhancing SEO without compromising content quality, a hallmark of the best AI Tool for Paraphrasing."
        },
        {
            mainHead: "Universal Accessibility",
            subHead: "Simplify high-quality content creation for everyone, from academic researchers to content marketers, using Paraphrase AI Tool."
        },
        {
            mainHead: "Adaptability Across Genres",
            subHead: "Whether it's for academic writing, content marketing, or creative storytelling, generative AI ensures your needs are met with precision and flair, embodying the essence of Content Rewriter Tool."
        },
    ]


    let useParaphraserLeft = [
        {
            img: img1,
            mainHead: "Improve your writing",
            subHead: "Benefit from AI-driven paraphrasing that ensures accuracy and maintains the original meaning.",
        },
        {
            img: img2,
            mainHead: "Instant Paraphrasing",
            subHead: "Improve your content's search engine ranking with SEO powered AI paraphrasing.",
        },
        {
            img: img3,
            mainHead: "Preserve original meaning",
            subHead: "Paraphrase without fear of losing the point of your text.",
        },
        {
            img: img4,
            mainHead: "Accurate Grammer",
            subHead: "Reliable and grammatically correct paraphrasing.",
        },

    ]


    let useParaphraserRight = [
        {
            img: img5,
            mainHead: "Instant Paraphrasing",
            subHead: "Get paraphrased content in seconds, not hours. Rewrite text with just one click — say goodbye to time-consuming rewrites.",
        },
        {
            img: img6,
            mainHead: "Multilingual Support",
            subHead: "Break language barriers by paraphrasing content in multiple languages and dialects.",
        },
        {
            img: img7,
            mainHead: "Creative Freedom",
            subHead: "Unleash your creativity with AI that adapts to your unique writing style, tone or persona preferences.",
        },
        {
            img: img8,
            mainHead: "Super simple to use",
            subHead: "A simple interface even your grandma could use.",
        },

    ]

    let useThisTool = [
        {
            img: Usetool1,
            mainHead: "Students",
            subHead: "Paraphrasing tools can help students to understand texts and improve the quality of their writing, ensuring your academic work stands out."
        },
        {
            img: Usetool2,
            mainHead: "Teachers/Professors",
            subHead: "Create original lesson plans, presentations & materials and make learning more engaging and accessible."
        },
        {
            img: Usetool3,
            mainHead: "Researchers",
            subHead: "Simplify explanation of complex concepts or findings to make your research understandable and relatable to a broader audience."
        },
        {
            img: Usetool4,
            mainHead: "Marketers",
            subHead: "AI Paraphrasing tool helps quickly rewrite your content,  whether it's for social media, email campaigns, or digital ads."
        },
        {
            img: Usetool5,
            mainHead: "Journalists",
            subHead: "Quickly rephrase text, avoiding redundancy and capturing your readers' attention with every sentence."
        },
        {
            img: Usetool6,
            mainHead: "Copywriters",
            subHead: "Rewrite content to adapt and refine your work for different contexts or audiences from articles to books, maintaining originality and voice."
        },
        {
            img: Usetool7,
            mainHead: "Bloggers",
            subHead: "Rewrite existing content to make it your own ensuring your blog remains vibrant and SEO-friendly."
        },
        {
            img: Usetool8,
            mainHead: "Writers",
            subHead: "Rewrite content to adapt and refine your work for different contexts or audiences from articles to books, maintaining originality and voice."
        },
        {
            img: Usetool9,
            mainHead: "Social Media Managers",
            subHead: "Write compelling posts & quickly rewrite your messaging to fit various platforms to engage your followers."
        },
    ]

    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{ margin: "60px 0px" }}>
                    <Grid container>
                        <Grid item xs={10} sx={{ margin: '0px auto 30px', textAlign: "center" }}>
                            <Typography
                                variant='span'
                                className='pr-diff-main-head-text'
                            >
                                <Typography
                                    variant='span'
                                    sx={{ color: "#6A097D", fontSize: "30px", fontWeight: '600' }}
                                >
                                    NLP
                                </Typography>
                                &nbsp;&nbsp;VS&nbsp;&nbsp;
                                <Typography
                                    variant='span'
                                    sx={{ color: "#6A097D", fontSize: "30px", fontWeight: '600' }}>
                                    Generative AI &nbsp;
                                </Typography>
                                Paraphrasing Explained
                            </Typography>
                        </Grid>
                        <Grid item xl={10} lg={10} md={11} sm={10} xs={11} sx={{ margin: "20px auto" }}>
                            <Grid container sx={{ gap: { sm: "20px", xs: "0px" }, justifyContent: "center" }}>
                                <Grid item className='pr-ai-diff-both-sec-item'>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xl={10} lg={10} md={10} sm={8} xs={10} sx={{ margin: '0 auto' }}>
                                                    <Grid container>
                                                        <Grid item xs={12} className='pr-ai-dif-left-head-item'>
                                                            <Typography variant='span' className='pr-ai-diff-head-text'>
                                                                NLP
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item className='pr-ai-diff-left-section'>
                                                    <Grid container>
                                                        <Grid item xs={11} className='pr-ai-diff-left-sec-item1'>
                                                            <Typography variant='span' className='pr-ai-diff-sec-global-subtext'>
                                                                Traditional NLP-based paraphrasing tools analyze and process text through rule-based or statistical approaches. These tools are designed to understand and manipulate the structure of sentences, ensuring content is rephrased to maintain the original meaning. While effective for basic rewriting tasks, NLP tools have their limitations.
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} sx={{ margin: "10px auto" }}>
                                                            <Grid container>

                                                                {leftDiffArray.map((content) => {
                                                                    return (
                                                                        <>
                                                                            <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                                                <Grid container sx={{ gap: "10px", alignItems: "baseline" }}>
                                                                                    <Grid item>
                                                                                        <img src={prLeftArrows} className='pr-ai-diff-arrow' alt="" />
                                                                                    </Grid>
                                                                                    <Grid item xs={11}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={12} >
                                                                                                <Typography variant='span' className='pr-ai-diff-sec-point-global-text'>
                                                                                                    {content.mainHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-diff-sec-point-global-subtext'>
                                                                                                    {content.subHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    )
                                                                })}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Typography variant='span' sx={{ fontSize: "40px", fontWeight: "600", color: "#6A097D" }}>VS</Typography>
                                </Grid>

                                <Grid item className='pr-ai-diff-both-sec-item'>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xl={10} lg={10} md={10} sm={8} xs={10} sx={{ margin: '0 auto' }}>
                                                    <Grid container>
                                                        <Grid item xs={12} className='pr-ai-dif-right-head-item'>
                                                            <Typography variant='span' className='pr-ai-diff-head-text'>
                                                                Gen AI
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item className='pr-ai-diff-right-section'>
                                                    <Grid container>
                                                        <Grid item xs={11} className='pr-ai-diff-left-sec-item1'>
                                                            <Typography variant='span' className='pr-ai-diff-sec-global-subtext'>
                                                                Leveraging the power of generative AI, paraphrasing transcends traditional boundaries. his AI-driven approach understands context, generates creative and nuanced expressions, and ensures content is both unique and engaging. It's a paradigm shift in how writers can approach rewriting tasks.
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} sx={{ margin: "10px auto" }}>
                                                            <Grid container>

                                                                {rightDiffArray.map((content) => {
                                                                    return (
                                                                        <>
                                                                            <Grid item xs={12} sx={{ margin: '10px 0px' }}>
                                                                                <Grid container sx={{ gap: "10px", alignItems: "baseline" }}>
                                                                                    <Grid item>
                                                                                        <img src={prRightArrows} className='pr-ai-diff-arrow' alt="" />
                                                                                    </Grid>
                                                                                    <Grid item xs={11}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={12} >
                                                                                                <Typography variant='span' className='pr-ai-diff-sec2-point-global-text'>
                                                                                                    {content.mainHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <Typography variant='span' className='pr-ai-diff-sec-point-global-subtext'>
                                                                                                    {content.subHead}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    )
                                                                })}

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* WHY USE THIS PARAPHRASING TOOL */}
                <Grid item xs={12} className='pr-ai-que-main-item'>
                    <Grid container>

                        <Grid item xs={12} sx={{ textAlign: 'center', margin: '10px auto 60px' }}>
                            <Typography variant='span' className='pr-use-tool-main-head'>
                                Why Use This Paraphrasing Tool?
                            </Typography>
                        </Grid>
                        <Grid item xl={10} lg={11} md={11} sm={11} xs={11} sx={{ margin: "0 auto" }}>
                            <Grid container sx={{ justifyContent: "center", }}>

                                {useParaphraserLeft.map((ele) => {
                                    return (
                                        <>
                                            <Grid item xl={5} lg={5} md={5.5} sm={12} xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} sx={{ marginBottom: { sm: "60px", xs: "35px" } }}>
                                                        <Grid container sx={{ gap: "10px", alignItems: "flex-start" }}>
                                                            <Grid item>
                                                                <img src={ele.img} style={{ width: "50px", height: "auto" }} alt="" />
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <Grid container>
                                                                    <Grid item xs={12} sx={{ margin: "0px 0px 0px" }}>
                                                                        <Typography variant='span' className='pr-ai-que-main-head-text'>
                                                                            {ele.mainHead}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} sx={{ margin: "0px 0px" }}>
                                                                        <Typography variant='span' className='pr-ai-que-main-sub-text'>
                                                                            {ele.subHead}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                })}

                                {useParaphraserRight.map((ele) => {
                                    return (
                                        <>
                                            <Grid item xl={5} lg={5} md={5.5} sm={12} xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} sx={{ marginBottom: { sm: "60px", xs: "35px" } }}>
                                                        <Grid container sx={{ gap: "10px", alignItems: "flex-start" }}>
                                                            <Grid item>
                                                                <img src={ele.img} alt="" style={{ width: "50px", height: "auto" }} />
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <Grid container>
                                                                    <Grid item xs={12} sx={{ margin: "0px" }}>
                                                                        <Typography variant='span' className='pr-ai-que-main-head-text'>
                                                                            {ele.mainHead}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} sx={{ margin: "0px 0px 0px" }} className='pr-ai-que-main-sub-text'>
                                                                        <Typography variant='span'>
                                                                            {ele.subHead}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* WHO CAN USE THIS PARAPHRSING TOOL */}
                <Grid item xs={12} className='pr-ai-use-tool-main-item'>
                    <Grid container>
                        <Grid item xl={10} lg={11} md={11} sm={11} xs={11} sx={{ margin: "0 auto" }}>
                            <Grid container sx={{ justifyContent: { xs: 'space-between', sm: 'space-between', md: 'center', lg: "center", xl: "center" } }}>
                                <Grid item xs={12} sx={{ textAlign: 'center', margin: "10px 0px 50px" }}>
                                    <Typography variant='span' className='pr-ai-section-global-head '>
                                        Who can use this <Typography variant='span' className='pr-ai-section-global-head-highlighted '>
                                            Paraphrasing</Typography> tool?
                                    </Typography>
                                </Grid>

                                {useThisTool.map((cards) => {
                                    return (
                                        <>
                                            <Grid item className='pr-ai-usetool-card'>
                                                <Grid container sx={{ justifyContent: "space-between" }}>
                                                    <Grid item xs={1}>
                                                        <img src={cards.img} style={{ width: "30px", height: "auto", objectFit: "fill" }} alt="" />
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Grid container>
                                                            <Grid item xs={12} sx={{ marginBottom: "5px" }}>
                                                                <Typography variant='span' className='pr-ai-use-tool-main-head'>
                                                                    {cards.mainHead}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant='span' className='pr-ai-use-tool-sub-head' >
                                                                    {cards.subHead}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                })}

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xl={11} lg={11} md={10} sm={11} xs={11} sx={{ margin: "60px auto" }}>
                    <Grid container>
                        <Grid item className='pr-tryit-section-item'>
                            <Grid container>
                                <div>
                                    <img className='pr-tryit-section-subimg' src={subImg} alt="" />
                                </div>
                                <Grid item xl={8} lg={8} md={10} sm={8} xs={12} sx={{ margin: "0px 0px 10px" }}>
                                    <Typography variant='span' className='pr-ai-tryit-main-head'>
                                        AI-powered paraphrasing tool for all your needs
                                    </Typography>
                                </Grid>
                                <Grid item xl={8} lg={8} md={8} sm={8} xs={11} sx={{ margin: "10px 0px 10px" }}>
                                    <Typography variant='span' className='pr-ai-tryit-sub-head'>
                                        Rewrite sentences and paragraphs quickly and easily in your preferred tone and get unique content from scratch.
                                    </Typography>
                                </Grid>
                                <Grid item xl={8} lg={8} md={8} sm={8} xs={8} sx={{ margin: "8px 0px" }}>
                                    <Grid container>
                                        <Grid item>
                                            <Button
                                                variant='span'
                                                size='large'
                                                fullWidth
                                                endIcon={<ArrowForwardIcon sx={{ color: "#6A097D" }} />}
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: '500',
                                                    color: "#6A097D",
                                                    textTransform: "none",
                                                    borderRadius: '5px',
                                                    background: "#fff",
                                                    '&.MuiButtonBase-root:hover': { background: "#fff", color: "#6A097D", }
                                                }}
                                                onClick={() => {
                                                    window.open("https://app.writewiz.ai/dashboard/paraphraser", '_blank')
                                                }}
                                            >Write with AI for FREE</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Section4