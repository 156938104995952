import React, { useEffect } from "react";
import "./Banner.css";
import profile1 from "../../assests/mAvatar1.jpg";
import profile2 from "../../assests/fAvatar1.jpg";
import profile3 from "../../assests/mAvatar3.jpg";
import profile4 from "../../assests/mAvatar4.jpg";
import profile5 from "../../assests/fAvatar2.jpg";
import profile6 from "../../assests/mAvatar2.jpg";
import profile7 from "../../assests/fAvatar5.jpg";
import profile8 from "../../assests/mAvatar5.jpg";
import profile9 from "../../assests/fAvatar3.jpg";
import profile10 from "../../assests/fAvatar4.jpg"

// import "bootstrap/dist/css/bootstrap.min.css";

//design of one testimonial box
const Testimonial = ({ profileImage, username, content }) => {
  return (
    <div className="testimonial marquee-item">
      <div className="tweet-content">{content}</div>
      <div className="user-name-block">
        <img
          // src={
          //   "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
          // }
          src={profileImage ? profileImage : "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"}
          alt="Profile"
          className="profile-image"
        />
        <span className="user-name">{username}</span>
      </div>
    </div>
  );
};

const Banner = () => {
  const TestimonialData = [
    {
      profileImage: profile1,
      username: "Divyang",
      content:
        "I absolutely LOVE this software. We all have slight challenges with selecting the appropriate words to express - this software is more than helpful - it's completely AMAZING!",
    },
    {
      profileImage: profile2,
      username: "Emily",
      content:
        "This software has been a game-changer for me. It's like having a personal writing assistant. Kudos to the developers!",
    },
    {
      profileImage: profile3,
      username: "JohnDoe",
      content:
        "I'm so impressed with this tool. It's making my writing process so much smoother. I'm recommending it to all my colleagues.",
    },
    {
      profileImage: profile4,
      username: "Alex123",
      content:
        "I can't believe I didn't discover this software sooner. It's making my communication much more effective.",
    },
    // {
    //   profileImage: "",
    //   username: "Anonymous",
    //   content:
    //     "As a computer aficionado, I'm astounded by this software's natural language processing powers. It's ground-breaking.",
    // },
    {
      profileImage: profile5,
      username: "Samantha",
      content:
        "This software is a must-have for anyone who writes regularly. It's like having a writing coach by your side.",
    },
    {
      profileImage: profile6,
      username: "MarkWriter",
      content:
        "I've tried many writing tools, but this one takes the cake. It's versatile, user-friendly, and truly enhances creativity.",
    },
    {
      profileImage: profile7,
      username: "GracefulWriter",
      content:
        "I'm falling in love with writing all over again, thanks to this software. It's a writer's dream come true.",
    },
    // {
    //   profileImage: "",
    //   username: "Anonymous",
    //   content:
    //     "I'm in love with this software. Everybody has occasional trouble choosing the right words to say, but this software is more than just useful—it's just incredible!",
    // },
    {
      profileImage: profile8,
      username: "TechSavvy23",
      content:
        "Being a tech enthusiast, I'm amazed at the natural language processing capabilities of this software. It's revolutionary.",
    },
    {
      profileImage: profile9,
      username: "AliceWrites",
      content:
        "This software is like a co-author who always knows the right words to use. It's boosting my writing to a new level.",
    },
    {
      profileImage: profile10,
      username: "Lily",
      content:
        "As a non-native English speaker, this software is a lifesaver. It helps me express my thoughts more eloquently.",
    },
    // {
    //   profileImage: "",
    //   username: "Anonymous",
    //   content:
    //     "Though I've used a lot of writing tools, this one is the best. It genuinely fosters creativity and is adaptable and user-friendly.",
    // },
  ];


//  when hovered on one row of animation testimonial also stopes the second row by adding hover class to it
  useEffect(() =>{
    var marqueeToRight = document.querySelector(".marquee-inner.to-right");
    var marqueeToLeft = document.querySelector(".marquee-inner.to-left");
  
    function handleHover() {
      marqueeToRight.classList.add("hovered");
      marqueeToLeft.classList.add("hovered");
    }
  
    function handleMouseOut() {
      marqueeToRight.classList.remove("hovered");
      marqueeToLeft.classList.remove("hovered");
    }
  
    marqueeToRight.addEventListener("mouseenter", handleHover);
    marqueeToLeft.addEventListener("mouseenter", handleHover);
  
    marqueeToRight.addEventListener("mouseleave", handleMouseOut);
    marqueeToLeft.addEventListener("mouseleave", handleMouseOut);
  },[])

  return (
    <>
    <section class="testimonial-container">
      <div className="testimonial-content-top-text">
        Testimonials
      </div>

      <div style={{marginBottom:'30px', width:'100%'}} className="testimonial-top-header">
        <span className="banner-title-first" style={{ color: "#ff8045",fontWeight:'600' }}>A must have </span>
        <span className="separator">|</span>
        <span className="banner-title-second" style={{ color: "#a022ea" ,fontWeight:'600'}}>life changing </span>
        <span className="separator">|</span>
        <span className="banner-title-third" style={{ color: "#3fc5bd",fontWeight:'600' }}> for every writesmith</span>
      </div>

      <div class="marquee-wrapper">
        <div class="banner-container">
          <div class="marquee-block">
            <div class="marquee-inner to-left">
              <span>
                {TestimonialData.map((tweet, index) => (
                  <Testimonial
                    key={index}
                    profileImage={tweet.profileImage}
                    username={tweet.username}
                    timestamp={tweet.timestamp}
                    content={tweet.content}
                  />
                ))}
              </span>
              <span>
                {TestimonialData.map((tweet, index) => (
                  <Testimonial
                    key={index}
                    profileImage={tweet.profileImage}
                    username={tweet.username}
                    timestamp={tweet.timestamp}
                    content={tweet.content}
                  />
                ))}
              </span>
            </div>
          </div>
          <div class="marquee-block">
            <div class="marquee-inner to-right ">
              <span>
                {TestimonialData.map((tweet, index) => (
                  <Testimonial
                    key={index}
                    profileImage={tweet.profileImage}
                    username={tweet.username}
                    timestamp={tweet.timestamp}
                    content={tweet.content}
                  />
                ))}
              </span>
              <span>
                {TestimonialData.map((tweet, index) => (
                  <Testimonial
                    key={index}
                    profileImage={tweet.profileImage}
                    username={tweet.username}
                    timestamp={tweet.timestamp}
                    content={tweet.content}
                  />
                ))}
              </span>
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  );
};

export default Banner;
