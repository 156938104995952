import React from 'react'
import {
    Button,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import '../../Pages/Paraphraser/Paraphraser.css'
import img1 from '../../assests/Paraphraser-Forever-Free.svg'
import img2 from '../../assests/Nosignup.png'
import img3 from '../../assests/Add-Block.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import generalizedImage from '../../assests/Paraphraser-Generalized.svg'
import heart from '../../assests/Paraphraser-Heart.png'


const Section3 = () => {
    return (
        <>
            <Grid container>
                <Grid item xl={6} lg={6} md={11} sm={11} xs={11} className='pr-ai-generalized-main-item'>
                    <Grid container>
                        <Grid item xs={12} className='pr-ai-generalized-main-head-item'>
                            <Typography variant='span' className='pr-ai-section-global-head'>
                                Not Generalized, but <Typography variant='span' className='pr-ai-section-global-head-highlighted'>Personalized Paraphrasing</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={11} className='pr-ai-generalized-subhead-item'>
                            <Typography variant='span' className='pr-ai-generalized-subhead'>
                                At Writewiz AI, we don’t just paraphrase; we infuse your content with life. Our unique persona-based paraphrasing takes your writing beyond mere words, ensuring each sentence reflects the personality and tone you desire.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item sx={{ margin: "20px auto", textAlign: "center" }}>
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        size='large'
                                        endIcon={<ArrowForwardIcon style={{ color: "#fff" }} />}
                                        sx={{
                                            fontSize: "17px",
                                            fontWeight: '500',
                                            color: "#fff",
                                            padding: "10px 15px",
                                            textTransform: "none",
                                            borderRadius: '5px',
                                            background: "#6A097D",
                                            '&.MuiButtonBase-root:hover': { background: "#6A097D", color: "#fff", }
                                        }}
                                        onClick={() => {
                                            window.open('https://app.writewiz.ai/dashboard/persona', '_blank')
                                        }}
                                    >Choose from 100+ Personas</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={10} lg={10} md={10} sm={8} xs={12} className='pr-ai-generalized-img-item'>
                            <img src={generalizedImage} className='pr-ai-generalized-img' alt="paraphrase or rewrite with Ai with personalized and customized personas" />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xl={7} lg={7} md={7} sm={7} xs={12} sx={{ margin: '20px auto', textAlign: "center" }}>
                                    <Typography component={'p'} sx={{ fontSize: "18px", fontWeight: "500", color: "#1F384C" }}>
                                        Redefine Writing with AI-Infused Personality
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className='pr-ai-images-main-item'>
                    <Grid container>
                        <Grid item xl={5} lg={12} md={12} sm={12} xs={12} className='pr-sec3-head-item'>
                            <Typography variant='span' className='pr-sec3-head-text' >Why people <img src={heart} style={{ width: "25px", height: "auto", verticalAlign: "middle" }} alt="" /> our paraphrasing tool?</Typography>
                        </Grid>

                        <Grid item xl={10} lg={10} md={12} sm={12} xs={12} className='pr-sec3-content-item'>
                            <Grid container className='pr-sec3-content-container'>
                                <Grid item xl={3.5} lg={3} md={3} sm={3.5} xs={12}>
                                    <Grid container sx={{ justifyContent: { sm: "center", xs: "space-between" } }}>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={3.5}>
                                            <img src={img1} alt="Best free AI Paraphrasing" className='pr-sec3-imgs' />
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={8} sx={{ textAlign: { sm: "center", xs: "start" } }}>
                                            <Grid container>
                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <Typography variant='span' className='pr-sec3-global-text'>
                                                        Forever Free Plan
                                                    </Typography>
                                                </Grid>
                                                <Grid item xl={8} lg={12} md={12} sm={12} xs={12} className='pr-sec3-global-subtext-item'>
                                                    <Typography variant='span' className='pr-sec3-global-subtext'>
                                                        Enjoy premium paraphrasing with access to unlimited versions of your paraphrased copy
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xl={3.5} lg={3} md={3} sm={3.5} xs={12}>
                                    <Grid container sx={{ justifyContent: { sm: "center", xs: "space-between" } }}>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={3.5}>
                                            <img src={img2} alt="Best Rewrite Quality using AI" className='pr-sec3-imgs' />
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={8} sx={{ textAlign: { sm: "center", xs: "start" } }}>
                                            <Grid container>
                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <Typography variant='span' className='pr-sec3-global-text'>
                                                        Best in Class Quality Output
                                                    </Typography>
                                                </Grid>
                                                <Grid item xl={8} lg={12} md={12} sm={12} xs={12} className='pr-sec3-global-subtext-item'>
                                                    <Typography variant='span' className='pr-sec3-global-subtext'>
                                                        Benefit from the highest quality outputs. Get top-notch results every time you use it.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xl={3.5} lg={3} md={3} sm={3.5} xs={12}>
                                    <Grid container sx={{ justifyContent: { sm: "center", xs: "space-between" } }}>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={3.5}>
                                            <img src={img3} alt="Rewrite with No Ads for free" className='pr-sec3-imgs' />
                                        </Grid>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={8} sx={{ textAlign: { sm: "center", xs: "start" } }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant='span' className='pr-sec3-global-text'>
                                                        No Ads, No Interruptions
                                                    </Typography>
                                                </Grid>
                                                <Grid item xl={8} lg={12} md={12} sm={12} xs={12} className='pr-sec3-global-subtext-item'>
                                                    <Typography variant='span' className='pr-sec3-global-subtext'>
                                                        Enjoy an ad-free rewriting. Focus
                                                        on your writing, not distractions.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xl={9} lg={10} md={11} sm={10} xs={11} sx={{ margin: { xs: "20px auto", sm: "20px auto" } }}>
                    <Grid container sx={{ justifyContent: "space-between" }}>
                        <Grid item xl={5.5} lg={5.5} md={5.5} sm={12} xs={12} sx={{ margin: "0 auto", textAlign: { xs: "center", sm: 'center', md: "start" } }}>
                            <Grid container>
                                <Grid item xs={12} className='pr-ai-paraphrasing-head-item' >
                                    <Typography variant='span' className='pr-ai-global-head-text'>
                                        What is <Typography variant='span' className='pr-ai-global-head-highlighted-text'>Paraphrasing?</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xl={10} lg={10} md={12} sm={12} xs={12} className='pr-ai-paraphrasing-subhead-item'>
                                    <Typography variant='span' className='pr-ai-paraphrasing-subhead'>
                                        Paraphrasing is the art of expressing someone else’s ideas in your own words without changing the original meaning.
                                        It’s a skill that allows writers to avoid plagiarism, enhance clarity, and integrate source material seamlessly into their own work.
                                        With Writewiz AI, paraphrasing becomes an effortless process, ensuring originality and creativity in every piece you write.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={5.5} lg={5.5} md={5.5} sm={11} xs={12} sx={{ margin: { xs: '10px auto', sm: '10px auto' }, textAlign: "center" }}>
                            <img src={generalizedImage} style={{ width: "100%", maxWidth: "500px", height: "auto", objectFit: "fill" }} alt="What is rewrite or paraphrasing" />
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant='span'>
                                Not Generalized, <Typography variant='span'>but Personalized Paraphrasing
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </>
    )
}

export default Section3