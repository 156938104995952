import React from "react";
import "./ChromeExtension.css";
import chromeLogo from "../../assests/Chrome.svg";
import downloadImage from "../../assests/chromeExtensiondownloadImage.svg";
import firstImage from "../../assests/first.png";
import secondImage from "../../assests/second.png";
import thirdImage from "../../assests/third.png";
import gmailIcon from "../../assests/Gmail.svg";
import outlookIcon from "../../assests/Outlook.svg";
import messengerIcon from '../../assests/messenger.svg'
import slackIcon from "../../assests/Slack.svg";
import driveIcon from "../../assests/Drive.svg";
import wordIcon from '../../assests/Word.svg';
import wordpressIcon from "../../assests/WordPress.svg";
import mediumIcon from "../../assests/Medium.svg";
import facebookIcon from "../../assests/Facebook.svg";
import xIcon from "../../assests/X.svg";
import instagramIcon from "../../assests/Instagram.svg";
import linkedinIcon from "../../assests/Linkdin.svg";

const ChromeExtension = () => {
  return (
    <>
      <div className="ChromeExtension-main-page">
        <div className="ChromeExtension-inner-div">
          <div className="ChromeExtension-text-content">
            <div className="ChromeExtension-text-content-top-text">
              Chrome Extension
            </div>
            <div className="ChromeExtension-text-content-title">
              Your Personal AI Sidekick wherever you go
            </div>
            <div className="ChromeExtension-text-content-discription">
              Unleash your creative potential directly from your browser. Our
              chrome extension empowers your writing journey—whether drafting an
              important email, crafting a compelling social post, or sharing
              your thoughts in a blog. Dive into an array of templates and
              personalize with customized personas to make your content uniquely
              yours. Experience seamless writing like never before. Welcome to a
              smarter, more efficient way to write online!
            </div>
          </div>
          <div className="ChromeExtension-download-button-perent" onClick={() => window.open("https://chrome.google.com/webstore/detail/writewiz-ai-content-creat/nikfnkpdalalkollmjgjkgmfblfmlpbd?hl=en", "_blank")}>
            <div className="ChromeExtension-download-button">
              <img
                className="ChromeExtension-download-button-chrome-logo"
                alt="Chrome"
                src={chromeLogo}
              />
              <div className="ChromeExtension-download-button-text-wrapper">
                Install Extension
              </div>
              <img
                className="ChromeExtension-download-button-element"
                alt="Element"
                src={downloadImage}
              />
            </div>
          </div>
          <div className="ChromeExtension-used-by-x-users">
            {/* Extension installed by 20K+ users! */}
          </div>
          <div className="ChromeExtension-below-part">
            <div className="ChromeExtension-below-part-card">
              {/* <div className="ChromeExtension-below-part-card-image"> */}
              <div className="ChromeExtension-below-part-images">
                <table>
                  <tr>
                    <td><img src={gmailIcon} style={{width: '50px', margin: '11px'}}/></td>
                    <td><img src={outlookIcon} style={{width: '50px', margin: '11px'}}/></td>
                  </tr>
                  <tr>
                    <td><img src={messengerIcon} style={{width: '50px', margin: '11px'}}/></td>
                    <td><img src={slackIcon} style={{width: '50px', margin: '11px'}}/></td>
                  </tr>
                </table>
              </div> 
              {/* </div> */}
              <div className="ChromeExtension-below-part-card-title">
              Emails and Chatting
              </div>
              {/* <div className="ChromeExtension-below-part-card-discription">
                Lorem ipsum dolor sit amet consectetur. At tincidunt morbi
              </div> */}
            </div>
            <div className="ChromeExtension-below-part-card">
              {/* <div className="ChromeExtension-below-part-card-image"> */}
              <div className="ChromeExtension-below-part-images">
                <table>
                  <tr>
                    <td><img src={driveIcon} style={{width: '50px', margin: '11px'}}/></td>
                    <td><img src={wordIcon} style={{width: '50px', margin: '11px'}}/></td>
                  </tr>
                  <tr>
                    <td><img src={wordpressIcon} style={{width: '50px', margin: '11px'}}/></td>
                    <td><img src={mediumIcon} style={{width: '50px', margin: '11px'}}/></td>
                  </tr>
                </table>
              </div> 

              {/* </div> */}
              <div className="ChromeExtension-below-part-card-title">
              Documents and Blogging
              </div>
              {/* <div className="ChromeExtension-below-part-card-discription">
                Lorem ipsum dolor sit amet consectetur. At tincidunt morbi
              </div> */}
            </div>{" "}
            <div className="ChromeExtension-below-part-card">
              {/* <div className="ChromeExtension-below-part-card-image"> */}
              <div className="ChromeExtension-below-part-images">
                <table>
                  <tr>
                    <td><img src={facebookIcon} style={{width: '50px', margin: '11px'}}/></td>
                    <td><img src={xIcon} style={{width: '50px', margin: '11px'}}/></td>
                  </tr>
                  <tr>
                    <td><img src={instagramIcon} style={{width: '50px', margin: '11px'}}/></td>
                    <td><img src={linkedinIcon} style={{width: '50px', margin: '11px'}}/></td>
                  </tr>
                </table>
              </div> 

              {/* </div> */}
              <div className="ChromeExtension-below-part-card-title">
              Social media
              </div>
              {/* <div className="ChromeExtension-below-part-card-discription">
                Lorem ipsum dolor sit amet consectetur. At tincidunt morbi
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChromeExtension;
