import React, { useState } from 'react';
import { Grid, Button, Typography, Dialog } from '@mui/material';
import ChromeIcon from "../../assests/Chrome.svg"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "../ChatbotGradient/ChatbotGradient.css";
import "./ChatbotWhatsappCustomizeSection.css"
import BookAMeetingDialog from '../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog';

const ChatbotWhatsappCustomizeSection = () => {

    const [flagHover, setFlagHover] = useState(false)
    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);

    return (
        <>
            {flagOpenBookaMeetingDemo && (
                <Dialog open={flagOpenBookaMeetingDemo}
                    maxWidth='lg'
                    sx={{ '&.MuiPaper-root': { width: '100%' } }}
                    className="iframe-dialog"
                >
                    <BookAMeetingDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    />
                </Dialog>
            )}
            <Grid container>

                <Grid item xl={7} lg={8} md={10.5} sm={11} xs={11} className='cb-gradiant-main-item'>
                    <Grid container>
                        <Grid item xl={8} lg={10.5} md={10} sm={10} xs={10} sx={{ textAlign: 'center', margin: "0px auto 20px" }}>
                            <Typography component={'span'} className='cw-gradiant-head-text'>
                                Get customized AI Chatbot solutions to Engage, Retain & Grow'
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: 'center', margin: '20px 0px' }}>
                            <Typography variant='span' className='cw-subhead-text'>
                                Ready to explore Writewiz AI? Request a demo today!
                            </Typography>
                        </Grid>

                        <Grid item xl={10} lg={10} md={11} sm={12} xs={12} sx={{ margin: '10px auto 0px' }}>
                            <Grid container sx={{ justifyContent: 'space-between', gap: '10px', alignItems: 'center' }}>
                                <Grid item xs={5.8}>
                                    <Grid container sx={{ justifyContent: 'end' }} >
                                        <Grid item xl={7.5} lg={8} md={8} sm={8} xs={7}>
                                            <Button
                                                fullWidth
                                                size='large'
                                                variant='contained'
                                                className='cw-ai-messaging-btn'
                                                // onClick={() => window.open(`https://writewiz.ai/contact-sales`, '_blank')}
                                                onClick={() => window.open(`https://chatbot.writewiz.ai/`, '_self')}

                                            >
                                                Start AI Messaging
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5.8}>
                                    <Grid container>
                                        <Grid item xl={7.5} lg={8} md={8} sm={8} xs={7}>
                                            <Button
                                                fullWidth
                                                size='large'
                                                variant='outlined'
                                                className={'cw-demo-btn'}
                                                onClick={() => {
                                                    setFlagOpenBookaMeetingDemo(true)
                                                }}
                                            // onClick={() => window.open(`https://writewiz.ai/contact-us`, "_blank")}

                                            >
                                                Schedule a Demo
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>

    )
}

export default ChatbotWhatsappCustomizeSection