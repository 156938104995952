import axios from 'axios';

const AxiosInstance = axios.create({
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_CALL_URL
});

export default AxiosInstance;