import React from 'react'
import {
    Button,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import './Paraphraser.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BrowserFrame from '../../assests/Browser-frame.png';
import Navbar from '../Navbar/Navbar';
import paraphrserImg from '../../assests/Paraphrase.png'
import bookbag from '../../assests/book-bag 1.png'
import Section1 from '../../Component/ParaphraserSections/Section1';
import Section2 from '../../Component/ParaphraserSections/Section2';
import Section3 from '../../Component/ParaphraserSections/Section3';
import Section4 from '../../Component/ParaphraserSections/Section4';
import Section5 from '../../Component/ParaphraserSections/Section5';
import Section6 from '../../Component/ParaphraserSections/Section6';
import LandingFooter from '../../Component/Footer/LandingFooter';
import OverlapFooter from '../../Component/Footer/OverlapFooter';
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter';
import line from '../../assests/Paraphraser-Line.png'

const Paraphraser = () => {
    return (
        <>
            <Navbar />
            <Grid container>
                <Grid item xs={12} className='pr-ai-main-item'>
                    <Grid container>

                        {/* SECTION 1 */}
                        <Grid item xs={12} className='pr-ai-main-section1'>
                            <Section1 />
                        </Grid>
                        {/* SECTION 1 */}

                        {/* SECTION 2 */}
                        <Grid item xs={12} className='pr-ai-main-section2'>
                            <Section2 />
                        </Grid>
                        {/* SECTION 2 */}

                        <Grid item xs={12}>
                            <img src={line} style={{ width: '100%', height: '3px' }} alt="" />
                        </Grid>

                        {/* SECTION 3 */}
                        <Grid item xs={12} className='pr-ai-main-section3'>
                            <Section3 />
                        </Grid>
                        {/* SECTION 3 */}

                        {/* SECTION 4 */}
                        <Grid item xs={12} className='pr-ai-main-section4'>
                            <Section4 />
                        </Grid>
                        {/* SECTION 4 */}

                        {/* SECTION 5 */}
                        <Grid item xs={12} className='pr-ai-main-section5'>
                            <Section5 />
                        </Grid>
                        {/* SECTION 5 */}

                        <Grid item xs={12} className='pr-overlap-item'>
                            <ParaphraseOverlapFooter />
                        </Grid>

                        {/* SECTION 5 */}
                        <Grid item xs={12} className='pr-ai-main-section6'>
                            <LandingFooter />
                        </Grid>
                        {/* SECTION 5 */}

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Paraphraser