import { Backdrop, Button, Grid } from '@mui/material';
import React from 'react'
import rightMarkIcon from '../../assests/rightTickMarkPopup.svg'
import './ContactSalesForm.css'
import CloseIcon from '@mui/icons-material/Close';

const ContactSalesFormPopup = ({setFlagShowPopup}) => {

  return (
    <>
        <div>
            <Backdrop
                sx={{color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >               
                <Grid container className='mainGrid' alignItems={"center"} direction={"column"}>
                    <Grid item className='upperGrid'>
                        <Grid container className='closeButtonGrid'>
                            <Grid item className='closeButtonGridItem'>
                                <Button className='closeButtonStyle'><CloseIcon className='closeIcon' onClick={()=>{setFlagShowPopup(false)}}/></Button>
                            </Grid>
                        </Grid>
                        <Grid container alignItems={"center"} direction="column">
                            <Grid item className='meterWrapper'>
                                <img src={rightMarkIcon} alt="premium" width="80px" height="80px" />
                            </Grid>
                            <Grid item className='heading'>
                                Your inquiry has been recorded successfully. Our team will reach out to you shortly. 
                            </Grid>
                            
                            <Grid item>
                                <Grid container alignItems={"center"} direction={"column"}>
                                    <Grid item className='signUpBtnWrapper' onClick={()=>{setFlagShowPopup(false)}}>
                                        <Button className='closeButton'>Close</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Backdrop>
        </div>
        
    </>
  );

}

export default ContactSalesFormPopup
