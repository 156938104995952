import React, { useState } from 'react'
import './WhatsappChatbot.css'
import "../Chatbot/Chatbot.css";
import '../../Pages/Paraphraser/Paraphraser.css'
import { Grid } from '@mui/material'
import Navbar from '../Navbar/Navbar'
import ChatbotWhatsppInfo from '../../Component/ChatbotWhatsppInfo/ChatbotWhatsppInfo'
import ChatbotWhatsappHeadSection from '../../Component/ChatbotWhatsappHeadSection/ChatbotWhatsappHeadSection'
import ChatbotWhatsappGridSection from '../../Component/ChatbotWhatsappGridSection/ChatbotWhatsappGridSection'
import ChatbotWhatsappTryitSection from '../../Component/ChatbotWhatsappTryitSection/ChatbotWhatsappTryitSection'
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs';
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter';
import LandingFooter from '../../Component/Footer/LandingFooter';
import ChatbotWhatsappCustomSection from '../../Component/ChatbotWhatsappCustomSection/ChatbotWhatsappCustomSection';
import ChatbotWhatsappCustomizeSection from '../../Component/ChatbotWhatsappCustomizeSection/ChatbotWhatsappCustomizeSection';
import ChatbotWhatsappBusinessSection from '../../Component/ChatbotWhatsappBusinessSection/ChatbotWhatsappBusinessSection';
import ChatbotWhatsappCustomAISection from '../../Component/ChatbotWhatsappCustomAISection/ChatbotWhatsappCustomAISection';

const WhatsappChatbot = () => {

    const [pageName, setPageName] = useState('Chatbot');


    return (
        <>
            <Navbar />

            <Grid container>
                <Grid item xs={12}>

                    <Grid container>

                        {/* CHAT HEAD SECTION */}
                        <Grid item xs={12} className='cw-head-section-main-item'>
                            <ChatbotWhatsappHeadSection />
                        </Grid>

                        <Grid item xs={12} className='cw-bz-section-main-item'>
                            <ChatbotWhatsappBusinessSection />
                        </Grid>

                        {/* CHAT GRID SECTION */}
                        <Grid item xs={12} >
                            <ChatbotWhatsappGridSection />
                        </Grid>

                        {/* CHAT INFO SECTION */}
                        <Grid item xs={12} className='cw-info-section-main-item'>
                            <ChatbotWhatsppInfo />
                        </Grid>

                        {/* CHAT GRID SECTION */}
                        <Grid item xs={12} className='cw-tryit-main-item'>
                            <ChatbotWhatsappTryitSection />
                        </Grid>

                        <Grid item xs={12} className='cw-custom-main-item'>
                            <ChatbotWhatsappCustomAISection />
                        </Grid>

                        <Grid item xs={12} className='cw-custom-main-item'>
                            <ChatbotWhatsappCustomizeSection />
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: '180px' }}>
                            {/* <ChatbotFAQs /> */}
                        </Grid>

                        <Grid item xs={12} className='pr-overlap-item'>
                            <ParaphraseOverlapFooter pageName={pageName} />
                        </Grid>

                        <Grid item xs={12} className='brsw-footer-component-main-item'>
                            <LandingFooter />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default WhatsappChatbot