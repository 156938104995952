import AxiosInstance from "../../Services/Axios";


export const createChatbotPlanSubscriptionAPI = async (data, onSuccess, onError) => {
    try {
        const response = await AxiosInstance.post('/stripe/create-chatbot-plan-subscription', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing response of ResendLoginOtpAPI api", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error ResendLoginOtpAPI", err);
        onError && onError(err)
    }
}