import './AlternativePageIntro.css'
import React from 'react'
import { Button, Grid, Typography } from '@mui/material';
import logo1 from "../../assests/logoImg1.svg"

const AlternativePageIntro = () => {
    return (
        <>
            <Grid container className="alternative-page-intro-banner" >
                <Grid item xl={7} lg={9} md={11} sm={11} xs={10} className="alternative-page-intro-banner-maintext">
                    <Typography
                        component={'span'}
                        className='ap-intro-main-head-text'

                    >
                        A&nbsp;
                        <Typography
                            component={'span'}
                            className='ap-intro-main-head-highlighted-text'

                        >
                            copy.ai
                        </Typography>&nbsp;
                        <Typography
                            component={'span'}
                            className='ap-intro-main-head-text'

                        >
                            alternative that confidently delivers impeccable factual accuracy and elevates content quality to a superior level
                        </Typography>

                    </Typography>
                </Grid>

                <Grid item xl={8} lg={11} md={11} sm={11} xs={10} className="alternative-page-intro-banner-subtext">
                    <Typography component={'span'} variant='subtitle1' className='ap-intro-subtitle-text'>
                        Lorem ipsum dolor sit amet consectetur. Elit commodo et feugiat pharetra sed integer.
                    </Typography>
                </Grid>

                <Grid item xl={5} lg={6} md={8} sm={11} xs={12} className='alternative-page-intro-banner-buttoncontainer'>
                    <Grid container>
                        <Grid item sx={{ margin: "0 auto" }}>
                            <Button
                                variant='contained'
                                size='large'
                                fullWidth
                                className='ap-try-writewiz-btn'
                            >Try WriteWiz for free</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AlternativePageIntro;