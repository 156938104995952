import React, { useEffect, useState } from 'react'
import './BrowserExtension.css'
import { Divider, Grid } from '@mui/material'
import Navbar from '../Navbar/Navbar'
import BrowserDownload from '../../Component/BrowserDownload/BrowserDownload'
import BrowserExtFeatures from '../../Component/BrowserExtFeatures/BrowserExtFeatures'
import BrowerExtContent from '../../Component/BrowerExtContent/BrowerExtContent'
import LandingFooter from '../../Component/Footer/LandingFooter'

const BrowserExtension = () => {

    const [scrollEffect, setScrollEffect] = useState(false);

    useEffect(() => {

        const changeStructure = () => {
            if (window.scrollY > 90) {
                setScrollEffect(true);
            } else {
                setScrollEffect(false);
            }
        };

        changeStructure();
        window.addEventListener("scroll", changeStructure);

        return () => {
            window.removeEventListener("scroll", changeStructure);
        };
    }, []);

    return (
        <>
            <Grid container>
                <Navbar scrollEffect={scrollEffect} setScrollEffect={setScrollEffect} />
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className='brsw-component-main-item'>
                            <BrowserDownload />
                        </Grid>
                        <Grid item xs={10} sx={{ margin: "10px auto" }} className='brsw-component-main-item'>
                            <Divider sx={{ border: "1px solid #6A097D" }} />
                        </Grid>
                        <Grid item xs={12} className='brsw-component-main-item'>
                            <BrowserExtFeatures />
                        </Grid>
                        <Grid item xs={10} sx={{ margin: "10px auto" }} className='brsw-component-main-item'>
                            <Divider sx={{ border: "1px solid #6A097D" }} />
                        </Grid>
                        <Grid item xs={12} className='brsw-component-main-item'>
                            <BrowerExtContent />
                        </Grid>
                        <Grid item xs={12} className='brsw-footer-component-main-item'>
                            <LandingFooter />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default BrowserExtension
