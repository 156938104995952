import React from 'react'
import './BrowserExtFeatures.css'
import { CardMedia, Divider, Grid, Typography } from '@mui/material'
import chromeExt from '../../assests/ext1.png'
import rewrite from "../../assests/CE-Rewrite.jpg"
import persona from "../../assests/CE-Persona.jpg"
import customPrompt from "../../assests/CE-Custom Prompt.jpg";
import compose from "../../assests/CE-Compose.jpg";

const BrowserExtFeatures = () => {
    return (
        <>
            <Grid container>
                <Grid item xl={7} lg={8} md={10} sm={11} xs={11} className='brws-ext-feature-head-main-item'>
                    <Grid container>
                        <Grid item xs={12} className='brws-ext-feature-head-item'>
                            <Typography variant='span'>
                                Experience <span>Seamless AI</span> Writing
                            </Typography>
                        </Grid>
                        <Grid item xl={11} lg={10} md={11} sm={12} xs={12} className='brws-ext-feature-head-subtitle-item'>
                            <Typography variant='span' className=''>
                                Stop wrestling with words and start owning them. With Writewiz AI,
                                you get personalized AI assistance that helps you write better and faster.
                                So you can focus on what truly matters—your ideas, not just the words that express them.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xl={9} lg={10} md={11} sm={10} xs={11} sx={{ margin: "80px auto 20px" }} >
                    <Divider />
                </Grid> */}
                <Grid item xl={9} lg={10} md={11} sm={10} xs={11} className='brws-ext-feature-main-item'>
                    <Grid container>
                        <Grid item xs={12} className='brws-ext-feature-sub-item'>
                            <Grid container className='brws-ext-feature-sub-container'>
                                <Grid item xl={5.5} lg={5.5} md={5.7} sm={12} xs={12} >
                                    <Grid container>
                                        <Grid item xs={12} className='brws-ext-features-heading-item'>
                                            <Typography component={'h3'} className=''>
                                                Rewrite with <span>Precision</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={9} lg={9} md={12} sm={12} xs={12} className='brws-ext-features-desc-list-item'>
                                            <dl>
                                                <dt className='brws-feature-dt-list'>Transform and refine your sentences with our intelligent rephrasing tool. Say goodbye to writer’s block!</dt>
                                                <dd>- Improve readability with Simplify</dd>
                                                <dd>- Elevate your content with more option on just a click</dd>
                                                <dd>- Rephrase in your own voice with custom prompting</dd>
                                            </dl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xl={5.5} lg={5.5} md={5.7} sm={12} xs={12} >
                                    <Grid container className='brws-ext-feature-sub-right-section-container' justifyContent={"center"}>
                                        <Grid item className='brws-ext-feature-sub-right-section'>
                                            <CardMedia
                                                component="img"
                                                src={rewrite}
                                                className='brws-ext-feature-sub-right-img'
                                            ></CardMedia>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid container className='brws-ext-feature-sub-container'>
                                <Grid item xl={5.5} lg={5.5} md={5.7} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item xs={10} className='brws-ext-features-heading-item'>
                                            <Typography component={'h3'} className=''>
                                                Your <span>Voice</span>, Your <span>Way</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={9} lg={9} md={12} sm={12} xs={12} className='brws-ext-features-desc-list-item'>
                                            <dl>
                                                <dt className='brws-feature-dt-list'>Choose your desired persona and let Writewiz AI learn your unique voice for a more personalized writing experience.</dt>
                                                <dd>- Improve readability with Simplify</dd>
                                                <dd>- Elevate your content with more option on just a click</dd>
                                                <dd>- Rephrase in your own voice with custom prompting</dd>
                                            </dl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xl={5.5} lg={5.5} md={5.7} sm={12} xs={12}>
                                    <Grid container className="brws-ext-feature-sub-right-section-even" justifyContent={"start"}>
                                        <Grid item className='brws-ext-feature-sub-right-section' >
                                            <CardMedia
                                                component="img"
                                                src={persona}
                                                // sx={{ height: 300, width: "100%", objectFit: "contain" }}
                                                className='brws-ext-feature-sub-right-img'
                                            ></CardMedia>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid container className='brws-ext-feature-sub-container'>
                                <Grid item xl={5.5} lg={5.5} md={5.7} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item xs={10} className='brws-ext-features-heading-item'>
                                            <Typography component={'h3'} className=''>
                                                Stay <span>Contextually</span> Relevant
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={9} lg={9} md={12} sm={12} xs={12} className='brws-ext-features-desc-list-item'>
                                            <dl>
                                                <dt className='brws-feature-dt-list'>Our AI is not just smart; it's aware. Get contextually appropriate suggestions to elevate your content.</dt>
                                                <dd>- Improve readability with Simplify</dd>
                                                <dd>- Elevate your content with more option on just a click</dd>
                                                <dd>- Rephrase in your own voice with custom prompting</dd>
                                            </dl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xl={5.5} lg={5.5} md={5.7} sm={12} xs={12}>
                                    <Grid container justifyContent={"center"}>
                                        <Grid item className='brws-ext-feature-sub-right-section'>
                                            <CardMedia
                                                component="img"
                                                src={customPrompt}
                                                // sx={{ height: 300, objectFit: "contain" }}
                                                className='brws-ext-feature-sub-right-img'
                                            ></CardMedia>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid container className='brws-ext-feature-sub-container'>
                                <Grid item xl={5.5} lg={5.5} md={5.7} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item xs={10} className='brws-ext-features-heading-item'>
                                            <Typography component={'h3'} className=''>
                                                Compose Like a <span>Pro</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={9} lg={9} md={12} sm={12} xs={12} className='brws-ext-features-desc-list-item'>
                                            <dl>
                                                <dt className='brws-feature-dt-list'>From emails to essays, our AI crafts content tailored to your needs. All you need to do is type!</dt>
                                                <dd>- Improve readability with Simplify</dd>
                                                <dd>- Elevate your content with more option on just a click</dd>
                                                <dd>- Rephrase in your own voice with custom prompting</dd>
                                            </dl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xl={5.5} lg={5.5} md={5.7} sm={12} xs={12}>
                                    <Grid container className="brws-ext-feature-sub-right-section-even" justifyContent={"start"}>
                                        <Grid item className='brws-ext-feature-sub-right-section'>
                                            <CardMedia
                                                component="img"
                                                src={compose}
                                                // sx={{ height: 300, width: "100%", objectFit: "contain" }}
                                                className='brws-ext-feature-sub-right-img'
                                            ></CardMedia>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default BrowserExtFeatures