import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";

// Rest of your code remains the same
import "./TextAnimation.css";


const TextAnimation = (props) => {
  const blockquoteRef = useRef(null);

  gsap.registerPlugin(SplitText);

  useEffect(() => {
    // gsap.registerPlugin(SplitText)
    const blockquote = blockquoteRef.current;
    const lines = blockquote.querySelectorAll("#text");
    let currentIndex = 0;

    const quotesArray = [
      "can generate engaging novel dialogues, immersing readers in a world of romance.",
      "can draft persuasive marketing emails, resonating with your target audience.",
      "can summarize lengthy research papers, extracting the key findings & methodologies.",
      "can generate attention-grabbing social media posts, boosting engagement with your followers.",
      "can develop step-by-step DIY guides, ensuring clarity and simplicity in every instruction.",
      // "can write a blog that contains tables comparing the features of various mobile phones for better comparisons",
      "crafts comments and replies that encourage engagement and keep discussions alive.",
      "assists in developing a persona that aligns with your brand, fostering recognition and trust.",
      // "helps you convey emotions effectively, tailored to your persona, whether it's a heartfelt message or a funny anecdote.",
      "not only generates content but proofreads it, so you don't have to worry about typos or grammar errors.",
      "designs Chatbots, enhancing user interaction and satisfaction in a snap.",
      "can bulk-generate marketing assets from just a brief for Campaigns.",
      "can list SEO-powered keywords to watch your article soar to the top of Google.",
      // "can generate captivating dialogue for your novel, immersing readers in a world of suspense or romance”",
      // "can draft persuasive marketing emails that drive higher conversion rates, using language that resonates with your target audience”",
      // "can summarize lengthy research papers into concise and insightful abstracts, extracting the key findings and methodologies”",
      // "can generate witty and attention-grabbing social media posts, increasing your engagement with your followers”",
      // "can develop step-by-step DIY guides for home improvement projects, ensuring clarity and simplicity in every instruction”",
      // "can create a blog that contains table comparing features of different phones”",
      // "helps you convey emotions effectively, resonating with your audience on a personal level, whether it's a heartfelt message or a funny anecdote”",
    ];

    function animateLine() {
      if (currentIndex >= quotesArray.length) {
        currentIndex = 0;
      }
      const currentLine = lines[0]; // Select the only line for animation
      currentLine.textContent = quotesArray[currentIndex]; // Change the content
      // const s = split(currentLine)
      const s = new SplitText(currentLine, {
        type: "words",
        wordsClass: "ts-word"
      });

      const tl = gsap.timeline({
        delay: 0.5,
        repeatDelay: 0.5,
        onComplete: function () {
          setTimeout(function () {
            tl.clear();
            currentLine.textContent = ""; // Clear the content
            // currentIndex+=1;
            animateLine();
          }, 1500); // Delay between animations in milliseconds (2 seconds)
        }
      });

      tl.addLabel("enter");

      tl.staggerFromTo(
        s.words,
        0,
        {
          xPercent: 100
        },
        {
          xPercent: 0,
          ease: "Circ.easeOut"
        },
        0,
        "enter"
      );

      tl.staggerFromTo(
        s.words,
        0.6,
        {
          opacity: 0
        },
        {
          opacity: 1,
          ease: "Power1.easeOut"
        },
        0.25, // time between two wro
        "enter"
      );

      currentIndex++;
    }

    animateLine();
  }, []);



  return (
    <div
      ref={blockquoteRef}
      className="text-animation-container-wrapper"
    >
      <div className="text-animation-container">
        <span className="writeWizz-animation-title"  >Writewiz AI</span>
        <span id="text" className="writeWizz-animation-text" style={{ display: 'inline' }}></span>
      </div>
    </div>
  );
};

export default TextAnimation;
