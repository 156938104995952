import './AlternativeAiPartner.css'
import React from 'react'
import { Button, CardMedia, Grid, Typography } from '@mui/material'
import browserFrame from '../../assests/Browser frame.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const AlternativeAiPartner = () => {
    return (
        <>
            <Grid container className='ap-ai-main-container'>
                <Grid item xl={9} lg={11} md={11} sm={11} xs={11} className='ap-ai-main-item'>
                    <Grid container className='ap-ai-sub-container'>
                        <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className='ap-ai-partner-item1'>
                            <Grid container>
                                <Grid item xs={12} className='ap-ai-head-text-item'>
                                    <Typography variant='span' className='ap-ai-head-text'>Your <Typography variant='span' className='ap-ai-highlight-head-text'>WriteWiz</Typography> AI partner is ready</Typography>
                                </Grid>
                                <Grid item xs={12} className='ap-ai-subtitle-text-item'>
                                    <Typography variant='span' className='ap-ai-subtitle-text'>
                                        Lorem ipsum dolor sit amet consectetur. Elit commodo et feugiat pharetra sed integer. Lorem ipsum dolor sit amet consectetur. Elit commodo et feugiat pharetra sed integer. Lorem ipsum dolor sit amet consectetur. Elit commodo et  feugiat pharetra sed integer.Lorem ipsum dolor sit amet consectetur. Elit commodo et feugiat pharetra sed integer. Lorem ipsum dolor sit amet consectetur. Elit commodo et feugiat pharetra sed integer.
                                    </Typography>
                                </Grid>
                                <Grid item className='ap-ai-wz-btn-item'>
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        size='large'
                                        className='ap-ai-wz-btn'
                                        endIcon={<ArrowForwardIcon />}
                                    >Start Writing with WriteWiz</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className='ap-ai-partner-item2'>
                            <CardMedia
                                component="img"
                                sx={{ height: 400, width: "100%", objectFit: "contain" }}
                                src={browserFrame}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AlternativeAiPartner