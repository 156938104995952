import './AlternativePageInfo.css';
import { Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react';
import WriteWizAlternative from "../../assests/WriteWizAlternative.svg"
import chrome from '../../assests/ChromeLogo.png'
import download from '../../assests/DownloadLogo.svg'
import CardMedia from '@mui/material/CardMedia';
import myImage from '../../assests/CardCheckMark.png'


const AlternativePageInfo = () => {

  const [cardDetails, setcardDetails] = useState([
    {
      title: "Limited Lorem ipsum",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor!",
    },
    {
      title: "Limited Lorem ipsum",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor!",
    },
    {
      title: "Limited Lorem ipsum",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor!",
    },
    {
      title: "Limited Lorem ipsum",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor!",
    },
    {
      title: "Limited Lorem ipsum",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor!",
    },
    {
      title: "Limited Lorem ipsum",
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore, commodi ea nam molestiae facere reprehenderit molestias blanditiis exercitationem, neque accusantium error possimus, labore incidunt porro. Suscipit doloremque repellendus temporibus dolor!",
    },
  ])

  return (
    <Grid container className='ap-info-main-container'>

      <Grid item xl={7} lg={9} md={9} sm={9} xs={9} className='ap-info-ext-header-item'>
        <Grid container>
          <Grid item sx={{ margin: "0 auto", borderBottom: "2px solid #6A097D" }}>
            <Typography variant='span' className='ap-info-head-text1' sx={{ color: "black" }}>Why&nbsp;
              <Typography variant='span' sx={{ color: "#6A097D" }}>WriteWiz</Typography>&nbsp;Wins
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      <Grid item xl={9} lg={10.5} md={11} sm={11} xs={11} className='ap-info-ext-item'>
        <Grid container className='ap-info-ext-container'>

          <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  className='ap-info-ext-head-text'
                  variant='span'
                >
                  Why look for a <Typography component={'span'} className='ap-info-ext-head-text2' sx={{ color: "#6A097D" }}>WriteWiz</Typography>  alternative in the first place?
                </Typography>
              </Grid>
              <Grid item xs={12} className='ap-info-ext-peragraph-item'>
                <Typography
                  variant='p'
                  component={'p'}
                >
                  Lorem ipsum dolor sit amet consectetur. Elit commodo et
                  feugiat pharetra sed integer. Lorem ipsum dolor sit amet consectetur. Elit commodo et feugiat pharetra sed integer. Lorem ipsum dolor sit amet consectetur. Elit commodo et  feugiat pharetra sed integer.Lorem ipsum dolor sit amet consectetur. Elit commodo et feugiat pharetra sed integer.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item className='ap-info-ext-btn-item'>
                    <Button
                      variant='contained'
                      size='large'
                      fullWidth
                      className='ap-info-ext-btn'
                    >
                      <img src={chrome} alt="" />&nbsp;&nbsp;<span>Download Extension</span>&nbsp;&nbsp;<img src={download} alt="" /></Button>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className='ap-info-card-img-item'>
            <CardMedia
              component="img"
              sx={{ height: 400, width: "100%", objectFit: "contain" }}
              src={WriteWizAlternative}
              className='ap-info-alternative-image'
              alt="WriteWiz Intro"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xl={9} lg={11.5} md={11} sm={11} xs={11} className='ap-info-card-item'>
        <Grid container className='ap-info-card-main-container'>
          {cardDetails.map((ele) => {
            return (
              <>
                <Grid item xl={3.8} lg={3.8} md={5.7} sm={5.5} xs={12} className='ap-info-card-main-item'>
                  <Grid container >
                    <Grid item xs={12} className='ap-card-item' >
                      <CardMedia
                        component="img"
                        sx={{ height: 70, width: "auto" }}
                        image={myImage}
                        className='ap-card'
                        alt="Description"
                      />
                    </Grid>
                    <Grid item xs={12} className='ap-info-card-head-item' >
                      <span>{ele.title}</span>
                    </Grid>
                    <Grid item xs={12} className='ap-info-card-details-item'>
                      <Typography variant='span' component={'span'}>
                        {ele.text}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid >
              </>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AlternativePageInfo;