import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import WriteWizMainLogo from '../../assests/WriteWizMainLogo.svg'
import WritewizaiPrimaryLogo from "../../assests/GlobalImages/WritewizAI-Primary-Logo.svg"
import './PageNotFound.css'
import { useNavigate } from 'react-router-dom'


const PageNotFound = () => {

    const navigate = useNavigate()

    return (
        <>
            <Grid container className='ww-pnf-main-container'>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className='ww-pnf-logo-item'>
                            <img
                                src={WritewizaiPrimaryLogo}
                                alt="writewiz logo"
                                style={{ width: "200px", height: "auto", cursor: "pointer" }}
                                onClick={() => { navigate('/') }}
                            />
                        </Grid>
                        <Grid item xs={12} className='ww-pnf-text-item'>
                            <Typography variant='span' className='ww-pnf-text'>Page Not Found</Typography>
                        </Grid>
                        <Grid item xs={12} className='ww-pnf-subtext-item'>
                            <Grid container sx={{ justifyContent: "center" }}>
                                <Grid item xs={2.4}>
                                    <Typography variant='span' className='ww-pnf-subtext-text'>
                                        The page you are looking for doesn't exist
                                        or has been moved
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className='ww-pnf-home-page-btn'>
                            <Button
                                variant='contained'
                                fullWidth
                                size='large'
                                sx={{
                                    color: "white",
                                    textTransform: "none",
                                    background: "#6A097D",
                                    borderRadius: "5px",
                                    fontWeight: "500",
                                    padding: "12px 25px !important",
                                    '&.MuiButton-root:hover': { bgcolor: '#6A097D' },
                                    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                                    fontSize: "17px",
                                    fontWeight: "500"
                                }}
                                onClick={() => { navigate('/') }}
                            >
                                Back to homepage
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default PageNotFound