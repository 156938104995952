import { Button, CardMedia, Divider, Grid, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import checkMark from './assets/CheckMark.svg'
import crossMark from './assets/CrossMark.svg'

const PricingPlansFeatures = (props) => {

    const { subType, plusValue, premiumValue, calculateCredit, pricingFeatureDetails, pricingDetails } = props

    // console.log("pricingFeatureDetails", pricingDetails);
    // console.log("pricingDetailsfeaturepricingDetails aabb", pricingDetails.length);

    const [comparePlansDetails, setComparePlansDetails] = useState(pricingFeatureDetails)
    const [starterPlanCredit, setStarterPlanCredit] = useState({
        label: pricingDetails.find(e => e.plan_name === "Starter") && pricingDetails.length && pricingDetails.find(e => e.plan_name === "Starter")?.monthly_plan[0].value,
        monthlyValue: pricingDetails.find(e => e.plan_name === "Starter") && pricingDetails.length && pricingDetails.find(e => e.plan_name === "Starter")?.monthly_plan[0].value,
        yearlyValue: pricingDetails.find(e => e.plan_name === "Starter") && pricingDetails.length && pricingDetails.find(e => e.plan_name === "Starter")?.yearly_plan[0].value,
    })
    const [premiumPlanCredit, setPremiumPlanCredit] = useState({
        label: pricingDetails.length && pricingDetails.find(e => e.plan_name === "Premium") && pricingDetails.find(e => e.plan_name === "Premium")?.monthly_plan[0].value,
        monthlyValue: pricingDetails.length && pricingDetails.find(e => e.plan_name === "Premium") && pricingDetails.find(e => e.plan_name === "Premium")?.monthly_plan[0].value,
        yearlyValue: pricingDetails.length && pricingDetails.find(e => e.plan_name === "Premium") && pricingDetails.find(e => e.plan_name === "Premium")?.yearly_plan[0].value,
    })

    // console.log("subType ", pricingDetails.length && pricingDetails.find(e => e.plan_name === "Premium").monthly_plan[0].value);

    const [selectedPlan, setSelectedPlan] = useState(
        {
            label: null,
            monthlyValue: null,
            yearlyValue: null
        });

    useEffect(() => {
        console.log("pricingDetails->", pricingDetails);
        console.log("pricingDetails->", pricingDetails.length);
        if (pricingDetails.find(e => e.plan_name === "Starter")) {
            setStarterPlanCredit({
                label: pricingDetails.length && pricingDetails.find(e => e.plan_name === "Starter").monthly_plan[0].label,
                monthlyValue: pricingDetails.length && pricingDetails.find(e => e.plan_name === "Starter").monthly_plan[0].value,
                yearlyValue: pricingDetails.length && pricingDetails.find(e => e.plan_name === "Starter").yearly_plan[0].value,
            })
        }
        if (pricingDetails.find(e => e.plan_name === "Premium")) {
            setPremiumPlanCredit({
                label: pricingDetails.length && pricingDetails.find(e => e.plan_name === "Premium").monthly_plan[0].label,
                monthlyValue: pricingDetails.length && pricingDetails.find(e => e.plan_name === "Premium").monthly_plan[0].value,
                yearlyValue: pricingDetails.length && pricingDetails.find(e => e.plan_name === "Premium").yearly_plan[0].value,
            })
        }
    }, [pricingDetails])

    const handlePlanChange = (label, planName) => {

        const monthlyValue = pricingDetails.find(e => e.plan_name === planName).monthly_plan?.find(plan => plan.label === label)?.value;
        const yearlyValue = pricingDetails.find(e => e.plan_name === planName).yearly_plan?.find(plan => plan.label === label)?.value;

        if (planName == "Starter") {
            setStarterPlanCredit({
                label,
                monthlyValue,
                yearlyValue
            });
        }
        else if (planName == "Premium") {
            setPremiumPlanCredit({
                label,
                monthlyValue,
                yearlyValue
            });
        }

        setSelectedPlan({
            label,
            monthlyValue,
            yearlyValue
        });
    };

    // console.log("pricingDetailsfeaturepricingDetails starterPlanCredit", starterPlanCredit);
    // console.log("pricingDetailsfeaturepricingDetails starterPlanCredit premiumPlanCredit", premiumPlanCredit);

    // const handleStarterPlanChange = (event) => {
    //     setStarterPlanCredit(event.target.value);
    // }

    useEffect(() => {
        setComparePlansDetails(pricingFeatureDetails)
    }, [pricingFeatureDetails])

    // console.log("comparePlansDetails", comparePlansDetails);
    // console.log("pricingDetails", pricingDetails);

    const [plansFeaturesDetails, setPlansFeaturesDetails] = useState([
        {
            name: 'Rate per month - monthly / user',
            subtitle: 'Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue',
            plans: {
                Free_Plan: '10',
                Starter_Plan: ' 20',
                Premium_Plan: '30',
            }
        },
        {
            name: 'Users',
            subtitle: 'Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue',
            plans: {
                Free_Plan: 'Includes 1 User',
                Starter_Plan: 'Includes 1 Users',
                Premium_Plan: 'unlimited',
            }
        },
        {
            name: 'Users',
            subtitle: 'Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue',
            plans: {
                Free_Plan: 'Includes 1 User',
                Starter_Plan: 'Includes 1 Users',
                Premium_Plan: 'unlimited',
            }
        },
        {
            name: 'Users 2',
            subtitle: 'Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue',
            plans: {
                Free_Plan: false,
                Starter_Plan: true,
                Premium_Plan: true,
            }
        },
    ])

    console.log("pricingDetails", pricingDetails);


    return (
        <>
            <Grid container>
                <Grid item xs={12} className='pp-compare-plans-main-item'>
                    <Grid container className='pp-compare-plans-main-container' sx={{ alignItems: "center" }}>

                        <Grid item xs={12}>
                            <Grid container className='pp-plan-feature-container1'>

                                <Grid item xl={3} lg={3} md={3} sm={3.2} xs={12}>
                                    <Grid container  >
                                        <Grid item xs={12} className='pp-cp-feature-grid-title-item' >
                                            <Typography
                                                component={"span"}
                                                variant='h5'
                                                className='pp-cp-feature-grid-title'
                                            >
                                                Compare Plans
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xl={8} lg={8} md={8} sm={8.2} xs={12} className='pp-plans-table-item1' >
                                    <Grid container className='pp-plans-table-container1'>

                                        {pricingDetails.find(e => e.plan_name === "Free") &&
                                            <Grid item
                                                className='pp-plan-right-border-item pp-free-plan-sub-item1'
                                                xl={4} lg={4} md={pricingDetails.length == 2 ? 6 : 4} sm={pricingDetails.length == 2 ? 6 : 4} xs={pricingDetails.length == 2 ? 6 : 4}
                                            >
                                                <Grid container>
                                                    <Grid item xs={12} className='pp-cp-title-item'>
                                                        <Typography
                                                            component={"span"}
                                                            variant='body1'
                                                            className='pp-cp-plan-names'
                                                        >
                                                            Free
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ textAlign: "center" }} className='pp-plan-feature-subtitle-item' >
                                                        <Typography
                                                            component={"span"}
                                                            className='pp-plan-subtitle-text'
                                                        >
                                                            Access to both Web app & chrome <br />
                                                            extension
                                                        </Typography>
                                                    </Grid>
                                                    {window.screen.width > 600 &&
                                                        <Grid item xs={12} className='pp-plan-feature-btn-item'>
                                                            <Button
                                                                variant='outlined'
                                                                size='medium'
                                                                // fullWidth={true}
                                                                className='pp-cp-outlined-btn'
                                                                onClick={() => {
                                                                    window.location.href = 'https://app.writewiz.ai/dashboard/settings'
                                                                }}
                                                            >
                                                                Select Plan
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }

                                        {pricingDetails.find(e => e.plan_name === "Starter") &&
                                            <Grid item
                                                xl={4} lg={4} md={pricingDetails.length == 2 ? 6 : 4} sm={pricingDetails.length == 2 ? 6 : 4} xs={pricingDetails.length == 2 ? 6 : 4}
                                                className='pp-plan-right-border-item pp-starter-plan-sub-item1'
                                            >
                                                <Grid container>
                                                    <Grid item xs={12} className='pp-cp-title-item'>
                                                        <Typography
                                                            component={"span"}
                                                            variant='body1'
                                                            className='pp-cp-plan-names'
                                                        >
                                                            Starter
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ textAlign: "center" }} className='pp-plan-feature-subtitle-item' >
                                                        <Typography
                                                            component={"span"}
                                                            className='pp-plan-subtitle-text'
                                                        >
                                                            Access to both Web app & chrome <br />
                                                            extension
                                                        </Typography>
                                                    </Grid>
                                                    {window.screen.width > 600 &&
                                                        <Grid item xs={12} className='pp-plan-feature-btn-item'>
                                                            <Button
                                                                variant='outlined'
                                                                size='medium'
                                                                // fullWidth={true}
                                                                className='pp-cp-outlined-btn'
                                                                onClick={() => {
                                                                    window.location.href = 'https://app.writewiz.ai/dashboard/settings'
                                                                }}
                                                            >
                                                                Select Plan
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }

                                        {pricingDetails.find(e => e.plan_name === "Premium") &&
                                            <Grid item
                                                xl={4} lg={4} md={pricingDetails.length == 2 ? 6 : 4} sm={pricingDetails.length == 2 ? 6 : 4} xs={pricingDetails.length == 2 ? 6 : 4}
                                                className='pp-premium-plan-sub-item1'
                                            >
                                                <Grid container>
                                                    <Grid item xs={12} className='pp-cp-title-item'>
                                                        <Typography
                                                            component={"span"}
                                                            variant='body1'
                                                            className='pp-cp-plan-names'
                                                        >
                                                            Premium
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ textAlign: "center" }} className='pp-plan-feature-subtitle-item' >
                                                        <Typography
                                                            component={"span"}
                                                            className='pp-plan-subtitle-text'
                                                        >
                                                            Access to both Web app & chrome <br />
                                                            extension
                                                        </Typography>
                                                    </Grid>
                                                    {window.screen.width > 600 &&
                                                        <Grid item xs={12} className='pp-plan-feature-btn-item'>
                                                            <Button
                                                                variant='outlined'
                                                                size='medium'
                                                                // fullWidth={true}
                                                                className='pp-cp-outlined-btn'
                                                                onClick={() => {
                                                                    window.location.href = 'https://app.writewiz.ai/dashboard/settings'
                                                                }}
                                                            >
                                                                Select Plan
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {comparePlansDetails?.map((ele, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Grid container className='pp-plan-feature-container2'>
                                        <Grid item xl={3} lg={3} md={3} sm={3.2} xs={12} className='pp-cpc-gb-left-head-item'>
                                            <Grid container>
                                                <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography component={'span'} className='pp-cpc-gb-left-head-text'>
                                                        {ele?.name}
                                                    </Typography>
                                                    &nbsp;
                                                    <HelpOutlineRoundedIcon className='pp-cpc-gb-help-icon' />
                                                </Grid>
                                                <Grid item xs={12} className='pp-cpc-gb-left-sub-head-text-item' >
                                                    <Typography component={'span'} className='pp-cpc-gb-left-sub-head-text'>
                                                        {/* {ele?.subtitle} */}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xl={8} lg={8} md={8} sm={8.2} xs={12}
                                            className={`pp-plans-table-item2 ${index === comparePlansDetails.length - 1 ? 'pp-plans-table-item2-last-row' : ''}`}
                                        >
                                            <Grid container className='pp-plans-table-container2' >
                                                {pricingDetails.find(e => e.plan_name === "Free") &&
                                                    <Grid item
                                                        xl={4} lg={4} md={pricingDetails.length == 2 ? 6 : 4} sm={pricingDetails.length == 2 ? 6 : 4} xs={pricingDetails.length == 2 ? 6 : 4}
                                                        className='pp-cpc-gb-feature-item pp-plan-right-border-item'
                                                    >
                                                        {typeof ele?.plans.Free_Plan === 'boolean' ? (
                                                            ele?.plans.Free_Plan ? (
                                                                <CardMedia
                                                                    component="img"
                                                                    className='pp-cpc-gb-left-card-media'
                                                                    image={checkMark}
                                                                    alt="Checked"
                                                                />
                                                            ) : (
                                                                <CardMedia
                                                                    component="img"
                                                                    className='pp-cpc-gb-left-card-media'
                                                                    image={crossMark}
                                                                    alt="Cross"
                                                                />
                                                            )
                                                        ) : (
                                                            <Typography component={'span'} className='pp-cpc-gb-feature-text'>
                                                                {ele?.plans.Free_Plan}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                }

                                                {pricingDetails.find(e => e.plan_name === "Starter") &&
                                                    <Grid item
                                                        xl={4} lg={4} md={pricingDetails.length == 2 ? 6 : 4} sm={pricingDetails.length == 2 ? 6 : 4} xs={pricingDetails.length == 2 ? 6 : 4}
                                                        className='pp-cpc-gb-feature-item pp-plan-right-border-item'
                                                    >
                                                        {typeof ele?.plans.Starter_Plan === 'boolean' ? (
                                                            ele?.plans.Starter_Plan ? (
                                                                <CardMedia
                                                                    component="img"
                                                                    className='pp-cpc-gb-left-card-media'
                                                                    image={checkMark}
                                                                    alt="Checked"
                                                                />
                                                            ) : (
                                                                <CardMedia
                                                                    component="img"
                                                                    className='pp-cpc-gb-left-card-media'
                                                                    image={crossMark}
                                                                    alt="Cross"
                                                                />
                                                            )
                                                        ) : ((ele.select == true) ? (
                                                            <Grid container>
                                                                <Grid item xl={5} lg={5} md={5.5} sm={8} xs={9} sx={{ margin: "0 auto" }}>
                                                                    <Select
                                                                        id="demo-simple-select"
                                                                        className='pp-plan-dropdown'
                                                                        fullWidth
                                                                        size='small'
                                                                        value={starterPlanCredit.label
                                                                            //     pricingDetails.find(e => e.plan_name === "Starter").monthly_plan?.map((ele1, i) => (
                                                                            //     ele1?.label
                                                                            // ))
                                                                        }
                                                                        // label="Age"
                                                                        onChange={(e) => {
                                                                            handlePlanChange(e.target.value, "Starter");
                                                                        }}
                                                                    >
                                                                        {pricingDetails.find(e => e.plan_name === "Starter").monthly_plan?.map((ele1, i) => (
                                                                            <MenuItem value={ele1?.label} key={ele1?.label}>
                                                                                {ele1?.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </Grid>
                                                            </Grid>

                                                        ) : (
                                                            <Typography component={'span'} className='pp-cpc-gb-feature-text'>
                                                                {ele.name === "Rate per month - monthly / user" ? starterPlanCredit.monthlyValue ? starterPlanCredit.monthlyValue : "-" : ele.name === "Rate per month - Annually / user" ? starterPlanCredit.yearlyValue ? starterPlanCredit.yearlyValue : "-" :
                                                                    // ele.name == "Brand Voice" ?
                                                                    ele.plans.Starter_Plan
                                                                    //  : "-"
                                                                }
                                                            </Typography>
                                                        )
                                                        )}
                                                    </Grid>
                                                }

                                                {pricingDetails.find(e => e.plan_name === "Premium") &&
                                                    <Grid item
                                                        xl={4} lg={4} md={pricingDetails.length == 2 ? 6 : 4} sm={pricingDetails.length == 2 ? 6 : 4} xs={pricingDetails.length == 2 ? 6 : 4}
                                                        className='pp-cpc-gb-feature-item'
                                                    >
                                                        {typeof ele?.plans.Premium_Plan === 'boolean' ? (
                                                            ele?.plans.Premium_Plan ? (
                                                                <CardMedia
                                                                    component="img"
                                                                    className='pp-cpc-gb-left-card-media'
                                                                    image={checkMark}
                                                                    alt="Checked"
                                                                />
                                                            ) : (
                                                                <CardMedia
                                                                    component="img"
                                                                    className='pp-cpc-gb-left-card-media'
                                                                    image={crossMark}
                                                                    alt="Checked"
                                                                />
                                                            )
                                                        ) : ((ele.select == true) ? (
                                                            <Grid container>
                                                                <Grid item xl={5} lg={5} md={5.5} sm={8} xs={9} sx={{ margin: "0 auto" }}>
                                                                    <Select
                                                                        id="demo-simple-select"
                                                                        className='pp-plan-dropdown'
                                                                        fullWidth
                                                                        size='small'
                                                                        value={premiumPlanCredit.label
                                                                            //     pricingDetails.find(e => e.plan_name === "Premium").monthly_plan?.map((ele1, i) => (
                                                                            //     ele1?.label
                                                                            // ))
                                                                        }
                                                                        onChange={(e) => {
                                                                            handlePlanChange(e.target.value, "Premium");
                                                                        }}
                                                                    >
                                                                        {pricingDetails.find(e => e.plan_name === "Premium").monthly_plan?.map((ele1, i) => (
                                                                            <MenuItem value={ele1?.label} key={ele1?.label}>
                                                                                {ele1?.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </Grid>
                                                            </Grid>
                                                        ) :
                                                            (
                                                                <Typography component={'span'} className='pp-cpc-gb-feature-text'>
                                                                    {ele.name === "Rate per month - monthly / user" ? premiumPlanCredit.monthlyValue ? premiumPlanCredit.monthlyValue : "-" : ele.name === "Rate per month - Annually / user" ? premiumPlanCredit.yearlyValue ? premiumPlanCredit.yearlyValue : "-" :
                                                                        //  ele.name == "Brand Voice" ? 
                                                                        ele.plans.Premium_Plan
                                                                        //  : "-"
                                                                    }
                                                                </Typography>
                                                            )
                                                        )}
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            )
                        })}



                        {/* {plansFeaturesDetails.map((ele, index) => (
                            <Grid item xs={12} key={index}>
                                <Grid container className='pp-plan-feature-container2'>
                                    <Grid item xl={3} lg={3} md={3} sm={3.2} xs={3} className='pp-cpc-gb-left-head-item'>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                                <Typography component={'span'} className='pp-cpc-gb-left-head-text'>
                                                    {ele?.name}
                                                </Typography>
                                                &nbsp;
                                                <HelpOutlineRoundedIcon className='pp-cpc-gb-help-icon' />
                                            </Grid>
                                            <Grid item xs={12} className='pp-cpc-gb-left-sub-head-text-item' >
                                                <Typography component={'span'} className='pp-cpc-gb-left-sub-head-text'>
                                                    {ele?.subtitle}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xl={8} lg={8} md={8} sm={8.2} xs={8}
                                        className={`pp-plans-table-item2 ${index === plansFeaturesDetails.length - 1 ? 'pp-plans-table-item2-last-row' : ''
                                            }`}
                                    >
                                        <Grid container className='pp-plans-table-container2' >
                                            <Grid item xs={4} className='pp-cpc-gb-feature-item pp-plan-right-border-item'>
                                                <Typography component={'span'} className='pp-cpc-gb-feature-text'>
                                                    {typeof ele?.plans.Free_Plan === 'boolean' ? (
                                                        ele?.plans.Free_Plan ? (
                                                            <CardMedia
                                                                component="img"
                                                                // sx={{ width: 60, height: 60 }}
                                                                image={checkMark}
                                                                alt="Checked"
                                                            />
                                                        ) : (
                                                            <CardMedia
                                                                component="img"
                                                                // sx={{ width: 60, height: 60 }}
                                                                image={crossMark}
                                                                alt="Checked"
                                                            />
                                                        )
                                                    ) : (
                                                        <Typography component={'span'} className='pp-cpc-gb-feature-text'>
                                                            {ele?.plans.Free_Plan}
                                                        </Typography>
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} className='pp-cpc-gb-feature-item pp-plan-right-border-item'>
                                                <Typography component={'span'} className='pp-cpc-gb-feature-text'>
                                                    {typeof ele?.plans.Starter_Plan === 'boolean' ? (
                                                        ele?.plans.Starter_Plan ? (
                                                            <CardMedia
                                                                component="img"
                                                                // sx={{ width: 60, height: 60 }}
                                                                image={checkMark}
                                                                alt="Checked"
                                                            />
                                                        ) : (
                                                            <CardMedia
                                                                component="img"
                                                                // sx={{ width: 60, height: 60 }}
                                                                image={crossMark}
                                                                alt="Checked"
                                                            />
                                                        )
                                                    ) : (
                                                        <Typography component={'span'} className='pp-cpc-gb-feature-text'>
                                                            {ele?.plans.Starter_Plan}
                                                        </Typography>
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} className='pp-cpc-gb-feature-item'>
                                                <Typography component={'span'} className='pp-cpc-gb-feature-text'>
                                                    {typeof ele?.plans.Premium_Plan === 'boolean' ? (
                                                        ele?.plans.Premium_Plan ? (
                                                            <CardMedia
                                                                component="img"
                                                                // sx={{ width: 60, height: 60 }}
                                                                image={checkMark}
                                                                alt="Checked"
                                                            />
                                                        ) : (
                                                            <CardMedia
                                                                component="img"
                                                                // sx={{ width: 60, height: 60 }}
                                                                image={crossMark}
                                                                alt="Checked"
                                                            />
                                                        )
                                                    ) : (
                                                        <Typography component={'span'} className='pp-cpc-gb-feature-text'>
                                                            {ele?.plans.Premium_Plan}
                                                        </Typography>
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        ))} */}


                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default PricingPlansFeatures