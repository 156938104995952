import React, { useState } from 'react'
import {
    Button,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Typography,
    ListItemIcon
} from "@mui/material";
import '../../Pages/Paraphraser/Paraphraser.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import toneofVoice from '../../assests/Sec1-List-Tone-Of-Voice.svg'
import multiLanguageSupport from '../../assests/Sec1-List-Multi-Language.svg'
import customPrompting from '../../assests/Sec1-List-Custom-Prompt.svg'
import readingEase from '../../assests/Sec1-List-Book.svg'
import includeExcludeWords from '../../assests/Sec1-List-Include-exclude.svg'
import { useMediaQuery } from "@mui/material";
import arrow from '../../assests/Paraphraser-Arrow.svg'

const Section1 = () => {

    const smallScreen = useMediaQuery('(min-width: 0px) and (max-width: 630px)')
    const [paraphraserPoints, setParaphraserPoints] = useState(
        [
            {
                index: 1,
                label: "Tone of Voice",
                icon: toneofVoice
            },
            {
                index: 2,
                label: "Multi Language Support",
                icon: multiLanguageSupport,
            },
            {
                index: 3,
                label: "Custom Prompting",
                icon: customPrompting,
            },
            {
                index: 4,
                label: "Reading Ease",
                icon: readingEase
            },
            {
                index: 5,
                label: "Persona",
                icon: readingEase
            },
            {
                index: 6,
                label: "Include/Exclude Words",
                icon: includeExcludeWords
            },
        ]
    )


    return (
        <>
            <Grid container>
                <Grid item xl={11} lg={11} md={11.5} sm={smallScreen ? 11.5 : 11} xs={11.5} className='pr-ai-sub-section'>
                    <Grid container className='pr-ai-sub-section-container'>
                        <Grid item xl={5} lg={6.4} md={8} sm={12} xs={12}>
                            <Grid container>
                                <Grid item xs={12} className='pr-ai-main-head-item' >
                                    <Typography
                                        component={'span'}
                                        className='pr-ai-main-head'
                                    >
                                        Free AI <Typography variant='span' sx={{ fontSize: { sm: "46px", xs: "30px" }, fontWeight: "600", color: "#FFB762" }}>Paraphrase</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className='pr-ai-sub-head-item'>
                                    <Typography
                                        component={'span'}
                                        className='pr-ai-sub-head'
                                    >
                                        Rewrite Your Text with Advanced AI Features. Go beyond simple rewording to draft your message with precision. Get SEO-friendly, unique rewritten content using a range of
                                        <Typography component={'span'} className='pr-ai-sec1-italic-label'> features like</Typography>.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className='pr-ai-subtitle-item'>
                                    <Grid container sx={{ justifyContent: { sm: 'start', xs: 'space-between' } }}>
                                        {/* {paraphraserPoints.map((points) => {
                                            return (
                                                <>
                                                    <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={5.5} className='pr-ai-points-list-main-item'>
                                                        <Grid container className='pr-ai-points-list-main-container' >
                                                            <Grid item>
                                                                <img src={markIcon} style={{ width: "20px", height: "auto" }} alt="" />
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <Typography variant='span' sx={{ color: "#fff" }}>
                                                                    {points}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                        })} */}

                                        {paraphraserPoints.map((points) => {
                                            return (
                                                <>
                                                    <Grid item className='pr-ai-points-list-main-item'>
                                                        <Grid container className='pr-ai-points-list-main-container'>
                                                            <Grid item className='pr-ai-points-part1'>
                                                                <Grid container sx={{ justifyContent: 'center', alignItem: 'center' }}>
                                                                    <img src={points.icon} alt="" className='pr-ai-points-icon' />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item className='pr-ai-points-part2'>
                                                                <Typography variant='span'>
                                                                    {points.label}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Grid container sx={{ justifyContent: "start", gap: "30px" }}>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                fullWidth
                                                size='large'
                                                endIcon={<ArrowForwardIcon style={{ color: "#6A097D" }} />}
                                                className='pr-start-paraphrase-btn'
                                                onClick={() => {
                                                    window.open("https://app.writewiz.ai/dashboard/paraphraser", '_blank')
                                                }}
                                            >
                                                Start Paraphrase
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid item xs={12} sx={{ margin: "0px 0px 10px" }} >
                                    <Typography component={'span'} sx={{ fontSize: "15px", fontWeight: "500", color: "white" }}>
                                        No credit card required
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={5} lg={5} md={12} sm={12} xs={12} className='pr-sec1-img-item'>
                            <Grid container>
                                <Grid item xs={12}>
                                    <img className='browser-img' src={"https://storage.writewiz-staging.in/general/Paraphrasing_GIF_1708005027529.gif"} alt="Writewiz AI Powered Paraphrasing Tool" />
                                </Grid>
                                <Grid item xs={12} className='pr-start-btn-main-item'>
                                    <Grid container sx={{ justifyContent: "center" }}>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                fullWidth
                                                size='large'
                                                endIcon={<img src={arrow} style={{ width: "20px", height: "auto" }} />}
                                                className='pr-start-paraphrase-btn'
                                                onClick={() => {
                                                    window.open("https://app.writewiz.ai/dashboard/paraphraser", '_blank')
                                                }}
                                            >
                                                Start Paraphrase
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Section1