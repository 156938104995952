import React, { useEffect, useState } from 'react'
import './WelcomeForm.css'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, Card, CardContent, CardMedia, Dialog, Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Step2 from './Step2';
import Step1 from './Step1';
import Step3 from './Step3';
import Step4 from './Step4';
import writerLogo from '../../assests/Wzeditorsvg.svg'
import wzchatbot from '../../assests/Wzchatbot.svg'
import WelcomeNavbar from './WelcomeNavbar';
import WelcomeCExtDialog from './WelcomeCExtDialog';


const WelcomeForm = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const pageURL = window.location.search

    const [searchParams, setSearchParams] = useSearchParams();
    let currentPage = searchParams.get("currentPage");
    const [flagOpenChromeExtentionDialog, setFlagOpenChromeExtentionDialog] = useState(false)
    const [showCreditsMessage, setShowCreditsMessage] = useState(false)
    const [flagCheckUserLogin, setFlagCheckUserLogin] = useState(null)
    const [loginDetails, setLoginDetails] = useState([])

    const searchKey = "currentPage";
    const searchIndex = location.search.indexOf(searchKey);
    const pathWithSearchKey = searchIndex !== -1 ? location.pathname + location.search.substring(0, searchIndex + searchKey.length) : location.pathname;
    const steps = ["rewrite", "spellchecker", "pinbrowser", "useplatform"];
    const stepComponents = {
        "rewrite": <Step1 />,
        "spellchecker": <Step2 />,
        "pinbrowser": <Step3 />,
        "useplatform": <Step4 />
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_CALL_URL + '/user/get-user-detail', {
                    credentials: "include",
                });

                if (!response.ok) {
                    setFlagOpenChromeExtentionDialog(true)
                    throw new Error('Network response was not ok');

                }
                const jsonData = await response.json();

                console.log("jsonData", jsonData);

                if (jsonData?.status) {
                    let userDetails = jsonData?.data[0]
                    // console.log("userDetails", userDetails);
                    setLoginDetails({
                        ...loginDetails,
                        userEmail: userDetails?.user_email,
                        userFirstName: userDetails?.user_first_name,
                    })
                    setFlagCheckUserLogin(true)
                    setFlagOpenChromeExtentionDialog(false)
                }
                else {
                    // setFlagCheckUserLogin(false)
                    setFlagOpenChromeExtentionDialog(true)
                }
            }
            catch (error) {
                console.log("error", error);
            }
        };

        // Call the fetchData function when the component mounts
        fetchData();
    }, []);



    // Old comented
    // useEffect(() => {
    //     if (!url.searchParams.has("currentPage")) {
    //         url.searchParams.append("currentPage", "one");
    //         const newUrl = url.toString();
    //         window.location.href = newUrl;
    //     }
    // }, [])

    // const handleNext = () => {
    //     let currentPage = searchParams.get("currentPage");
    //     if (currentPage == "one") {
    //         navigate(`/onboarding?currentPage=two`);
    //     }
    //     else if (currentPage == "two") {
    //         navigate(`/onboarding?currentPage=three`);
    //     }
    //     else if (currentPage == "three") {
    //         navigate(`/onboarding?currentPage=four`);
    //     }
    // };

    // const handleBack = () => {
    //     let currentPage = searchParams.get("currentPage");

    //     if (currentPage == "four") {
    //         navigate(`/onboarding?currentPage=three`);
    //     }
    //     else if (currentPage == "three") {
    //         navigate(`/onboarding?currentPage=two`);
    //     }
    //     else if (currentPage == "two") {
    //         navigate(`/onboarding?currentPage=one`);
    //     }
    // };

    // const renderStepComponent = () => {
    //     let currentPage = searchParams.get("currentPage");

    //     if (currentPage == "one") {
    //         return <Step1 />
    //     }
    //     else if (currentPage == "two") {
    //         return <Step2 />
    //     }
    //     else if (currentPage == "three") {
    //         return <Step3 />
    //     }
    //     else if (currentPage == "four") {
    //         return <Step4 />;
    //     }
    // };

    // useEffect(() => {
    //     const dialogShownBefore = localStorage.getItem('dialogShownBefore');
    //     if (dialogShownBefore) {
    //         setShowCreditsMessage(true);
    //     }
    // }, []);

    useEffect(() => {
        const url = new URL(window.location.href);
        const currentPage = url.searchParams.get("currentPage");

        if (!currentPage) {
            url.searchParams.append("currentPage", "rewrite");
            const newUrl = url.toString();
            window.location.href = newUrl;
        }

        // Check if the dialog has been shown before
        const dialogShownBefore = localStorage.getItem('dialogShownBefore');
        if (currentPage === 'rewrite' && !dialogShownBefore) {
            setFlagOpenChromeExtentionDialog(true);
        }
    }, []);

    const handleSkip = () => {
        setFlagOpenChromeExtentionDialog(false);
        localStorage.setItem('dialogShownBefore', 'true');
        setShowCreditsMessage(true);
    };

    useEffect(() => {
        renderStepComponent()
    }, [searchParams])

    const handleNext = () => {
        const currentPage = searchParams.get("currentPage");
        const currentIndex = steps.indexOf(currentPage);
        if (currentIndex < steps.length - 1) {
            const nextPage = steps[currentIndex + 1];
            navigate(`${pathWithSearchKey}=${nextPage}`);
        }
    };

    const handleBack = () => {
        const currentPage = searchParams.get("currentPage");
        const currentIndex = steps.indexOf(currentPage);
        if (currentIndex > 0) {
            const previousPage = steps[currentIndex - 1];
            navigate(`${pathWithSearchKey}=${previousPage}`);
        }
    };

    const renderStepComponent = () => {
        const currentPage = searchParams.get("currentPage");
        return stepComponents[currentPage] || null;
    };

    const handleDialogClose = () => {
        setFlagOpenChromeExtentionDialog(false);
    };

    return (
        <>
            {flagOpenChromeExtentionDialog &&
                <Dialog open={flagOpenChromeExtentionDialog} maxWidth={'sm'} onClose={handleDialogClose}>
                    <WelcomeCExtDialog
                        flagOpenChromeExtentionDialog={flagOpenChromeExtentionDialog}
                        setFlagOpenChromeExtentionDialog={setFlagOpenChromeExtentionDialog}
                        onSkip={handleSkip}
                        onClose={handleDialogClose}
                        flagCheckUserLogin={flagCheckUserLogin}
                        setFlagCheckUserLogin={setFlagCheckUserLogin}
                    />
                </Dialog>
            }
            <Grid container className='wp-main-container' >
                <Grid item xs={12} className='wp-main-item'>
                    <Grid container className='wp-sub-container'>
                        <Grid item xs={11.7} className='wp-sub-item'>
                            <Grid container className='wp-onbarding-sub-container'>
                                <Grid item xs={12} className="wp-navbar-item">
                                    <WelcomeNavbar showCreditsMessage={showCreditsMessage} flagCheckUserLogin={flagCheckUserLogin} />
                                </Grid>

                                {currentPage == "rewrite" ? (
                                    <Grid item xs={12} className='wp-extention-link-item'>
                                        <span>Try it out</span>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} className='wp-extention-link-item2' >
                                        <span></span>
                                    </Grid>
                                )
                                }

                                <Grid item xl={6} lg={9} md={11} sm={12} xs={12} className='wp-ext-input-ht-item' >
                                    <Grid container>

                                        <Grid item xl={12} lg={12} md={11} sm={12} xs={12} className='wp-render-component-item'>
                                            {renderStepComponent()}
                                        </Grid>

                                        {pageURL !== "?currentPage=useplatform" &&
                                            <>
                                                <Grid item xl={11} lg={9} md={8} xs={12} className='wp-ext-next-back-btn-item'>
                                                    <Grid container className='wp-ext-next-back-btn-container'>
                                                        {currentPage !== "rewrite" ?
                                                            (<Grid item xs={1.5}>
                                                                <Button
                                                                    size='small'
                                                                    variant="text"
                                                                    fullWidth
                                                                    className='wp-ext-back-btn'
                                                                    startIcon={<ArrowBackIcon />}
                                                                    onClick={handleBack}
                                                                    disabled={currentPage == "rewrite"}
                                                                >Back</Button>
                                                            </Grid>) : (currentPage == "useplatform" ?
                                                                (
                                                                    <Grid item xs={1.5}>
                                                                        <Button
                                                                            size='small'
                                                                            variant="text"
                                                                            fullWidth
                                                                            className='wp-ext-back-btn'
                                                                            startIcon={<ArrowBackIcon />}
                                                                            onClick={handleBack}
                                                                            disabled={currentPage == "useplatform"}
                                                                        >Back</Button>
                                                                    </Grid>
                                                                ) :
                                                                (
                                                                    <Grid item xs={1.5}></Grid>
                                                                )
                                                            )
                                                        }

                                                        <Grid item xs={1.5}>
                                                            <Button
                                                                size='small'
                                                                variant="contained"
                                                                fullWidth
                                                                className='wp-ext-next-btn'
                                                                onClick={handleNext}
                                                            >Next</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }

                                    </Grid>
                                </Grid>

                                {pageURL == "?currentPage=useplatform" &&
                                    <Grid item xs={12} className='wp-ext-ussage-wz-item'>
                                        <Grid container>
                                            <Grid item xs={12} className='wp-ext-use-head-item'>
                                                <Typography
                                                    component={'span'}
                                                    className='wp-normal-text'
                                                >
                                                    Other ways to use&nbsp;
                                                    <Typography
                                                        component={'span'}
                                                        className='wp-highlighted-text'
                                                    >
                                                        Writewiz AI
                                                    </Typography>

                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} className='wz-editor-item'>
                                                <Grid container>
                                                    <Grid item xl={9} lg={10} md={11} sm={12} xs={12} className='wz-editor-sub-item'>
                                                        <Grid container className='wz-editor-container'>
                                                            <Grid item xs={5.7}>
                                                                <Card sx={{ display: 'flex' }} className='wz-editor-card'>
                                                                    <CardMedia
                                                                        component="img"
                                                                        sx={{ width: 60, height: 60 }}
                                                                        image={writerLogo}
                                                                        className='wz-editor-logo'
                                                                        alt="Writewiz editor logo"
                                                                    />
                                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <CardContent >
                                                                            <Typography component="div" variant="h5">
                                                                                Writewiz AI Editor
                                                                            </Typography>
                                                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                {/* Lorem ipsum dolor sit amet consectetur. Auctor consectetur dignissim faucibus ac cursus */}
                                                                            </Typography>
                                                                        </CardContent>
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pb: 1 }}>
                                                                            <Button
                                                                                variant='outlined'
                                                                                className='wp-ext-cb-et-btn'
                                                                            >
                                                                                Open Editor
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </Card>
                                                            </Grid>

                                                            <Grid item xs={5.7}>
                                                                <Card sx={{ display: 'flex' }} className='wz-editor-card'>
                                                                    <CardMedia
                                                                        component="img"
                                                                        sx={{ width: 60, height: 60 }}
                                                                        image={wzchatbot}
                                                                        className='wz-editor-logo'
                                                                        alt="Writewiz chatbot logo"
                                                                    />
                                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <CardContent >
                                                                            <Typography component="div" variant="h5">
                                                                                Writewiz AI Editor
                                                                            </Typography>
                                                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                                                                {/* Lorem ipsum dolor sit amet consectetur. Auctor consectetur dignissim faucibus ac cursus */}
                                                                            </Typography>
                                                                        </CardContent>
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, pb: 1 }}>
                                                                            <Button
                                                                                variant='outlined'
                                                                                className='wp-ext-cb-et-btn'
                                                                            >
                                                                                Open Chat bot
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>

                                                                </Card>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}

export default WelcomeForm