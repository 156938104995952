import React from 'react'
import { Grid, Typography } from "@mui/material";
import "./HeaderWithUnderLine.css";

const HeaderWithUnderLine = (props) => {
  const { text } = props;

  return (
    <Grid item xs={11} className="HWU-header-container">
      <h3 className="HWU-header-text">{text.normalText}
        <span class="HWU-purple-word">{text.coloredText}</span>
        {text.normalText2}
      </h3>
      <p className='HWU-titleText'>{text.subTitleText}</p>
    </Grid>
  )
}

export default HeaderWithUnderLine;