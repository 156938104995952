import React from 'react';
import './WelcomeForm.css';
import { CardMedia, Grid, Typography } from '@mui/material';
import WritewizVideoPlaceholder from "../../assests/WriteWizzVideoPlaceholder.png";

const Step3 = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container sx={{ justifyContent: "center" }}>
                    <Grid item xl={11} lg={9} md={9} xs={12} className='wp-gb-sentences-item' >

                        <Typography
                            variant='span'
                            className='wp-highlighted-text'
                        >
                            Pin WriteWiz <Typography variant='span' className='wp-normal-text' >to your browser for instant access to more features.</Typography>
                        </Typography>
                    </Grid>
                    <Grid item xl={11} lg={9} md={9} xs={12} >
                        {/* <CardMedia
                            // className='step3-card-media-iframe'
                            sx={{ height: 300, width: "100%", objectFit: "cover" }}
                            src={Video}
                            component={"iframe"}
                        /> */}
                        <img src={WritewizVideoPlaceholder} alt="" style={{ height: 300, width: "100%", borderRadius: "10px" }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Step3;