import React from 'react';
import './WelcomeForm.css';
import { Grid, Typography } from '@mui/material';
import googleDoc from '../../assests/GoogleDoc.svg'
import slack from '../../assests/Slack.svg'
import gmail from '../../assests/Gmail.svg'
import facebook from '../../assests/Facebook.svg'
import twitter from '../../assests/Twitter.svg'
import outlook from '../../assests/Outlook.svg'
import whatsapp from '../../assests/Whatsapp.svg'
import linkedin from '../../assests/Linkdin.svg'
import writerLogo from '../../assests/Wzeditorsvg.svg'
import wzchatbot from '../../assests/Wzchatbot.svg'
import { Box, Button, Card, CardContent, CardMedia } from '@mui/material';

const Step4 = () => {
    return (
        <Grid container>
            <Grid item xs={12} >
                <Grid container>
                    <Grid item xs={12} className='wp-ext-step4-main-head'>
                        <Typography
                            variant='span'
                            className='wp-highlighted-text'
                        >
                            You’re all set. <Typography variant='span' className='wp-normal-text'>What’s next?</Typography>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className='wp-ext-step4-sub-head'>
                        <Typography
                            variant='span'
                            className='wp-normal-small-text'
                        >
                            Open <Typography variant='span' className='wp-highlighted-link-text'>Google Docs</Typography> or any of your writing platforms, enabling it to imagine, respond, reshape or write, all the while highlighting your distinctive tone.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container className='wp-ext-media-main-container'>
                            {/* Google Docs */}
                            <Grid item xs={2.7} className='wp-ext-media-item' sx={{ cursor: 'pointer' }} onClick={() => window.open("https://docs.google.com/", "_blank")}>
                                <Grid container>
                                    <Grid item xs={12} className='wp-ext-img-item'>
                                        <img src={googleDoc} width={35} height={35} alt="" />
                                    </Grid>
                                    <Grid item xs={12} className='wp-ext-media-label-item'>
                                        <Typography
                                            component={"span"}
                                        >
                                            Google Docs
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Slack Web */}
                            <Grid item xs={2.7} className='wp-ext-media-item' sx={{ cursor: 'pointer' }} onClick={() => window.open("https://slack.com/", "_blank")}>
                                <Grid container>
                                    <Grid item xs={12} className='wp-ext-img-item'>
                                        <img src={slack} width={30} height={30} alt="" />
                                    </Grid>
                                    <Grid item xs={12} className='wp-ext-media-label-item'>
                                        <Typography
                                            component={"span"}
                                        >
                                        </Typography>
                                        Slack Web
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Gmail */}
                            <Grid item xs={2.7} className='wp-ext-media-item' sx={{ cursor: 'pointer' }} onClick={() => window.open("https://mail.google.com", "_blank")}>
                                <Grid container>
                                    <Grid item xs={12} className='wp-ext-img-item'>
                                        <img src={gmail} width={30} height={30} alt="" />
                                    </Grid>
                                    <Grid item xs={12} className='wp-ext-media-label-item'>
                                        <Typography
                                            component={"span"}
                                        >
                                            Gmail
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Linkdin */}
                            <Grid item xs={2.7} className='wp-ext-media-item' sx={{ cursor: 'pointer' }} onClick={() => window.open("https://www.linkedin.com/showcase/writewiz-ai/", "_blank")}>
                                <Grid container>
                                    <Grid item xs={12} className='wp-ext-img-item'>
                                        <img src={linkedin} width={30} height={30} alt="" />
                                    </Grid>
                                    <Grid item xs={12} className='wp-ext-media-label-item'>
                                        <Typography
                                            component={"span"}
                                        >
                                            Linkedin
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Facebook */}
                            <Grid item xs={2.7} className='wp-ext-media-item' sx={{ cursor: 'pointer' }} onClick={() => window.open("https://www.facebook.com/writewizai", "_blank")}>
                                <Grid container>
                                    <Grid item xs={12} className='wp-ext-img-item'>
                                        <img src={facebook} width={30} height={30} alt="" />
                                    </Grid>
                                    <Grid item xs={12} className='wp-ext-media-label-item'>
                                        <Typography
                                            component={"span"}
                                        >
                                            Facebook
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Twitter */}
                            <Grid item xs={2.7} className='wp-ext-media-item' sx={{ cursor: 'pointer' }} onClick={() => window.open("https://twitter.com/Writewiz_ai", "_blank")}>
                                <Grid container>
                                    <Grid item xs={12} className='wp-ext-img-item'>
                                        <img src={twitter} width={30} height={30} alt="" />
                                    </Grid>
                                    <Grid item xs={12} className='wp-ext-media-label-item'>
                                        <Typography
                                            component={"span"}
                                        >
                                            Twitter
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* outlook */}
                            <Grid item xs={2.7} className='wp-ext-media-item' sx={{ cursor: 'pointer' }} onClick={() => window.open("https://www.microsoft.com/en-us/microsoft-365/outlook/email-and-calendar-software-microsoft-outlook", "_blank")}>
                                <Grid container>
                                    <Grid item xs={12} className='wp-ext-img-item'>
                                        <img src={outlook} width={30} height={30} alt="" />
                                    </Grid>
                                    <Grid item xs={12} className='wp-ext-media-label-item'>
                                        <Typography
                                            component={"span"}
                                        >
                                            Outlook
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Whatsapp */}
                            <Grid item xs={2.7} className='wp-ext-media-item' sx={{ cursor: 'pointer' }} onClick={() => window.open("https://www.whatsapp.com/", "_blank")}>
                                <Grid container>
                                    <Grid item xs={12} className='wp-ext-img-item'>
                                        <img src={whatsapp} width={30} height={30} alt="" />
                                    </Grid>
                                    <Grid item xs={12} className='wp-ext-media-label-item'>
                                        <Typography
                                            component={"span"}
                                        >
                                            Whatsapp
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default Step4;