import React, { useEffect, useState } from 'react'
import { Alert, Button, CardMedia, Dialog, Divider, Grid, Input, List, ListItem, ListItemIcon, ListItemText, Slider, Snackbar, Typography } from '@mui/material'
import './Pricing.css';
import { Box, spacing, width } from '@mui/system';
import styled from '@emotion/styled';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RecommendedLabel from '../PricingPage/assets/RecommendedLabel.png'
import star from './assets/CreditStar.svg'
import TalkToContactSales from '../../Component/TalkToContactSales/TalkToContactSales';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const PricingCard = (props) => {

    const {
        plusValue,
        premiumValue,
        onPlusSliderChange,
        onPremiumSliderChange,
        calculateCredit,
        subType,
        pricingDetails
    } = props

    console.log("pricingDetails", pricingDetails);

    const mobileScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')
    const [selectedPlan, setSelectedPlan] = useState('Premium');
    const [flagTalkToSalesDialog, setFlagTalkToSalesDialog] = useState(false)
    const [isRecommended, setIsRecommended] = useState(true);
    const [flagShowMoreFreePlan, setFlagShowMoreFreePlan] = useState(false);
    const [flagShowMoreStarterPlan, setFlagShowMoreStarterPlan] = useState(false);
    const [flagShowMorePremiumPlan, setFlagShowMorePremiumPlan] = useState(false);


    const [selectedStarterMonthlyCreditValue, setSelectedStarterMonthlyCreditValue] = useState(0);
    const [selectedStarterYearlyCreditValue, setSelectedStarterYearlyCreditValue] = useState(0);

    const [selectedFreeMontlyCreditValue, setSelectedFreeMonthlyCreditValue] = useState(0);
    const [selectedFreeYearlyCreditValue, setSelectedFreeYearlyCreditValue] = useState(0);

    const [selectedPremiumMonthlyCreditValue, setSelectedPremiumMonthlyCreditValue] = useState(0);
    const [selectedPremiumYearlyCreditValue, setSelectedPremiumYearlyCreditValue] = useState(0);

    const [annuallyPlanSavingValue, setAnnuallyPlanSavingValue] = useState();
    const [pricingCardDetails, setPricingCardDetails] = useState(pricingDetails)
    console.log("pricingCardDetails", pricingCardDetails);
    console.log("annuallyPlanSavingValue", annuallyPlanSavingValue);

    useEffect(() => {
        setPricingCardDetails(pricingDetails)
    }, [pricingDetails])



    const handleAnnualSavingValue = (monthlyValue, yearlyValue) => {
        const savings = ((monthlyValue - yearlyValue) / monthlyValue) * 100;
        return savings.toFixed(0);

    }

    const [alertMessage, setAlertMessage] = useState({
        status: false,
        message: "",
        error: "",
    });


    const handleClose = () => {
        setAlertMessage({
            status: false,
            message: "",
            error: "",
        })
    };


    const handlePlanClick = (planName) => {
        setSelectedPlan(planName);
        if (planName == 'Premium') {
            setIsRecommended(true);
        }
        else {
            setIsRecommended(false);
        }
    };

    useEffect(() => {
        console.log("herePricing", alertMessage)
    }, [alertMessage])

    return (
        <>
            {alertMessage.status &&
                <Snackbar open={alertMessage.status} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alertMessage.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                        {alertMessage.message}
                    </Alert>
                </Snackbar>
            }
            {flagTalkToSalesDialog && (
                <Dialog open={flagTalkToSalesDialog} maxWidth={'sm'} onClose={() => { setFlagTalkToSalesDialog(false) }}>
                    <TalkToContactSales
                        setFlagTalkToSalesDialog={setFlagTalkToSalesDialog}
                        alertMessage={alertMessage}
                        setAlertMessage={setAlertMessage}
                        handleClose={handleClose}
                    />
                </Dialog>
            )}
            <Grid container className='pp-plans-panel-main-container'>
                {/* free plan */}
                {pricingCardDetails?.filter((e) => (e?.plan_name == 'Free'))?.map((ele) => {
                    return (
                        <Grid item xl={3.6} lg={3.8} md={5.5} sm={8} xs={12}
                            onClick={() => handlePlanClick('Free')}
                            className={'pp-plans-panel-sub-item'}
                        >
                            <Grid container>
                                <Grid item xs={11} className='pp-plan-card-item1'>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography
                                                component={'span'}
                                                className='pp-plan-package-label-title'
                                            >
                                                {ele?.plan_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='pp-plan-subtitle-item'>
                                            <Typography
                                                component={'span'}
                                                className='pp-plan-package-label-sub-title'
                                            >{ele?.plan_desc}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className='pp-plan-package-amount-label-item'>
                                            <Grid container className='pp-plan-amount-discount-container'>
                                                <Grid item xs={6.5}>
                                                    {subType === "monthly" &&
                                                        <Typography
                                                            component={'span'}
                                                            className='pp-plan-package-amount-label'
                                                        >
                                                            ${ele.monthly_plan[selectedFreeMontlyCreditValue]?.value}<Typography component={'span'} className='pp-plan-package-amount-month-label'>/month</Typography>
                                                        </Typography>
                                                    }
                                                    {subType == "yearly" &&
                                                        <Typography
                                                            component={'span'}
                                                            className='pp-plan-package-amount-label'
                                                        >
                                                            ${ele?.yearly_plan ? ele?.yearly_plan[selectedFreeYearlyCreditValue]?.value : "0"}
                                                            <Typography component={'span'} className='pp-plan-package-amount-month-label'>/month</Typography>
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={5} className='pp-free-plan-label-item'>
                                                    <Typography
                                                        component={'span'}
                                                        className='pp-free-plan-label'
                                                    >Free of charge</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className='pp-plan-package-dis-amount-label-item'>
                                            <Typography
                                                component={"span"}
                                                sx={{ color: "black" }}
                                                className='pp-plan-package-dis-amount-label-item-typo'
                                            >
                                                <img src={star} alt="" /> Credits
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='pp-plan-slider-item' >
                                            <Grid container>
                                                {subType == "monthly" &&
                                                    ele?.monthly_plan?.map((opt, i) => {
                                                        return (
                                                            <Grid item xs={2} key={i} className='pp-plan-credit-btn-item' >
                                                                <Button
                                                                    variant={opt.value == selectedFreeMontlyCreditValue ? 'contained' : 'outlined'}
                                                                    className={opt.value == selectedFreeMontlyCreditValue ? 'pp-plan-credit-btn-selected' : 'pp-plan-credit-btn'}
                                                                    size='medium'
                                                                    fullWidth
                                                                    onClick={() => {
                                                                        setSelectedFreeMonthlyCreditValue(opt.value)
                                                                    }}
                                                                >
                                                                    {opt.label}
                                                                </Button>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                                {subType == "yearly" &&
                                                    <Grid item xs={2} sx={{ margin: "20px 0px" }} >
                                                        <span style={{ margin: "20px 0px" }}></span>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className='pp-plan-btn-item'>
                                            <Button
                                                variant={selectedPlan == 'Free' ? 'contained' : 'outlined'}
                                                fullWidth={true}
                                                className={selectedPlan == 'Free' ? 'pp-plan-btn-selected' : 'pp-plan-btn'}
                                                sx={{ boxShadow: "none" }}
                                                size='large'
                                                onClick={() => {
                                                    window.location.href = 'https://app.writewiz.ai/dashboard/settings'
                                                }}
                                            >
                                                {/* {selectedPlan == 'Free' ? "Upgrade Now" : "Get Started"} */}
                                                Get Started
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider sx={{ color: "black", width: "100%" }} />

                                {mobileScreen ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container sx={{ position: 'relative' }}>
                                                {flagShowMoreFreePlan == false ?
                                                    (<>
                                                        <Grid item xs={12} sx={{ textAlign: "center", margin: "10px 0px" }}>
                                                            <Button
                                                                variant='text'
                                                                endIcon={
                                                                    <ExpandMoreRoundedIcon
                                                                        sx={{
                                                                            color: "#fff",
                                                                            background: "#6A097D",
                                                                            borderRadius: "50%",
                                                                            "&.MuiSvgIcon-root": { fontSize: '15px !important' }
                                                                        }}
                                                                    />
                                                                }
                                                                sx={{
                                                                    textTransform: "none",
                                                                    fontSize: "14px",
                                                                    fontWeight: "500",
                                                                    color: "#6A097D",
                                                                }}
                                                                onClick={() => { setFlagShowMoreFreePlan(true) }}
                                                            >Show Features</Button>
                                                        </Grid>

                                                    </>) : (<></>)
                                                }


                                                {flagShowMoreFreePlan &&
                                                    <Grid item xs={11}
                                                        sx={{ visibility: flagShowMoreFreePlan ? "visible" : "hidden" }}
                                                        className={`pp-plan-card-item2`}
                                                    >
                                                        <List>
                                                            {ele?.feature_list?.map((ele) => {
                                                                return (
                                                                    <>
                                                                        <ListItem className='pp-plans-list-points'>
                                                                            <ListItemIcon className='pp-plan-check-icon'>
                                                                                <TaskAltIcon sx={{ color: "black" }} className='pp-plan-check-icon-svg' />
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <span className='pp-plans-points'>{ele}</span>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </>
                                                                )
                                                            })}
                                                        </List>
                                                    </Grid>
                                                }

                                                {flagShowMoreFreePlan ?
                                                    (<>
                                                        <Grid item xs={12} sx={{ textAlign: 'center', margin: "10px 0px" }}>
                                                            <Button
                                                                variant='text'
                                                                endIcon={
                                                                    <KeyboardArrowUpRoundedIcon
                                                                        sx={{
                                                                            color: "#fff",
                                                                            background: "#6A097D",
                                                                            borderRadius: "50%",
                                                                            "&.MuiSvgIcon-root": { fontSize: '15px !important' }
                                                                        }}
                                                                    />
                                                                }
                                                                sx={{
                                                                    textTransform: "none",
                                                                    fontSize: "14px",
                                                                    fontWeight: "500",
                                                                    color: "#6A097D"
                                                                }}
                                                                onClick={() => { setFlagShowMoreFreePlan(false) }}
                                                            >Show Less Features</Button>
                                                        </Grid>
                                                    </>) : (<></>)
                                                }



                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={11} className='pp-plan-card-item2'>
                                            <List>
                                                {ele?.feature_list?.map((ele) => {
                                                    return (
                                                        <>
                                                            <ListItem className='pp-plans-list-points'>
                                                                <ListItemIcon className='pp-plan-check-icon'>
                                                                    <TaskAltIcon sx={{ color: "black" }} className='pp-plan-check-icon-svg' />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <span className='pp-plans-points'>{ele}</span>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </>
                                                    )
                                                })}
                                            </List>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    )
                })}

                {/* plus plan */}
                {pricingCardDetails?.filter((e) => (e?.plan_name == "Starter"))?.map((ele) => {
                    return (
                        <Grid item xl={3.6} lg={3.8} md={5.5} sm={8} xs={12}
                            className={'pp-plans-panel-sub-item'}
                            onClick={() => handlePlanClick('Plus')}
                        >
                            <Grid container>
                                <Grid item xs={11} className='pp-plan-card-item1'>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography
                                                component={'span'}
                                                className='pp-plan-package-label-title'
                                            >
                                                {ele?.plan_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='pp-plan-subtitle-item'>
                                            <Typography
                                                component={'span'}
                                                className='pp-plan-package-label-sub-title'
                                            >
                                                {ele?.plan_desc}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} className='pp-plan-package-amount-label-item'>
                                            <Grid container className='pp-plan-amount-discount-container'>
                                                <Grid item xs={5.5}>
                                                    {subType === "monthly" &&
                                                        <Typography
                                                            component={'span'}
                                                            className='pp-plan-package-amount-label'
                                                        >
                                                            ${ele.monthly_plan[selectedStarterMonthlyCreditValue]?.value}<Typography component={'span'} className='pp-plan-package-amount-month-label'>/month</Typography>
                                                        </Typography>
                                                    }
                                                    {subType === "yearly" &&
                                                        <Typography
                                                            component={'span'}
                                                            className='pp-plan-package-amount-label'
                                                        >${ele.yearly_plan[selectedStarterYearlyCreditValue]?.value}<Typography component={'span'} className='pp-plan-package-amount-month-label'>/month</Typography></Typography>
                                                    }
                                                </Grid>

                                                {/* Dyanmic Percentage of yearly plan */}
                                                <Grid item xs={5.5} className='pp-plan-amount-discount-item'>
                                                    <Typography
                                                        component={'span'}
                                                        className='pp-plan-package-dis-amount-label'
                                                    >Save {handleAnnualSavingValue(ele.monthly_plan[selectedStarterMonthlyCreditValue]?.value, ele.yearly_plan[selectedStarterYearlyCreditValue]?.value)}% on yearly plan</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                                <Grid item xs={5.8}>
                                                    <Grid container>
                                                        <Grid item xs={12} className='pp-plan-package-dis-amount-label-item'>
                                                            <Typography
                                                                component={"span"}
                                                                sx={{ color: "black" }}
                                                                className='pp-plan-package-dis-amount-label-item-typo'
                                                            >
                                                                <img src={star} alt="" /> Credits
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} className='pp-plan-slider-item' >
                                                            <Grid container className='pp-plan-slider-conainer'>
                                                                {subType == "monthly" &&
                                                                    ele?.monthly_plan?.map((opt, i) => {
                                                                        return (
                                                                            <Grid item xs={2} key={i} className='pp-plan-credit-btn-item' >
                                                                                <Button
                                                                                    variant={i == selectedStarterMonthlyCreditValue ? 'contained' : 'outlined'}
                                                                                    className={i == selectedStarterMonthlyCreditValue ? 'pp-plan-credit-btn-selected' : 'pp-plan-credit-btn-starter'}
                                                                                    size='medium'
                                                                                    fullWidth
                                                                                    onClick={() => {
                                                                                        setSelectedStarterMonthlyCreditValue(i)
                                                                                        setSelectedStarterYearlyCreditValue(i)
                                                                                    }}
                                                                                >
                                                                                    {opt.label}
                                                                                </Button>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                                {subType == "yearly" &&
                                                                    ele?.yearly_plan?.map((opt, i) => {
                                                                        return (
                                                                            <Grid item xs={2} key={i} className='pp-plan-credit-btn-item' >
                                                                                <Button
                                                                                    variant={i == selectedStarterYearlyCreditValue ? 'contained' : 'outlined'}
                                                                                    className={i == selectedStarterYearlyCreditValue ? 'pp-plan-credit-btn-selected' : 'pp-plan-credit-btn-starter'}
                                                                                    size='medium'
                                                                                    fullWidth
                                                                                    onClick={() => {
                                                                                        setSelectedStarterMonthlyCreditValue(i)
                                                                                        setSelectedStarterYearlyCreditValue(i)
                                                                                    }}
                                                                                >
                                                                                    {opt.label}
                                                                                </Button>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={5.8}>
                                                    <Grid container>
                                                        <Grid item xs={12} className='pp-plan-more-user-item'>
                                                            <Typography component={'span'} className='pp-plan-more-user-item-typo'>
                                                                Looking for more users?
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} className='pp-plan-talk-to-sales-btn-item'>
                                                            <Grid container class="pp-plan-talk-to-sales-div">
                                                                <button
                                                                    class="pp-plan-talk-to-sales-btn"
                                                                    data="Talk to Sales"
                                                                    onClick={() => {
                                                                        window.open(`https://writewiz.ai/contact-sales`, '_blank')
                                                                    }}
                                                                ></button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className='pp-plan-btn-item'>
                                            <Button
                                                variant={selectedPlan == 'Plus' ? 'contained' : 'outlined'}
                                                fullWidth={true}
                                                className={selectedPlan == 'Plus' ? 'pp-plan-btn-selected' : 'pp-plan-btn'}
                                                sx={{ boxShadow: "none" }}
                                                size='large'
                                                onClick={() => {
                                                    window.location.href = 'https://app.writewiz.ai/dashboard/settings'
                                                }}
                                            >
                                                Get Started
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ color: "black", width: "100%" }} />
                                {mobileScreen ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container sx={{ position: 'relative' }}>
                                                {flagShowMoreStarterPlan == false ?
                                                    (<>
                                                        <Grid item xs={12} sx={{ textAlign: "center", margin: "10px 0px" }}>
                                                            <Button
                                                                variant='text'
                                                                endIcon={
                                                                    <ExpandMoreRoundedIcon
                                                                        sx={{
                                                                            color: "#fff",
                                                                            background: "#6A097D",
                                                                            borderRadius: "50%",
                                                                            "&.MuiSvgIcon-root": { fontSize: '15px !important' }
                                                                        }}
                                                                    />
                                                                }
                                                                sx={{
                                                                    textTransform: "none",
                                                                    fontSize: "14px",
                                                                    fontWeight: "500",
                                                                    color: "#6A097D",
                                                                }}
                                                                onClick={() => { setFlagShowMoreStarterPlan(true) }}
                                                            >Show Features</Button>
                                                        </Grid>

                                                    </>) : (<></>)
                                                }


                                                {flagShowMoreFreePlan &&
                                                    <Grid item xs={11}
                                                        sx={{ visibility: flagShowMoreStarterPlan ? "visible" : "hidden" }}
                                                        className={`pp-plan-card-item2`}
                                                    >
                                                        <List>
                                                            {ele?.feature_list?.map((ele) => {
                                                                return (
                                                                    <>
                                                                        <ListItem className='pp-plans-list-points'>
                                                                            <ListItemIcon className='pp-plan-check-icon'>
                                                                                <TaskAltIcon sx={{ color: "black" }} className='pp-plan-check-icon-svg' />
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <span className='pp-plans-points'>{ele}</span>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </>
                                                                )
                                                            })}
                                                        </List>
                                                    </Grid>
                                                }

                                                {flagShowMoreStarterPlan ?
                                                    (<>
                                                        <Grid item xs={12} sx={{ textAlign: 'center', margin: "10px 0px" }}>
                                                            <Button
                                                                variant='text'
                                                                endIcon={
                                                                    <KeyboardArrowUpRoundedIcon
                                                                        sx={{
                                                                            color: "#fff",
                                                                            background: "#6A097D",
                                                                            borderRadius: "50%",
                                                                            "&.MuiSvgIcon-root": { fontSize: '15px !important' }
                                                                        }}
                                                                    />
                                                                }
                                                                sx={{
                                                                    textTransform: "none",
                                                                    fontSize: "14px",
                                                                    fontWeight: "500",
                                                                    color: "#6A097D"
                                                                }}
                                                                onClick={() => { setFlagShowMoreStarterPlan(false) }}
                                                            >Show Less Features</Button>
                                                        </Grid>
                                                    </>) : (<></>)
                                                }



                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={11} className='pp-plan-card-item2'>
                                            <List>
                                                {ele?.feature_list?.map((ele) => {
                                                    const isComingSoon = ele.includes("(coming soon)");
                                                    return (
                                                        <>
                                                            <ListItem className='pp-plans-list-points'>
                                                                <ListItemIcon className='pp-plan-check-icon'>
                                                                    <TaskAltIcon sx={{ color: "black" }} className='pp-plan-check-icon-svg' />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <span className='pp-plans-points'>
                                                                            {ele.replace("(coming soon)", "").trim()}
                                                                            {isComingSoon ? (
                                                                                <span style={{ color: "#6A097D", fontWeight: "500" }}> (coming soon) </span>
                                                                            ) : null}

                                                                        </span>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </>
                                                    )
                                                })
                                                }
                                            </List>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid >
                    )
                })}

                {/* premium plan */}
                {pricingCardDetails?.filter((e) => (e?.plan_name == "Premium"))?.map((ele) => {
                    console.log("ele plans", ele);
                    return (
                        <Grid Grid item xl={3.6} lg={3.8} md={5.5} sm={8} xs={12}
                            className={'pp-plans-panel-sub-item selected-item'}
                            onClick={() => handlePlanClick('Premium')}
                        >
                            <Grid container>
                                <Grid item xs={12} className='pp-plan-head-label-item'>
                                    <Grid container className='pp-plan-head-label-container'>
                                        <Grid item xs={5} className='pp-plan-head-label-sub-item'>
                                            <Typography
                                                component={'span'}
                                                className='pp-plan-package-label-title-premium'
                                            >{ele?.plan_name}</Typography>
                                        </Grid>

                                        <Grid item xs={7} className='pp-plan-RecommendedLabel' >
                                            <CardMedia
                                                src={RecommendedLabel}
                                                sx={{ width: "auto", height: 50 }}
                                                component="img"
                                                alt="Checked"
                                                className='pp-plan-RecommendedLabel-img'
                                            />
                                            {/* {isRecommended && <span className="pp-recommended-label">Recommended</span>} */}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={11} className='pp-plan-head-label-main-item1'>
                                    <Grid container>
                                        <Grid item xs={12} className='pp-plan-subtitle-item'>
                                            <Typography
                                                component={'span'}
                                                className='pp-plan-package-label-sub-title-premium'
                                            >{ele?.plan_desc}</Typography>
                                        </Grid>

                                        <Grid item xs={12} className='pp-plan-package-amount-label-item'>
                                            <Grid container className='pp-plan-amount-discount-container'>
                                                <Grid item xs={5.5}>
                                                    {subType === "monthly" &&
                                                        <Typography
                                                            component={'span'}
                                                            className='pp-plan-package-amount-label'
                                                        >${ele?.monthly_plan[selectedPremiumMonthlyCreditValue]?.value}<Typography component={'span'} className='pp-plan-package-amount-month-label'>/month</Typography>
                                                        </Typography>
                                                    }
                                                    {subType === "yearly" &&
                                                        <Typography
                                                            component={'span'}
                                                            className='pp-plan-package-amount-label'
                                                        >${ele?.yearly_plan[selectedPremiumYearlyCreditValue]?.value == undefined ? "0" : ele?.yearly_plan[selectedPremiumYearlyCreditValue]?.value}<Typography component={'span'} className='pp-plan-package-amount-month-label'>/month</Typography></Typography>
                                                    }
                                                </Grid>

                                                {/* Dyanmic Percentage of yearly plan */}
                                                <Grid item xs={5.5} className='pp-plan-amount-discount-item'>
                                                    <Typography
                                                        component={'span'}
                                                        className='pp-plan-package-dis-amount-label'
                                                    >
                                                        Save {handleAnnualSavingValue(ele.monthly_plan[selectedPremiumMonthlyCreditValue]?.value, ele.yearly_plan[selectedPremiumYearlyCreditValue]?.value)}% on yearly plan
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                                <Grid item xs={5.8}>
                                                    <Grid container>
                                                        <Grid item xs={12} className='pp-plan-package-dis-amount-label-item'>
                                                            <Typography
                                                                component={"span"}
                                                                sx={{ color: "black" }}
                                                                className='pp-plan-package-dis-amount-label-item-typo'
                                                            >
                                                                <img src={star} alt="" /> Credits
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} className='pp-plan-slider-item'>
                                                            <Grid container className='pp-plan-slider-conainer'>
                                                                {subType == "monthly" &&
                                                                    ele?.monthly_plan?.map((ele, i) => {
                                                                        return (
                                                                            <Grid item xs={2} key={i} className='pp-plan-credit-btn-item' >
                                                                                <Button
                                                                                    variant={i == selectedPremiumMonthlyCreditValue ? 'contained' : 'outlined'}
                                                                                    className={i == selectedPremiumMonthlyCreditValue ? 'pp-plan-credit-btn-selected' : 'pp-plan-credit-btn'}
                                                                                    size='medium'
                                                                                    fullWidth
                                                                                    onClick={() => {
                                                                                        setSelectedPremiumMonthlyCreditValue(i)
                                                                                        setSelectedPremiumYearlyCreditValue(i)
                                                                                    }}
                                                                                >
                                                                                    {ele.label}
                                                                                </Button>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                                {subType == "yearly" &&
                                                                    ele?.yearly_plan?.map((ele, i) => {
                                                                        return (
                                                                            <Grid item xs={2} key={i} className='pp-plan-credit-btn-item' >
                                                                                <Button
                                                                                    variant={i == selectedPremiumYearlyCreditValue ? 'contained' : 'outlined'}
                                                                                    className={i == selectedPremiumYearlyCreditValue ? 'pp-plan-credit-btn-selected' : 'pp-plan-credit-btn'}
                                                                                    size='medium'
                                                                                    fullWidth
                                                                                    onClick={() => {
                                                                                        setSelectedPremiumYearlyCreditValue(i)
                                                                                        setSelectedPremiumMonthlyCreditValue(i)
                                                                                    }}
                                                                                >
                                                                                    {ele?.label}
                                                                                </Button>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={5.8}>
                                                    <Grid container>
                                                        <Grid item xs={12} className='pp-plan-more-user-item'>
                                                            <Typography component={'span'} className='pp-plan-more-user-item-typo'>
                                                                Looking for more users?
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} className='pp-plan-talk-to-sales-btn-item'>
                                                            <Grid container class="pp-plan-Premium-talk-to-sales-div">
                                                                <button
                                                                    class="pp-plan-Premium-talk-to-sales-btn"
                                                                    data="Talk to Sales"
                                                                    onClick={() => {
                                                                        window.open(`https://writewiz.ai/contact-sales`, '_blank')
                                                                    }}
                                                                ></button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className='pp-plan-btn-item'>
                                            <Button
                                                variant={selectedPlan == 'Premium' ? 'contained' : 'outlined'}
                                                fullWidth={true}
                                                className={selectedPlan == 'Premium' ? 'pp-plan-btn-selected' : 'pp-plan-btn'}
                                                sx={{ boxShadow: "none" }}
                                                size='large'
                                                onClick={() => {
                                                    window.location.href = 'https://app.writewiz.ai/dashboard/settings'
                                                }}
                                            >
                                                {/* {selectedPlan == 'Unlimited' ? "Upgrade Now" : "Get Started"} */}
                                                Get Started
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider sx={{ color: "black", width: "100%" }} />

                                {mobileScreen ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container sx={{ position: 'relative' }}>
                                                {flagShowMorePremiumPlan == false ?
                                                    (<>
                                                        <Grid item xs={12} sx={{ textAlign: "center", margin: "10px 0px" }}>
                                                            <Button
                                                                variant='text'
                                                                endIcon={
                                                                    <ExpandMoreRoundedIcon
                                                                        sx={{
                                                                            color: "#fff",
                                                                            background: "#6A097D",
                                                                            borderRadius: "50%",
                                                                            "&.MuiSvgIcon-root": { fontSize: '15px !important' }
                                                                        }}
                                                                    />
                                                                }
                                                                sx={{
                                                                    textTransform: "none",
                                                                    fontSize: "14px",
                                                                    fontWeight: "500",
                                                                    color: "#6A097D",
                                                                }}
                                                                onClick={() => { setFlagShowMorePremiumPlan(true) }}
                                                            >Show Features</Button>
                                                        </Grid>

                                                    </>) : (<></>)
                                                }


                                                {flagShowMorePremiumPlan &&
                                                    <Grid item xs={11} className='pp-plan-card-item2'>
                                                        <List>
                                                            {ele?.feature_list?.map((ele) => {
                                                                const isComingSoon = ele.includes("(coming soon)");
                                                                return (
                                                                    <>
                                                                        <ListItem className='pp-plans-list-points'>
                                                                            <ListItemIcon className='pp-plan-check-icon'>
                                                                                <TaskAltIcon sx={{ color: "black" }} className='pp-plan-check-icon-svg' />
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <span className='pp-plans-points'>
                                                                                        {ele.replace("(coming soon)", "").trim()}
                                                                                        {isComingSoon ? (
                                                                                            <span style={{ color: "#6A097D", fontWeight: "500" }}> (coming soon) </span>
                                                                                        ) : null}
                                                                                    </span>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    </>
                                                                )
                                                            })}
                                                        </List>
                                                    </Grid>
                                                }

                                                {flagShowMorePremiumPlan ?
                                                    (<>
                                                        <Grid item xs={12} sx={{ textAlign: 'center', margin: "10px 0px" }}>
                                                            <Button
                                                                variant='text'
                                                                endIcon={
                                                                    <KeyboardArrowUpRoundedIcon
                                                                        sx={{
                                                                            color: "#fff",
                                                                            background: "#6A097D",
                                                                            borderRadius: "50%",
                                                                            "&.MuiSvgIcon-root": { fontSize: '15px !important' }
                                                                        }}
                                                                    />
                                                                }
                                                                sx={{
                                                                    textTransform: "none",
                                                                    fontSize: "14px",
                                                                    fontWeight: "500",
                                                                    color: "#6A097D"
                                                                }}
                                                                onClick={() => { setFlagShowMorePremiumPlan(false) }}
                                                            >Show Less Features</Button>
                                                        </Grid>
                                                    </>) : (<></>)
                                                }



                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={11} className='pp-plan-card-item2'>
                                            <List>
                                                {ele?.feature_list?.map((ele) => {
                                                    const isComingSoon = ele.includes("(coming soon)");
                                                    return (
                                                        <>
                                                            <ListItem className='pp-plans-list-points'>
                                                                <ListItemIcon className='pp-plan-check-icon'>
                                                                    <TaskAltIcon sx={{ color: "black" }} className='pp-plan-check-icon-svg' />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={
                                                                        <span className='pp-plans-points'>
                                                                            {ele.replace("(coming soon)", "").trim()}
                                                                            {isComingSoon ? (
                                                                                <span style={{ color: "#6A097D", fontWeight: "500" }}> (coming soon) </span>
                                                                            ) : null}
                                                                        </span>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        </>
                                                    )
                                                })}
                                            </List>
                                        </Grid>
                                    </>
                                )}




                            </Grid>
                        </Grid >
                    )
                })}
            </Grid>
        </>
    )
}

export default PricingCard