import "./AlternativePageTable.css";
import React from 'react'
import Logo from '../../assests/Final - WW Full logo.svg'
import Cross from "../../assests/crossIcon.svg";
import RightMark from '../../assests/rightTickMark.svg';
import RightMarkDark from '../../assests/rightTickMarkDark.svg'
import { Grid, Typography } from '@mui/material';


const PricingData = [
  {
    title: "Rate per month - monthly / user",
    subTile: "Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue",
    writer: '$ 0.00',
    writeWiz: "$ 0.00"
  },

  {
    title: "Rate per month - Annually / user",
    subTile: "Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue",
    writer: '$ 0.00',
    writeWiz: "$ 0.00"
  },
]

const FeaturesData = [
  {
    title: "Rate per month - monthly / user",
    subTile: "Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue",
    isText: false,
    writer: true,
    writeWiz: true
  },

  {
    title: "Rate per month - Annually / user",
    subTile: "Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue",
    isText: true,
    writer: "afsfg",
    writeWiz: "sfsfs"
  },

  {
    title: "Rate per month - monthly / user",
    subTile: "Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue",
    isText: false,
    writer: false,
    writeWiz: true
  },

  {
    title: "Rate per month - Annually / user",
    subTile: "Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue",
    isText: true,
    writer: "afsfg",
    writeWiz: "sfsfs"
  },

  {
    title: "Rate per month - monthly / user",
    subTile: "Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue",
    isText: false,
    writer: true,
    writeWiz: false
  },

  {
    title: "Rate per month - Annually / user",
    subTile: "Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue",
    isText: true,
    writer: "afsfg",
    writeWiz: "sfsfs"
  },
]


const AlternativePageTable = () => {
  return (
    <>
      <Grid container className='ap-table-main-container'>
        <Grid item xl={7} lg={9} md={11} sm={11} xs={11} className='ap-table-head-item'>
          <Grid container>
            <Grid item xs={12} sx={{ marginBottom: "15px" }}>
              <Typography variant="span" className="ap-comparison-table-head-text1">WriteWiz&nbsp;
                <Typography variant="span" className="ap-comparison-table-head-text2">
                  Vs Writer:&nbsp;
                </Typography>
              </Typography>
              <Typography variant="span" className="ap-comparison-table-head-text3">
                Quick Comparison&nbsp;
              </Typography>
              {/* <h3 className='table-header-text'>
                <span className='highlighted-text-1'>WriteWiz</span> Vs Writer:&nbsp;
                <span className='highlighted-text-2'>Quick Comparison</span>
              </h3> */}
            </Grid>
            <Grid item xs={11}>
              <Typography
                component={'p'}
                variant='subtitle1'
                className="ap-comparison-table-subtitle-text"
                sx={{ color: "#7A7A7A" }}
              >With our browser extension, optimizing your content for search engines and aligning it with your brand's voice has never been more effortless
                -Whether you're crafting engaging emails, captivating social media posts, or compelling blog articles, Writewiz AI empowers you to save time and produce top-notch, SEO-friendly content that resonates with your audience</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xl={8} lg={9} md={11} sm={11} xs={11} className='ap-table-item'>
          <table className='comparison-table-main'>
            <thead className='comparison-table-head'>
              <tr className='comparison-table-head-row'>
                <th></th>
                <th>Writer</th>
                <th className='comparison-table-head-writewiz-column'>
                  <div className='comparison-table-head-writewiz-column-content'>
                    <div className='comparison-table-head-writewiz-column-topchoice'>Top Choice</div>
                  </div>
                  <img src={Logo} alt="WritrWiz Logo" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>

              {/* Pricing Header */}
              <tr className='comparison-table-body-row'>
                <td className='comparison-table-body-col1 comparison-table-body-col1-header'>
                  Pricing
                </td>
                <td className='comparison-table-body-writer-column'></td>
                <td className='comparison-table-body-writewiz-column'></td>
                <td></td>
              </tr>

              {PricingData.map((item, index) => (
                <tr key={index} className='comparison-table-body-row'>
                  <td className='comparison-table-body-col1'>
                    <p className='comparison-table-body-col1-text'>
                      {item.title}
                    </p>
                    <p className='comparison-table-body-col1-subtext'>
                      {item.subTile}
                    </p>
                  </td>
                  <td className='comparison-table-body-writer-column'>{item.writer}</td>
                  <td className='comparison-table-body-writewiz-column'>{item.writeWiz}</td>
                </tr>
              ))}

              <tr className='comparison-table-body-row'>
                <td className='comparison-table-body-col1 comparison-table-body-col1-header'>
                  Features
                </td>
                <td className='comparison-table-body-writer-column'></td>
                <td className='comparison-table-body-writewiz-column'></td>
              </tr>

              {FeaturesData.map((item, index) => (
                <tr key={index} className='comparison-table-body-row'>
                  <td className='comparison-table-body-col1'>
                    <p className='comparison-table-body-col1-text'>
                      {item.title}
                    </p>
                    <p className='comparison-table-body-col1-subtext'>
                      {item.subTile}
                    </p>
                  </td>
                  {item.isText ? (
                    <>
                      <td className='comparison-table-body-writer-column'>{item.writer}</td>
                      <td className='comparison-table-body-writewiz-column'>{item.writeWiz}</td>
                    </>
                  ) : (
                    <>
                      <td className='comparison-table-body-writer-column'>{item.writer ? (<img src={RightMarkDark} alt="Right Mark" />) : (<img src={Cross} alt="Cross Mark" />)}</td>
                      <td className='comparison-table-body-writewiz-column'>{item.writeWiz ? (<img src={RightMark} alt="Right Mark" />) : (<img src={Cross} alt="Cross Mark" />)}</td>
                    </>
                  )}

                </tr>
              ))}
              <tr className='comparison-table-body-row'>
                <td className='comparison-table-body-lastrow'></td>
                <td ></td>
                <td className='comparison-table-body-writewiz-column'></td>
                <td ></td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    </>
  )
}

export default AlternativePageTable;