import React from 'react';
import { Grid } from '@mui/material';
import "./AlternativePage.css";
import AlternativePageIntro from '../../Component/AlternativePageIntro/AlternativePageIntro';
import AlternativeCardItem from '../../Component/AlternativeCardItem/AlternativeCardItem';
import AlternativePageTable from '../../Component/AlternativePageTable/AlternativePageTable';
import AlternativePageInfo from '../../Component/AlternativePageInfo/AlternativePageInfo';
import AlternativeAiPartner from '../../Component/AlternativeAiPartner/AlternativeAiPartner';
import LandingFooter from '../../Component/Footer/LandingFooter';

const AlternativePage = () => {
  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className='ap-page-intro-item ap-page-bottom-margin'>
              <AlternativePageIntro />
            </Grid>

            <Grid item xs={12} className='ap-page-bottom-margin'>
              <AlternativeCardItem />
            </Grid>

            <Grid item xs={12} className='ap-page-bottom-margin'>
              <AlternativePageTable />
            </Grid>

            <Grid item xs={12} className='ap-page-bottom-margin'>
              <AlternativePageInfo />
            </Grid>

            <Grid item xs={12} >
              <AlternativeAiPartner />
            </Grid>

            <Grid item xs={12}>
              <LandingFooter />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>

  )
}

export default AlternativePage