import React from "react";
import ArticleCard from "../ArticleCard/ArticleCard";
import './AllArticle.css'
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import world from '../../assests/world.png'
import settings from '../../assests/settings.png'
import video from '../../assests/video.png'
import sms from '../../assests/sms.png'
import database from '../../assests/database.png'
import customerSupport from '../../assests/customer-support.png'
import { Box } from "@mui/system";


const AllArticle = () => {
  const cards = [
    {
      icon: world,
      title: "WriteWiz",
      subTitle: "Tools, packages, and common questions",
    },
    {
      icon: settings,
      title: "Getting Started",
      subTitle: "Account basics and general settings",
    },
    {
      icon: video,
      title: "What can Wordtune do?",
      subTitle: "Rewrite, summarize, and explore features",
    },
    {
      icon: sms,
      title: "WriteWiz Web",
      subTitle: "Install and use our iOS keyboard",
    },
    {
      icon: database,
      title: "Billing & Subsriptions",
      subTitle: "Policies, subscriptions, and discounts",
    },
    {
      icon: customerSupport,
      title: "Technical Help",
      subTitle: "Troubleshooting and tech-related questions",
    },
  ];
  return (
    <>
      <Grid container>
        <Grid item xl={6.5} lg={8} md={10} sm={11} xs={11} className="artical-card-item">
          <Grid container className="artical-card-container">
            {cards.map((ele) => {
              return (
                <>
                  <Grid item xl={3.7} lg={3.7} md={3.5} sm={3.7} xs={12} className="help-artical-card-item">
                    <Card className="help-artical-card">

                      <Box className='artical-image-wrapper'>
                        <CardMedia
                          component="img"
                          image={ele.icon}
                          sx={{ height: "30px", width: "100%", objectFit: "contain" }}
                        ></CardMedia>
                      </Box>

                      <CardContent>
                        <Typography gutterBottom variant="span" className="help-article-head-title">
                          {ele.title}
                        </Typography>
                        <Typography component={'p'} variant="p" className="help-article-sub-title">
                          {ele.subTitle}
                        </Typography>
                      </CardContent>

                    </Card>
                  </Grid>
                </>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AllArticle;
