import React from 'react';
import './UseCaseMobile.css'
import NewScrollElementImageOne from "../../../assests/newscrollelement-first-image.jpg";
import NewScrollElementImageTwo from "../../../assests/newscrollelement-second-image.jpg";
import NewScrollElementImageThree from "../../../assests/campaign.jpg";
import NewScrollElementImageFour from "../../../assests/template.jpg";
import NewScrollElementImageFive from "../../../assests/NewScrollElementImageFour.jpg";
import NewScrollElementImageSix from "../../../assests/chatbot.jpg";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useMediaQuery from '@mui/material/useMediaQuery';

const UseCaseMobile = () => {


  const smallScreen = useMediaQuery('(min-width: 0px) and (max-width: 1000px)')

  const mainData = [
    {
      name: "one",
      text: "Engage with your Ideas Instantly!",
      smallTitle: "Chrome Extension",
      title: "Turn every tab into a canvas with your AI-powered companion.",
      image: NewScrollElementImageOne,
      description: `Ever had an idea while browsing the web? With our Chrome Extension, instantly access WriteWiz’s powerful features. Create, edit, and save content without switching tabs. The web is your canvas; let’s make every word count,`,
    },
    {
      name: "two",
      text: "Make it more than content—make it you.",
      smallTitle: "Brand/Individual Persona",
      image: NewScrollElementImageTwo,
      title: "Your voice, our craft—persona perfected",
      description:
        "Craft content that echoes your unique voice or your brand’s personality. Whether you're an enthusiastic startup or a seasoned blogger, define your persona and let WriteWiz curate content that resonates with your audience. Stand out. Be authentic. Be you",
    },
    {
      name: "three",
      text: "Power Your Marketing with Precision",
      image: NewScrollElementImageThree,
      smallTitle: "Campaigns",
      title: "Every asset, every platform—your campaign content, simplified",
      description:
        "Embarking on a new product launch? Promoting a cause? With WriteWiz, structure your campaigns with ease. Generate series of related content, keeping a consistent voice throughout, and ensure every piece aligns perfectly with your campaign’s objective.",
    },
    {
      name: "four",
      image: NewScrollElementImageFour,
      text: "Transform Ideas into Impactful Words",
      smallTitle: "Generate Content or Summarize",
      title: "Less fluff, more stuff.",
      description:
        "Whether you need detailed blog posts or concise summaries, WriteWiz has your back. Turn your thoughts into well-structured content or distill lengthy articles into bite-sized insights. Embrace clarity and maximize engagement.",
    },
    {
      name: "six",
      text: "Enhance User Experience with AI-powered Dialogues",
      smallTitle: "Chatbots",
      image: NewScrollElementImageSix,
      title: "Chat today, convert tomorrow",
      description:
        "Elevate your chatbot's conversational prowess with WriteWiz. Integrate seamlessly and craft responses that feel human. With our AI, ensure every chat leads to user satisfaction and brand loyalty.",
    },
  ];

  return (
    <div className="usecasemobile-main-div">
      <Carousel
        autoPlay={true}
        className='CarouselStyles'
        infiniteLoop={true}
        autoFocus={true}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        showStatus={false}

      >
        {mainData.map((data, index) => {
          return (
            <div className="usecasemobile-inner-div" key={index}>
              <div className="usecasemobile-section">
                <div
                  className="usecasemobile-main-text"
                >
                  <div
                    className="usecasemobile-main-text-inner-div"
                  >
                    <div className="usecasemobileSmallTitle" >
                      {data.smallTitle}
                    </div>

                    <div className="carousel-main-title-text">{data.title}</div>

                    {smallScreen ? (<></>) :
                      (<>
                        <div id="usecasemobileTitleDiv">
                          <span className="usecasemobileTitle">
                            {mainData[0].title?.split(" ").slice(0, -1).join(" ")}
                          </span>
                          <span className="usecasemobileTitle">
                            {mainData[0].title?.split(" ").splice(-1)[0]}
                          </span>
                        </div>
                      </>)
                    }

                    <div id="usecasemobileText">{data.text}</div>


                    <div className="usecasemobileDescription">{data.description}</div>



                  </div>
                </div>
                <div className="usecasemobile-main-image">
                  <div className="usecasemobile-main-image-inner-div">
                    <img
                      src={data.image}
                      style={{ width: "100%", }}
                      alt=""
                    />
                  </div>
                </div>

              </div>
            </div>
          )
        })}

      </Carousel>
    </div>
  )
}

export default UseCaseMobile