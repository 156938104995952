import React, { useContext, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "./Pages/LandingPage/LandingPage";
import TestScroll from "./Component/TestScroll/TestScroll.jsx";
import Pricing from "./Pages/PricingPage/Pricing";
import FeedbackForm from "./Pages/FeedbackForm/FeedbackForm";
import WelcomeForm from './Pages/WelcomeForm/WelcomeForm';
import EnterpriseSection from "./Component/EnterpriseSection/EnterpriseSection";
import ChromeExtension from "./Component/ChromeExtension/ChromeExtension";
import NewScrollElement from "./Component/NewScrollElement/NewScrollElement";
import UseCaseMobile from "./Component/ComponentForMobiles/UseCaseMobile/UseCaseMobile";
import { Alert, Dialog, Snackbar, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import ArticlePage from "./Pages/ArticlePage/ArticlePage";
import AllArticle from "./Component/AllArticle/AllArticle";
import ArticleHomePage from "./Pages/ArticlePage/ArticleHomePage";
import AlternativePage from "./Pages/AlternativePage/AlternativePage";
import BrowserExtension from "./Pages/BrowserExtentions/BrowserExtension";
import ContactSales from "./Pages/ContactSales/ContactSales";
import PageNotFound from "./Component/PageNotFound/PageNotFound";
import SomethingWentWrong from "./Component/SomethingWentWrong/SomethingWentWrong";
import { Context as mainContext } from './Context/MainContext/GlobalContext'
import Loader from "./Pages/Loader";
import ContactSupport from "./Component/ContactSupport/ContactSupport";
import GitexInvite from "./Pages/GitexInvite/GitexInvite";
import Chatbot from "./Pages/Chatbot/Chatbot";
import Paraphraser from "./Pages/Paraphraser/Paraphraser.jsx";
import WhatsappChatbot from "./Pages/WhatsappChatbot/WhatsappChatbot.jsx";
import ChatbotPricing from "./Pages/ChatbotPricing/ChabotPricing.jsx";
import { Provider as ChatbotPricingProvider } from './Context/ChatbotPricing/ChatbotPricingContext'
import BookAMeetingDialog from "./Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog.jsx";
import { UserMeAPI } from "./Api/Global/GlobalAPI.js";

const RoutePage = () => {

  const [isBelow1000, setIsBelow1000] = useState(window.innerWidth < 1000);
  const { userDetails, loading, toastNotification } = useContext(mainContext).state
  const { setUserDetails, setToastNotification } = useContext(mainContext)
  const location = useLocation();
  const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dialogFlag = queryParams.get('meeting_flag') === 'true';
    setTimeout(() => {
      setFlagOpenBookaMeetingDemo(dialogFlag);
    }, 1000);
  }, [location]);

  console.log("userDetails", userDetails);
  console.log("toastNotification", toastNotification);


  const fetchMetaKeywords = async () => {

    // https://api.writewiz-staging.in/api/global/seo-keywords
    try {
      const response = await fetch(`${process.env.REACT_APP_API_CALL_URL}/global/seo-keywords`);
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error('Failed to fetch data from the API');
      }
    }
    catch (error) {
      return null;
    }
  };


  const handleUserMeDetails = async () => {
    try {
      UserMeAPI(
        (res) => {
          if (res?.data?.status) {
            console.log("response of user me API", res);
            setUserDetails({
              flagUserLoggedIn: res?.data?.status,
              userInfo: res?.data?.data[0]
            })
          }
          else {
            setUserDetails({
              flagUserLoggedIn: false,
              userInfo: {}
            })
          }
        },
        (err) => {
          console.log("error of user me API", err);
          setUserDetails({
            flagUserLoggedIn: false,
            userInfo: {}
          })
        })
    } catch (error) {
      return null;
    }
  }

  const updateMetaKeywords = async () => {

    await handleUserMeDetails()

    await fetchMetaKeywords().then((data) => {
      let seoData;

      // Check if the current page is /chatbot and set the keywords accordingly
      if (window.location.pathname === '/chatbot') {
        seoData = data?.data?.find(seo => seo?.page_name === 'conversational-ai-chatbot');
      } else {
        // Find SEO data for the current page or use the default
        seoData = data?.data?.find(seo => window.location.pathname?.includes(seo?.page_name)) || data?.data?.find(seo => seo?.flag_default);
      }

      if (seoData) {
        const keywords = seoData.keywords;
        const metaKeywordsTag = document.querySelector('meta[name="keywords"]');

        if (metaKeywordsTag) {
          metaKeywordsTag.setAttribute('content', keywords);
        }
      }
    }).catch((error) => {
      console.error('Error updating meta keywords:', error);
    });

  };

  useEffect(() => {
    updateMetaKeywords();
  }, []);



  const handleClose = () => {
    setToastNotification({
      status: false,
      message: "",
      error: "",
    });
  }

  const handleNotificationClose = () => {
    setToastNotification({
      status: false,
      message: "",
      error: "",
    });
    return;
  };

  return (
    <>
      {loading && <Loader />}

      {
        flagOpenBookaMeetingDemo &&
        <Dialog open={flagOpenBookaMeetingDemo}
          maxWidth='lg'
          sx={{ '&.MuiPaper-root': { width: '100%' } }}
          className="iframe-dialog"
        >
          <BookAMeetingDialog
            flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
            setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
          />
        </Dialog>
      }

      {toastNotification.status &&
        <Snackbar open={true} autoHideDuration={8000} onClose={handleNotificationClose}>
          <Alert onClose={handleClose} severity={toastNotification.error == true ? "error" : "success"} >
            {toastNotification.message}
          </Alert>
        </Snackbar>
      }

      <Routes>
        <Route path="/usecase" element={isBelow1000 ? <UseCaseMobile /> : <NewScrollElement />} />
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/scroll" element={<TestScroll />} /> */}
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/feedback" element={<FeedbackForm />} />
        {/* <Route path="/enterprice" element={<EnterpriseSection />} /> */}
        <Route path="/extension-onboarding" element={<WelcomeForm />} />
        <Route path="/chrome" element={<ChromeExtension />} />
        <Route path="/support" element={<ArticleHomePage />} />
        <Route path="/support/inner" element={<ArticlePage />} />
        <Route path="/alternative" element={<AlternativePage />} />
        <Route path="/Browser-Extension" element={<BrowserExtension />} />
        <Route path="/contact-sales" element={<ContactSales />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="/error" element={<SomethingWentWrong />} />
        <Route path="/contact-support" element={<ContactSupport />} />
        <Route path="/meet-at-gitex" element={<GitexInvite />} />
        <Route path="/conversational-ai-chatbot" element={<Chatbot />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/ai-paraphrasing-tool" element={<Paraphraser />} />
        <Route path="/whatsapp-ai-chatbot" element={<WhatsappChatbot />} />
        <Route
          path="/chatbot-pricing"
          element={
            <ChatbotPricingProvider>
              <ChatbotPricing />
            </ChatbotPricingProvider>
          }
        />
        <Route path="/" element={<BookAMeetingDialog />} />
        <Route path="/contact-us" element={<ContactSales />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};


export default RoutePage;
