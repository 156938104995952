import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, CardMedia, Divider, Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TextAnimation from "../../../Component/TextAnimation/TextAnimation.jsx";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import "./First2PageAnimation.css";
import TryOurProduct from "../../../Component/TryOurProduct/TryOurProduct";
import { height } from "@mui/system";
import WriteWizzPlaceHolder from "../../../assests/WriteWizzVideoPlaceholder.png";
import GitexImage from "../../../assests/Gitex.png"
import { Context as maincontext } from "../../../Context/MainContext/GlobalContext.js"
// import GSAPScroller from "../../Component/GSAPScroller/GSAPScroller";

const First2PageAnimation = () => {
  const { loader, setLoader } = useContext(maincontext)
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           const animationId = entry.target.dataset.animationId;

  //           if (!playedAnimations.includes(animationId)) {
  //             const animationData = getAnimationData(animationId);
  //             const animation = lottie.loadAnimation({
  //               container: entry.target.querySelector(".animation-placeholder"),
  //               animationData,
  //               loop: false,
  //             });
  //             animation.play();

  //             setPlayedAnimations((prevState) => [...prevState, animationId]);
  //           }
  //         }
  //       });
  //     },
  //     { threshold: 0.5 }
  //   );

  //   if (firstPageRef.current) {
  //     observer.observe(firstPageRef.current);
  //   }
  //   if (secondPageRef.current) {
  //     observer.observe(secondPageRef.current);
  //   }
  //   if (thirdPageRef.current) {
  //     observer.observe(thirdPageRef.current);
  //   }
  //   if (fourthPageRef.current) {
  //     observer.observe(fourthPageRef.current);
  //   }

  //   return () => observer.disconnect();
  // }, [playedAnimations]);

  const keyPoints = ['Unique Persona', 'Intelligent Chatbots', 'Campaigns', 'Chrome Extension', 'AI Editor', 'Pre-built Templates'];

  gsap.registerPlugin(ScrollToPlugin);

  let isMouseDown = false;

  useEffect(() => {
    document.addEventListener("mousedown", function () {
      isMouseDown = true;
      // setIsMouseDown(true);
      console.log("isMouseDown", isMouseDown);
    });

    document.addEventListener("mouseup", function () {
      isMouseDown = false;

      // setIsMouseDown(false);
      console.log("isMouseDown", isMouseDown);
    });

    // Now you can check the isMouseDown variable at any point in your code

    let observer = new IntersectionObserver(
      function (entries) {
        try {
          var ele = entries.filter((entry) => entry.isIntersecting);
          ele = ele[0].target;
          console.log("Visible element is now: " + ele.id);
          if (isMouseDown) {
          } else {
            gsap.to(window, {
              duration: 0.5,
              scrollTo: { y: "#" + ele.id },
            });
          }
        } catch (err) {
          console.log("++++++++++", err);
        }
      },
      {
        rootMargin: "-10px 0px -10px 0px", // Add buffering space of 100px at top and bottom
        threshold: 0.1, // Trigger callback when 1% of the element is visible
      }
    );
    if (window.innerWidth > 1000) {
      //PAUSING SCROLL EFFECT IF ON MOBILE
      document
        .querySelectorAll(".pageone")
        .forEach((ele) => observer.observe(ele));
    }

    return () => { };
  }, []);
  const handleRedirection = () => {
    window.open(process.env.REACT_APP_DASHBOARD_URL, "_blank");
  }
  return (
    <Grid container>
      <Grid item id="text-animation-page" className="lp-main-text-animation-container pageone">
        <Grid container className="lp-main-text-animation-grid-container">

          <Grid item className="lp-text-animation swipe">
            <TextAnimation />
          </Grid>

          {/***********  HERE GITEX BANNER IS COMMENTED  *************/}
          {/* <Grid item className="lp-gitex-banner-container">
            <Grid container className="lp-gitex-banner-ele-wrapper">
            <Box xs={12} lg={6}>
              <img src={GitexImage} alt="" className="lp-gitex-image" />
            </Box>
            <Box xs={12} lg={6}>
              <Typography variant="h6" className="lp-gitex-banner-text">
                Join us at GITEX Global 2023 and come say 'hello'!
                </Typography>
                <Grid container className='lp-gitex-banner-desk-info'>
                    <Grid item>
                        <span>Hall No:</span>
                        <h6>Hall 9</h6>
                    </Grid>
                    <Grid item>
                        <Divider orientation="vertical" sx={{background: "white", marginInline: '30px'}} />
                    </Grid>
                    <Grid item>
                        <span>Stand No:</span>
                        <h6>H9-25</h6>
                    </Grid>
                </Grid>
            </Box>
            </Grid>
          </Grid> */}

          <Grid item className="HomePage-sub-text" width={"90%"}>
            <Grid container direction={"column"}>
              <Grid item>
                <Typography
                  component={"p"}
                  sx={{ color: "black" }}
                  className="wz-head-points-text"
                >
                  Revolutionizing the Content Horizon with Conversational AI{" "}
                  <br />
                  {/* Craft, Engage & Excel with the Power of Conversational AI <br />
                  Unleash the Power of Conversational AI in Every Word <br /> */}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  component={"p"}
                  sx={{ color: "black" }}
                  className="wz-head-points-text"
                >
                  {/* From crafting personalized stories to conversational wonders, Writewiz is the future of content and engagement. <br /> */}
                  {/* Personalized Persona, Intelligent Chatbots, Campaigns & much more. <br /> */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item width={'90%'}>
            <Grid container rowGap={2} columnGap={1.5}>
              {
                keyPoints.map(point => (
                  <Grid item>
                    <Button
                      sx={{ cursor: 'default' }}
                      variant={"text"}
                      className="wz-lp-head-key-point"
                    >
                      {point}
                    </Button>
                  </Grid>
                ))
              }
            </Grid>
          </Grid>

          <Grid item width={"90%"} className="wz-lp-head-twf-btn-item">
            <Grid container flexDirection={"column"} justifyContent={"center"}>
              <Grid item>
                <Button
                  variant="contained"
                  className="wz-lp-head-twf-btn"
                  size="medium"
                  onClick={() => {
                    handleRedirection()
                  }}
                  endIcon={<ArrowForwardIcon />}
                >
                  Start writing for FREE
                </Button>
              </Grid>
              <Grid
                item
                // marginLeft={"30px"}
                marginTop={"5px"}
                sx={{ color: "#7A7A7A", fontSize: "16px", fontweight: "500" }}
              >
                No credit card required
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>

      {/* <Grid item id="video-page" className=" pageone" width={"100%"}>
        <Grid container>
          {" "}
          <Grid item className="wz-lp-video-main-item ">
            <Grid container justifyContent={"center"}>
              <Grid item sm={9} xs={11} className="wz-lp-video-item">

                <img
                  src={WriteWizzPlaceHolder}
                  alt=""
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}

      {/* <Grid
        item
        id="tryout-our-product"
        className="pageone"
        width={"100%"}
        height={"auto !important"}
      >
        <TryOurProduct />
      </Grid> */}
    </Grid>
  );
};

export default First2PageAnimation;
