import createDataContext from "./MainContext";




const context_reducer = (state, action) => {
    switch (action.type) {

        case "loader": return {
            ...state,
            loading: action.payload
        };

        case "userData": return {
            ...state,
            userData: action.payload
        };

        case "promptList": return {
            ...state,
            promptList: action.payload
        };

        case "templateGenericData": return {
            ...state,
            templateGenericData: action.payload
        };

        case "routeSourceListData": return {
            ...state,
            routeSourceList: action.payload
        };

        case "outputFormatGenericListData": return {
            ...state,
            outputFormatGenericList: action.payload
        };

        case "setUserDetails": return {
            ...state,
            userDetails: action.payload
        };

        case "setToastNotification": return {
            ...state,
            toastNotification: action.payload
        };

        case "setFlagOpenAuthenticationDialog": return {
            ...state,
            flagOpenAuthenticationDialog: action.payload
        };
    }
};

const setLoader = (dispatch) => async (data) => {
    dispatch({
        type: "loader",
        payload: data
    })
}
const setUserData = (dispatch) => async (data) => {
    dispatch({
        type: "userData",
        payload: data
    })
}
const setPromptList = (dispatch) => async (data) => {
    dispatch({
        type: "promptList",
        payload: data
    })
}
const setTemplateGenericData = (dispatch) => async (data) => {
    dispatch({
        type: "templateGenericData",
        payload: data
    })
}
const setRouteSourceListData = (dispatch) => async (data) => {
    dispatch({
        type: "routeSourceListData",
        payload: data
    })
}
const setOutputFormatGenericListData = (dispatch) => async (data) => {
    dispatch({
        type: "outputFormatGenericListData",
        payload: data
    })
}

const setUserDetails = (dispatch) => async (data) => {
    dispatch({
        type: "setUserDetails",
        payload: data
    })
}

const setToastNotification = (dispatch) => async (data) => {
    dispatch({
        type: "setToastNotification",
        payload: data
    })
}

const setFlagOpenAuthenticationDialog = (dispatch) => async (data) => {
    dispatch({
        type: "setFlagOpenAuthenticationDialog",
        payload: data
    })
}

export const { Provider, Context } = createDataContext(
    context_reducer,
    {
        setLoader,
        setUserData,
        setPromptList,
        setTemplateGenericData,
        setRouteSourceListData,
        setOutputFormatGenericListData,
        setUserDetails,
        setToastNotification,
        setFlagOpenAuthenticationDialog,
    },
    {
        loading: false,
        userData: null,
        promptList: [],
        templateGenericData: [],
        routeSourceList: [],
        outputFormatGenericList: [],
        userDetails: {},
        toastNotification: {
            status: false,
            message: "",
            error: false,
        },
        flagOpenAuthenticationDialog: false
    }
);