import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import { Grid } from '@mui/material'
import ContactSalesForm from '../../Component/ContactSalesForm/ContactSalesForm'
import LandingFooter from '../../Component/Footer/LandingFooter'
import '../../Component/ContactSalesForm/ContactSalesForm.css'
import ContactSalesOverlapFooter from '../../Component/ContactSalesOverLapFooter/ContactSalesOverlapFooter'


const ContactSales = () => {

    const [scrollEffect, setScrollEffect] = useState(false);



    useEffect(() => {

        const changeStructure = () => {
            if (window.scrollY > 90) {
                setScrollEffect(true);
            } else {
                setScrollEffect(false);
            }
        };

        changeStructure();
        window.addEventListener("scroll", changeStructure);

        return () => {
            window.removeEventListener("scroll", changeStructure);
        };
    }, []);

    return (
        <>
            <Grid>
            <Navbar scrollEffect={scrollEffect} setScrollEffect={setScrollEffect} />
            <Grid container>
                <Grid item xs={12} className='csf-main'>
                    <Grid container>
                        <Grid item xs={12}>
                            <ContactSalesForm />
                        </Grid>
                        <Grid item xs={12} style={{position: "relative", marginTop: "30px"}}>
                            <ContactSalesOverlapFooter />
                        </Grid>
                        <Grid item xs={12}>
                            <LandingFooter />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </>
    )
}

export default ContactSales