import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
// import WriteWizMainLogo from "../../assests/WriteWizMainLogo.svg";
import WritewizaiPrimaryLogo from "../../assests/GlobalImages/WritewizAI-Primary-Logo.svg"
import "./WelcomeForm.css";
import { Avatar, Typography } from "@mui/material";
import star from "../../assests/star.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import userProfilePicture from "../../assests/FakeUserProfile.png";

const WelcomeNavbar = (props) => {
  const writeWizWebsiteURL = "https://writeWiz.ai";
  const { showCreditsMessage, flagCheckUserLogin } = props;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" className="wp-navbar">
          <Toolbar className="wp-tool-navbar">
            <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <a href={writeWizWebsiteURL} target="_self">
                <img
                  src={WritewizaiPrimaryLogo}
                  alt="WriteWiz"
                  width={"auto"}
                  height={30}
                />
              </a>
            </div>
            {flagCheckUserLogin == null ? null : flagCheckUserLogin == true ? (
              <Typography component={"div"}>
                <Avatar
                  alt="User"
                  src={userProfilePicture}
                  sx={{ cursor: "pointer" }}
                />
              </Typography>
            ) : (
              <Typography component={"div"} className="wp-signup-normal-text">
                You can get&nbsp;
                <Typography variant="span" className="wp-signup-normal-text">
                  <img src={star} alt="" />
                  &nbsp;100&nbsp;
                </Typography>
                credits added to your WriteWiz account by
                <Button
                  variant="text"
                  onClick={() => {
                    window.open(process.env.REACT_APP_REGISTER_PAGE, "_blank");
                  }}
                  endIcon={<ArrowForwardIcon />}
                  className="wp-signup-highlight-text"
                >
                  Sign Up
                </Button>
              </Typography>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default WelcomeNavbar;
