import React, { useContext, useEffect, useRef, useState } from 'react'
import '../ContactSalesForm/ContactSalesForm.css'
import './TalkToContactSales.css'
import { Alert, Button, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Snackbar, TextField, Typography, makeStyles, } from '@mui/material'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import rightMarkIcon from '../../assests/rightTickMark.png'
import ReCAPTCHA from 'react-google-recaptcha';
import { Context as mainContext } from '../../Context/MainContext/GlobalContext'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const TalkToContactSales = (props) => {

    console.log("props of talk to sales:", props)

    const {
        setFlagTalkToSalesDialog,
        alertMessage,
        setAlertMessage,
        handleClose

    } = props

    const { setLoader } = useContext(mainContext)

    // const [alertMessage, setAlertMessage] = useState({
    //     status: false,
    //     message: "",
    //     error: "",
    // })

    const grecaptcha = useRef(null);
    const [pointsValue, setPointsValue] = useState([
        "Use Seamless bulk generation and custom integrations",
        "Personalize Chatbots embody your brand's knowledge and ethos.",
        "Govern Brand persona ensuring integrity and consistency",
        "Get Expert Consultation to best benefit from Writewiz AI and enhance ROI",
        "Protect your brand and data privacy",
        "Generate AI templates and workflows tailored to your use cases"
    ]);
    const [instrestedIn, setInstrestedIn] = useState([
        // 'I’m new to Gen AI & want to learn more',
        // 'How my team can collaborate with Writewiz',
        // 'Learn more about a specific Writewiz feature (e.g. Persona, Chatbot, Extension, Templates etc.)',
        'Starter Plan - Upto 5 users',
        'Starter Plan - More than 5 users',
        'Premium Plan - Upto 5 users',
        'Premium Plan - More than 5 users',
    ]);
    const [aboutUs, setAboutUs] = useState([
        "Billboard",
        "Blog Post",
        "Event",
        "Friend, family, or work colleague",
        "Google search",
        "News",
        "Newsletter",
        "Online advertisement",
        "Social media post",
        "Webinar",
        "Other",
    ])
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(true);
    const [contactUsDetails, setContactUsDetails] = useState({
        email_address: "",
        demo_request_flag: true,
        firstname: "",
        lastname: "",
        subject: "",
        re_captcha: "",
        existing_customer_flag: false,
        company_name: "",
        employee_count: "",
        interests: "",
        about_us: "",
        message: "",
        help: ""
    });

    const [employees, setEmployees] = useState([
        "1-10",
        "11-20",
        "21-50",
        "51-100",
        "101-1000",
        "1000+"
    ])

    useEffect(() => {
        const requiredFields = ["firstname", "lastname", "company_name", "employee_count", "interests", "re_captcha", "email_address"];
        const allFieldsFilled = requiredFields.every(field => contactUsDetails[field] && contactUsDetails[field].trim() !== "" && isValidEmail);
        setIsButtonEnabled(!allFieldsFilled);

    }, [contactUsDetails]);


    const handleChangeRadio = (event) => {
        // console.log("event", typeof event.target.value);
        if (event.target.value == "true") {
            setContactUsDetails({
                ...contactUsDetails,
                existing_customer_flag: event.target.value == "false" ? false : true,
            });
            setInstrestedIn([
                'Customized Solutions',
                'Billing & Support questions',
                'I want to add users',
                'I need more personas',
                'I want personalized chatbots',
                'I want more integrations',
            ]);
        } else {
            setContactUsDetails({
                ...contactUsDetails,
                existing_customer_flag: event.target.value == "false" ? false : true,
            });
            setInstrestedIn([
                // 'I’m new to Gen AI & want to learn more',
                // 'How my team can collaborate with Writewiz',
                // 'Learn more about a specific Writewiz feature (e.g. Persona, Chatbot, Extension, Templates etc.)',
                'Starter Plan - Upto 5 users',
                'Starter Plan - More than 5 users',
                'Premium Plan - Upto 5 users',
                'Premium Plan - More than 5 users',
            ]);
        }
    };

    const handleSubmitContactSalesDetails = () => {

        setLoader(true)
        fetch(process.env.REACT_APP_API_CALL_URL + "/global/contact-us", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(contactUsDetails)
        })
            .then((response) => response.json())
            .then((res) => {
                // debugger
                console.log("response->", res);
                if (res?.status) {
                    console.log('here')
                    // debugger
                    setAlertMessage({
                        status: true,
                        message: res.data.message,
                        error: false,
                    })
                    setFlagTalkToSalesDialog(false)

                    setContactUsDetails({
                        ...contactUsDetails,
                        email_address: "",
                        firstname: "",
                        lastname: "",
                        subject: "",
                        re_captcha: "",
                        existing_customer_flag: false,
                        company_name: "",
                        employee_count: "",
                        interests: "",
                        about_us: "",
                        message: "",
                        help: ""
                    })
                    grecaptcha.current.reset();
                    setLoader(false)

                }
                else {
                    // debugger
                    setFlagTalkToSalesDialog(false)
                    setAlertMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                    setContactUsDetails({
                        ...contactUsDetails,
                        email_address: "",
                        firstname: "",
                        lastname: "",
                        subject: "",
                        re_captcha: "",
                        existing_customer_flag: false,
                        company_name: "",
                        employee_count: "",
                        interests: "",
                        about_us: "",
                        message: "",
                        help: ""
                    })
                    grecaptcha.current.reset();
                    setLoader(false)
                }
                // setFlagTalkToSalesDialog(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                setFlagTalkToSalesDialog(false)
                setAlertMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })

                setFlagTalkToSalesDialog(false);
                grecaptcha.current.reset();
                setLoader(false)
            });
    }

    useEffect(() => {
        console.log("here", alertMessage)
    }, [alertMessage])

    return (
        <>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container>
                    <Grid item xs={12} className='ts-form-item'>
                        <Grid container>
                            <Grid item xs={12} className='csf-form-head-text'>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <Typography variant='span' sx={{ fontSize: "22px", fontWeight: "600", color: "#6A097D" }}>Contact our sales team</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => { setFlagTalkToSalesDialog(false) }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className='csf-comman-global-item'>
                                <Grid container>
                                    <Grid item xs={12} className='csf-form-label-item'>
                                        <Typography variant='span' className='csf-global-label'>Business Email <Typography variant='span' sx={{ color: "red" }}>*</Typography></Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            variant='outlined'
                                            size='small'
                                            type='email'
                                            fullWidth
                                            className='csf-form-textfields'
                                            value={contactUsDetails?.email_address}
                                            onChange={(e) => {
                                                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                setIsValidEmail(emailPattern.test(e.target.value));
                                                setContactUsDetails({
                                                    ...contactUsDetails,
                                                    email_address: e.target.value
                                                })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className='csf-comman-global-item'>
                                <Grid container sx={{ justifyContent: "space-between" }}>
                                    <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                        <Grid container>
                                            <Grid item xs={12} className='csf-global-label-item'>
                                                <Typography variant='span' className='csf-global-label'>First name <Typography variant='span' sx={{ color: "red" }}>*</Typography></Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant='outlined'
                                                    size='small'
                                                    type='email'
                                                    fullWidth
                                                    className='csf-form-textfields'
                                                    value={contactUsDetails?.firstname}
                                                    onChange={(e) => {
                                                        setContactUsDetails({
                                                            ...contactUsDetails,
                                                            firstname: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                        <Grid container>
                                            <Grid item xs={12} className='csf-global-label-item'>
                                                <Typography variant='span' className='csf-global-label'>Last name <Typography variant='span' sx={{ color: "red" }}>*</Typography></Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant='outlined'
                                                    size='small'
                                                    type='email'
                                                    fullWidth
                                                    className='csf-form-textfields'
                                                    value={contactUsDetails?.lastname}
                                                    onChange={(e) => {
                                                        setContactUsDetails({
                                                            ...contactUsDetails,
                                                            lastname: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className='csf-comman-global-item'>
                                <Grid container sx={{ justifyContent: "space-between" }}>
                                    <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                        <Grid container>
                                            <Grid item xs={12} className='csf-global-label-item'>
                                                <Typography variant='span' className='csf-global-label'>Company name <Typography variant='span' sx={{ color: "red" }}>*</Typography></Typography>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <TextField
                                                    variant='outlined'
                                                    size='small'
                                                    type='email'
                                                    fullWidth
                                                    className='csf-form-textfields'
                                                    value={contactUsDetails?.company_name}
                                                    onChange={(e) => {
                                                        setContactUsDetails({
                                                            ...contactUsDetails,
                                                            company_name: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                        <Grid container>
                                            <Grid item xs={12} className='csf-global-label-item'>
                                                <Typography variant='span' className='csf-global-label'>Are you an existing customer? <Typography variant='span' sx={{ color: "red" }}>*</Typography></Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        row
                                                        aria-label="existing-customer"
                                                        name="existing-customer"
                                                        value={contactUsDetails.existing_customer_flag}
                                                        onChange={handleChangeRadio}
                                                    >
                                                        <FormControlLabel
                                                            value={true}
                                                            control={<Radio
                                                                sx={{
                                                                    color: "#6A097D",
                                                                    '&.Mui-checked': { color: "#6A097D" },
                                                                }}
                                                            />}
                                                            label="Yes"
                                                        />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio
                                                                sx={{
                                                                    color: "#6A097D",
                                                                    '&.Mui-checked': { color: "#6A097D" },
                                                                }}
                                                            />}
                                                            label="No"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className='csf-comman-global-item'>
                                <Grid container>
                                    <Grid item xs={12} className='csf-global-label-item'>
                                        <Typography variant='span' className='csf-global-small-text'>How many employees does your company have? <Typography variant='span' sx={{ color: "red" }}>*</Typography></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size='medium'
                                                className='csf-intrested-dropdown'
                                                value={contactUsDetails?.employee_count}
                                                onChange={(e) => {
                                                    setContactUsDetails({
                                                        ...contactUsDetails,
                                                        employee_count: e.target.value
                                                    })
                                                }}
                                            >
                                                {employees.map((option, index) => (
                                                    <MenuItem key={index} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className='csf-comman-global-item'>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant='span' className='csf-global-label'>I'm most interested in <Typography variant='span' sx={{ color: "red" }}>*</Typography></Typography>
                                    </Grid>
                                    <Grid item xs={12} className='csf-global-label-item'>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                size='medium'
                                                className='csf-intrested-dropdown'
                                                value={contactUsDetails?.interests}
                                                onChange={(e) => {
                                                    setContactUsDetails({
                                                        ...contactUsDetails,
                                                        interests: e.target.value
                                                    })
                                                }}

                                            >
                                                {instrestedIn.map((option, index) => (
                                                    <MenuItem key={index} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* {contactUsDetails.existing_customer_flag == false && (
                                <>
                                    <Grid item xs={12} className='csf-comman-global-item'>
                                        <Grid container>
                                            <Grid item xs={12} className='csf-global-label-item'>
                                                <Typography variant='span' className='csf-global-label'>Where did you hear about us?</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        size='medium'
                                                        className='csf-intrested-dropdown'
                                                        value={contactUsDetails?.about_us}
                                                        onChange={(e) => {
                                                            console.log("gfgffg", e.target.value)
                                                            setContactUsDetails({
                                                                ...contactUsDetails,
                                                                about_us: e.target.value
                                                            })
                                                        }}
                                                    >
                                                        {aboutUs.map((option, index) => (
                                                            <MenuItem key={index} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )} */}

                            {/* <Grid item xs={12} className='csf-comman-global-item'>
                                <Grid container>
                                    <Grid item xs={12} className='csf-global-label-item'>
                                        <Typography variant='span' className='csf-global-label'>What would you like help with?</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <textarea
                                            style={{ width: "100%", fontSize: "15px", padding: "7px 13px" }}
                                            rows={5}
                                            minLength={500}
                                            className='csf-textarea'
                                            value={contactUsDetails?.help}
                                            onChange={(e) => {
                                                setContactUsDetails({
                                                    ...contactUsDetails,
                                                    help: e.target.value
                                                })
                                            }}
                                        >
                                        </textarea>
                                    </Grid>
                                </Grid>
                            </Grid> */}

                            <Grid item xs={12} className='csf-comman-global-item'>
                                <ReCAPTCHA
                                    className='google-recaptcha'
                                    sitekey="6Ld0tIgnAAAAAM6flWQfozA41EW4o13PRW78EILx"
                                    ref={grecaptcha}
                                    onChange={(token) => {
                                        setContactUsDetails({
                                            ...contactUsDetails,
                                            re_captcha: token
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} className='csf-comman-global-item'>
                                <Button
                                    variant='contained'
                                    fullWidth
                                    size='large'
                                    className='csf-request-demo-btn'
                                    disabled={isButtonEnabled}
                                    onClick={handleSubmitContactSalesDetails}
                                >
                                    Request Demo
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default TalkToContactSales