import React, { useEffect, useState } from 'react'
import './FeedbackForm.css'
import { Alert, Button, Checkbox, FormControlLabel, FormGroup, Grid, Snackbar, Typography } from '@mui/material'
import FeedbackNavbar from '../FeedbackNavbar/FeedbackNavbar'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const FeedbackForm = () => {

    const API_ENDPOINT = process.env.REACT_APP_API_CALL_URL + "/global/add-feedback";
    const [feedbackFormDetails, setFeedbackFormDetails] = useState({
        user_email: "",
        re_install_flag: false,
        not_worked_properly_flag: false,
        too_pricey_flag: false,
        dont_know_how_to_use_flag: false,
        wz_do_not_operate_on_writing_flag: false,
        privacy_concern_flag: false,
        icon_bothering_flag: false,
        need_more_free_use_flag: false,
        expected_something_else_flag: false,
        another_reason_flag: false,
        how_we_improve_wz: ""
    })
    const [alertMessage, setAlertMessage] = useState({
        status: false,
        message: "",
        error: "",
    });
    const [flagDisabledBtn, setFlagDisabledBtn] = useState(true)

    useEffect(() => {
        if (feedbackFormDetails.re_install_flag || feedbackFormDetails.not_worked_properly_flag || feedbackFormDetails.too_pricey_flag ||
            feedbackFormDetails.dont_know_how_to_use_flag || feedbackFormDetails.wz_do_not_operate_on_writing_flag || feedbackFormDetails.privacy_concern_flag || feedbackFormDetails.expected_something_else_flag ||
            feedbackFormDetails.icon_bothering_flag || feedbackFormDetails.need_more_free_use_flag || feedbackFormDetails.another_reason_flag || feedbackFormDetails.how_we_improve_wz
        ) {
            setFlagDisabledBtn(false)
        } else {
            setFlagDisabledBtn(true)
        }
    }, [feedbackFormDetails])


    const handleSubmitFeedbackform = () => {

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(feedbackFormDetails)
        };

        fetch(API_ENDPOINT, requestOptions).then(response => {
            if (!response.ok) {
                setAlertMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
            }
            return response.json();
        })
            .then(data => {
                console.log("Success of feedback form: ", data);
                setAlertMessage({
                    status: true,
                    message: "Feedback sent successfully!",
                    error: false,
                })
                setFeedbackFormDetails([{
                    user_email: "",
                    re_install_flag: false,
                    not_worked_properly_flag: false,
                    too_pricey_flag: false,
                    dont_know_how_to_use_flag: false,
                    wz_do_not_operate_on_writing_flag: false,
                    privacy_concern_flag: false,
                    icon_bothering_flag: false,
                    need_more_free_use_flag: false,
                    expected_something_else_flag: false,
                    another_reason_flag: false,
                    how_we_improve_wz: ""
                }])
                // Handle success scenario
            })
            .catch(error => {
                console.error("Error:", error);
                setAlertMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
                setFeedbackFormDetails([{
                    user_email: "",
                    re_install_flag: false,
                    not_worked_properly_flag: false,
                    too_pricey_flag: false,
                    dont_know_how_to_use_flag: false,
                    wz_do_not_operate_on_writing_flag: false,
                    privacy_concern_flag: false,
                    icon_bothering_flag: false,
                    need_more_free_use_flag: false,
                    expected_something_else_flag: false,
                    another_reason_flag: false,
                    how_we_improve_wz: ""
                }])
                // props.setFlagOpenHelpCenterDialog(false)
                // Handle error scenario
            });
    };

    const handleClose = () => {
        setAlertMessage({
            status: false,
            message: "",
            error: "",
        })
    };




    return (
        <>
            {alertMessage.status &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alertMessage.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                        {alertMessage.message}
                    </Alert>
                </Snackbar>
            }
            <FeedbackNavbar />
            <Grid container className='fb-form-main-container'>
                <Grid item xs={12} className='fb-form-main-item'>
                    <Grid container sx={{ height: "100%" }}>
                        <Grid item xl={8} lg={9} md={10} sm={10} xs={11} className='fb-form-white-item'>
                            <Grid container sx={{ height: "100%", alignItems: 'start' }}>
                                <Grid item xl={8} lg={10} md={12} sm={12} xs={12} className='fb-form-white-sub-item'>
                                    <Grid container sx={{ height: "100%" }}>
                                        <Grid item xl={7} lg={8} md={8} sm={12} xs={12} sx={{ margin: "0 auto", textAlign: "start" }}>
                                            <Grid container >
                                                <Grid item xs={12} sx={{ margin: "0px 0px 15px", textAlign: "start" }}>
                                                    <Typography variant='span' className='fb-form-main-head-text'>Help Us Improve</Typography>
                                                </Grid>

                                                <Grid item xs={12} className='fb-form-common-item'>
                                                    <Typography variant='span' className='fb-form-sub-head-text'>
                                                        Why did you uninstall <Typography variant='span' className='fb-form-highlighted-text'>WriteWiz AI</Typography> ? Check all that apply.
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} className='fb-form-common-item'>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={feedbackFormDetails.re_install_flag}
                                                                    checked={feedbackFormDetails.re_install_flag}
                                                                    onChange={(e) => {
                                                                        setFeedbackFormDetails({
                                                                            ...feedbackFormDetails,
                                                                            re_install_flag: e.target.checked
                                                                        })
                                                                    }}
                                                                />}
                                                            className='fb-checkbox-label'
                                                            label="I plan to reinstall WriteWiz AI"
                                                        />
                                                        <FormControlLabel
                                                            label="WriteWiz AI didn’t work properly"
                                                            className='fb-checkbox-label'
                                                            control={
                                                                <Checkbox
                                                                    value={feedbackFormDetails.not_worked_properly_flag}
                                                                    checked={feedbackFormDetails.not_worked_properly_flag}
                                                                    onChange={(e) => {
                                                                        setFeedbackFormDetails({
                                                                            ...feedbackFormDetails,
                                                                            not_worked_properly_flag: e.target.checked
                                                                        })
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={feedbackFormDetails.too_pricey_flag}
                                                                    checked={feedbackFormDetails.too_pricey_flag}
                                                                    onChange={(e) => {
                                                                        setFeedbackFormDetails({
                                                                            ...feedbackFormDetails,
                                                                            too_pricey_flag: e.target.checked
                                                                        })
                                                                    }}
                                                                />
                                                            }
                                                            className='fb-checkbox-label'
                                                            label="It’s too pricey for me"
                                                        />
                                                        <FormControlLabel
                                                            className='fb-checkbox-label'
                                                            label="I wasn’t sure how to use it"
                                                            control={
                                                                <Checkbox
                                                                    value={feedbackFormDetails.dont_know_how_to_use_flag}
                                                                    checked={feedbackFormDetails.dont_know_how_to_use_flag}
                                                                    onChange={(e) => {
                                                                        setFeedbackFormDetails({
                                                                            ...feedbackFormDetails,
                                                                            dont_know_how_to_use_flag: e.target.checked
                                                                        })
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={feedbackFormDetails.wz_do_not_operate_on_writing_flag}
                                                                    checked={feedbackFormDetails.wz_do_not_operate_on_writing_flag}
                                                                    onChange={(e) => {
                                                                        setFeedbackFormDetails({
                                                                            ...feedbackFormDetails,
                                                                            wz_do_not_operate_on_writing_flag: e.target.checked
                                                                        })
                                                                    }}
                                                                />}
                                                            className='fb-checkbox-label'
                                                            label="WriteWiz AI doesn’t operate where I do my writing"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={feedbackFormDetails.icon_bothering_flag}
                                                                    checked={feedbackFormDetails.icon_bothering_flag}
                                                                    onChange={(e) => {
                                                                        setFeedbackFormDetails({
                                                                            ...feedbackFormDetails,
                                                                            icon_bothering_flag: e.target.checked
                                                                        })
                                                                    }}
                                                                />}
                                                            className='fb-checkbox-label'
                                                            label="The purple W icon was bothering me"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                value={feedbackFormDetails.need_more_free_use_flag}
                                                                checked={feedbackFormDetails.need_more_free_use_flag}
                                                                onChange={(e) => {
                                                                    setFeedbackFormDetails({
                                                                        ...feedbackFormDetails,
                                                                        need_more_free_use_flag: e.target.checked
                                                                    })
                                                                }}
                                                            />}
                                                            className='fb-checkbox-label'
                                                            label="I need more free uses"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={feedbackFormDetails.expected_something_else_flag}
                                                                    checked={feedbackFormDetails.expected_something_else_flag}
                                                                    onChange={(e) => {
                                                                        setFeedbackFormDetails({
                                                                            ...feedbackFormDetails,
                                                                            expected_something_else_flag: e.target.checked
                                                                        })
                                                                    }}
                                                                />}
                                                            className='fb-checkbox-label'
                                                            label="I expected something else"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={feedbackFormDetails.another_reason_flag}
                                                                    checked={feedbackFormDetails.another_reason_flag}
                                                                    onChange={(e) => {
                                                                        setFeedbackFormDetails({
                                                                            ...feedbackFormDetails,
                                                                            another_reason_flag: e.target.checked
                                                                        })
                                                                    }}
                                                                />
                                                            }
                                                            className='fb-checkbox-label'
                                                            label="Another reason"
                                                        />
                                                    </FormGroup>
                                                </Grid>

                                                <Grid item xs={12} className='fb-form-common-item'>
                                                    <Grid container>
                                                        <Grid item xs={12} sx={{ textAlign: "start", margin: '5px 0px' }}>
                                                            <Typography variant='span' className='fb-form-input-sub-head-text'>
                                                                How else we can improve  <Typography variant='span' className='fb-form-input-highlighted-text'>WriteWiz AI</Typography> ?
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sx={{ margin: '5px 0px' }}>
                                                            <textarea
                                                                rows={5}
                                                                className='fb-form-textarea'
                                                                value={feedbackFormDetails.how_we_improve_wz}
                                                                onChange={(e) => {
                                                                    setFeedbackFormDetails({
                                                                        ...feedbackFormDetails,
                                                                        how_we_improve_wz: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <Button
                                                                variant='contained'
                                                                size='medium'
                                                                disabled={flagDisabledBtn}
                                                                sx={{
                                                                    background: "#6A097D",
                                                                    color: "#FFF",
                                                                    textTransform: "capitalize",
                                                                    fontSize: "15px",
                                                                    fontWeight: "500",
                                                                }}
                                                                onClick={() => {
                                                                    handleSubmitFeedbackform()
                                                                }}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default FeedbackForm