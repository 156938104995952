import React, { useContext, useRef, useState } from 'react'
import './ContactSalesForm.css'
import { Alert, Autocomplete, Button, Grid, List, ListItem, ListItemIcon, ListItemText, Snackbar, TextField, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl';
import rightMarkIcon from '../../assests/rightTickMarkLight.svg'
import metaProvider from '../../assests/metaProvider.svg'
import ReCAPTCHA from 'react-google-recaptcha';
import { Context as mainContext } from '../../Context/MainContext/GlobalContext'
import ContactSalesFormPopup from './ContactSalesFormPopup';

const ContactSalesForm = () => {

    const { setLoader } = useContext(mainContext)

    const grecaptcha = useRef(null);
    const [pointsValue, setPointsValue] = useState([
        {
            title: "Automate with Intelligence: ",
            value: "Use our advanced AI chatbots for seamless bulk interactions and custom integrations."
        },
        {
            title: "Personalize Effortlessly: ",
            value: "Our chatbots adapt your brand’s voice and ethos, offering personalized engagements at scale."
        },
        {
            title: "Maintain Brand Integrity: ",
            value: "Govern your brand’s persona with our AI, ensuring consistency and integrity across all communications."
        },
        {
            title: "Leverage Expert Insights: ",
            value: "Gain expert consultation to maximize the benefits from our AI solutions and boost your ROI."
        },
        {
            title: "Secure Your Data: ",
            value: "Protect your brand and maintain data privacy with our secure AI-driven platforms."
        },
        {
            title: "Customize AI-Driven Solutions: ",
            value: "Generate AI templates and workflows specifically tailored to meet your unique business needs."
        },
    ]);
    const [instrestedIn, setInstrestedIn] = useState([
        'AI Chatbot Solutions',
        'WhatsApp AI Chatbot Services',
        'AI Content Generation',
        'Personalized Writing Assistant',
        'Customized Enterprise Solutions',
        'Other'
    ]);
    const [aboutUs, setAboutUs] = useState([
        "Billboard",
        "Blog Post",
        "Event",
        "Friend, family, or work colleague",
        "Google search",
        "News",
        "Newsletter",
        "Online advertisement",
        "Social media post",
        "Webinar",
        "Other",
    ])
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(true);
    const [contactUsDetails, setContactUsDetails] = useState({
        email_address: "",
        demo_request_flag: false,
        firstname: "",
        lastname: "",
        subject: "",
        re_captcha: "",
        existing_customer_flag: false,
        company_name: "",
        employee_count: "",
        interests: [],
        about_us: "",
        message: "",
        help: "",
        contact_number: "",
        industry: "",
        designation: ""
    });
    const [alertMessage, setAlertMessage] = useState({
        status: false,
        message: "",
        error: "",
    });
    const [employees, setEmployees] = useState([
        "1-10",
        "11-20",
        "21-50",
        "51-100",
        "101-1000",
        "1000+"
    ])
    const [industry, setIndustry] = useState([
        "Retail and eCommerce",
        "Banking and Financial Services",
        "Healthcare",
        "Education",
        "Real Estate",
        "Travel and Hospitality",
        "Telecommunications",
        "Insurance",
        "Technology and IT Services",
        "Media and Entertainment",
        "Automotive",
        "Government and Public Sector",
        "Non-Profit",
        "Manufacturing",
        "Professional Services"
    ])
    const [flagOthers, setFlagOthers] = useState(false);
    const [flagShowPopup, setFlagShowPopup] = useState(false);

    const isDisabledSubmitCondition = () => {
        const requiredFields = ["firstname", "interests", "re_captcha", "email_address"];
        const otherRequiredFields = ["firstname", "interests", "re_captcha", "email_address", "help"];
        if (flagOthers) {
            return !otherRequiredFields.every(field => {
                const value = contactUsDetails[field];
                if (field === "interests") {
                    return Array.isArray(value) && value.length > 0;
                }
                if (field === "email_address") {
                    return value && value.trim() !== "" && isValidEmail;
                }
                return value && value.trim() !== "";
            });
        }
        else {
            return !requiredFields.every(field => {
                const value = contactUsDetails[field];
                if (field === "interests") {
                    return Array.isArray(value) && value.length > 0;
                }
                if (field === "email_address") {
                    return value && value.trim() !== "" && isValidEmail;
                }
                return value && value.trim() !== "";
            });
        }
    }


    const handleChangeRadio = (event) => {

        // console.log("event", typeof event.target.value);
        if (event.target.value == "true") {
            setContactUsDetails({
                ...contactUsDetails,
                existing_customer_flag: event.target.value == "false" ? false : true,
            });
            setInstrestedIn([
                'Customized Solutions',
                'Billing & Support questions',
                'I want to add users',
                'I need more personas',
                'I want personalized chatbots',
                'I want more integrations',
            ]);
        } else {
            setContactUsDetails({
                ...contactUsDetails,
                existing_customer_flag: event.target.value == "false" ? false : true,
            });
            setInstrestedIn([
                'I’m new to Gen AI & want to learn more',
                'How my team can collaborate with Writewiz AI',
                'Learn more about a specific Writewiz AI feature (e.g. Persona, Chatbot, Extension, Templates etc.)',
            ]);
        }
    };

    const handleClose = () => {
        setAlertMessage({
            status: false,
            message: "",
            error: "",
        })
    };

    const convertInterestValInRequiredFormat = (interest) => {
        let formattedInterests = "";
        if (Array.isArray(interest) && interest.length > 0) {
            if (interest.length === 1) {
                formattedInterests = interest[0];
            } else {
                const lastIndex = interest.length - 1;
                const interestsExceptLast = interest.slice(0, lastIndex).join(", ");
                const lastInterest = interest[lastIndex];
                formattedInterests = `${interestsExceptLast} & ${lastInterest}`;
            }
            return formattedInterests
        }
    }
    const handleSubmitContactSalesDetails = () => {
        if (!navigator.online) {
            grecaptcha.current.reset();
            setContactUsDetails({
                ...contactUsDetails,
                re_captcha: ""
            })
        }
        setLoader(true)
        const payload = { ...contactUsDetails }
        payload.interests = convertInterestValInRequiredFormat(payload.interests)
        fetch(process.env.REACT_APP_API_CALL_URL + "/global/contact-us", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(payload)
        })
            .then((response) => response.json())
            .then((res) => {
                console.log("response->", res);
                if (res?.status) {
                    setAlertMessage({
                        status: true,
                        message: res.data.message,
                        error: false,
                    })
                    setFlagShowPopup(true)
                    grecaptcha.current.reset();
                    setContactUsDetails({
                        ...contactUsDetails,
                        email_address: "",
                        firstname: "",
                        lastname: "",
                        subject: "",
                        re_captcha: "",
                        existing_customer_flag: false,
                        company_name: "",
                        employee_count: "",
                        interests: [],
                        about_us: "",
                        message: "",
                        help: "",
                        contact_number: "",
                        industry: "",
                        designation: ""
                    })
                    setLoader(false)
                }
                else {
                    setAlertMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                    grecaptcha.current.reset();
                    setContactUsDetails({
                        ...contactUsDetails,
                        email_address: "",
                        firstname: "",
                        lastname: "",
                        subject: "",
                        re_captcha: "",
                        existing_customer_flag: false,
                        company_name: "",
                        employee_count: "",
                        interests: [],
                        about_us: "",
                        message: "",
                        help: "",
                        contact_number: "",
                        industry: "",
                        designation: ""
                    })
                    setLoader(false)
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setAlertMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
                grecaptcha.current.reset();
                setLoader(false)
            });
    }


    return (
        <>
            {alertMessage.error &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={"error"} sx={{ width: '100%' }}>
                        {alertMessage.message}
                    </Alert>
                </Snackbar>
            }
            <Grid container>
                <Grid item xl={9} lg={10} md={9} sm={10} xs={11} className='csf-main-item'>
                    <Grid container className='csf-main-container' gap={1}>

                        <Grid item xl={5.5} lg={5.8} md={12} sm={12} xs={12}>
                            <Grid container>
                                <Grid item xs={12} className='csf-head-text-item'>
                                    <Grid container className='csf-head-container'>
                                        <Grid item xs={12} lg={7}>
                                            <Typography component={'span'} className='csf-head-text'>
                                                AI Your Business Will Thrive On
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={5} className='csf-head-meta-provider'>
                                            <img src={metaProvider} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant='span' className='csf-head-sub-text'>
                                                Top organizations trust our AI solutions to empower their teams — marketing,
                                                customer support, product development, HR, and beyond — enhancing creativity
                                                and multiplying productivity tenfold.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} className='csf-point-text-item'>
                                            <Typography variant='span' className='csf-point-text'>
                                                Purpose-built for modern enterprises! Discover how you can:
                                            </Typography>
                                            <div style={{ margin: "0px 0px 15px" }}></div>
                                            {pointsValue.map((ele) => {
                                                return (
                                                    <>
                                                        <List >
                                                            <ListItem className='csf-list-item' key={ele.title}>
                                                                <ListItemIcon className='csf-list-icon'  >
                                                                    <img src={rightMarkIcon} alt="" />
                                                                </ListItemIcon>
                                                                <ListItemText primary={<><span className='csf-list-text-title'>{ele.title}</span><span className='csf-list-text'>{ele.value}</span></>} />
                                                            </ListItem>
                                                        </List>
                                                    </>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item className='csf-popup-grid' sx={{ position: "relative" }} xl={5.5} lg={5.8} md={12} sm={12} xs={12}>
                            <Grid container>
                                <Grid item xs={12} className='csf-form-item'>
                                    {flagShowPopup && <ContactSalesFormPopup setFlagShowPopup={setFlagShowPopup} />}
                                    <Grid container>
                                        <Grid item xs={12} className='csf-form-head-text'>
                                            <Typography variant='span' className='csf-form-head-textfield'>Contact Writewiz AI Team</Typography>
                                        </Grid>

                                        <Grid item xs={12} className='csf-comman-global-sub-item'>
                                            <Grid container sx={{ justifyContent: "space-between" }}>
                                                <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                                    <Grid container>
                                                        <Grid item xs={12} className='csf-global-label-item'>
                                                            <Typography variant='span' className='csf-global-label'>First Name <Typography variant='span' sx={{ color: "red" }}>*</Typography></Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant='outlined'
                                                                size='small'
                                                                type='email'
                                                                fullWidth
                                                                placeholder='First Name'
                                                                className='csf-form-textfields'
                                                                value={contactUsDetails?.firstname}
                                                                onChange={(e) => {
                                                                    setContactUsDetails({
                                                                        ...contactUsDetails,
                                                                        firstname: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                                    <Grid container>
                                                        <Grid item xs={12} className='csf-global-label-item'>
                                                            <Typography variant='span' className='csf-global-label'>Last Name</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant='outlined'
                                                                size='small'
                                                                type='email'
                                                                fullWidth
                                                                placeholder='Last Name'
                                                                className='csf-form-textfields'
                                                                value={contactUsDetails?.lastname}
                                                                onChange={(e) => {
                                                                    setContactUsDetails({
                                                                        ...contactUsDetails,
                                                                        lastname: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className='csf-comman-global-sub-item'>
                                            <Grid container sx={{ justifyContent: "space-between" }}>
                                                <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                                    <Grid container>
                                                        <Grid item xs={12} className='csf-global-label-item'>
                                                            <Typography variant='span' className='csf-global-label'>Business Email <Typography variant='span' sx={{ color: "red" }}>*</Typography></Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant='outlined'
                                                                size='small'
                                                                type='email'
                                                                fullWidth
                                                                placeholder='Business Email'
                                                                className='csf-form-textfields'
                                                                value={contactUsDetails?.email_address}
                                                                onChange={(e) => {
                                                                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                                    setIsValidEmail(emailPattern.test(e.target.value));
                                                                    setContactUsDetails({
                                                                        ...contactUsDetails,
                                                                        email_address: e.target.value
                                                                    })
                                                                }}
                                                                helperText={
                                                                    (!isValidEmail && contactUsDetails?.email_address.trim() !== "") && "Please enter a valid Email Address"
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                                    <Grid container>
                                                        <Grid item xs={12} className='csf-global-label-item'>
                                                            <Typography variant='span' className='csf-global-label'>Contact Number</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant='outlined'
                                                                size='small'
                                                                type='email'
                                                                fullWidth
                                                                placeholder='Contact Number'
                                                                className='csf-form-textfields'
                                                                value={contactUsDetails?.contact_number}
                                                                onChange={(e) => {
                                                                    setContactUsDetails({
                                                                        ...contactUsDetails,
                                                                        contact_number: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className='csf-comman-global-sub-item'>
                                            <Grid container sx={{ justifyContent: "space-between" }}>
                                                <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                                    <Grid container>
                                                        <Grid item xs={12} className='csf-global-label-item'>
                                                            <Typography variant='span' className='csf-global-label'>Company Name</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <TextField
                                                                variant='outlined'
                                                                size='small'
                                                                type='email'
                                                                fullWidth
                                                                placeholder='Company Name'
                                                                className='csf-form-textfields'
                                                                value={contactUsDetails?.company_name}
                                                                onChange={(e) => {
                                                                    setContactUsDetails({
                                                                        ...contactUsDetails,
                                                                        company_name: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                                    <Grid container>
                                                        <Grid item xs={12} className='csf-global-label-item'>
                                                            <Typography variant='span' className='csf-global-label'>Industry</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControl fullWidth>
                                                                <Autocomplete
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size='medium'
                                                                    options={industry}
                                                                    className='csf-intrested-dropdown'
                                                                    value={contactUsDetails?.industry}
                                                                    onChange={(e, value) => {
                                                                        setContactUsDetails({
                                                                            ...contactUsDetails,
                                                                            industry: value
                                                                        })
                                                                    }}
                                                                    renderInput={(params) => (<TextField {...params} placeholder='Select Industry' />)}

                                                                >
                                                                </Autocomplete>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className='csf-comman-global-sub-item'>
                                            <Grid container sx={{ justifyContent: "space-between" }}>
                                                <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                                    <Grid container>
                                                        <Grid item xs={12} className='csf-global-label-item'>
                                                            <Typography variant='span' className='csf-global-label'>Designation</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <TextField
                                                                variant='outlined'
                                                                size='small'
                                                                type='email'
                                                                fullWidth
                                                                placeholder='Designation'
                                                                className='csf-form-textfields'
                                                                value={contactUsDetails?.designation}
                                                                onChange={(e) => {
                                                                    setContactUsDetails({
                                                                        ...contactUsDetails,
                                                                        designation: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='csf-global-textfield-mb-item'>
                                                    <Grid container>
                                                        <Grid item xs={12} className='csf-global-label-item'>
                                                            <Typography variant='span' className='csf-global-label'>Team Size</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControl fullWidth>
                                                                <Autocomplete
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    size='medium'
                                                                    options={employees}
                                                                    className='csf-intrested-dropdown'
                                                                    value={contactUsDetails?.employee_count}
                                                                    onChange={(e, value) => {
                                                                        setContactUsDetails({
                                                                            ...contactUsDetails,
                                                                            employee_count: value
                                                                        })
                                                                    }}
                                                                    renderInput={(params) => (<TextField {...params} placeholder='Select Team Size' />)}

                                                                >
                                                                </Autocomplete>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className='csf-comman-global-item'>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant='span' className='csf-global-label'>I'm interested in? <Typography variant='span' sx={{ color: "red" }}>*</Typography></Typography>
                                                </Grid>
                                                <Grid item xs={12} className='csf-global-label-item'>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            size='medium'
                                                            options={instrestedIn}
                                                            multiple
                                                            className='csf-intrested-dropdown'
                                                            value={contactUsDetails?.interests}
                                                            onChange={(e, value) => {
                                                                const includesOther = value.includes("Other");
                                                                setFlagOthers(includesOther);
                                                                setContactUsDetails({
                                                                    ...contactUsDetails,
                                                                    interests: value
                                                                })
                                                            }}
                                                            renderInput={(params) => (<TextField {...params} placeholder='Select' />)}

                                                        >
                                                        </Autocomplete>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className='csf-comman-global-item'>
                                            <Grid container>
                                                <Grid item xs={12} className='csf-global-label-item'>
                                                    <Typography variant='span' className='csf-global-label'>Tell us more about what you need {flagOthers && <Typography variant='span' sx={{ color: "red" }}>*</Typography>}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <textarea
                                                        style={{ width: "100%", fontSize: "15px", padding: "7px 13px" }}
                                                        rows={5}
                                                        minLength={500}
                                                        className='csf-textarea'
                                                        placeholder='Write Comment'
                                                        value={contactUsDetails?.help}
                                                        onChange={(e) => {
                                                            setContactUsDetails({
                                                                ...contactUsDetails,
                                                                help: e.target.value
                                                            })
                                                        }}
                                                    >
                                                    </textarea>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className='csf-comman-global-item'>
                                            <ReCAPTCHA
                                                sitekey="6Ld0tIgnAAAAAM6flWQfozA41EW4o13PRW78EILx"
                                                ref={grecaptcha}
                                                onChange={(token) => {
                                                    setContactUsDetails({
                                                        ...contactUsDetails,
                                                        re_captcha: token
                                                    })
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} className='csf-comman-global-item'>
                                            <Button
                                                variant='contained'
                                                // fullWidth
                                                size='large'
                                                className='csf-request-demo-btn'
                                                disabled={isDisabledSubmitCondition()}
                                                onClick={handleSubmitContactSalesDetails}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactSalesForm


