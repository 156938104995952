import React from 'react'
import './ChatbotWhatsappBusinessSection.css'
import { Button, Grid, Typography } from '@mui/material';
import WhatsAppIcon from '../../assests/ChatbotWhatsapp/cw-wp-icon.png';

import circleImage1 from '../../assests/ChatbotWhatsapp/circle-image-1.svg'
import circleImage2 from '../../assests/ChatbotWhatsapp/circle-image-2.svg'
import circleImage3 from '../../assests/ChatbotWhatsapp/cricle-image-3.svg'

const ChatbotWhatsappBusinessSection = () => {

    let array = [
        {
            mainHead: 'ONBOARDING SETUP',
            headText: 'Business Profile Setup & Verification',
            subText: "Our dedicated support doesn't stop at your business profile setup. We provide ongoing assistance to manage and optimize your WhatsApp Business profile for maximum impact.",
            circleImg: circleImage1,
            altText: 'business profile setup'
        },
        {
            mainHead: 'VERIFICATION',
            headText: 'Green Tick WhatsApp Business',
            subText: "Achieving verified status on WhatsApp, including the green tick, is crucial for establishing trust with your customers. We provide full support for setting up your Meta and WhatsApp business accounts, ensuring all necessary verifications are smoothly and successfully completed.",
            circleImg: circleImage2,
            altText: 'Whatsapp verified'
        },
        {
            mainHead: 'LAUNCH',
            headText: 'Embedded Signup Flow',
            subText: "Begin your business communication on WhatsApp without any delay. Our Embedded Signup flow is specifically designed to be quick and intuitive. You can set up your account and start engaging with customers in just a few minutes.",
            circleImg: circleImage3,
            altText: 'Authorise Whatsapp Business'
        },

    ]

    return (
        <>
            <Grid container>
                <Grid item xl={8} lg={10.5} md={10} sm={10} xs={8} className='cw-bz-head-item'>
                    <Grid container>
                        <Grid item xs={12} className='cw-bz-main-head-item'>
                            <Grid container>
                                <Grid item xs={12} className='cw-bz-main-head-label-item'>
                                    <Typography
                                        variant='span'
                                        className='cw-bz-main-head-label'
                                    >
                                        Start Your WhatsApp Business Chatting in Minutes, Not Days
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} className='cw-bz-main-subhead-label-item'>
                                    <Typography className='cw-bz-main-subhead-label'>
                                        Think WhatsApp Business Setting Up is Complicated?
                                    </Typography>
                                    <Typography className='cw-bz-subhead-label'>
                                        We Simplify It
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className='cw-bz-circle-main-item'>
                            <Grid container className='cw-bz-circle-main-container'>
                                {array.map((ele) => {
                                    return (
                                        <>

                                            <Grid item xs={3.2}>
                                                <Grid container>
                                                    <Grid item xs={12} className='cw-bz-circle-img-item'>
                                                        <img src={ele.circleImg} className='cw-circle-img' alt={ele.altText} />
                                                    </Grid>
                                                    <Grid item xs={12} className='cw-bz-circle-head-item'>
                                                        <Typography variant='span' className='cw-bz-circle-head'>
                                                            {ele.mainHead}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className='cw-bz-typography-head-item'>
                                                        <Typography variant='span' className='cw-bz-typography-head'>
                                                            {ele.headText}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant='span' className='cw-bz-sub-typography-head'>
                                                            {ele.subText}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </>
                                    )
                                })}
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container sx={{ justifyContent: 'center' }}>
                        <Grid item>
                            <Button
                                variant='span'
                                fullWidth
                                size='large'
                                className='cw-bz-circle-btn'
                                endIcon={<img src={WhatsAppIcon} style={{ width: '20px', height: 'auto' }} />}
                                onClick={() => { window.open('https://api.whatsapp.com/send?phone=917861834273', "_self") }}

                            >
                                Connect with us on WhatsApp
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotWhatsappBusinessSection;