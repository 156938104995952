import React, { useEffect, useRef, useState } from "react";
import "./LandingPage.css";
import { Button, CardMedia, Grid, Typography } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import { TypeAnimation } from "react-type-animation";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TextAnimation from "../../Component/TextAnimation/TextAnimation";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import GSAPScroller from "../../Component/GSAPScroller/GSAPScroller";
import First2PageAnimation from './First2PageAnimation/First2PageAnimation.jsx'
import Scroll from "../../Component/Scroll/Scroll";
import Banner from "../../Component/Banner/Banner";
import TryOurProduct from "../../Component/TryOurProduct/TryOurProduct.jsx"
import VerticalSection from "../../Component/VerticalSection/VerticalSection";
import SecondScroll from "../../Component/SecondScroll/SecondScroll";
import TestScroll from "../../Component/TestScroll/TestScroll";
import EnterpriseSection from "../../Component/EnterpriseSection/EnterpriseSection";
import ChromeExtension from "../../Component/ChromeExtension/ChromeExtension";
import NewScrollElement from "../../Component/NewScrollElement/NewScrollElement";
import UseCaseMobile from "../../Component/ComponentForMobiles/UseCaseMobile/UseCaseMobile";
import LandingFooter from "../../Component/Footer/LandingFooter";
import OverlapFooter from "../../Component/Footer/OverlapFooter";
import GitexPopup from "../../Component/GitexPopup/GitexPopup";

const LandingPage = () => {
  const sentences = [
    "can write some lyrics for your heartbreak anthem titled “Lovesick”",
    "can compose a melody for your upbeat summer single named “Sunshine Groove”",
    "can craft a chorus for your introspective ballad named “Whispering Echoes”",
    "can pen down verses for your energetic pop-rock track titled “Electric Beat”",
    "can create a bridge for your soulful R&B song named “Midnight Serenade”",
    "can conjure a hook for your nostalgic country tune named “Winding Roads”",
  ];

  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  }

  const [scrollEffect, setScrollEffect] = useState(false);
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const changeStructure = () => {
      if (window.scrollY > 90) {
        setScrollEffect(true);
      } else {
        setScrollEffect(false);
      }
    };

    changeStructure();
    window.addEventListener("scroll", changeStructure);

    setTimeout(() => {
      setOpenModal(true);
    });

    return () => {
      window.removeEventListener("scroll", changeStructure);
    };
  }, []);


  // scroll smother
  // useEffect(() => {
  //   ScrollSmoother.create({
  //     smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
  //     effects: true, // looks for data-speed and data-lag attributes on elements
  //     smoothTouch: 0.5, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
  //   });
  // }, []);

  const [isBelow1000, setIsBelow1000] = useState(window.innerWidth < 1000);


  return (
    <>
      <Navbar scrollEffect={scrollEffect} setScrollEffect={setScrollEffect} page="landing-page" />
      <div id="smoth-wrapper">
        <div id="smooth-content">
          <First2PageAnimation />
          <TryOurProduct />
          {/* <VerticalSection /> */}
          {/* <GSAPScroller /> */}
          {/* <TestScroll/> */}
          {isBelow1000 ? <UseCaseMobile /> : <NewScrollElement />}
          <EnterpriseSection />
          <ChromeExtension />
          <Banner />
          <OverlapFooter />
          <LandingFooter />
        </div>
      </div>
      {/* <GitexPopup openModal={openModal} handleClose={handleClose} /> */}
    </>
  );
};

export default LandingPage;
