import React, { useEffect } from 'react'
import './WelcomeForm.css'
import { Grid, Typography } from '@mui/material';
import { Textarea } from '@mui/joy';

const Step2 = () => {

    useEffect(() => {
        const inputField = document.getElementsByClassName('mainTextArea')[0]
        inputField.addEventListener('input', (e) => {
            console.log(e.target.type, e.target)
        })
        setTimeout(() => {
            inputField.value += " ";
            var event = new Event("input", {
                bubbles: true,
                cancelable: true
            });

            inputField.dispatchEvent(event);
        }, 500)
    }, [])

    return (
        <>
            <Grid container className='wp-step1-text-container'>
                <Grid item xs={12}>
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid item xl={11} lg={9} md={9} xs={12} className='wp-gb-sentences-item'>
                            <Typography
                                variant='span'
                                className='wp-highlighted-text'
                            >
                                Tap the underlined <Typography variant='span' className='wp-normal-text' >text to make flawless&nbsp;
                                    <Typography variant='span' className='wp-spelling-underline-text'>spalling</Typography>
                                    &nbsp;and grammar choices as you write.</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xl={11} lg={9} md={9} xs={12} className='wp-steps-textarea-gb-item'>
                            <Grid container>
                                {/* <Textarea
                                name="Outlined"
                                variant="outlined"
                                defaultValue="Lorem ipsum dolor sit amet consectetur. Urna quam ultrices placerat diam eget."
                                minRows={"10"}
                                className="wp-ext-textarea"
                                autoFocus={true}
                                style={{ '--Textarea-focusedHighlight': 'transparent' }}
                            /> */}
                                <textarea
                                    style={{ width: "100%" }}
                                    defaultValue={`Hey John, \nWe just got the signed contact for the new season of our podcast.`}
                                    className="wp-ext-textarea mainTextArea"
                                    autoFocus={true}
                                    rows={11}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}

export default Step2