import React, { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import "./GSAPScroller.css";
import Lottie from "react-lottie";
import lottie from "lottie-web";
import lotte from "../Scroll/first.json";
import secondLottie from "../Scroll/secondLottie.json";
import thirdLottie from "../Scroll/thirdLottie.json";
import fourthLottie from "../Scroll/forthLottie.json";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";

function GSAPScroller() {
  const containerRef = useRef(null);
  const firstPageRef = useRef(null);
  const secondPageRef = useRef(null);
  const thirdPageRef = useRef(null);
  const fourthPageRef = useRef(null);
  const [focusedDiv, setFocusedDiv] = useState(null);
  const [playedAnimations, setPlayedAnimations] = useState([]);

  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

  let scrollTimeout;

  function handleScroll() {
    // Clear any previous timeout
    clearTimeout(scrollTimeout);

    // Set a new timeout of 500ms
    scrollTimeout = setTimeout(function () {
      // Your scroll event handling code here
      console.log("Scroll event handled after 500ms");
    }, 500);
  }

  const [shouldObserve, setShouldObserve] = useState(true);


  useEffect(() => {
    const container = containerRef.current;

    // document.addEventListener("scroll", (e) => {
    //   console.log("insile function", e.target);
    // });
    let observer = new IntersectionObserver(
      function (entries) {
        try {
          var ele = entries.filter((entry) => entry.isIntersecting);
          ele = ele[0].target;
          console.log("Visible element is now: " + ele.id);

          if (shouldObserve) {
            gsap.to(window, {
              duration: 0.3,
              scrollTo: { y: "#" + ele.id },
              // ease :'expo.inOut',
            });
          }

          // HANDALING INDICATOR
          const allIndicators = document.getElementsByClassName("indicator");
          for (const eleIndicator of allIndicators) {
            eleIndicator.classList.remove("active-indicator");
          }

          document
            .getElementById(`${ele.id}-indicator`)
            .classList.add("active-indicator");
        } catch (err) {
          console.log("++++++++++", err);
        }
      },
      {
        rootMargin: "-10px 0px", // Add buffering space of 100px at top and bottom
        threshold: 0.1, // Trigger callback when 1% of the element is visible
      }
    );
    document.querySelectorAll(".page").forEach((ele) => observer.observe(ele));

    // window.addEventListener("scroll", (e) => {
    // observer.disconnect();
    // });
    return () => {
      // container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const TempTextAnimation = () => {
  //   let enterTl = gsap.timeline({ paused: true });
  //   let Element = document.getElementsByClassName(
  //     "GSAPScroller-text-discription"
  //   )[0];

  //   enterTl.fromTo(
  //     Element,
  //     { opacity: 0, y: 50 },
  //     {
  //       opacity: 1,
  //       y: 0,
  //       duration: 2,
  //       stagger: 0.2,
  //     },
  //     0.5
  //   );
  // };

  const animationOptions = {
    loop: false,
    autoplay: false,
  };

  //! HANDLING LOADING OF LOTTIE FILES
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           if (
  //             entry.target === firstPageRef.current &&
  //             !playedAnimations.includes("lotte1")
  //           ) {
  //             TempTextAnimation();
  //             const animation = lottie.loadAnimation({
  //               container: entry.target.querySelector(
  //                 "#lotte-animation-placeholder-1"
  //               ),
  //               animationData: lotte,
  //               loop: false,
  //             });
  //             animation.play();
  //             setPlayedAnimations((prevState) => [...prevState, "lotte1"]);
  //           } else if (
  //             entry.target === secondPageRef.current &&
  //             !playedAnimations.includes("lotte2")
  //           ) {
  //             const animation = lottie.loadAnimation({
  //               container: entry.target.querySelector(
  //                 "#lotte-animation-placeholder-2"
  //               ),
  //               animationData: secondLottie,
  //               loop: false,
  //             });
  //             animation.play();
  //             setPlayedAnimations((prevState) => [...prevState, "lotte2"]);
  //           } else if (
  //             entry.target === thirdPageRef.current &&
  //             !playedAnimations.includes("lotte3")
  //           ) {
  //             const animation = lottie.loadAnimation({
  //               container: entry.target.querySelector(
  //                 "#lotte-animation-placeholder-3"
  //               ),
  //               animationData: thirdLottie,
  //               loop: false,
  //             });
  //             animation.play();
  //             setPlayedAnimations((prevState) => [...prevState, "lotte3"]);
  //           } else if (
  //             entry.target === fourthPageRef.current &&
  //             !playedAnimations.includes("lotte4")
  //           ) {
  //             const animation = lottie.loadAnimation({
  //               container: entry.target.querySelector(
  //                 "#lotte-animation-placeholder-4"
  //               ),
  //               animationData: fourthLottie,
  //               loop: false,
  //             });
  //             animation.play();
  //             setPlayedAnimations((prevState) => [...prevState, "lotte4"]);
  //           }
  //         }
  //       });
  //     },
  //     { threshold: 0.5 }
  //   );

  //   if (firstPageRef.current) {
  //     observer.observe(firstPageRef.current);
  //   }
  //   if (secondPageRef.current) {
  //     observer.observe(secondPageRef.current);
  //   }
  //   if (thirdPageRef.current) {
  //     observer.observe(thirdPageRef.current);
  //   }
  //   if (fourthPageRef.current) {
  //     observer.observe(fourthPageRef.current);
  //   }

  //   return () => observer.disconnect();
  // }, [playedAnimations]);

  useEffect(() => {
    // ANIMATION CONFIGURATION
    const headerAnimationConfig = {
      y: 300,
      opacity: 1,
      ease: "power2.out",
      duration: 0.8,
    };
    const discriptionAnimationConfig = {
      y: 300,
      opacity: 1,
      ease: "power2.out",
      duration: 1,
    };

    // CREATE ANIMATIONS FOR EACH SECTION
    gsap.utils.toArray(".page").forEach((section) => {
      const heading = section.querySelector(".heading-is-black");
      const description = section.querySelector(
        ".GSAPScroller-text-discription"
      );

      gsap.from(heading, {
        ...headerAnimationConfig,
        scrollTrigger: {
          trigger: section,
          start: "top 10%", // Adjust the start point based on your needs
          toggleActions: "play reset play reset",
          // markers: true,
        },
      });

      gsap.from(description, {
        ...discriptionAnimationConfig,
        scrollTrigger: {
          trigger: section,
          start: "top 10%", // Adjust the start point based on your needs
          toggleActions: "play reset play reset",
          // markers: true,
        },
      });
    });
  }, []);

  // TO SHOW AND HIDE PAGE INDICATOR
  useEffect(() => {
    const sectionsWithFixedIndicator = document.querySelectorAll(
      ".GSAPScroller-content-wrapper.with-fixed-element"
    );
    window.addEventListener("scroll", () => {
      sectionsWithFixedIndicator.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const indicator = section.querySelector(".page-indicator");
        console.log(
          rect.bottom <= window.innerHeight - window.innerHeight * (10 / 100),
          "++++"
        );
        if (
          rect.top <= window.innerHeight - window.innerHeight &&
          rect.bottom >= window.innerHeight - window.innerHeight * (10 / 100)
        ) {
          indicator.style.display = "block";
        } else {
          indicator.style.display = "none";
        }
      });
    });
  }, []);

  return (
    <div>
      <div class="GSAPScroller-content-wrapper with-fixed-element">
        <div ref={containerRef} id="container">
          <div ref={firstPageRef} className="first-page page" id="first-page">
            <div className="table-view">
              <div className="left-section">
                <p className="heading-is-black">
                  Watch your tone{" "}
                  <span className="heading-is-yellow">Everywhere</span>
                </p>
                <div className="GSAPScroller-text-discription">
                  Jasper is ready to collaborate whenever and wherever you
                  create. Whether you’re writing social captions, emails, blogs,
                  sonnets, love letters, or needing some slightly friendlier
                  recommendations for that email to your boss (hey, we’ve all
                  been there). The Jasper Everywhere extension keeps Jasper by
                  your side anywhere you create online, from your CMS to email
                  to social media. Your team can even build our AI engine
                  directly into your platforms with Jasper API.
                </div>
              </div>
              <div className="right-section ">
                <div
                  id="lotte-animation-placeholder-1"
                  style={{
                    width: "500px",
                    marginLeft: "auto",
                  }}
                >
                  {" "}
                  <Lottie
                    options={{ ...animationOptions, animationData: lotte }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            ref={secondPageRef}
            className="second-page page"
            id="second-page"
          >
            <div className="table-view">
              <div className="left-section ">
                <p className="heading-is-black">
                  Watch your tone{" "}
                  <span className="heading-is-yellow">Everywhere</span>
                </p>
                <div className="GSAPScroller-text-discription">
                  Jasper is ready to collaborate whenever and wherever you
                  create. Whether you’re writing social captions, emails, blogs,
                  sonnets, love letters, or needing some slightly friendlier
                  recommendations for that email to your boss (hey, we’ve all
                  been there). The Jasper Everywhere extension keeps Jasper by
                  your side anywhere you create online, from your CMS to email
                  to social media. Your team can even build our AI engine
                  directly into your platforms with Jasper API.
                </div>
              </div>
              <div className=" right-section ">
                <div
                  id="lotte-animation-placeholder-2"
                  style={{
                    width: "500px",
                    marginLeft: "auto",
                  }}
                >
                  <Lottie
                    options={{
                      ...animationOptions,
                      animationData: secondLottie,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div ref={thirdPageRef} className="third-page page" id="third-page">
            <div className="table-view">
              <div className="left-section ">
                <p className="heading-is-black">
                  Watch your tone{" "}
                  <span className="heading-is-yellow">Everywhere</span>
                </p>
                <div className="GSAPScroller-text-discription">
                  Jasper is ready tos collaborate whenever and wherever you
                  create. Whether you’re writing social captions, emails, blogs,
                  sonnets, love letters, or needing some slightly friendlier
                  recommendations for that email to your boss (hey, we’ve all
                  been there). The Jasper Everywhere extension keeps Jasper by
                  your side anywhere you create online, from your CMS to email
                  to social media. Your team can even build our AI engine
                  directly into your platforms with Jasper API.
                </div>
              </div>
              <div className="right-section ">
                <div
                  id="lotte-animation-placeholder-3"
                  style={{
                    width: "500px",
                    marginLeft: "auto",
                  }}
                >
                  {" "}
                  <Lottie
                    options={{
                      ...animationOptions,
                      animationData: thirdLottie,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            ref={fourthPageRef}
            className="fourth-page page"
            id="fourth-page"
          >
            <div className="table-view">
              <div className="left-section ">
                <p className="heading-is-black">
                  Watch your tone{" "}
                  <span className="heading-is-yellow">Everywhere</span>
                </p>
                <div className="GSAPScroller-text-discription">
                  Jasper is ready to collaborate whenever and wherever you
                  create. Whether you’re writing social captions, emails, blogs,
                  sonnets, love letters, or needing some slightly friendlier
                  recommendations for that email to your boss (hey, we’ve all
                  been there). The Jasper Everywhere extension keeps Jasper by
                  your side anywhere you create online, from your CMS to email
                  to social media. Your team can even build our AI engine
                  directly into your platforms with Jasper API.
                </div>
              </div>
              <div className="right-section ">
                <div
                  id="lotte-animation-placeholder-4"
                  style={{
                    width: "500px",
                    marginLeft: "auto",
                  }}
                >
                  {" "}
                  <Lottie
                    options={{
                      ...animationOptions,
                      animationData: fourthLottie,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-indicator">
          <div id="first-page-indicator" className="indicator"></div>
          <div id="second-page-indicator" className="indicator"></div>
          <div id="third-page-indicator" className="indicator"></div>
          <div id="fourth-page-indicator" className="indicator"></div>
        </div>
      </div>
    </div>
  );
}

export default GSAPScroller;
