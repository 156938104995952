import React, { useEffect } from 'react';
import { Modal, Backdrop, Box, Fade, Typography, Grid, Divider } from '@mui/material';
import GitexImage from "../../assests/Gitex.png";
import CloseIcon from '@mui/icons-material/Close';
import './GitexPopup.css'
import ConfettiExplosion from 'react-confetti-explosion';
  
const GitexPopup = ({openModal, handleClose}) => {
  return (
    <Modal
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
        backdrop: {
            timeout: 1000,
        },
        }}
        >
        <div style={{outline: 'none'}}>
        <Fade in={openModal}>
            <Box className="gitex-popup">
                <Box display={"flex"} justifyContent={"end"}>
                    <CloseIcon fontSize='large' sx={{color: "white", cursor: "pointer"}} onClick={handleClose} />
                </Box>
                <Typography variant="h6" className="gx-popup-description">
                "We are excited to announce our presence at GITEX Global 2023. 
                We cordially invite you to visit our booth and engage in meaningful conversations. 
                Don't miss the opportunity to connect with us!"
                </Typography>
                <Grid container className='gx-popup-desk-info'>
                    <Grid item>
                        <span>Hall No:</span>
                        <h6>Hall 9</h6>
                    </Grid>
                    <Grid item>
                        <Divider orientation="vertical" sx={{background: "white", marginInline: '30px'}} />
                    </Grid>
                    <Grid item>
                        <span>Stand No:</span>
                        <h6>H9-25</h6>
                    </Grid>
                </Grid>
                <Box display={"flex"} justifyContent={"center"} height={"0px"}>
                    <ConfettiExplosion force={1} duration={3000} particleCount={250} width={window.innerWidth <= 600 ? window.innerWidth + 200 : window.innerWidth} zIndex={"2000"} />
                </Box>
                <Box className="gitex-image-container">
                    <img src={GitexImage} alt="Gitex" className='popup-gitex-image'/>
                </Box>
            </Box>
        </Fade>
        </div>
    </Modal>
  )
}

export default GitexPopup