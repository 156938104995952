import React from 'react'
import { Grid, Typography } from "@mui/material";
import "./ChatbotWorkFlow.css";
import HeaderWithUnderLine from '../HeaderWithUnderLine/HeaderWithUnderLine';
import embedIcon from "../../assests/embedIcon.png";
import supportIcon from "../../assests/supportIcon.png";
import technologyIcon from "../../assests/technologyIcon.png";
import chatbotIcon from "../../assests/chatbotIcon.png";



// const ChatBotFeaturesCard = (props) => {
//   const { logoSrc, index, title, description, colors, degree, borderColor } = props;

//   const borderStyle = {
//     border: colors.length ? `double 4px ${borderColor}` : `4px solid ${borderColor}`,
//     backgroundImage: colors.length ? `linear-gradient(white, white), linear-gradient(${degree}deg, ${colors.join(', ')})` : 'none',
//     backgroundClip: 'padding-box, border-box',
//     backgroundOrigin: 'border-box',
//     borderRadius: "10px",
//   };

//   return (
//     <Grid container style={borderStyle} className="CBFC-mainOuterContainer">

//       <Grid item xs={12}>
//         <Grid container sx={{ gap: "25px" }}>

//           <Grid item xs={12} >
//             <Grid container className='CBFC-logoIndexContainer'>
//               <Grid item>
//                 <img src={logoSrc} alt="logo" className="CBW-cardLogo" />
//               </Grid>
//               <Grid item>
//                 <span className='CBFC-IndexText'>
//                   {index}
//                 </span>
//               </Grid>
//             </Grid>
//           </Grid>

//           {/* <Grid item xs={12}>
//             <Grid container direction={'column'} justifyContent={'start'}> */}
//           <Grid item xs={12} sx={{ minHeight: "95px", height: "95px" }}>
//             <h3 className='CBFC-titleText'>
//               {title}
//             </h3>
//           </Grid>
//           <Grid item xs={12}>
//             <p className='CBFC-DescriptionText'>
//               {description}
//             </p>
//           </Grid>
//         </Grid>
//       </Grid>

//     </Grid>
//     //   </Grid>
//     // </Grid>
//   )
// }

const ChatBotFeaturesCard = (props) => {
  const { logoSrc, index, title, description, colors, degree, borderColor } = props;

  const borderStyle = {
    border: colors.length ? `double 4px ${borderColor}` : `4px solid ${borderColor}`,
    backgroundImage: colors.length ? `linear-gradient(white, white), linear-gradient(${degree}deg, ${colors.join(', ')})` : 'none',
    backgroundClip: 'padding-box, border-box',
    backgroundOrigin: 'border-box',
    borderRadius: "10px",
  };

  return (
    <Grid container style={borderStyle} className="CBFC-mainOuterContainer">
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <Grid item>
                <img src={logoSrc} className='CBFC-card-logo' />
              </Grid>
              <Grid item>
                <Typography variant='span' className='cbf-index-item'>
                  {index}
                </Typography>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Typography variant='span' className='CBFC-titleText'>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Typography variant='span' className='CBFC-DescriptionText'>
              {description}
            </Typography>
          </Grid>
        </Grid>

      </Grid>

    </Grid>
  )
}

const ChatbotWorkFlow = () => {

  const ChatBotWorkFlowData = [
    {
      logoSrc: technologyIcon,
      index: 1,
      title: 'Upload Your Knowledge Base',
      description: 'Start by feeding your chatbot the knowledge it needs to serve your customers. Simple, swift, and secure.',
      degree: 0,
      colors: [],
      borderColor: "#E8E021"
    },
    {
      logoSrc: chatbotIcon,
      index: 2,
      title: 'Customize Your Bot’s Behavior and Appearance',
      description: 'Tailor your chatbot’s responses and look to align with your brand’s identity. Personalization made easy',
      degree: 89.77,
      colors: ['#E8E021 71.69%', '#CCD138 99.82%'],
      borderColor: "transparent"
    },
    {
      logoSrc: embedIcon,
      index: 3,
      title: 'Deploy Your Chatbot and Embed Seamlessly on Your Website.',
      description: 'Go live in moments. Embed your AI chatbot on your site for immediate interaction.',
      degree: 89.89,
      colors: ["#CCD138 0.11%", "#4388AC 99.91%"],
      borderColor: "transparent"
    },
    {
      logoSrc: supportIcon,
      index: 4,
      title: 'Integrate with Your Tools',
      description: 'Seamlessly connect with WhatsApp and more to streamline operations and enhance customer touchpoints.',
      degree: 89.89,
      colors: ["#4388AC 0.11%", "#0A6ADD 99.91%"],
      borderColor: "transparent"
    },
  ];

  const titleText = {
    normalText: "How it works?",
    coloredText: "",
    normalText2: "",
    subTitleText: "Tech-Free Setup: Your Custom AI Chatbot in Just 4 Easy Steps"
  }

  return (
    <Grid container className="CBW-mainOuterContainer">
      <HeaderWithUnderLine text={titleText} />
      <Grid item xs={11} className='CBW-cardMainOuterContainer'>
        <Grid container className='CBW-card-container'>
          {ChatBotWorkFlowData.map((data, index) => (

            <Grid item className='CBW-cardContainer-item'>
              {/* xl={2.75} lg={2.75} md={2.75} sm={5} xs={12} */}
              <ChatBotFeaturesCard
                key={index}
                logoSrc={data.logoSrc}
                index={data.index}
                title={data.title}
                description={data.description}
                degree={data.degree}
                colors={data.colors}
                borderColor={data.borderColor}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid >
  )
}

export default ChatbotWorkFlow