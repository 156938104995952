import React, { useEffect, useRef } from 'react'
import './WelcomeForm.css'
import { Grid, Typography } from '@mui/material';
import chromExtArrow from '../../assests/chromExtArrow.svg'
import TextareaAutosize from '@mui/material/TextareaAutosize';

const Step1 = () => {

    useEffect(() => {
        const inputField = document.getElementsByClassName('mainTextArea')[0]
        inputField.addEventListener('input', (e) => {
            console.log(e.target.type, e.target)
        })
        setTimeout(() => {
            inputField.value += " ";
            var event = new Event("input", {
                bubbles: true,
                cancelable: true
            });

            inputField.dispatchEvent(event);
        }, 500)
    }, [])

    return (
        <>
            <Grid container className='wp-step1-text-container'>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                    <Grid container sx={{ justifyContent: "center" }}>
                        <Grid item xl={11} lg={11} md={9} xs={11} className='wp-gb-sentences-item'>
                            <Typography
                                variant='span'
                                className='wp-highlighted-text'
                                sx={{ margin: "0 auto" }}
                            >
                                Start your journey <Typography variant='span' className='wp-normal-text' >by&nbsp;
                                    <Typography variant='span' className='wp-normal-text wp-bg-highlight-text'>highlighting the sentence</Typography> below</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={1} className='wp-step1-arrow-img-item'>
                            <img src={chromExtArrow} alt="" />
                        </Grid>
                        <Grid item xl={11} lg={9} md={9} xs={9} className='wp-steps-textarea-gb-item'>
                            <Grid container>
                                <textarea
                                    style={{ width: "100%" }}
                                    defaultValue={`Hey John, \nWe just got the signed contact for the new season of our podcast.`}
                                    className="wp-ext-textarea mainTextArea"
                                    autoFocus={true}
                                    rows={11}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}

export default Step1