import React, { useEffect, useState } from "react";
import "./EnterpriseSection.css";
import $ from "jquery";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import rightMarkIcon from '../../assests/rightTickMark.png'

import marketingAndBranding from '../../assests/marketingAndBranding.jpg'
import salescustomer from '../../assests/salescustomer support.jpg'
import producttech from '../../assests/producttech.jpg'
import webdevelopmentdesign from '../../assests/webdevelopmentdesign.jpg'
import ediorpublishing from '../../assests/ediorpublishing.jpg'
import innovationStrategy from '../../assests/InnovationStrategy.jpg'


const EnterpriseSection = () => {
  const mainData = [
    {
      name: "Marketing & Branding",
      value: "MarketingBranding",
      title: "Crafting a narrative that speaks your brand's truth.",
      description: [
        {
          title: "Persona-Driven Content",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Supercharge promotions with AI-boosted content.",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Brand Consistency",
          subtitle: "Ensure uniformity and relevance in every word.",
        },
      ],
      image: marketingAndBranding,
      header: "Marketing & Branding",
    },
    {
      name: "Sales & Customer Support",
      value: "SalesCustomerSupport",
      title: "Transforming customer touchpoints into memorable experiences.",
      description: [
        {
          title: "Persona-Driven Content",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Supercharge promotions with AI-boosted content.",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Brand Consistency",
          subtitle: "Ensure uniformity and relevance in every word.",
        },
      ],
      image: salescustomer,
    },
    {
      name: "Product & Tech",
      value: "ProductTech",
      title: "Demystifying tech jargon for an accessible user journey.",
      description: [
        {
          title: "Persona-Driven Content",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Supercharge promotions with AI-boosted content.",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Brand Consistency",
          subtitle: "Ensure uniformity and relevance in every word.",
        },
      ],
      image: producttech,
    },
    {
      name: "Web Development & Design",
      value: "WebDevelopmentDesign",
      title: "Where design meets compelling storytelling.",
      description: [
        {
          title: "Persona-Driven Content",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Supercharge promotions with AI-boosted content.",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Brand Consistency",
          subtitle: "Ensure uniformity and relevance in every word.",
        },
      ],
      image: webdevelopmentdesign,
    },
    {
      name: "Editorial & Publishing",
      value: "EditorialPublishing",
      title: "Shaping stories that captivate and inspire.",
      description: [
        {
          title: "Persona-Driven Content",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Supercharge promotions with AI-boosted content.",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Brand Consistency",
          subtitle: "Ensure uniformity and relevance in every word.",
        },
      ],
      image: ediorpublishing,
    },
    {
      name: "Innovation & Strategy",
      value: "InnovationStrategy",
      title: "Fueling forward-thinking with the power of AI.",
      description: [
        {
          title: "Persona-Driven Content",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Supercharge promotions with AI-boosted content.",
          subtitle:
            "Writewiz AI tailors messages to echo your brand voice, ensuring authenticity and connection.",
        },
        {
          title: "Brand Consistency",
          subtitle: "Ensure uniformity and relevance in every word.",
        },
      ],
      image: innovationStrategy,
    },
  ];

  const [selectedPart, setSelectedPart] = useState("MarketingBranding");
  const [selectedPardData, setSelectedPartData] = useState(mainData[0]);

  useEffect(() => {
    const currentObject = mainData.filter((el) => el.value === selectedPart)[0];
    setSelectedPartData(currentObject);

    const allButtons = $(".EnterpriseSection-button");

    for (const button of allButtons) {
      button.classList.remove("EnterpriseSection-button-selected");
    }

    $(".EnterpriseSection-left-side-section-description")
      .addClass("enterprise-selected-data");

    $("#EnterpriseSection-right-image")
      .addClass("enterprise-selected-data");

    $(".EnterpriseSection-left-side-section-title")
      .addClass("enterprise-selected-data");

    setTimeout(() => {
      $(".EnterpriseSection-left-side-section-description").removeClass(
        "enterprise-selected-data"
      );

      $(".EnterpriseSection-left-side-section-title").removeClass(
        "enterprise-selected-data"
      );

      $("#EnterpriseSection-right-image").removeClass(
        "enterprise-selected-data"
      );
    }, 1000);

    $(`#${selectedPart}-button`).addClass("EnterpriseSection-button-selected");
    // 

    console.log(`#${selectedPart}-button`, "+++++++++++");

    // ;
  }, [selectedPart]);

  console.log("selectedPart", selectedPart);

  return (
    <>
      <div className="EnterpriseSection-main-div">
        <div className="EnterpriseSection-inner-div">
          <div className="EnterpriseSection-text-title">
            <div className="EnterpriseSection-text-title-upper-text">
              Enterprise Ready
            </div>
            <div className="EnterpriseSection-text-title-below-text">
              Empower your entire organization Writewiz Enterprise AI
            </div>
          </div>
          {window.innerWidth < 800 && (
            <div className="EnterpriseSection-left-side-section-buttons">
              <div className="EnterpriseSection-top-side-buttons">
                <div
                  id="MarketingBranding-button"
                  className="EnterpriseSection-button EnterpriseSection-button-selected"
                  onClick={() => setSelectedPart("MarketingBranding")}
                >
                  <div className="EnterpriseSection-button-numbers">01</div>
                  Marketing & Branding
                </div>
                <div
                  id="SalesCustomerSupport-button"
                  className="EnterpriseSection-button "
                  onClick={() => setSelectedPart("SalesCustomerSupport")}
                >
                  <div className="EnterpriseSection-button-numbers">02</div>
                  Sales & Customer Support
                </div>
              </div>
              <div className="EnterpriseSection-bottom-side-buttons">
                <div
                  id="ProductTech-button"
                  className="EnterpriseSection-button "
                  onClick={() => setSelectedPart("ProductTech")}
                >
                  <div className="EnterpriseSection-button-numbers">03</div>
                  Product & Tech
                </div>
                <div
                  id="WebDevelopmentDesign-button"
                  className="EnterpriseSection-button "
                  onClick={() => setSelectedPart("WebDevelopmentDesign")}
                >
                  <div className="EnterpriseSection-button-numbers">04</div>
                  Web Development & Design
                </div>
                <div
                  id="EditorialPublishing-button"
                  className="EnterpriseSection-button "
                  onClick={() => setSelectedPart("EditorialPublishing")}
                >
                  <div className="EnterpriseSection-button-numbers">05</div>
                  Editorial & Publishing
                </div>
                <div
                  id="InnovationStrategy-button"
                  className="EnterpriseSection-button "
                  onClick={() => setSelectedPart("InnovationStrategy")}
                >
                  <div className="EnterpriseSection-button-numbers">06</div>
                  Innovation & Strategy
                </div>
              </div>
            </div>
          )}
          <div className="EnterpriseSection-below-main-section">
            <div className="EnterpriseSection-left-side-section">
              {/* <div className="EnterpriseSection-left-side-section-one">
                Product
              </div> */}
              <div className="EnterpriseSection-left-side-section-name">
                {selectedPardData?.name}
              </div>
              <div className="EnterpriseSection-left-side-section-title">
                {selectedPardData?.title}
              </div>
              {selectedPardData?.description?.map((el) => {
                return (
                  <>
                    <div className="EnterpriseSection-left-side-section-description">
                      <div className="EnterpriseSection-left-side-section-description-icon">
                        <img src={rightMarkIcon} alt="" style={{ marginTop: '5px' }} />
                      </div>
                      <div>
                        <div className="EnterpriseSection-left-side-section-description-title">
                          {el.title}
                        </div>
                        <div className="EnterpriseSection-left-side-section-description-subtitle">
                          {el.subtitle}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              {window.innerWidth >= 800 && (
                <div className="EnterpriseSection-left-side-section-buttons">
                  <div className="EnterpriseSection-top-side-buttons">
                    <div
                      id="MarketingBranding-button"
                      className="EnterpriseSection-button EnterpriseSection-button-selected"
                      // onMouseEnter={() => {
                      //   setTimeout(() => {
                      //     setSelectedPart("MarketingBranding");
                      //   }, 100);
                      // }}
                      onClick={() => setSelectedPart("MarketingBranding")}
                    >
                      <div className="EnterpriseSection-button-numbers">01</div>
                      Marketing & Branding
                    </div>
                    <div
                      id="SalesCustomerSupport-button"
                      className="EnterpriseSection-button "
                      // onMouseEnter={() => {
                      //   setTimeout(() => {
                      //     setSelectedPart("SalesCustomerSupport");
                      //   }, 100);
                      // }}
                      onClick={() => setSelectedPart("SalesCustomerSupport")}
                    >
                      <div className="EnterpriseSection-button-numbers">02</div>
                      Sales & Customer Support
                    </div>
                  </div>
                  <div className="EnterpriseSection-bottom-side-buttons">
                    <div
                      id="ProductTech-button"
                      className="EnterpriseSection-button "
                      // onMouseEnter={() => {
                      //   setTimeout(() => {
                      //     setSelectedPart("ProductTech");
                      //   }, 100);
                      // }}
                      onClick={() => setSelectedPart("ProductTech")}
                    >
                      <div className="EnterpriseSection-button-numbers">03</div>
                      Product & Tech
                    </div>
                    <div
                      id="WebDevelopmentDesign-button"
                      className="EnterpriseSection-button "
                      // onMouseEnter={() => {
                      //   setTimeout(() => {
                      //     setSelectedPart("WebDevelopmentDesign");
                      //   }, 100);
                      // }}
                      onClick={() => setSelectedPart("WebDevelopmentDesign")}
                    >
                      <div className="EnterpriseSection-button-numbers">04</div>
                      Web Development & Design
                    </div>
                  </div>
                  <div className="EnterpriseSection-bottom-side-buttons">
                    <div
                      id="EditorialPublishing-button"
                      className="EnterpriseSection-button "
                      // onMouseEnter={() => {
                      //   setTimeout(() => {
                      //     setSelectedPart("EditorialPublishing");
                      //   }, 100);
                      // }}
                      onClick={() =>
                        setSelectedPart("EditorialPublishing")
                      }
                    >
                      <div className="EnterpriseSection-button-numbers">05</div>
                      Editorial & Publishing
                    </div>
                    <div
                      id="InnovationStrategy-button"
                      className="EnterpriseSection-button "
                      // onMouseEnter={() => {
                      //   setTimeout(() => {
                      //     setSelectedPart("InnovationStrategy");
                      //   }, 100);
                      // }}
                      onClick={() => setSelectedPart("InnovationStrategy")}
                    >
                      <div className="EnterpriseSection-button-numbers">06</div>
                      Innovation & Strategy
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="EnterpriseSection-right-side-section">
              <div className="EnterpriseSection-right-side-section-image-div">
                {selectedPart == "MarketingBranding" &&
                  <img
                    id="EnterpriseSection-right-image"
                    src={marketingAndBranding}
                    height={"100%"}
                    width={"100%"}
                    alt=""
                  />
                }
                {(selectedPart == "SalesCustomerSupport") &&
                  <img
                    id="EnterpriseSection-right-image"
                    src={salescustomer}
                    height={"100%"}
                    width={"100%"}
                    alt=""
                  />
                }
                {(selectedPart == "ProductTech") &&
                  <img
                    id="EnterpriseSection-right-image"
                    src={producttech}
                    height={"100%"}
                    width={"100%"}
                    alt=""
                  />
                }
                {(selectedPart == "WebDevelopmentDesign") &&
                  <img
                    id="EnterpriseSection-right-image"
                    src={webdevelopmentdesign}
                    height={"100%"}
                    width={"100%"}
                    alt=""
                  />
                }

                {(selectedPart == "EditorialPublishing") &&
                  <img
                    id="EnterpriseSection-right-image"
                    src={ediorpublishing}
                    height={"100%"}
                    width={"100%"}
                    alt=""
                  />
                }
                {(selectedPart == "InnovationStrategy") &&
                  <img
                    id="EnterpriseSection-right-image"
                    src={innovationStrategy}
                    height={"100%"}
                    width={"100%"}
                    alt=""
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterpriseSection;
