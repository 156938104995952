import React, { useEffect, useState } from 'react'
import "./ContactSupport.css"
import { Alert, Avatar, AvatarGroup, Button, Grid, Snackbar, TextField, Typography } from '@mui/material'
import { Textarea } from '@mui/joy'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseIcon from '@mui/icons-material/Close';
import ReCAPTCHA from "react-google-recaptcha";
import Navbar from '../../Pages/Navbar/Navbar';

const ContactSupport = (props) => {

    const API_ENDPOINT = process.env.REACT_APP_API_CALL_URL + "/global/contact-us";
    const [contactUsDetails, setContactUsDetails] = useState({
        email_address: "",
        username: "",
        subject: "",
        message: "",
        re_captcha: ""
    })
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [alertMessage, setAlertMessage] = useState({
        status: false,
        message: "",
        error: "",
    });

    const [scrollEffect, setScrollEffect] = useState(false);

    useEffect(() => {

        const changeStructure = () => {
            if (window.scrollY > 90) {
                setScrollEffect(true);
            } else {
                setScrollEffect(false);
            }
        };

        changeStructure();
        window.addEventListener("scroll", changeStructure);

        return () => {
            window.removeEventListener("scroll", changeStructure);
        };
    }, []);


    useEffect(() => {
        const allFieldsFilled = contactUsDetails.email_address
            && contactUsDetails.username &&
            contactUsDetails.subject &&
            contactUsDetails.message &&
            contactUsDetails.re_captcha;
        setIsButtonEnabled(allFieldsFilled && isValidEmail);
    }, [contactUsDetails, isValidEmail]);

    const handleClose = () => {
        setAlertMessage({
            status: false,
            message: "",
            error: "",
        })
    };

    const handleSubmitContactUsForm = () => {

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(contactUsDetails)
        };

        fetch(API_ENDPOINT, requestOptions)
            .then(response => {
                if (!response.ok) {
                    setAlertMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                    props.setFlagOpenHelpCenterDialog(false)
                }
                return response.json();
            })
            .then(data => {
                console.log("Success:", data);
                setAlertMessage({
                    status: true,
                    message: data.data.message,
                    error: false,
                })
                props.setFlagOpenHelpCenterDialog(false)
                // Handle success scenario
            })
            .catch(error => {
                console.error("Error:", error);
                setAlertMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
                props.setFlagOpenHelpCenterDialog(false)
                // Handle error scenario
            });
    };

    return (
        <>
            {alertMessage.status &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alertMessage.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                        {alertMessage.message}
                    </Alert>
                </Snackbar>
            }
            <Navbar scrollEffect={scrollEffect} setScrollEffect={setScrollEffect} />
            <Grid container>
                <Grid item xs={12} className='help-dialog-main-item'>
                    <Grid container>
                        <Grid item xs={12} className='hlp-dialog-head-section-item'>
                            <Grid container>
                                {/* <Grid item xs={12} sx={{ textAlign: "end", margin: "10px 0px 0px" }}>
                                    <CloseIcon
                                        sx={{ color: "#6A097D", cursor: "pointer" }}
                                        className='hlp-dialog-cross-icon'
                                        onClick={() => {
                                            props.setFlagOpenHelpCenterDialog(false)
                                            setAlertMessage({
                                                status: false,
                                                message: "",
                                                error: false,
                                            })
                                        }} />
                                </Grid> */}
                                <Grid item xs={12} className='hlp-head-text-item'>
                                    <Typography variant='span' className='hlp-head-text'>
                                        Send a message
                                    </Typography>
                                </Grid>
                                {/* <Grid item xs={12} className='hlp-avtar-item' >
                                    <AvatarGroup sx={{ justifyContent: "center" }} max={2}>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src="/static/images/avatar/1.jpg"
                                            sx={{ width: "60px", height: "60px" }}
                                        />
                                        <Avatar
                                            alt="Travis Howard"
                                            src="/static/images/avatar/2.jpg"
                                            sx={{ width: "60px", height: "60px" }}
                                        />
                                    </AvatarGroup>
                                </Grid> */}
                                <Grid item xs={8} className='hlp-subhead-items'>
                                    <Grid container>
                                        <Grid item xs={10} className='hlp-subhead-item1'>
                                            <Typography variant='span' className='hlp-subhead-text'>How can we help?</Typography>
                                        </Grid>
                                        <Grid item xs={10} className='hlp-subhead-item2'>
                                            <Typography variant='span' className='hlp-subhead-text2'>We usually respond in a few hour</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} className='hlp-dialog-form-main-item'>
                            <Grid container>
                                <Grid item xs={12} className='hlp-form-head-label-item'>
                                    <Grid container>
                                        <Grid item xs={12} className='hlp-form-head-item'>
                                            <Typography variant='span' className='hlp-form-head-label'>Name</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                id="outlined-basic"
                                                className='hlp-form-textfield'
                                                value={contactUsDetails?.username}
                                                onChange={(e) => {
                                                    setContactUsDetails({
                                                        ...contactUsDetails,
                                                        username: e.target.value
                                                    })
                                                }}
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className='hlp-form-head-label-item'>
                                    <Grid container>
                                        <Grid item xs={12} className='hlp-form-head-item'>
                                            <Typography variant='span' className='hlp-form-head-label'>Subject</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                id="outlined-basic"
                                                className='hlp-form-textfield'
                                                value={contactUsDetails?.subject}
                                                onChange={(e) => {
                                                    setContactUsDetails({
                                                        ...contactUsDetails,
                                                        subject: e.target.value
                                                    })
                                                }}

                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className='hlp-form-head-label-item'>
                                    <Grid container>
                                        <Grid item xs={12} className='hlp-form-head-item'>
                                            <Typography variant='span' className='hlp-form-head-label'>Email Address</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                type='email'
                                                size='small'
                                                variant="outlined"
                                                className='hlp-form-textfield'
                                                value={contactUsDetails?.email_address}
                                                onChange={(e) => {
                                                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                    setIsValidEmail(emailPattern.test(e.target.value));
                                                    setContactUsDetails({
                                                        ...contactUsDetails,
                                                        email_address: e.target.value
                                                    });
                                                }}
                                                InputProps={{
                                                    style: {
                                                        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "rgba(0, 0, 0, 0.23)",  // default border color
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "rgba(0, 0, 0, 0.23)",  // default border color
                                                        }
                                                    }
                                                }}

                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className='hlp-form-head-label-item'>
                                    <Grid container>
                                        <Grid item xs={12} className='hlp-form-head-item'>
                                            <Typography variant='span' className='hlp-form-head-label'>How can i help?</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Textarea
                                                minRows={4}
                                                size="md"
                                                className="hlp-form-textfield"
                                                value={contactUsDetails?.message}
                                                onChange={(e) => {
                                                    setContactUsDetails({
                                                        ...contactUsDetails,
                                                        message: e.target.value
                                                    })
                                                }}

                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ margin: "20px 0px 10px" }}>
                                    <ReCAPTCHA
                                        sitekey="6Ld0tIgnAAAAAM6flWQfozA41EW4o13PRW78EILx"
                                        onChange={(token) => {
                                            setContactUsDetails({
                                                ...contactUsDetails,
                                                re_captcha: token
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className='hlp-form-submit-btn-item'>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Button
                                                variant='contained'
                                                size='large'
                                                fullWidth
                                                className='hlp-form-submit-btn'
                                                disabled={!isButtonEnabled}
                                                onClick={() => {
                                                    handleSubmitContactUsForm()
                                                }}
                                            >Send a message</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default ContactSupport