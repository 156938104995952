import React from 'react'
import './BrowserDownload.css'
import { Button, CardMedia, Grid, Typography } from '@mui/material'
import BoltIcon from '@mui/icons-material/Bolt';
import chrome from '../../assests/ChromeLogo.png'
import appsGroup from '../../assests/Apps-group.png';
import downloadExtension from '../../assests/download.png';

const BrowserDownload = () => {
    return (
        <>
            <Grid container className='brws-download-img-item-wrapper'>
                <Grid item xs={12} className='brws-download-img-item'>
                    <Grid container className='brws-download-img-container'>
                        <Grid item xl={10} lg={10} md={11} sm={11} xs={11} className='brws-download-img-sub-item' >
                            <Grid container className='brws-gif-content-container' alignItems={"center"}>
                                <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xl={10} lg={11} md={12} sm={12} xs={12} className='brws-head-text-item'>
                                            <Typography component={'div'} className='brws-head-text' >
                                                {/* Write Faster With AI, Save Time */}
                                                <span className='brws-head-text-colored'>Your Personal AI Sidekick</span> <span className='brws-head-text-black'>Wherever you go</span>
                                            </Typography>
                                            {/* <BoltIcon sx={{ fontSize: "40px", fontWeight: 600 }} /> */}
                                        </Grid>
                                        <Grid item xl={10} lg={12} md={12} sm={12} xs={12} className='brws-head-subtitle-text-item'>
                                            <Typography component={'span'} className='brws-head-subtitle-text'>
                                                {/* Compose AI is a Chrome extension that cuts your writing time by 40% with AI-powered autocompletion & text generation. */}
                                                Personalize, compose, and perfect your content on-the-go with the Writewiz AI Chrome Extension.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item className='brws-download-btn-item'>
                                                    <Button
                                                        variant='contained'
                                                        fullWidth
                                                        size='large'
                                                        className='brws-download-btn'
                                                        startIcon={<img src={chrome} />}
                                                        endIcon={<img src={downloadExtension} style={{ width: '80%' }} />}
                                                        onClick={() => window.open("https://chrome.google.com/webstore/detail/writewiz-ai-content-creat/nikfnkpdalalkollmjgjkgmfblfmlpbd/related?hl=en&authuser=0", "_blank")}
                                                    >
                                                        Download Chrome Extension
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xl={5} lg={5} md={5} sm={10} xs={10} className='brws-head-iframe-item'>
                                    {/* <CardMedia
                                        src={gif}
                                        component={'iframe'}
                                        sx={{ height: 400, width: "100%" }}
                                    ></CardMedia> */}
                                    <img src={appsGroup} className='brws-head-iframe-item-img' />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default BrowserDownload