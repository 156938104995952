import React from 'react'
import './ChatbotWhatsappTryitSection.css'
import { Button, Grid, Typography } from '@mui/material'
import WhatsAppIcon from '../../assests/ChatbotWhatsapp/cw-wp-icon.png'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ChatbotWhatsappTryitSection = () => {
    return (
        <>
            <Grid container>
                <Grid item xl={8} lg={8.5} md={8} xs={8} sx={{ margin: '0 auto' }}>
                    <Grid container>
                        <Grid item xs={12} className='cw-tryit-head-text-item'>
                            <Typography variant='span' className='cw-tryit-head-text'>
                                Why Smart Businesses Choose&nbsp;<Typography variant='span' className='cw-tryit-highlighted-head-text'>Conversational AI?</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={11} xs={11} className='cw-tryit-head-subhead-item'>
                            <Typography variant='span' className='cw-tryit-subhead-text'>
                                Experience real-time intelligence that feels natural and human. Rule-based chatbots are limited in their capabilities and are structured like a dialog tree.
                                Conversational AI chatbots use machine learning, natural language processing,
                                and large amounts of data to imitate human interactions. Conversational AI chatbots offer intelligence and superior customer experience
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className='cw-tryit-btn-text-item'>
                            <Grid container sx={{ justifyContent: 'start', gap: '20px', alignItems: 'center' }}>
                                {/* <Grid item>
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        size='large'
                                        className='cw-tryit-free-btn'
                                    >
                                        Try Free for Web
                                    </Button>
                                </Grid> */}
                                <Grid item >
                                    <Button
                                        variant='outlined'
                                        fullWidth
                                        size='medium'
                                        className='cw-tryit-wp-free-btn'
                                        endIcon={<ArrowForwardIosIcon style={{ color: '#6A097D', verticalAlign: 'middle' }} />}
                                        onClick={() => window.open(`https://chatbot.writewiz.ai/`, '_self')}
                                    >
                                        Start Automating Your Business
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotWhatsappTryitSection