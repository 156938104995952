import React from 'react';
// import "./LandingFooter.css";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const OverlapFooter = () => {
    const navigate = useNavigate();
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container className="lp-footer-main-container">
                    <Grid
                        item
                        xl={9}
                        lg={9}
                        md={10}
                        sm={10}
                        xs={10}
                        className="lp-ft-white-overlap-item"
                    >
                        <Grid container className="lp-ft-white-overlap-container">
                            <Grid
                                item
                                xl={8}
                                lg={9}
                                md={7}
                                sm={7}
                                xs={11}
                                className="lp-ft-overlap-text-main-item"
                            >
                                <Grid container>
                                    <Grid item xs={12} className="lp-ft-overlap-text-item">
                                        <span>Talk to us today!</span>
                                    </Grid>
                                    <Grid item xs={12} className="lp-ft-overlap-text-item">
                                        <span>Increase productivity, align your brand, and accelerate your growth</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xl={3} lg={3} md={5} sm={5} xs={11}>
                                <Grid container className="lp-ft-contact-btn-container">
                                    <Grid item xs={8}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            className="footer-contact-us-btn"
                                        // onClick={() => navigate("/support")}
                                        >
                                            <a href="/contact-sales" target="_blank" style={{ textDecoration: 'none', color: "white" }}>Request Demo</a>
                                        </Button>
                                        {/* <Button
                variant="contained"
                fullWidth
                size="large"
                className="footer-contact-us-btn"
                >
                Try for free
                </Button> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OverlapFooter