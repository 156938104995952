import React from 'react'
import './BrowerExtContent.css'
import { Button, CardMedia, Grid, Typography } from '@mui/material'
import BottomImg from '../../assests/CE-Bottom.jpg'
import chrome from '../../assests/ChromeLogo.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BrowerExtContent = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={12} className='brws-extc-main-item'>
                    <Grid container>
                        <Grid item xl={10} lg={10} md={10} sm={10} xs={11} className='brws-extc-head-item'>
                            <Typography component={'span'} className='brws-extc-head-normal-text'>
                                Adopt a <Typography component={'span'} className='brws-extc-head-color-text'>Writing Persona</Typography> That Fits You
                            </Typography>
                        </Grid>
                        <Grid item className='brws-extc-iframe-item'>
                            <CardMedia
                                component="img"
                                src={BottomImg}
                                className='brws-extc-iframe-item-img'
                            ></CardMedia>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='brws-extc-iframe-desc-text-item'>
                            <Grid container sx={{ justifyContent: "center" }}>
                                <Grid item xl={6.5} lg={7} md={9} sm={10} xs={11}>
                                    <Typography component={'span'} className='brws-extc-iframe-desc-text'>
                                        Your content should reflect your voice and tone. With Writewiz AI, choose the persona that aligns with your style.
                                        Whether it's professional, casual, humorous, or authoritative, we've got you covered. Train the platform to understand
                                        and mimic your style for a seamless and genuine writing experience.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={5} lg={7} md={11} sm={11} xs={11} className='brws-extc-ai-partner-text-item' pt={10}>
                            <Grid container>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='brws-extc-text-item'>
                                    <Typography component={'span'} className='brws-extc-normal-text'>
                                        Your <Typography component={'span'} className='brws-extc-color-text'>AI partner</Typography> is ready to write content.
                                    </Typography>
                                </Grid>
                                <Grid item xl={7} lg={7} md={5} sm={6} xs={11} className='brws-extc-subtitle-text-item'>
                                    <Typography component={'span'} className='brws-extc-subtitle-text'>
                                        Save time and improve your writing using Writewiz AI. Create content in seconds in every textbox.
                                    </Typography>
                                </Grid>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Grid container sx={{ justifyContent: "center" }}>
                                        <Grid item className='brws-signup-btn-item'>
                                            <Button
                                                variant='outlined'
                                                fullWidth
                                                size='medium'
                                                className='brws-signup-btn'
                                                onClick={() => {
                                                    window.open(process.env.REACT_APP_REGISTER_PAGE, '_blank');
                                                }}

                                            >Sign Up</Button>
                                        </Grid>
                                        <Grid item className='brws-add-chrome-btn-item'>
                                            <Button
                                                variant='contained'
                                                fullWidth
                                                size='medium'
                                                className='brws-add-chrome-btn'
                                                startIcon={<img src={chrome} />}
                                                endIcon={<ArrowForwardIcon />}
                                                onClick={() => window.open("https://chrome.google.com/webstore/detail/writewiz-ai-content-creat/nikfnkpdalalkollmjgjkgmfblfmlpbd/related?hl=en&authuser=0", "_blank")}
                                            >
                                                Add To Browser  {/* &nbsp;&nbsp;Install Chrome Extension */}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default BrowerExtContent