import './Pricing.css';
import { Button, Grid, Switch, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/system';
import PricingCard from './PricingCard';
import PricingPlansFeatures from './PricingPlansFeatures';
import Navbar from '../Navbar/Navbar'; // change this navbar for other project
import PricingFAQ from './PricingFAQ';
import LandingFooter from '../../Component/Footer/LandingFooter';
import { Context as mainContext } from '../../Context/MainContext/GlobalContext'

const Pricing = () => {

    const { setLoader, loading } = useContext(mainContext)
    const [subType, setSubType] = useState("yearly");
    const [scrollEffect, setScrollEffect] = useState(false);
    const [plusValue, setPlusValue] = useState(0);
    const [premiumValue, setPremiumValue] = useState(0);
    const [pricingDetails, setPricingDetails] = useState([
        //     {
        //     plan_name: "",
        //     plan_desc: "",
        //     feature_list: [],
        //     monthly_plan: [{
        //         label: null,
        //         value: null,
        //     }],
        //     yearly_plan: []
        // }
    ])
    const [pricingFeatureDetails, setPricingFeatureDetails] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true)
                const response = await fetch(process.env.REACT_APP_API_CALL_URL + '/stripe/get-stripe-pricing-detail');
                if (!response.ok) {
                    setLoader(false)
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                // console.log("json", jsonData);
                jsonData.data.map((response) => {
                    // console.log("response", response);
                    pricing(response)
                })
                pricingComparePlanDetails(jsonData)
                setLoader(false)
            }
            catch (error) {
                console.log("error", error);
                setLoader(false)

            }
        };

        // Call the fetchData function when the component mounts
        fetchData();
    }, []);



    const pricing = (ele) => {

        let montlyPlan;
        let yearlyPlan;

        const FeatureDataArr = ele.gc_stripe_product_feature_data.map((featureData) => {
            return featureData.stripe_product_feature_name;
        });

        const priceDataArr = ele.gc_stripe_product_price_data.map((ele) => {
            ele.stripe_product_price_flag_recurring_is_monthly ? montlyPlan =
                ele.gc_stripe_product_price_cutomized_data.map((priceData) => {
                    return {
                        value: priceData.stripe_product_price_customized_unit_amount,
                        label: priceData.stripe_product_price_customized_credit
                    }
                }) : yearlyPlan = ele.gc_stripe_product_price_cutomized_data.map((priceData) => {
                    return {
                        value: priceData.stripe_product_price_customized_unit_amount / 12,
                        label: priceData.stripe_product_price_customized_credit
                    }
                })
        })

        setPricingDetails((prevPricingDetails) => [
            ...prevPricingDetails,
            {
                plan_name: ele.stripe_product_name,
                plan_desc: ele.stripe_product_description,
                feature_list: FeatureDataArr,
                monthly_plan: montlyPlan,
                yearly_plan: yearlyPlan
            },
        ]);
    };

    const pricingComparePlanDetails = (jsonData) => {

        // console.log("jsondata", jsonData);

        let featureData = jsonData.data.map((element) => {
            return {
                name: element.stripe_product_name,
                feature: element.gc_stripe_product_feature_data.map((el1) => {
                    return {
                        feature_name: el1.stripe_product_feature_name,
                        feature_description: el1.stripe_product_feature_description,
                    }
                })
            }
        })
        // console.log("featureData", featureData);

        let allFeatureList = featureData.filter((plans) => {
            if (plans.name == "Premium") {
                return plans.feature
            }
        })
        // console.log("allFeatureList", allFeatureList);

        let planAllFeatures = allFeatureList[0].feature
        // console.log("planAllFeatures", planAllFeatures);

        // let featureObject = planAllFeatures.map((element) => {
        //     var find
        //     return {
        //         name: element.feature_name,
        //         subtitle: element.feature_description,
        //         plans: {
        //             free_plan: featureData.filter(ele => ele.name == "Free").filter(ele2 => ele2.feature_name == element.name),
        //             Starter_Plan: featureData.filter(ele => ele.name == "Starter").filter(ele2 => ele2.feature_name == element.name),
        //             Premium_Plan: featureData.filter(ele => ele.name == "Premium").filter(ele2 => ele2.feature_name == element.name),
        //         }
        //     }
        // })

        let featureObject = planAllFeatures.map((element) => {
            const checkFeaturePresence = (planName) => {
                const plan = featureData.find(ele => ele.name === planName);
                const feature = plan && plan.feature.find(feat => feat.feature_name === element.feature_name);
                if (feature) {
                    return feature.feature_description !== null ? feature.feature_description : true;
                }
                return false;
            };

            return {
                name: element.feature_name,
                subtitle: element.feature_description,
                plans: {
                    Free_Plan: checkFeaturePresence("Free"),
                    Starter_Plan: checkFeaturePresence("Starter"),
                    Premium_Plan: checkFeaturePresence("Premium")
                }
            }
        });

        featureObject.unshift(
            {
                name: 'Rate per month - monthly / user',
                subtitle: '',
                // subtitle: 'Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue',
                select: false,
                plans: {
                    Free_Plan: "0",
                    Starter_Plan: "$18",
                    Premium_Plan: "$37",
                }
            },
            {
                name: 'Rate per month - Annually / user',
                subtitle: '',
                // subtitle: 'Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue',
                select: false,
                plans: {
                    Free_Plan: "0",
                    Starter_Plan: "$12",
                    Premium_Plan: "$25",
                }
            },
            {
                name: 'Total Credits',
                subtitle: '',
                // subtitle: 'Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue',
                select: true,
                plans: {
                    Free_Plan: "100",
                    Starter_Plan: "1000",
                    Premium_Plan: "2000",
                }
            }
        )
        // setPricingFeatureDetails(featureObject);

        // console.log("featureObject", featureObject);
        setPricingFeatureDetails(featureObject)
    }

    // console.log("pricingFeatureDetails", pricingFeatureDetails);

    useEffect(() => {
        const changeStructure = () => {
            if (window.scrollY > 90) {
                setScrollEffect(true);
            } else {
                setScrollEffect(false);
            }
        };

        changeStructure();
        window.addEventListener("scroll", changeStructure);

        return () => {
            window.removeEventListener("scroll", changeStructure);
        };
    }, []);


    const handlePlusSliderChange = (newValue) => {
        setPlusValue(newValue);
    };

    const handlePremiumSliderChange = (newValue) => {
        setPremiumValue(newValue);
    };

    const calculateCredit = (value, planType) => {
        const increment = planType === 'plus' ? 100 : 200;
        return value * increment;
    };

    return (
        <>

            <Navbar scrollEffect={scrollEffect} setScrollEffect={setScrollEffect} />
            <Grid container>
                <Grid item xs={12} className='pp-main-item'>
                    <Grid container>

                        <Grid item xl={12} lg={12} md={12} sm={11} xs={12} className='pp-title-item'>
                            <Typography
                                variant='h3'
                                component="span"
                                className='pp-main-title'
                            >
                                Pick the price thats <Typography
                                    variant='h3'
                                    component="span"
                                    className='pp-main-color-title'
                                >right for you</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className='pp-sub-title-item'>
                            <Typography
                                variant='h6'
                                component="span"
                                className='pp-sub-title-typo'
                            >
                                Choose how to level up your productivity
                            </Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='pp-monthly-annually-item'>
                            <Grid container className='pp-monthly-annually-container'>
                                <Box className="mask-box">
                                    <Box
                                        className="mask"
                                        style={{
                                            transform: `translateX(${subType === "monthly" ? 0 : "150px"})`
                                        }}
                                    />
                                    <Button
                                        disableRipple
                                        variant="text"
                                        onClick={() => {
                                            setSubType("monthly")
                                        }}
                                        className={subType === 'monthly' ? 'selected-monthly-button' : ''}
                                    >
                                        Monthly
                                    </Button>
                                    <Button
                                        disableRipple
                                        variant="text"
                                        onClick={() => {
                                            setSubType("yearly")
                                        }}
                                        className={subType === 'yearly' ? 'selected-yearly-button' : ''}
                                    >
                                        Annual
                                    </Button>
                                </Box>

                            </Grid>
                        </Grid>
                        <Grid item xl={9} lg={11} md={10} sm={11.5} xs={11} className='pp-plans-panel-main-item'>
                            <PricingCard
                                subType={subType}
                                plusValue={plusValue}
                                premiumValue={premiumValue}
                                onPlusSliderChange={handlePlusSliderChange}
                                onPremiumSliderChange={handlePremiumSliderChange}
                                calculateCredit={calculateCredit}

                                pricingDetails={pricingDetails}
                                setPricingDetails={setPricingDetails}
                            />
                        </Grid>
                        <Grid item xs={12} className='pp-plans-bg-main-item'>
                            <Grid container>
                                <Grid item xl={9} lg={11} md={11} sm={11.5} xs={11.5} className='pp-plan-feature-item'>
                                    <PricingPlansFeatures
                                        plusValue={plusValue}
                                        premiumValue={premiumValue}
                                        calculateCredit={calculateCredit}
                                        subType={subType}
                                        pricingDetails={pricingDetails}
                                        pricingFeatureDetails={pricingFeatureDetails}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={9} lg={11} md={11} sm={10} xs={11} className='pp-plan-faq-item'>
                            <PricingFAQ />
                        </Grid>
                        <Grid item xs={12}>
                            <LandingFooter />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Pricing