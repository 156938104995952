import React, { useEffect, useState } from "react";
import "./Navbar.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../../assests/ValtflowIcon.png";
import WriteWizMainLogo from "../../assests/WriteWizMainLogo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import backButtonIcon from "../../assests/backButtonIcon.png";
import { Dialog, Fab, Grid } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import HelpCenterDialog from "../../Component/HelpCenterDialog/HelpCenterDialog";
import chrome from "../../assests/ChromeLogo.png";
import WriteWizWhiteLogo from "../../assests/writeWizWhiteLogo.png"
import metaProvider from '../../assests/ChatbotWhatsapp/cw-meta-provider-img.png'
import BookAMeetingDialog from "../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog";
import WritewizaiPrimaryLogo from "../../assests/GlobalImages/WritewizAI-Primary-Logo.svg"
import WritewizaiWhiteLogo from "../../assests/GlobalImages/WritewizAI-White-Logo.svg"


const Navbar = (props) => {

  const drawerWidth = "100%";
  const writeWizWebsiteURL = "https://writeWiz.ai";
  const webPageName = window.location.pathname.replace('/', '')

  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [flagOpenHelpCenterDialog, setFlagOpenHelpCenterDialog] = useState(false);
  const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);
  const [isScroll, setIsScroll] = useState(false);



  useEffect(() => {
    window.addEventListener("scroll", changeStructure);
    return () => {
      window.removeEventListener("scroll", changeStructure);
    };
  }, []);

  const changeStructure = () => {
    if (window.scrollY > 90) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      className="mobileHamburgerSideBar"
      sx={{ textAlign: "center", width: "100%" }}
    >
      <div className="mobileHamburgerSideBar-inner-div">
        <div className="mobileHamburgerSideBar-logo-container">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div onClick={() => { navigate('/') }} style={{ lineHeight: 0.8, marginLeft: '40px' }}>
              <img
                src={WritewizaiWhiteLogo}
                // src={WriteWizMainLogo}
                color="white"
                alt="WriteWiz"
                width={"auto"}
                marginTop={'10px !important'}
                height={25}
              />
            </div>
          </div>

          <Fab
            className="mobileHamburgerSideBar-back-button"
            color="primary"
            aria-label="add"
          >
            <ArrowBackIosNewIcon sx={{ transform: "rotate(180deg)" }} />
          </Fab>
        </div>

        <div className="mobileHamburgerSideBar-center-box">
          <div className="mobileHamburgerSideBar-button" onClick={() => window.open(`/ai-paraphrasing-tool`, '_blank')}>
            AI Paraphrasing
          </div>

          <div className="mobileHamburgerSideBar-button" onClick={() => navigate("/conversational-ai-chatbot")}>
            Chatbot
          </div>

          <div className="mobileHamburgerSideBar-button" onClick={() => navigate("/browser-extension")}>
            Extension
          </div>

          <div className="mobileHamburgerSideBar-button" onClick={() => navigate("/pricing")}>
            Pricing
          </div>

          <div className="mobileHamburgerSideBar-button" onClick={() => window.open(process.env.REACT_APP_DASHBOARD_URL, "_blank")}>
            Start Writing For free
          </div>

          <div className="mobileHamburgerSideBar-footer-parent">
            <div className="mobileHamburgerSideBar-footer-right" style={{ marginLeft: "40px" }}>
              <div onClick={() => window.open("https://app.writewiz.ai/privacy-policy", "_blank")}>Privacy Policy</div>
              <div onClick={() => window.open("https://app.writewiz.ai/terms", "_blank")}>T&C</div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <>
      {flagOpenHelpCenterDialog && (
        <Dialog open={true}>
          <HelpCenterDialog
            setFlagOpenHelpCenterDialog={setFlagOpenHelpCenterDialog}
            flagOpenHelpCenterDialog={flagOpenHelpCenterDialog}
          />
        </Dialog>
      )}

      {flagOpenBookaMeetingDemo && (
        <Dialog open={flagOpenBookaMeetingDemo}
          maxWidth='lg'
          sx={{ '&.MuiPaper-root': { width: '100%' } }}
          className="iframe-dialog"
        >
          <BookAMeetingDialog
            flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
            setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
          />
        </Dialog>
      )}

      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar
          component="nav"
          className={isScroll ? "wz-header-scroll" : "wz-header"}
          sx={{
            padding: "0px 5px",
            alignItems: "center",
            "& .MuiToolbar-root": {
              width: "93%",
            },
          }}
        >
          <Toolbar className="wz-header-toolbar" sx={{ overflow: "hidden" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
              className={props.scrollEffect ? "wz-header-logo-text-after-scroll" : "wz-header-logo-text-before-scroll"}
            >
              <div style={{ display: "flex", alignItems: "center" }} className="wz-mainlogo-container">
                <div onClick={() => { navigate('/') }} style={{ lineHeight: 0.8 }}>
                  <img
                    src={WritewizaiPrimaryLogo}
                    alt="WriteWiz"
                    width={"auto"}
                    marginTop={'10px !important'}
                    height={25}
                    style={{ cursor: 'pointer' }}
                  />
                </div>

                {webPageName == "whatsapp-ai-chatbot" &&
                  <img
                    src={metaProvider}
                    alt="WriteWiz"
                    width={"140px"}
                    marginTop={'10px !important'}
                    height={"auto"}
                  />
                }
              </div>
            </Typography>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              className="navbar-drawer-button"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, color: "black" }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              className={props.page ? props.scrollEffect ? "navbar-computer-right-button-group" : "navbar-computer-right-button-group-with-hidden-button" : "navbar-computer-right-button-group-without-transition"}
              sx={{ overflow: !props.scrollEffect ? "visible" : "hidden" }}
            >
              {webPageName !== "whatsapp-ai-chatbot" ?
                (
                  <div>
                    <Button
                      key={"gitex"}
                      variant={"text"}
                      className={"wz-nav-menu-btn"}
                      onClick={() => { window.open(`/ai-paraphrasing-tool`, '_blank') }}
                    >
                      AI Paraphrasing
                    </Button>

                    <Button
                      key={"chatbot"}
                      variant={"text"}
                      className={"wz-nav-menu-btn"}
                    >
                      <a href="/conversational-ai-chatbot" target="_blank" style={{ textDecoration: "none", textTransform: "none", color: "inherit" }}>Chatbot</a>
                    </Button>

                    <Button
                      key={"extension"}
                      variant={"text"}
                      className={"wz-nav-menu-btn"}
                    >
                      <a href="/browser-extension" target="_blank" style={{ textDecoration: "none", textTransform: "none", color: "inherit" }}>Extension</a>
                    </Button>

                    <Button
                      key={"Pricing"}
                      variant={"text"}
                      className={"wz-nav-menu-btn"}
                    >
                      <a href="/pricing" target="_blank" style={{ textDecoration: "none", color: "inherit" }}>Pricing</a>
                    </Button>

                    <Button
                      key={"Blog"}
                      variant={"text"}
                      sx={{ marginLeft: "10px !important", minWidth: "40px" }}
                      className={props.scrollEffect ? "wz-nav-menu-add-browser-btn" : "wz-nav-menu-add-browser-btn-transition"}
                      onClick={() => window.open("https://chrome.google.com/webstore/detail/writewiz-ai-content-creat/nikfnkpdalalkollmjgjkgmfblfmlpbd?hl=en", "_blank")}
                    >
                      <img src={chrome} alt="" />
                      {window.innerWidth >= 1050 && <span> &nbsp; Install Extension </span>}
                    </Button>

                    {
                      props.showHelpNavbar && (
                        <Button
                          key={"Help"}
                          variant={"text"}
                          className={"wz-nav-menu-btn"}
                          onClick={() => {
                            // setFlagOpenHelpCenterDialog(true);
                            navigate('/contact-support')
                          }}
                        >
                          Contact Support
                        </Button>
                      )}

                    {props.page ? (
                      <Button
                        key={"Home"}
                        variant={"contained"}
                        className={props.scrollEffect ? "wz-free-transition-btn" : "wz-free-transition-btn-hidden"}
                        onClick={() => { window.open(process.env.REACT_APP_DASHBOARD_URL, "_blank") }}
                      >
                        Start writing for free
                      </Button>
                    ) : (
                      <Button
                        key={"Home"}
                        variant={"contained"}
                        className={"wz-signup-btn-without-transition"}
                        onClick={() => { window.open(process.env.REACT_APP_DASHBOARD_URL, "_blank") }}
                      >
                        Start writing for free
                      </Button>
                    )
                    }
                  </div>
                ) : (
                  <Grid item>
                    <Grid container sx={{ gap: '10px' }}>
                      <Grid item>
                        <Button
                          key={"Get Demo"}
                          variant={"outlined"}
                          sx={{
                            marginLeft: "10px !important",
                            minWidth: "40px",
                            color: '#6A097D',
                            background: 'white',
                            borderRadius: '5px',
                            border: '1px solid #6A097D',
                            fontSize: '15px',
                            fontWeight: '500',
                            textTransform: 'none',
                            padding: '5px 20px'
                          }}
                          onClick={
                            // () => window.open("https://scheduler.zoom.us/krishna-writewiz/one-on-one", "_blank")
                            () => {
                              setFlagOpenBookaMeetingDemo(true)
                            }
                          }
                        >
                          Book a Meeting
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          key={"Blog"}
                          variant={"text"}
                          sx={{
                            marginLeft: "10px !important",
                            minWidth: "40px",
                            color: 'white',
                            background: '#6A097D',
                            borderRadius: '5px',
                            fontSize: '15px',
                            fontWeight: '500',
                            textTransform: 'none',
                            padding: '5px 20px',
                          }}
                          className="navbar-contact-us"
                          onClick={() => window.open(`https://chatbot.writewiz.ai`, "_self")}
                        >
                          Get started for Free
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }
            </Box>
          </Toolbar>
        </AppBar>

        <Box component="nav" className="mobileHamburgerSideBar-parent-div">
          <div>
            <Drawer
              // container={container}
              variant="persistent"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              anchor="right"
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  backgroundColor: "#6A097D",
                },
              }}
            >
              {drawer}
            </Drawer>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
