import React from 'react';
import "./ChatbotExploreMobile.css"
import { Carousel } from 'react-responsive-carousel';

const ChatbotExploreMobile = () => {
  const mainData = [
    {
      name: "one",
      text: "",
      smallTitle: "",
      image: "https://storage.writewiz-staging.in/general/Ominchanncel_support_image_1710941882652.png",
      title: "Effortless Omnichannel Support",
      description: "AI chatbot offers frictionless omnichannel experiences, ensuring your brand is where your customers are. Elevate your customer service with unified conversations.",
    },
    {
      name: "two",
      text: "",
      smallTitle: "",
      image: "https://storage.writewiz-staging.in/general/no_code_platform_image_1710941920521.png",
      title: "No-Code Platform - Empowering Every Team Member",
      description:
        "Harness the power of our user-friendly, no-code platform to create and customize your chatbot effortlessly.",
    },
    {
      name: "three",
      text: "",
      smallTitle: "",
      image: "https://storage.writewiz-staging.in/general/Brand_conversations_image_1710942691802.png",
      title: "Crafting Conversations That Reflect Your Brand",
      description:
        "Infuse your chatbot with a personality that mirrors your brand, making every interaction memorable and on-brand.",
    },
  ];
  return (
    // className="usecasemobile-main-div"
    <div style={{ background: "#FFF" }}>
      <div>
        <div className="cbem-explore-heading-container">
          <p className="cbem-explore-heading">Conversational <span>AI Chatbot</span> for Your Business</p>
          <p className="cbem-explore-sub-heading"> Ensuring a personal touch in every interaction, tailored just for them</p>
        </div>
      </div>
      <div className="chatbot-usecasemobile-main-div">
        <Carousel
          autoPlay={true}
          className='CarouselStyles'
          infiniteLoop={true}
          autoFocus={true}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          showStatus={false}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (isSelected) {
              return (
                <li
                  style={{ background: "#6A097D", width: "30px", height: "7px", borderRadius: "8px", display: "inline-block", margin: "0 5px", cursor: "pointer" }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                  title={`Selected: ${label} ${index + 1}`}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  tabIndex={0}
                  role="button"
                />
              );
            }
            return (
              <li
                style={{ background: "#c9c8c8", width: "7px", height: "7px", borderRadius: "50%", display: "inline-block", margin: "0 5px", cursor: "pointer" }}
                aria-label={`${label} ${index + 1}`}
                title={`${label} ${index + 1}`}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                tabIndex={0}
                role="button"
              />
            );
          }}
        >
          {mainData.map((data) => {
            return (
              <div className="usecasemobile-inner-div">
                <div className="chatbot-usecasemobile-main-image">
                  <div className="chatbot-usecasemobile-section" >

                    <div className="chatbot-usecasemobile-main-image-inner-div">
                      <img
                        src={data.image}
                        style={{ width: "100%", }}
                        alt=""
                      />

                    </div>
                    <div className="chatbot-usecasemobile-main-text" >
                      <div className="chatbot-usecasemobile-main-text-inner-div">
                        {/* <div className="usecasemobileSmallTitle" >
                    {data.smallTitle}
                  </div> */}
                        <div className="chatbot-usecasemobileTitle">{data.title}</div>
                        {/* <div id="usecasemobileTitleDiv">
                    <span className="usecasemobileTitle">
                      {mainData[0].title?.split(" ").slice(0, -1).join(" ")}
                    </span>
                    <span className="usecasemobileTitle">
                      {mainData[0].title?.split(" ").splice(-1)[0]}
                    </span>
                  </div> */}
                        {/* <div id="usecasemobileText">{mainData[0].text}</div> */}

                        <div className="chatbot-usecasemobileDescription">{data.description}</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            )
          })}
        </Carousel>
      </div>
      {/* <div className="chatbot-expolre-border-bottom"></div> */}
    </div>
  )
}

export default ChatbotExploreMobile