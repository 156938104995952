import React from 'react'
import {
    Button,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    Typography,
    Accordion, AccordionDetails, AccordionSummary,
} from "@mui/material";
import '../../Pages/Paraphraser/Paraphraser.css'
import '../../Pages/PricingPage/PricingFAQ.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Section5 = () => {

    const [expanded, setExpanded] = React.useState([]);
    const faqData = [
        {
            question: "Do AI paraphrasing tools support different languages?",
            answer: "Many tools support multiple languages, offering a great resource for language learners to improve grammar, fluency, and vocabulary. Writewiz leverages advanced AI to understand and paraphrase text in various languages, bridging communication gaps and fostering global connections.",
        },
        {
            question: "Can AI paraphrasing tools generate content in different tones and styles?",
            answer: "Yes, Writewiz AI Paraphrasing tool offers the capability to generate content across a variety of tones and styles. Whether you're looking for a formal tone for professional articles or a casual tone for blogs and social media, our tool adapts to your specific content creation needs, ensuring versatility and creativity in your writing projects."
        },
        {
            question: "Whats the difference between paraphrasing, rephrasing, and rewording?",
            answer: "Paraphrasing involves restating someone else's ideas in your own words, focusing on altering sentence structure and word choice to convey the same meaning. Rephrasing may include more substantial changes, such as altering sentence order or the overall text structure. Rewording involves changing individual words without altering meaning or structure. Each method serves a unique purpose in ensuring your content remains original and engaging.",
        },
        {
            question: "What is paraphrasing and how does it differ from quoting?",
            answer: "Paraphrasing is the technique of expressing someone else's thoughts in your own words, distinguishing it from quoting, where you directly cite the source text verbatim. Writewiz excels in transforming the original text into a new form, ensuring that the essence of the message is preserved while offering a fresh perspective, thus differentiating it significantly from direct quotes."
        },
        {
            question: "How can I paraphrase effectively without losing the original meaning?",
            answer:
                "To paraphrase effectively without losing the original meaning, Writewiz AI uses advanced Gen AI to ensure the essence of the original text is preserved while transforming the wording. Our tool intelligently adjusts sentence structures and vocabularies, ensuring your paraphrased content remains true to the original intent and message."
        },
        {
            question: "Is paraphrasing considered plagiarism",
            answer:
                "Paraphrasing without proper citation is considered plagiarism. However, when done correctly with citations, paraphrasing is not plagiarism."
        },
        {
            question: "How can I use paraphrasing to improve the readability and tone of my content?",
            answer:
                "Paraphrasing can improve readability and tone by fixing grammatical errors and adapting the language to better fit the new context or audience."
        },
        {
            question: "Is using an AI paraphrasing tool considered cheating?",
            answer:
                "Utilizing an AI paraphrasing tool like Writewiz AI is not considered cheating, provided that all sources are properly credited. Out tool is designed to aid in the creative process, offering new ways to express ideas and ensuring that your content remains unique and original."
        },
        {
            question: "How does paraphrasing contribute to SEO and content originality?",
            answer:
                "Effective paraphrasing can enhance SEO by diversifying the expression of key concepts, thus avoiding duplicate content issues and enriching the content's originality and value to readers."
        },
        {
            question: "Are AI paraphrasing tools suitable for academic writing?",
            answer:
                "Yes, they are designed to assist in generating new ideas and refreshing academic content while maintaining academic integrity. Our tool helps students and researchers articulate their thoughts more clearly, ensuring their work remains original and impactful."
        },
    ];


    const handleChange = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded([...expanded, panel]);
        } else {
            setExpanded(expanded.filter(item => item !== panel));
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xl={8} lg={8} md={10} sm={11} xs={11} sx={{ margin: '0 auto' }}>
                    <Grid container>
                        <Grid item xs={12} className='pr-ai-question-item'>
                            <Typography variant='span' className='pr-ai-global-head-text'>Questions?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ borderBottomWidth: "1px", borderColor: "#6A097D" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ margin: "30px 0px 10px" }}>
                                    <Typography variant='span' className='pr-ai-faq-main-head'>Frequently Asked Questions</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ margin: "0px 0px" }}>
                                    <Typography variant='span' className='pr-ai-faq-sub-head'>Got a question? We’re here to answer! If you don’t see your question here, drop us a line on our mail.</Typography>
                                </Grid>
                                <Grid item xs={12} className='pp-faq-accordion-item'>

                                    {faqData.map((item, index) => (
                                        <Accordion
                                            key={index}
                                            expanded={expanded.includes(`panel${index}`)}
                                            onChange={handleChange(`panel${index}`)}
                                            className="pr-ai-faq-accordion"

                                        >
                                            <AccordionSummary
                                                className="pr-ai-expand-accordion-summary"
                                                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                                                aria-controls={`panel${index}bh-content`}
                                                id={`panel${index}bh-header`}
                                            >
                                                <Typography className="pp-faq-ques-head">{item.question}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className="pp-faq-details-section">
                                                {item.answer.split('\n').map((it) => {
                                                    return (
                                                        <Typography className="pp-faq-ans-text" sx={{ marginBottom: "1rem" }}>
                                                            {it}
                                                        </Typography>
                                                    )
                                                })}
                                                {/* <Typography>{item.answer}</Typography> */}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Section5