import React, { useEffect, useState } from 'react'
import TopPanel from '../../Component/TopPanel/TopPanel.jsx'
import PopularArticle from '../../Component/PopularArticle/PopularArticle.jsx';
import AllArticle from '../../Component/AllArticle/AllArticle.jsx'
import { Grid } from '@mui/material';
import LandingFooter from '../../Component/Footer/LandingFooter.jsx';
import Navbar from '../Navbar/Navbar.jsx';


const ArticleHomePage = () => {

  const [showHelpNavbar, setShowHelpNavbar] = useState(true)
  const [scrollEffect, setScrollEffect] = useState(false);

  useEffect(() => {

    const changeStructure = () => {
      if (window.scrollY > 90) {
        setScrollEffect(true);
      } else {
        setScrollEffect(false);
      }
    };

    changeStructure();
    window.addEventListener("scroll", changeStructure);

    return () => {
      window.removeEventListener("scroll", changeStructure);
    };
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Navbar showHelpNavbar={showHelpNavbar} scrollEffect={scrollEffect} />
            </Grid>
            <Grid item xs={12}>
              <TopPanel />
            </Grid>
            <Grid item xs={12}>
              <PopularArticle />
            </Grid>
            <Grid item xs={12}>
              <AllArticle />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LandingFooter />
        </Grid>
      </Grid>
    </>
  )
}

export default ArticleHomePage