import React from 'react'
import './PopularArticle.css'
import { Grid, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


const PopularArticle = () => {

  const articles = [
    'What is WriteWiz',
    'What languages does WriteWiz support?',
    'How to find my invoices',
    'WriteWiz Billing Policy',
    'What websites and platforms is Wordtune available on?',
    'What is WriteWiz API?'
  ];

  return (
    <>
      <Grid container>
        <Grid item xl={6.5} lg={8} md={10} sm={11} xs={11} className='sp-link-list-item'>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='sp-popular-artical-text-item'>
              <Typography variant='span' className='sp-popular-artical-text'>Popular Article</Typography>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='sp-links-section-item' >
              <Grid container sx={{ justifyContent: "space-between" }}>
                {articles.map((ele) => {
                  return (
                    <>
                      <Grid item xl={5.5} lg={5.5} md={5.5} sm={5.5} xs={12} className='sp-links-main-item'>
                        <Grid container>
                          <a href="" className="sp-link-items">
                            <Grid item xs={10}>
                              <Typography variant='span' className='sp-link-text'>
                                {ele}
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <KeyboardArrowRightIcon sx={{ color: "black" }} />
                            </Grid>
                          </a>
                        </Grid>
                      </Grid>
                    </>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PopularArticle