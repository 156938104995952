import './App.css';
import RoutePage from './Routes';
import { BrowserRouter } from 'react-router-dom';
import { Fragment } from 'react';
import { Provider as AuthProvider } from './Context/MainContext/GlobalContext'

// import Table from './Pages/Table';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Fragment>
          <RoutePage />
        </Fragment>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
