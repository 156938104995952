import React, { useContext, useEffect, useState } from "react";
import "./TabPanel.css";
import { Tabs, Tab, Box, Snackbar, Alert, Tooltip, CircularProgress, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import thunderImage from "../../assests/thunderIcon.png";
import leftArrowInsideButton from "../../assests/leftArrowInButton.png";
import CopyIcon from "../../assests/copyIcon.png";
import { event } from "jquery";
import RecaptchaPopup from "./RecaptchaPopup/RecaptchaPopup";
import writewizStarWhite from "../../assests/writewiz-star-white.png";
import radioButtonImg from "../../assests/radio-button-image.svg";
import radioButtonSelectedImg from "../../assests/radio-selected-image.svg";
import W from "../../assests/W.svg";
import { useNavigate } from "react-router-dom";
import { Context as MainContext } from "../../Context/MainContext/GlobalContext";

const SkeletonScreen = () => {
  return (
    <div>
      <div className="single-sentence-skeleton">
        {" "}
        <Skeleton
          variant="text"
          animation="wave"
          width={"70%"}
          sx={{ background: "#F7E9FF" }}
        />
      </div>
      <div className="single-sentence-skeleton">
        {" "}
        <Skeleton
          variant="text"
          animation="wave"
          width={"50%"}
          sx={{ background: "#F7E9FF" }}
        />
      </div>

      <div className="single-sentence-skeleton">
        {" "}
        <Skeleton
          variant="text"
          animation="wave"
          width={"80%"}
          sx={{ background: "#F7E9FF" }}
        />
      </div>

      <div className="single-sentence-skeleton">
        <Skeleton
          variant="text"
          animation="wave"
          width={"90%"}
          sx={{ background: "#F7E9FF" }}
        />
      </div>
      <div className="single-sentence-skeleton">
        <Skeleton
          variant="text"
          animation="wave"
          width={"30%"}
          sx={{ background: "#F7E9FF" }}
        />
      </div>
      <div className="single-sentence-skeleton">
        <Skeleton
          variant="text"
          animation="wave"
          width={"50%"}
          sx={{ background: "#F7E9FF" }}
        />
      </div>
    </div>
  );
};

const DocumentLoader = () => {
  return (
    <div style={{ display: "flex", position: "relative", justifyContent: "center", alignItems: "center", zIndex: "1001" }}>
      <div style={{ position: 'absolute', width: 100, height: 100 }}>
        <CircularProgress
          size={100}
          thickness={3.6}
          style={{
            color: "rgba(106, 9, 125, 0.8)"
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '53%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <img
            src={W}
            alt="Your Image"
            style={{ width: '100%', height: '100%' }}
          />
        </div>

        <Typography variant="body1" color="textSecondary" style={{
          position: 'absolute',
          left: '45%',
          top: 110,
          transform: 'translateX(-45%)',
          whiteSpace: 'nowrap',
          color: "rgba(106, 9, 125, 1)",
          // backgroundImage: `linear-gradient(135deg, #6A097D, #0A6ADD)`,
          // WebkitBackgroundClip: 'text',
          // color: 'transparent',
          fontWeight: "600"
        }}>
          Please wait while we generate a document ..
        </Typography>
      </div>
    </div>
  );
};

const ShowSelectedTabData = (props) => {
  const [sentences, setSentences] = useState([]);

  const [copiedAiResponse, setCopiedAiResponse] = useState();
  const [copyText, setCopyText] = useState("Copy");

  const sentences1 = [
    {
      header: "The sun is shining brightly in the sky.",
      description: "The sun is shining brightly in the sky.",
      isSelected: true,
    },
    {
      header: "The sun is shining brightly in the sky.",
      description: "The sun is shining brightly in the sky.",
    },
    {
      header: "The sun is shining brightly in the sky.",
      description: "The sun is shining brightly in the sky.",
    },
    {
      header: "The sun is shining brightly in the sky.",
      description: "The sun is shining brightly in the sky.",
    },
    {
      header: "The sun is shining brightly in the sky.",
      description: "The sun is shining brightly in the sky.",
    },
    {
      header: "The sun is shining brightly in the sky.",
      description: "The sun is shining brightly in the sky.",
    },
  ];
  const [open, setOpen] = useState(false);
  useEffect(() => {
    // setSentences(sentences1);
    setSentences(props.apiResponceData);
    if (props.apiResponceData?.length > 0) {
      props.setLoading(false);
    }
    // Simulating a 2-second delay
  }, [props.apiResponceData]);

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleSelection = (sentence, index) => {
    if (index < 0 || index >= sentence.length) {
      throw new Error("Invalid index.");
    }

    const updatedSentences = sentences.map((sentence, idx) => {
      let newSentence = { ...sentence };
      if (newSentence.isSelected) {
        delete newSentence.isSelected;
      }
      if (index === idx) {
        newSentence.isSelected = true;
      } else {
        newSentence.isSelected = false;
      }
      return newSentence;
    });
    setSentences(updatedSentences);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Copied succesfully!
        </Alert>
      </Snackbar>
      <div className="suggetion-box-inner-box">
        <div className="map-suggetion-box">
          {props.loading ? (
            <SkeletonScreen animation="wave" />
          ) : (
            sentences.map((sentence, index) => {

              return (
                <>
                  {Object.keys(sentence).length < 3 ? (
                    <div className="single-sentence" key={index}>
                      <div
                        className={
                          sentence.isSelected
                            ? "single-sentence-text-selected"
                            : "single-sentence-text "
                        }
                      >
                        {sentence[Object.keys(sentence)[0]]}
                      </div>
                      <div>
                        <img
                          src={CopyIcon}
                          alt=""
                          width={"18px"}
                          style={{ cursor: "pointer", opacity: "0.6" }}
                          onClick={() => {
                            navigator.clipboard
                              .writeText(sentence[Object.keys(sentence)[0]])
                              .then(() => {
                                setOpen(true);
                                // alert("Text copied to clipboard: " + sentence);
                              })
                              .catch((err) => {
                                console.error("Failed to copy text: ", err);
                              });
                            setCopiedAiResponse(sentence);
                          }}
                        />
                        <img
                          onClick={() => handleSelection(sentence, index)}
                          src={
                            sentence.isSelected
                              ? radioButtonSelectedImg
                              : radioButtonImg
                          }
                          alt=""
                          width={"22px"}
                          style={{ marginLeft: "15px", cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="double-sentence" key={index}>
                      <div
                        className={
                          sentence.isSelected
                            ? "single-sentence-text-selected"
                            : "single-sentence-text "
                        }
                        style={{ flexDirection: "column" }}
                      >
                        <div
                          style={{
                            color: sentence.isSelected ? "#6a097d" : "grey",
                            // color: sentence.isSelected ? "black" : "grey",
                            fontWeight: 500
                          }}
                        >
                          {Object.keys(sentence)[0].slice(0, 1).toUpperCase() + Object.keys(sentence)[0].slice(1)}
                        </div>
                        <div>{sentence[Object.keys(sentence)[0]]}</div>
                        <div
                          style={{
                            color: sentence.isSelected ? "#6a097d" : "grey",
                            // color: sentence.isSelected ? "black" : "grey",
                            marginTop: "5px",
                          }}
                        >
                          {Object.keys(sentence)[1].slice(0, 1).toUpperCase() + Object.keys(sentence)[1].slice(1)}
                        </div>
                        <div>{sentence[Object.keys(sentence)[1]]}</div>
                      </div>
                      <div>
                        <Tooltip
                          title={copyText}
                          onMouseLeave={() => setCopyText("Copy!")}
                          // onMouseOut={() => setCopyText('Copy!') }

                          onClick={() => {
                            setCopyText("Copied!");
                          }}

                        >
                          <img
                            src={CopyIcon}
                            alt=""
                            width={"18px"}
                            style={{ cursor: "pointer", opacity: "0.6" }}
                            onClick={() => {
                              setCopyText("Copied!");
                              navigator.clipboard
                                .writeText(`${Object.keys(sentence)[0].replace('_', ' ')} : ${sentence[Object.keys(sentence)[0]]}, ${Object.keys(sentence)[1].replace('_', ' ')} : ${sentence[Object.keys(sentence)[1]]}`)
                                .then(() => {
                                  setOpen(true);
                                  // alert("Text copied to clipboard: " + sentence);
                                })
                                .catch((err) => {
                                  console.error("Failed to copy text: ", err);
                                });
                              setCopiedAiResponse(sentence);
                            }}
                          />
                        </Tooltip>
                        <img
                          onClick={() => handleSelection(sentence, index)}
                          src={
                            sentence.isSelected
                              ? radioButtonSelectedImg
                              : radioButtonImg
                          }
                          alt=""
                          width={"22px"}
                          style={{ marginLeft: "15px", cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                  )}
                </>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};
var mappingPromptJSON = {
  Custom: {
    key: "phrase",
    prompt_id: 7,
  },
  Improve: {
    key: "phrase",
    prompt_id: 5,
  },
  Shorten: {
    key: "phrase",
    prompt_id: 6,
  },
  Simplify: {
    key: "phrase",
    prompt_id: 7,
  },
  ISS: {
    key: "phrase",
    prompt_id: 8,
  },
  "Blog Post Ideas": {
    key: "phrase",
    prompt_id: 74,
  },
  "Video Hooks": {
    key: "phrase",
    prompt_id: 83,
  },
  "Ads": {
    // google ads, temporary changed for make only one ads call
    key: "phrase",
    prompt_id: 84,
  },
  "Facebook Ad": {
    key: "phrase",
    prompt_id: 85,
  },
};
var getResult = mappingPromptJSON["improve"];

const TabPanel = ({
  value,
  index,
  userAddedData,
  setUserAddedData,
  userSelectedPrompt,
  setUserSelectedPrompt,
  inputFieldDetail,
  setIsRecaptchaVerified,
  isRecaptchaVerified,
  // promptList,
  // templateGenericData,
  // GetPromptListAndTemplateGenericData
}) => {
  const { setUserData, state, setPromptList, setTemplateGenericData, setRouteSourceListData, setOutputFormatGenericListData } = useContext(MainContext)
  console.log("stateeee", state)
  const [selectedTab, setSelectedTab] = useState(0);
  const [displaySuggetionBox, setDisplaySuggetionBox] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [userTypedData, setUserTypedData] = useState([]);
  const [isRecaptchaPopupOpen, setIsRecaptchaPopupOpen] = useState(false);
  const [apiResponceData, setApiResponceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  // const [userData, setUserData] = useState(null);
  const [currentSelected, setCurrentSelected] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [creditExpired, setCreditExpired] = useState(false);
  // const [promptList, setPromptList] = useState([])
  // const [templateGenericData, setTemplateGenericData] = useState([])
  console.log("userData", state.userData)
  // const [promptList, setPromptList] = useState([])
  console.log("promptList---", state.promptList)
  console.log("userSelectedPrompt---", userSelectedPrompt)
  console.log("templateGenericData----", state.templateGenericData)
  const navigate = useNavigate();

  const GetPromptList = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_CALL_URL + "/ai/get-prompt-list",
        {
          credentials: "include",
        }
      );
      console.log("response of prompt list----", response)
      if (!response.ok) {
        // setIsRecaptchaPopupOpen(true);
        // setUserData(null);
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      console.log("jsonData", jsonData);

      if (jsonData?.status) {
        setPromptList(jsonData?.data);
        return jsonData?.data
        // HandleButton();
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const GetRouteSourceList = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_CALL_URL + "/global/get-route-source-list",
        {
          credentials: "include",
        }
      );
      console.log("response of prompt list----", response)
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      console.log("jsonData", jsonData);

      if (jsonData?.status) {
        setRouteSourceListData(jsonData?.data);
        return jsonData?.data
        // HandleButton();
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  const GetOutputFormatGenericListData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_CALL_URL + "/global/get-output-format-generic-list",
        {
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      console.log("jsonData", jsonData);

      if (jsonData?.status) {
        setOutputFormatGenericListData(jsonData?.data);
        return jsonData?.data
        // HandleButton();
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  const GetTemplateGenericData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_CALL_URL + "/template/get-template-generic-data",
        {
          credentials: "include",
        }
      );
      console.log("response of prompt list----", response)
      if (!response.ok) {
        // setIsRecaptchaPopupOpen(true);
        // setUserData(null);
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      console.log("jsonData", jsonData);

      if (jsonData?.status) {
        setTemplateGenericData(jsonData?.data);
        return jsonData?.data
        // HandleButton();
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  const TemplatePromptSyncData = [
    {
      templateName: "Blog post ideas",
      template_id: 68,
      prompt_id: 74,
      mandatory_field_id: 159
    },
    {
      templateName: "Video Hooks",
      template_id: 81,
      prompt_id: 83,
      mandatory_field_id: 186
    },
    {
      templateName: "Ads", // google ads, temporary changed for make only one ads call
      template_id: 82,
      prompt_id: 84,
      mandatory_field_id: 191
    },
    {
      templateName: "Facebook AD",
      template_id: 84,
      prompt_id: 85,
      mandatory_field_id: 198
    }
  ]

  // commented this to avoid prompt  id to set in userSelectedPrompt
  // useEffect(() => {
  //   debugger;
  //   let prompt;
  //   if (inputFieldDetail?.options?.length > 0) {
  //     prompt = mappingPromptJSON[inputFieldDetail?.options[0]]["prompt_id"];
  //     setUserSelectedPrompt(prompt);
  //   } else {
  //     if (userSelectedPrompt) {
  //       console.log("mappingPromptJSON?.[userSelectedPrompt]?.prompt_id", mappingPromptJSON?.[userSelectedPrompt]?.prompt_id)
  //       setUserSelectedPrompt(
  //         mappingPromptJSON?.[userSelectedPrompt]?.prompt_id
  //       );
  //     }
  //   }
  // }, []);

  useEffect(() => {
    // Apply the style to disable scrolling when isLoading is true
    if (documentLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling when isLoading is false
      document.body.style.overflow = 'auto';
    }
  }, [documentLoading]);


  const GetUserDataAPI = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_CALL_URL + "/user/get-user-detail",
        {
          credentials: "include",
        }
      );

      if (!response.ok) {
        setIsRecaptchaPopupOpen(true);
        setUserData(null);
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      console.log("jsonData", jsonData);

      if (jsonData?.status) {
        setUserData(jsonData?.data[0]);
        HandleButton();
        // GetPromptListAndTemplateGenericData()
      } else {
      }
    } catch (error) {
      setIsRecaptchaPopupOpen(true);
      setUserData(null);
      console.log("error", error);
    }
  };

  async function PromptCallApi(prompt_id, data, userSelectedPromptOfficialName, userSelectedPrompt, templateGenericData, responseSourceList, outputFormatGenericList) {
    console.log(data);
    setLoading(true);
    const url =
      process.env.REACT_APP_API_CALL_URL + "/global/execute-general-ai";
    if (data[1]) {
      data[1].value = userSelectedPrompt
    } else {
      data.push({ key: "tone_of_voice", value: userSelectedPrompt },
        // { key: "language", value: "English" }
      )
    }
    console.log("dataaaaaa---------", data)
    let payload = {
      route_source_id: responseSourceList.find((routeSource) => routeSource.route_source_name == "Website").route_source_id ?? 1,
      output_format_id: outputFormatGenericList.find((outputFormat) => outputFormat.output_format_name == "JSON").output_format_id ?? 1,
      prompt_id: prompt_id,
      data: data,
    };
    switch (userSelectedPromptOfficialName) {
      case "Blog Post Ideas": {
        const template = templateGenericData.find((template) => template.prompt_name === userSelectedPromptOfficialName
        );
        const dynamicTemplateFormFieldId = template.template_form_field_data[0].template_form_field_id;
        payload = {
          // prompt_id: prompt_id,
          route_source_id: responseSourceList.find((routeSource) => routeSource.route_source_name == "Website").route_source_id ?? 1,
          output_format_id: outputFormatGenericList.find((outputFormat) => outputFormat.output_format_name == "JSON").output_format_id ?? 1,
          prompt_id: templateGenericData.find((template) => template.prompt_name == userSelectedPromptOfficialName).prompt_id,
          document_uuid: "-1",
          // language: "English",
          template_form_field_data: {
            [`template_form_field_id--${dynamicTemplateFormFieldId}`]: data[0].value,
          },
        };
        break;
      }
      case "Video Hook & Introduction": {
        const template = templateGenericData.find((template) => template.prompt_name === userSelectedPromptOfficialName);
        const dynamicTemplateFormFieldId = template.template_form_field_data[0].template_form_field_id;
        payload = {
          // prompt_id: prompt_id,
          route_source_id: responseSourceList.find((routeSource) => routeSource.route_source_name == "Website").route_source_id ?? 1,
          output_format_id: outputFormatGenericList.find((outputFormat) => outputFormat.output_format_name == "JSON").output_format_id ?? 1,
          prompt_id: templateGenericData.find((template) => template.prompt_name == userSelectedPromptOfficialName).prompt_id,
          // language: "English",
          document_uuid: "-1",
          template_form_field_data: {
            [`template_form_field_id--${dynamicTemplateFormFieldId}`]: data[0].value,
          },
        };
        break;
      }
      case "Google Ads Headline and Content": {
        const template = templateGenericData.find((template) => template.prompt_name === userSelectedPromptOfficialName);
        const dynamicTemplateFormFieldId = template.template_form_field_data[0].template_form_field_id;
        payload = {
          // prompt_id: prompt_id,
          output_format_id: outputFormatGenericList.find((outputFormat) => outputFormat.output_format_name == "JSON").output_format_id ?? 1,
          route_source_id: responseSourceList.find((routeSource) => routeSource.route_source_name == "Website").route_source_id ?? 1,
          prompt_id: templateGenericData.find((template) => template.prompt_name == userSelectedPromptOfficialName).prompt_id,
          // language: "English",
          document_uuid: "-1",
          template_form_field_data: {
            [`template_form_field_id--${dynamicTemplateFormFieldId}`]: data[0].value,
          },
        };
        break;
      }
      case "Facebook Ads Headline and Content": {
        const template = templateGenericData.find((template) => template.prompt_name === userSelectedPromptOfficialName);
        const dynamicTemplateFormFieldId = template.template_form_field_data[0].template_form_field_id;
        payload = {
          // prompt_id: prompt_id,
          output_format_id: outputFormatGenericList.find((outputFormat) => outputFormat.output_format_name == "JSON").output_format_id ?? 1,
          route_source_id: responseSourceList.find((routeSource) => routeSource.route_source_name == "Website").route_source_id ?? 1,
          prompt_id: templateGenericData.find((template) => template.prompt_name == userSelectedPromptOfficialName).prompt_id,
          // language: "English",
          document_uuid: "-1",
          template_form_field_data: {
            [`template_form_field_id--${dynamicTemplateFormFieldId}`]: data[0].value,
          },
        };
        break;
      }
      default: {
      }
    }
    // prior code of getting payload by prompt id
    // switch (prompt_id) {
    //   case 74: {
    //     payload = {
    //       prompt_id: prompt_id,
    //       document_uuid: "-1",
    //       template_form_field_data: {
    //         "template_form_field_id--159": data[0].value,
    //       },
    //     };
    //     break;
    //   }
    //   case 83: {
    //     payload = {
    //       prompt_id: prompt_id,
    //       document_uuid: "-1",
    //       template_form_field_data: {
    //         "template_form_field_id--186": data[0].value,
    //       },
    //     };
    //     break;
    //   }
    //   case 84: {
    //     payload = {
    //       prompt_id: prompt_id,
    //       document_uuid: "-1",
    //       template_form_field_data: {
    //         "template_form_field_id--191": data[0].value,
    //       },
    //     };
    //     break;
    //   }
    //   case 85: {
    //     payload = {
    //       prompt_id: prompt_id,
    //       document_uuid: "-1",
    //       template_form_field_data: {
    //         "template_form_field_id--198": data[0].value,
    //       },
    //     };
    //     break;
    //   }
    //   default: {
    //   }
    // }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      setDisplaySuggetionBox(false);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();

    if (responseData.error) {
      setDisplaySuggetionBox(false);
      setCreditExpired(true);
    }
    setLoading(false);
    setShowLoader(false);
    return responseData;
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function MakeUserApiCall(token) {
    const response = fetch(process.env.REACT_APP_API_CALL_URL + "/user/create-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",

      body: JSON.stringify({
        google_recaptcha_token: token,
      }),
    })
      .then(async (response) => response.json()) // Assuming server responds with json
      .then(async (data) => {
        console.log("Success:", data);
        // await GetPromptListAndTemplateGenericData()
        HandleButton();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  async function HandleButton() {
    debugger;
    // await GetPromptListAndTemplateGenericData()
    let responsePromptList = state.promptList
    let responseTemplateGenericData = state.templateGenericData
    let responseSourceList = state.routeSourceList
    let outputFormatGenericList = state.outputFormatGenericList
    if (state.promptList.length == 0 || state.templateGenericData.length == 0 || state.outputFormatGenericList.length == 0 || state.routeSourceList.length == 0) {
      responseSourceList = await GetRouteSourceList();
      responsePromptList = await GetPromptList();
      responseTemplateGenericData = await GetTemplateGenericData();
      outputFormatGenericList = await GetOutputFormatGenericListData();
    }
    const tempData = [
      {
        key: "phrase",
        value: userAddedData,
      },
    ];
    setUserTypedData(tempData);
    setDisplaySuggetionBox(true);

    let temp = [];
    let userSelectedPromptOfficialName = ""
    if (userSelectedPrompt == "Blog Ideas") {
      userSelectedPromptOfficialName = "Blog Post Ideas"
    } else if (userSelectedPrompt == "Video Hooks") {
      userSelectedPromptOfficialName = "Video Hook & Introduction"
    } else if (userSelectedPrompt == "Ads") {
      userSelectedPromptOfficialName = "Google Ads Headline and Content"
    } else {
      userSelectedPromptOfficialName = "Paraphrase"
    }
    let prompt_id = responsePromptList.find((prompt) => prompt.prompt_name == userSelectedPromptOfficialName).prompt_id
    console.log("prompt_id", prompt_id);
    PromptCallApi(prompt_id, tempData, userSelectedPromptOfficialName, userSelectedPrompt, responseTemplateGenericData, responseSourceList, outputFormatGenericList)
      .then((res) => {
        if (res.status) {
          //* Header and description is available
          if (Object.keys(res.data[0]).length > 1) {
            res?.data?.map((el, index) => {
              if (index == 0) {
                temp.push({
                  ...el,
                  // header: el[Object.keys(el)[0]],
                  // description: el[Object.keys(el)[1]],
                  isSelected: true,
                });
              } else {
                temp.push({
                  ...el,
                  // header: el[Object.keys(el)[0]],
                  // description: el[Object.keys(el)[1]],
                  isSelected: false,
                });
              }
            });
          }
          //* only header is available
          else {
            res?.data?.map((el, index) => {
              if (index == 0) {
                temp.push({
                  ...el,
                  // header: el[Object.keys(el)[0]],
                  // header: el[Object.keys(el)[0]],
                  isSelected: true,
                });
              } else {
                temp.push({
                  ...el,
                  // header: el[Object.keys(el)[0]],
                  isSelected: false,
                });
              }
            });
          }

          console.log("data", temp);
          setApiResponceData(temp);
        }
      })
      .catch((err) => console.log("err", err));
  }

  function HandleUserTyping(e) {
    setCharacterCount(e.target.value.length);
    setUserAddedData(e.target.value);
  }

  useEffect(() => {
    if (displaySuggetionBox) {
      let userSelectedPromptOfficialName = ""
      if (userSelectedPrompt == "Blog Ideas") {
        userSelectedPromptOfficialName = "Blog Post Ideas"
      } else if (userSelectedPrompt == "Video Hooks") {
        userSelectedPromptOfficialName = "Video Hook & Introduction"
      } else if (userSelectedPrompt == "Ads") {
        userSelectedPromptOfficialName = "Google Ads Headline and Content"
      } else {
        userSelectedPromptOfficialName = "Paraphrase"
      }
      let prompt_id = state.promptList.find((prompt) => prompt.prompt_name == userSelectedPromptOfficialName).prompt_id
      console.log("prompt_id", prompt_id);
      PromptCallApi(prompt_id, userTypedData, userSelectedPromptOfficialName, userSelectedPrompt, state.templateGenericData, state.routeSourceList, state.outputFormatGenericList)
      // PromptCallApi(userSelectedPrompt, userTypedData, "");
    }
  }, [userSelectedPrompt]);

  const ExecutePromptAPI = async (payload, currentActiveTemplateData) => {
    await fetch(process.env.REACT_APP_API_CALL_URL + "/ai/execute-prompt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",

      body: JSON.stringify(payload)
    })
      .then((response) => response.json()) // Assuming server responds with json
      .then((data) => {
        console.log("apiResponceData-------", apiResponceData)
        console.log("Success of execute prompt:----", data);
        console.log("can redirect to web app now")
        console.log(`${process.env.REACT_APP_WEB_APP_REDIRECT_URL}/doc/${payload.document_uuid}/3/${currentActiveTemplateData.template_id}`, "url-- -")
        window.open(`${process.env.REACT_APP_WEB_APP_REDIRECT_URL}/doc/${payload.document_uuid}/3/${currentActiveTemplateData.template_id}`, '_blank');
        // redirect
      })
      .catch((error) => {
        console.error("Error: of execute prompt", error);
      });
    setDocumentLoading(false);
  }
  const UpdateDocFormSubmissionData = async (payload, currentActiveTemplate, currentActiveTemplateData) => {
    await fetch(process.env.REACT_APP_API_CALL_URL + "/document/update-document-form-detail", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",

      body: JSON.stringify(payload)
    })
      .then((response) => response.json()) // Assuming server responds with json
      .then((data) => {
        console.log("Success of update doc form:----", data);
        const executePromptPayload = {
          document_uuid: payload.document_uuid,
          prompt_id: currentActiveTemplateData.prompt_id
        }
        console.log("payload of execute prompt")
        ExecutePromptAPI(executePromptPayload, currentActiveTemplateData)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const CreateDocApi = async (payload, currentActiveTemplate, currentActiveTemplateData) => {
    await fetch(process.env.REACT_APP_API_CALL_URL + "/document/create-document", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",

      body: JSON.stringify(payload)
    })
      .then((response) => response.json()) // Assuming server responds with json
      .then((data) => {
        console.log("apiResponceData-------", apiResponceData)
        console.log("Success of create doc:----", data);
        let isSelectValArray = apiResponceData.filter((suggestions) => suggestions.isSelected == true);
        const concatenatedStrings = isSelectValArray.map(item => {
          const concatenatedString = Object.keys(item)
            .filter(key => key !== 'isSelected')
            .map(key => `${key.charAt(0).toUpperCase()}${key.slice(1)}: ${item[key]}`)
            .join('. ');
          return concatenatedString;
        });
        console.log()
        const updateDocFormPayload = {
          document_uuid: data.data[0].document_uuid,
          document_form_submission_id: data.data[0].document_step_data[0].document_form_submission_data[0].document_form_submission_id,
          document_form_field_submission_data: [{
            // // document_form_field_submission_data_id: currentActiveTemplate.mandatory_field_id,
            document_form_field_submission_data_id: data.data[0].document_step_data[0].document_form_submission_data[0].document_form_field_submission_data.filter((obj) => obj.template_form_field_id == currentActiveTemplateData.template_form_field_data[0].template_form_field_id)[0].document_form_field_submission_data_id,
            document_form_field_submission_data_value: concatenatedStrings[0]
          }]
        }
        console.log("payload of update doc form", updateDocFormPayload)
        UpdateDocFormSubmissionData(updateDocFormPayload, currentActiveTemplate, currentActiveTemplateData)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleCreateDoc = (userSelectedPrompt) => {
    if (userSelectedPrompt == "Improve" || userSelectedPrompt == "Shorten" || userSelectedPrompt == "Simplify" || userSelectedPrompt == "Rewrite") {
      setDocumentLoading(false);
      window.open(`${process.env.REACT_APP_WEB_APP_REDIRECT_URL}/dashboard/paraphraser`, '_blank');
    } else {
      let userSelectedPromptOfficialName = ""
      if (userSelectedPrompt == "Blog Ideas") {
        userSelectedPromptOfficialName = "Blog Post Ideas"
      } else if (userSelectedPrompt == "Video Hooks") {
        userSelectedPromptOfficialName = "Video Hook & Introduction"
      } else if (userSelectedPrompt == "Ads") {
        userSelectedPromptOfficialName = "Google Ads Headline and Content"
      } else {
        userSelectedPromptOfficialName = "Paraphrase"
      }
      let prompt_id = state.promptList.find((prompt) => prompt.prompt_name == userSelectedPromptOfficialName).prompt_id
      let currentActiveTemplateData = state.templateGenericData.find((template) => template.prompt_name == userSelectedPromptOfficialName)

      let currentActiveTemplate = state.templateGenericData.find((template) => template.prompt_name == userSelectedPromptOfficialName).template_id
      const payload = {
        template_id: currentActiveTemplate
      }
      CreateDocApi(payload, currentActiveTemplate, currentActiveTemplateData)
      console.log("selectedTab", selectedTab)
      console.log("userSelectedPrompt", userSelectedPrompt)

    }
  }

  return (
    <div className={documentLoading && 'redirect-to-dashboard'}>
      {
        documentLoading && <div className="overlay" />
      }
      {
        documentLoading && <DocumentLoader />
      }
      <div
        className="tabpanel-main-div"
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        <div className="tabpanel-inner-box">
          <div
            className={
              displaySuggetionBox
                ? "tabpanel-text-panel-div-without-bottom-border-radius"
                : "tabpanel-text-panel-div"
            }
          >
            {!creditExpired ? (
              <>
                <textarea
                  className="tabpanel-text-box"
                  maxLength={"250"}
                  placeholder={inputFieldDetail.placeholder}
                  onChange={HandleUserTyping}
                ></textarea>
                <div style={{ flexDirection: "row" }}>
                  <div className="character-count">
                    {characterCount >= 250 && <>{characterCount}/250 </>}
                  </div>

                  <div
                    className={
                      displaySuggetionBox
                        ? "button-at-bottom-of-textarea-without-border-radius"
                        : "button-at-bottom-of-textarea"
                    }
                  >
                    <button
                      type="button"
                      className="text-area-left-side-button"
                      onClick={() => {
                        setShowLoader(true);
                        setInitialLoading(true);
                        if (state.userData) {
                          HandleButton();
                        } else {
                          GetUserDataAPI();
                        }
                        setInitialLoading(false);
                      }}
                      disabled={characterCount <= 0 || showLoader}
                    >
                      {
                        !showLoader ? <img
                          src={writewizStarWhite}
                          style={{ width: "20px" }}
                          alt=""
                        />
                          :
                          <CircularProgress color="secondary" size={22} />
                      }


                      <span>{inputFieldDetail.leftButtonText}</span>
                    </button>

                    <RecaptchaPopup
                      open={isRecaptchaPopupOpen}
                      setRecaptchaToken={setRecaptchaToken}
                      HandleButton={HandleButton}
                      MakeUserApiCall={MakeUserApiCall}
                      onVerify={() => {
                        setIsRecaptchaVerified(true);
                        setIsRecaptchaPopupOpen(false);
                      }}
                      onClose={() => {
                        setIsRecaptchaPopupOpen(false);
                      }}
                    />

                    {/* {!displaySuggetionBox && (
                <button
                  className="use-suggetion-button-inside-textbox"
                  style={{ margin: "0px 0px" }}
                >
                  {inputFieldDetail.rightButtonText}
                  <img
                    src={leftArrowInsideButton}
                    style={{ marginLeft: "5px" }}
                    alt=""
                  />
                </button>
              )} */}

                    {!loading && displaySuggetionBox && <button className="use-suggetion-button" onClick={async () => {
                      setDocumentLoading(true);
                      await handleCreateDoc(userSelectedPrompt);
                      // setDocumentLoading(false); 
                    }}
                    >
                      {inputFieldDetail?.rightButtonText}
                      <img
                        src={leftArrowInsideButton}
                        style={{ marginLeft: "5px" }}
                        alt=""
                      />
                    </button>}
                  </div>
                </div>
              </>
            )
              :
              (
                <div className="creditExpired-container">
                  <div className="creditExpired-text">Enjoying Writewiz?</div>
                  <div className="creditExpired-subtext">Get started with Writewiz AI for free</div>
                  <div style={{ margin: "auto" }}>
                    <button
                      type="button"
                      className="text-area-left-side-button"
                    >
                      <a href={process.env.REACT_APP_REGISTER_PAGE} target="_blank" style={{ textDecoration: 'none', color: "white", display: "flex", alignItems: "center" }}>
                        <img src={writewizStarWhite} style={{ marginRight: "10px", width: "20px" }} alt="" />
                        Get Writewiz AI, it's free
                      </a>
                    </button>
                  </div>
                </div>
              )
            }
          </div>
          {displaySuggetionBox && (
            <div className="tab-panel-suggetion-box">
              {/* {  && (
  */}
              {inputFieldDetail?.options?.length > 0 && !initialLoading && (
                <div className="tabpanel-tab-and-button">
                  <Tabs
                    className="tabpanel-tab-and-button"
                    value={selectedTab}
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: {
                        height: "3px",
                        // width: "100%",
                        width: "98px",
                        // marginLeft: "calc((100% - 300px) / 6)",
                        // marginLeft: `calc(18.25% - ${110 / 2}px)`,
                        borderRadius: "5px 5px 0 0",
                        backgroundColor: "#6A097D",
                        // color: "#6A097D",
                      },
                    }}
                    sx={{
                      "$Mui-selected": {
                        color: "red",
                      },
                      "$MuiTabs-root": {
                        marginBottom: "0px",
                      },
                    }}
                    textColor="inherit"
                    indicatorColor="none"
                    variant="fullWidth"
                  >
                    {inputFieldDetail?.options?.map((e, index) => {
                      console.log(
                        "++++++++++++++++++",
                        inputFieldDetail?.options
                      );

                      return (
                        <Tab
                          label={e}
                          icon={<img src={inputFieldDetail?.optionIcons?.[index++]} />}
                          iconPosition="start"
                          sx={{ textTransform: "none", paddingLeft: "0", justifyContent: "start" }}
                          onClick={() =>
                            setUserSelectedPrompt(e)
                            // setUserSelectedPrompt(
                            //   mappingPromptJSON[e]["prompt_id"]
                            // )
                          }
                        />
                      );
                    })}
                  </Tabs>
                </div>
              )}
              {/* <button className="use-suggetion-button" onClick={handleCreateDoc}>
                {inputFieldDetail?.rightButtonText}
                <img
                  src={leftArrowInsideButton}
                  style={{ marginLeft: "5px" }}
                  alt=""
                />
              </button> */}
              {/* )} */}
              <ShowSelectedTabData
                index={selectedTab}
                apiResponceData={apiResponceData}
                setLoading={setLoading}
                loading={loading}
              />
              {/* {a === 1 && (
              <div value={a} index={1}>
                hello
              </div>
            )}
            {a === 2 && (
              <div value={a} index={2}>
                hello
              </div>
            )}
            {a === 3 && (
              <div value={a} index={3}>
                hello
              </div>
            )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TabPanel;
