import React from 'react'
import './TopPanel.css'
import { Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const TopPanel = () => {

  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} className='arp-head-main-item'>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={11} className="arp-main-head-item">
              <Typography
                component={"h2"}
                className='arp-main-head'
              >Help Article</Typography>
            </Grid>

            <Grid item xl={8} lg={9} md={9} sm={9} xs={11} className='arp-subtitle-text-item'>
              <Typography
                component={'span'}
                className='arp-subtitle-text'
              >Hi there, how can we help you today?</Typography>
            </Grid>

            <Grid item xl={5} lg={6} md={7} sm={8} xs={11} className='arp-search-item'>
              <TextField
                id="search"
                type="search"
                className='arp-searchbar'
                placeholder='Search Artical'
                value={searchTerm}
                onChange={handleChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="center" sx={{ mr: 2 }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent', // Remove border
                      // boxShadow: 'none',         // Remove box-shadow if any
                    },
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  )
}

export default TopPanel